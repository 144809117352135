import { store } from '../store';
import * as constants from '../constants';
import { API } from "aws-amplify";
import { HandleApiError } from '../libs/apiHelper'
import * as pricingHelper from '../libs/pricingHelper'

export function reqeustGoogleReauth(value) {

  return store.dispatch((dispatch) => {
    dispatch({
      type: constants.REQUEST_GOOGLE_REAUTH,
      value
    });
  })
}

export function loadSpecsPromise(dispatch) {
  return new Promise((resolve, reject) => {
    let path = '/jobs/pricingSpecs';
  
    API.get("pricing", path).then(
      response => { dispatch({
        type: constants.RECEIVE_GET_PRICING_SPECS,
        specs: response["data"]
      });
      resolve();},
      error =>{
        dispatch({
          type: constants.RECEIVE_GET_PRICING_SPECS_ERROR,
          error,
          receivedAt: Date.now()
        })
        console.log(error);
        HandleApiError(error);
        reject();
      }
      )
  });
  
}

export function loadSpecs() {
  
  store.dispatch( (dispatch, getState) => {
 
    loadSpecsPromise(dispatch);

    // let path = '/jobs/pricingSpecs';
  
    //  API.get("pricing", path).then(
    //    response => dispatch({
    //      type: constants.RECEIVE_GET_PRICING_SPECS,
    //      specs: response["data"]
    //    }),
    //    error =>{
    //      dispatch({
    //        type: constants.RECEIVE_GET_PRICING_SPECS_ERROR,
    //        error,
    //        receivedAt: Date.now()
    //      })
    //      console.log(error);
    //      HandleApiError(error);
    //    }
    //    )
   });
}

export function fetchPricingPagesPromise( pricebookId, dispatch, getState) { 

  return new Promise((resolve, reject) => {
    
    if (!pricebookId) {
      const pricebook = getState().pricebook;
      if (pricebook && pricebook.json) {
        pricebookId = pricebook.json.id;
      }
    } 
    
    console.log('pricebookId',pricebookId);
    if (pricebookId) {

      const path = '/pricing/' + pricebookId;
      dispatch({ type: constants.REQUEST_FETCH_PRICING_PAGES })
    
      API.get("pricing", path).then(
        response => { 
          dispatch({ data: response["data"], type: constants.RECEIVE_FETCH_PRICING_PAGES }) 
          resolve(response["data"]);
        },
        error  => {
          HandleApiError(error);  
          dispatch({ error, type: constants.RECEIVE_FETCH_PRICING_PAGES_ERROR })
          reject(error);
        }
      ) 
    } else {
      resolve();
    }        

    

  });
}


export function fetchPricingPages(pricebookId=null) {

  store.dispatch((dispatch, getState) => {
        fetchPricingPagesPromise(pricebookId, dispatch, getState)                  
      })
  } 

export function refreshPricingSync(pricingId, callback) {

  return store.dispatch((dispatch, getState) => { 
 
    const pricebook = getState().pricebook;
    const pricebookId = pricebook.json.id;


    dispatch({ type: constants.REQUEST_PRICING_REFRESH })

    let url =  "/jobs/refreshPricingSync/" + pricebookId + "/" + pricingId;
  
    API.put("spreadsheets", url).then(
      response => {
      dispatch({ pricing: response["pricing"], type: constants.RECEIVE_GET_PRICING })
      if (callback) {
        callback();
      } 
    },
    error => {
      HandleApiError(error);
      dispatch({ data: error, type: constants.RECEIVE_PRICING_SAVE_ERROR });
    });
  });
}

export function refreshPricing(pricingId) {

  store.dispatch( (dispatch, getState) => {

   dispatch({   type: constants.REQUEST_PRICING_REFRESH  });

   const pricebookId = getState().pricebook.json.id;

   if (!pricebookId) { console.log("ERROR: pricebookId for current pricebook not set. ");}
   
   let url =  "/jobs/refreshPricing/" + pricebookId + "/" + pricingId;

   console.log('calling refreshPricing with ', url);
   API.put("spreadsheets", url).then(
     response => dispatch({
       type: constants.RECEIVE_PRICING_REFRESH,
       pricingId,
       receivedAt: Date.now()
     }),
      error => {
          HandleApiError(error);
          dispatch({
            type: constants.RECEIVE_PRICING_REFRESH_ERROR,
            pricingId,
            error,
            receivedAt: Date.now()
          })
        }
      )
  });
}
      
export function getPricing(pricingId) {


  store.dispatch( (dispatch, getState) => {

   const state = getState();
   if (!state.pricebook) {
     console.log("Pricebook reducer is not set. Aborting action getPricing");
     return;
   }

   pricingHelper.getDefaultDiscounts(state.pricebook.json.plans, state.pricebook.json.products);

   dispatch({ type: constants.REQUEST_GET_PRICING  });


   let pricebookId = state.pricebook.json.id;
   let path = pricingId ? '/pricing/' + pricebookId + '/' + pricingId : '/pricing/default/' + pricebookId;

   console.log(path);
    API.get("pricing", path).then(
      response => {
        const pricing = response["data"];
        if (!pricing.rowConfig) {
          pricing.rowConfig = pricingHelper.getDefaultPricingRowOrder(state.pricebook.json.products, state.pricebook.json.plans);
        } else {
          pricing.rowConfig = pricingHelper.mergePricingRowOrder(pricing.rowConfig, state.pricebook.json.products,  state.pricebook.json.plans);
          
        }

        dispatch({ 
        type: constants.RECEIVE_GET_PRICING,
        pricing 
      })},
      error =>{
        dispatch({
          type: constants.RECEIVE_GET_PRICING_ERROR,
          error,
          receivedAt: Date.now()
        })
        console.log(error);
        HandleApiError(error);
      }
      )
  });
}


export function clonePricing(pricingId) {

  store.dispatch( (dispatch, getState) => {

    
   let state = getState();
   let pricebookId = state.pricebook.json.id;

   dispatch({ pricebookId: pricebookId, pricingId, type: constants.REQUEST_PRICING_CLONE  });

    let url = `/pricing/clone/${pricebookId}/${pricingId}`;

    API.put("pricing", url).then(
      response => dispatch({
        type: constants.RECEIVE_PRICING_CLONE,
        pricingPage: response["data"],
        receivedAt: Date.now()
      }),
      error  => {
        HandleApiError(error); 
      
        dispatch({
          type: constants.RECEIVE_PRICING_CLONE_ERROR,
          error,
          receivedAt: Date.now()
        })
      }
    )
  });
}

export function archivePricing(pricingId, archive=true) {

  store.dispatch( (dispatch, getState) => {

   dispatch({ pricingId: pricingId, type: constants.REQUEST_PRICING_ARCHIVE, archived: archive  });

   console.log( 'archive pricing', pricingId );

   let state = getState();
   let pricebookId = state.pricebook.json.id;
   let url = (archive) ? "/pricing/archive/" + pricebookId + "/" + pricingId : "/pricing/unarchive/" + pricebookId + "/" + pricingId;

   console.log(url);
    API.put("pricing", url).then(
      response => dispatch({
        type: constants.RECEIVE_PRICING_ARCHIVE,
        pricingId: pricingId,
        archived: archive,
        receivedAt: Date.now()
      }),
      error =>
        dispatch({
          type: constants.RECEIVE_PRICING_ARCHIVE_ERROR,
          error,
          receivedAt: Date.now()
        })
      )
  });
}


