
import { v4 as uuidv4 } from 'uuid'; 
import { FREQUENCIES } from '../constants'

export async function plans() {

  // this.setState({"somevar": "Blah"});
  return "plans";
}


export function getFrequency(frequency) {

  switch (frequency) {
    case FREQUENCIES.YEARLY:
      return "year"
    case FREQUENCIES.MONTHLY:
      return "month"
    case FREQUENCIES.FREE:
      return "free"
    case FREQUENCIES.ONETIME:
      return "instance";
    default:
      return "period"
  }
}


export function getDiscountText(discount, price, frequency) {

  if (!discount) {
    return "";
  }

  let discountText = " (";
  if (discount.type === 'percentage') {
    if (discount.value === 1) {
      discountText += "free ";
    }
    else {
      discountText += "$" + (price * discount.value) + " off ";
    }
  }
  else {
    discountText = "to be implemented ";
  }

  let duration = "";
  switch (frequency) {
    case FREQUENCIES.YEARLY:
      duration = " year";
      break;
    case FREQUENCIES.MONTHLY:
      duration = " month(s)";
      break;
    case FREQUENCIES.ONETIME:
      duration = " once";
      break;
    default:
      duration = " period";

  }
  discountText += "for " + discount.duration + duration + ")";

  return discountText;
}
 
export function formatDiscountType(type, plural) {

  switch(type) {
    case 'percentage':
      return "percent";
    case 'fixed':
      return plural ? "dollars" : "dollar";
    case FREQUENCIES.YEARLY:
      return plural ? "years" : "year"; 
    case FREQUENCIES.MONTHLY:
      return plural ? "months" : "month"; 
    default:
      return type;
  }
}

export function formatFrequency(frequency, plural=false) {

  let value = plural ? "s" : "";
  switch(frequency) {
    case FREQUENCIES.MONTHLY:
      return "month" + value;
    case FREQUENCIES.YEARLY:
      return "year" + value;
    case FREQUENCIES.ONETIME:
      return "one time" + value;
    default:
      return frequency;
  }
}
 

export function formatFrequency2(charge, plural=false) {

  if (!charge) return '';
  
  let frequency = charge.frequency;
  let value = plural ? "s" : "";

  // if (charge.price === 0) { return "Free"; }

  switch(frequency) {
    case FREQUENCIES.MONTHLY:
      return "Monthly" + value;
    case FREQUENCIES.YEARLY:
      return "Yearly" + value;
    case FREQUENCIES.FREE:
        return "Free";
    case FREQUENCIES.CUSTOM:
        return "Custom";
    case FREQUENCIES.ONETIME:
      return "One Time" + value;
    default:
      return frequency;
  }
}


export function formatFrequency3(charge, plural=false) {

  if (!charge) return '';
  
  let frequency = charge.frequency;
  let value = plural ? "s" : "";

  // if (charge.price === 0) { return "Free"; }

  switch(frequency) {
    case FREQUENCIES.MONTHLY:
      return "monthly" + value;
    case FREQUENCIES.YEARLY:
      return "yearly" + value;
    case FREQUENCIES.FREE:
        return "free";
    case FREQUENCIES.CUSTOM:
        return "custom";
    case FREQUENCIES.ONETIME:
      return "one time" + value;
    default:
      return frequency;
  }
}


export function shapeValueMetrics(plan, products) {

  let allVMs = [];
  products.forEach(p => {
    p.valueMetrics.forEach(vm => {
      let value = 0;
      let vcId = null;
      if (plan.valueCharges) {
        plan.valueCharges.forEach(vc => {
          if (vc.valueMetric.id === vm.id) {
            value = vc.unitsInBase;
            vcId = vc.id;
          }
        })
      }

      if (!vcId) {
          vcId = uuidv4();
      }

      // allVMs.push(Object.assign({value: value, productId: p.id, valueChargeId : vcId}, vm));
      allVMs.push(({value: value, id: vm.id, productId: p.id, valueChargeId : vcId, unit : vm.unit}));
    })
  });

  return allVMs;
}

// export function shapePlans(plans, products) {

//   // de-normalize the model
//   let shapedPlans = [];

//   plans.forEach((plan) => {

//     if (plan) {

//       let shapedPlan = JSON.parse(JSON.stringify(plan));

//       let shapedProducts = [];
//       let addonProducts = [];

//       if (!shapedPlan.addonCharges) { shapedPlan.addonCharges = []; }
//       if (!shapedPlan.discounts) { shapedPlan.discounts = []; }
//       if (!shapedPlan.products) { shapedPlan.products = []; }
//       if (!shapedPlan.toggableFeatures) { shapedPlan.toggableFeatures = []; }

//       shapedPlan.addonCharges.forEach((addon) => {
//         // AddonProducts
//         // -> charge
//         products.forEach((fullProduct) => {
//           if (addon.active && addon.addonProductRefId === fullProduct.id) {
//             let addonProduct =  Object.assign({}, fullProduct);

//             addonProduct.charge = addon;

//             shapedPlan.discounts.forEach((discount) => {
//               if (discount.level === 'charge' && discount.chargeId === addon.id) {
//                 addonProduct.charge.discount = Object.assign({}, discount);
//               }
//             });

//             if (addon.valueMetric) {

//               fullProduct.valueMetrics.forEach((vm) => {
//                 if (vm.id === addon.valueMetric.id) {
//                   addonProduct.charge.unit = vm.unit;
//                 }
//               });
//             }

//             addonProduct.included = false;
//             shapedProducts.push(addonProduct);
//             // addonProducts.push(addonProduct);
//           }
//         });
//       });

//       shapedPlan.products.forEach((partialProduct) => {
//         products.forEach((fullProduct) => {
//           if (partialProduct.id === fullProduct.id) {

//             // match value metrics, costs, features
//             let valueCharges = [];
//             let toggableFeatures = [];
//             //fullProduct.costs = [];
//             // fullProduct.features = [];


//             // match value charges
//             // shapedPlan.valueCharges.forEach((valueCharge) => {
//             //
//             //   fullProduct.valueMetrics.forEach((valueMetric) => {
//             //     if (valueCharge.valueMetric && valueCharge.valueMetric.id == valueMetric.id) {
//             //
//             //       // clone it
//             //       let vc = Object.assign({}, valueCharge);
//             //       vc.valueMetric = valueMetric;
//             //       vc.name = vc.valueMetric.unit;
//             //       vc.units = vc.unitsInBase;
//             //       valueCharges.push(vc);
//             //     }
//             //   });
//             // });

//             fullProduct.valueMetrics.forEach((valueMetric) => {

//               let valueCharge = shapedPlan.valueCharges.find (vc =>vc.valueMetric && vc.valueMetric.id === valueMetric.id );
//               let vc = valueCharge ? Object.assign({}, valueCharge) : factory.createPlanValueCharge(valueMetric.id);

//               vc.valueMetric = valueMetric;
//               vc.name = vc.valueMetric.unit;
//               vc.units = vc.unitsInBase;

//               if (!valueCharge) { shapedPlan.valueCharges.push(vc); }
//               valueCharges.push(vc);

//             });

//             // match toggable features
//               shapedPlan.toggableFeatures.forEach((toggableFeature) => {

//               fullProduct.features.forEach((feature) => {
//                 if (toggableFeature.id === feature.id) {

//                     let f = Object.assign({}, feature);
//                     f.state = FeatureState.CHECKED;
//                     toggableFeatures.push(f);
//                 }
//               });
//             });

//             let includedFeatures = [];
//             fullProduct.features.forEach((feature) => {
//               if (!feature.toggable) {

//                   let f = Object.assign({}, feature);
//                   f.state = FeatureState.ALWAYS_ON;
//                   includedFeatures.push(f);

//               }
//             });

//             let shapedProduct = Object.assign({}, fullProduct);
//             shapedProduct.valueCharges = valueCharges;
//             shapedProduct.valueChargeUnits = valueCharges;
//             shapedProduct.toggableFeatures = toggableFeatures;
//             shapedProduct.includedFeatures = includedFeatures;
//             shapedProduct.included = true;
//             shapedProducts.push(shapedProduct);
//           }
//         });
//       });
//       shapedPlan.products = shapedProducts;
//       // shapedPlan.addonProducts = addonProducts;

//       shapedPlans.push(shapedPlan);
//     } else {
//       console.log("Found a null plan in plan shaping function.")
//     }

//   });

//   // console.log("257 shapedPlans.sort((a, b) => {return b.name < a.name });");
//   // shapedPlans.sort((a, b) => {return b.name < a.name });
//   return shapedPlans;
// }
//
// export get plans() {
//   return "plans";
// }

// let session = null;
//
// export function bind(session) {
//
//   this.session = session;
//   return session;
// }


export var data = {
  get plans() {

    this.setState({"somevar": "Blah"});

    return this.getState("somevar");
    // return "blah";
  }
};


export function timeAgo(time) {
  
  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  var time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  var seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1;

  if (seconds === 0) {
    return 'Just now'
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  let i = 0;
  let format = time_formats[i]; 
  while (format){

    if (seconds < format[0]) {
      if (typeof format[2] == 'string')
        return format[list_choice];
      else
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
    format = time_formats[i++];

  }
    
  return time;
}
