import * as constants from '../constants';
import * as pricebookSelector from '../libs/pricebookSelector'
 
export default function productPlansEdit(
  state = {
    plans: null
  },
  action
) {

  let plan = null; 
  let tempPlans = null;
  
  switch (action.type) {
    case constants.GLOBAL_LOGOUT:
      return null;
    case constants.PRODUCT_PLANS_EDIT:
      return {
        ...state,
        plans : action.plans,
        product: action.product
      };

    case constants.PRODUCT_PLANS_UPDATE_PRODUCT:

      // this assume the product is always there, no?
    
      tempPlans = [...state.plans];  
      plan = tempPlans.find(p => p.id === action.planId);
      
      if (action.value) {
        pricebookSelector.addProductToPlan(plan, action.product.id);
      } else {
        pricebookSelector.removeProductFromPlan(plan, action.product.id);
      }     
      
      return {
        plans: tempPlans,
        ...state
      };
    case constants.PRODUCT_PLANS_UPDATE_ADDON:

      tempPlans = [...state.plans]; 
      plan = tempPlans.find(p => p.id === action.planId);

      if (action.value) {
        pricebookSelector.addProductAddonToPlan(plan, action.product.id, [action.product]);
      } else {
        pricebookSelector.removeAllAddonChargesFromPlan(plan, action.product.id);
      }

      // pricebookSelector.togglePlanProductAddon(plan, action.product.id, action.value);
           
      return {
        plans: tempPlans,
        ...state
      };
    case constants.PRODUCT_PLANS_UPDATE_FEATURE:

      tempPlans = [...state.plans]; 
      plan = tempPlans.find(p => p.id === action.planId);
      
      if (action.value) {
        pricebookSelector.addPlanProductToggableFeature (plan, action.id);
      } else {
        pricebookSelector.removePlanProductToggableFeature(plan, action.id);
      }
      
      return {
        plans: tempPlans,
        ...state
      };
 
    case constants.PRODUCT_PLANS_UPDATE_VC:

      tempPlans = [...state.plans]; 
      plan = tempPlans.find(p => p.id === action.planId);

      pricebookSelector.editPlanProductValueCharge(plan, action.id, 'unitsInBase', action.value);
 
      return {
        plans: tempPlans,
        ...state
      };
    
    case constants.PRODUCT_PLANS_UPDATE_MS:

      tempPlans = [...state.plans]; 
      plan = tempPlans.find(p => p.id === action.planId);
      pricebookSelector.selectPlanProductMultioption(plan, action.id, action.value);
      
      return {
        plans: tempPlans,
        ...state
      };

    default:
      return state;
  }
}