import React from 'react'
import PropTypes from 'prop-types' 
import NumberFormat from 'react-number-format';
import DropdownControl from "./dropdownControl"
import {  PencilIcon } from '@heroicons/react/solid'
import { formatDiscountType } from "../libs/formatHelpers"
import { connect } from 'react-redux'

const mapStateToDiscountTypeeProps = (state, ownProps) => {

  let name = '';
  if (!ownProps.discountActive) {
    name = "No Discount"
  }
  else if (ownProps.discountType  === 'percentage')  {
    name = "Percent Discount";
  } else if (ownProps.discountType  === 'fixed')  {
    name = "Dollar Discount";
  } else {
    name = "No Discount"
  }
  console.log(ownProps.discountType); 

  return  {
    items: [{name:'No Discount', key: 'none'}, {name:'Percent Discount', key: 'percent'},  {name:'Dollar Discount', key: 'dollar'}],
    currentItem: name
  }
}

const mapDispatchToDiscountTypeeProps = (dispatch,ownProps) => {
  return {
    // dispatching plain actions
    onSelect: (eventKey, event) => {

      console.log(eventKey)
 
      switch (eventKey) {
        case 'dollar':
          ownProps.editHandler(ownProps.discountId, 'add') 
          ownProps.editHandler(ownProps.discountId, 'type', 'fixed')
          break;
        case 'percent':
            ownProps.editHandler(ownProps.discountId, 'add') 
            ownProps.editHandler(ownProps.discountId, 'type', 'percentage')
            break;
        case 'none':
          ownProps.editHandler(ownProps.discountId, 'remove')
          break;
        default:
          break;
      }

      
    }
  }
}

const DiscountType = connect(
  mapStateToDiscountTypeeProps,
  mapDispatchToDiscountTypeeProps )(DropdownControl)

const EditDiscount = ({ 
  frequency,
  discountActive=false,
  discountValue,
  discountType,
  discountDuration,
  discountId, 
  editChargeDiscountFieldHandler}) => (
    <>
      <div className="flex  justify-start   text-center max-w-3xl  ">
        <DiscountType discountActive={discountActive} discountId={discountId} discountType={discountType} editHandler={editChargeDiscountFieldHandler}/> 
      
        {(discountActive) &&
          <>  

            {/* amount */}
            <NumberFormat
              id="discountAmountControl"
              className="text-center	 shadow-sm mx-6 focus:ring-indigo-500  focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
      
              thousandSeparator={false}
              prefix={discountType === 'fixed' ? '$' : ''}
              suffix={discountType === 'percentage' ? '%' : ''}
              value={discountValue}
              decimalScale={2}
              isAllowed={values => { const {formattedValue, value} = values;
              return (discountType === 'percentage') ? value >= 0 && value <= 100 : value >= 0 && value <= 1000000000 } }
              onValueChange={values => {
                const {formattedValue, value} = values;
                editChargeDiscountFieldHandler(discountId, 'value', value);
              }}
            />
            <PencilIcon className="h-5 w-5 m-auto " aria-hidden="true" />

            {/* duration */}
            <div className="flex px-4 align-middle	m-auto">for</div>
            <NumberFormat
                id="discountDurationControl"
                thousandSeparator={false}
                className="shadow-sm mx-6 text-center	 focus:ring-indigo-500  focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
      
                autoFocus
                value={discountDuration}
                decimalScale={1}
                isAllowed={values => { const {formattedValue, value} = values; return value >= 0 &&  value <= 100} }
                onValueChange={values => {
                  const {formattedValue, value} = values;
                  editChargeDiscountFieldHandler(discountId, 'duration', value);
                }}
                />
                <PencilIcon className="h-5 w-5 m-auto " aria-hidden="true" />

            <div className="px-4 m-auto">{formatDiscountType(frequency, discountDuration>1)}</div>
               

          </>
        }
      </div>
        
    </>
)

EditDiscount.propTypes = {
  id: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  hidden: PropTypes.bool,
  price: PropTypes.number.isRequired,
  frequency: PropTypes.string.isRequired
}

export default EditDiscount
