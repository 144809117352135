import {
    GLOBAL_LOGOUT,
    RECEIVE_GET_SPREADSHEETS,
    RECEIVE_GET_SPREADSHEETS_SPECS,
    RECEIVE_SPREADSHEET_ARCHIVE
    
  } from '../constants'
 
  
  const initData = {
    data: null
  }

  let tempSpreadsheets = []; 
  let tempSpreadsheet = null;
  
  export default function pricebooks(
    state = initData,
    action
  ) {
    
    switch (action.type) {
  
    case GLOBAL_LOGOUT:
      return null;
    case RECEIVE_GET_SPREADSHEETS:
        return Object.assign({}, state,  {  
              data : action.spreadsheets 
          })   
    case RECEIVE_SPREADSHEET_ARCHIVE:
          tempSpreadsheets = [...state.data];   
          tempSpreadsheet = tempSpreadsheets.find( p=> p.id === action.spreadsheetId);  
          if (tempSpreadsheet) { tempSpreadsheet.active = !action.archived; }
    
          return Object.assign({}, state, {
            data: tempSpreadsheets
          })
    case RECEIVE_GET_SPREADSHEETS_SPECS:
      return Object.assign({}, state, {
        specs: action.specs
      })

    default:
      return state
    }
  }
   