/* This example requires Tailwind CSS v2.0+ */
  import { PencilIcon } from '@heroicons/react/solid'
import * as actions from '../actions/profileEditEditActions';  
import React, { Component } from "react";
import { connect } from 'react-redux'  
import TabControl from "../controls/tabControl"  
import Dropdown from "../controls/dropdownControl"
 
export class ProfileEditPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
          // currentTab: TABS.CONFIG 
        };
     
      }

      async componentDidMount() {

        let profileId = this.props.match.params.profileId;
        actions.loadProfileEdit();        
      }

      render() {
          
        return (
        <div className="max-w-7xl bg-white m-auto p-10 border rounded-md ">
        <div>
          <div className="flex justify-between	">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Editing {this.props.name}</h3>
            <div>
                <a
                  onClick={() => this.props.cancelEdit()}
                  className="font-medium px-6 text-gray-600 hover:text-gray-500"
                >Cancel</a>          
                <button
                  type="button"
                  onClick={() => actions.saveProfileEdit(() => this.props.saveEdit())}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
              Save
            </button>
            </div>
          </div>

          {/* <Button disabled={this.props.isBusy} size="lg" className="floatRight rightAlign btn btn-custom-default" onClick={() => this.props.saveEdit(this.props.cancelEdit)}>Save</Button>
         */}
        </div>
        { this.getProfileUI() }
      </div>)
      }

      getProfileUI() {
         
        
        return (
          <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
          
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Workspace</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"> 
                <div className="flex  justify-start   text-center max-w-3xl ">
                <Dropdown  
                    items={this.props.workspaces}
                    currentItem={this.props.currentWorkspace.name}
                    onSelect={(key)=> actions.setWorkspaceId(key)}
                  /> 
 
                      </div>
                    
                  </dd>
                </div>  
          </dl>
          </div>
        )
      } 

}


const mapProfileDispatchToProps = (state, ownProps) => {
  return {
    // dispatching plain actions
    // editProfileFieldHandler: (name, value) => actions.editProfileField(name, value),
    
    // tooltipHandler: (type, entryId, value) => {
    //   if (type === TOOLTIPTYPE.FEATURE) {actions.editFeatureField(entryId, 'tooltip', value)}
    //   else if (type === TOOLTIPTYPE.MULTIOPTION) {actions.editMultiOptionField(entryId, 'tooltip', value)}
    //   else { console.log("ERROR - unkonwn tooltip type")}
    // },
    saveEdit:  () => {ownProps.history.push('/pricebooks') },
    cancelEdit: () => {ownProps.history.push('/profile') }
      
  }
}
 
 

const mapProfileStateToProps = state => {

 
  const workspaces = state.user && state.user.workspaces ? state.user.workspaces.map( w => ({ name: w.tenantId, key: w.tenantId})) : [];
  const currentWorkspace = state.userEdit ? state.userEdit.workspaceId : null;

  return  { 
    name: "Profile",
    currentWorkspace: { name: currentWorkspace, id: currentWorkspace },
    workspaces
  }
}

export default connect(
  mapProfileStateToProps,
  mapProfileDispatchToProps
)(ProfileEditPage)
 
 