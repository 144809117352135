import React, { Component } from "react";   
import { connect } from 'react-redux' 
import PricebooksDropdown from '../controls/pricebookDropdown'

const mapProductStateToProps = state => {
  return  {
    isLoading: state.pricebooks ? state.pricebooks.isFetching : false   
  }
}


const mapStateToProps = (state, ownProps) => {

    if (!state.pricebooks || !state.pricebooks.items) { return { pricebookLoading: true }}
  
    return {
      pricebooks: state.pricebooks.items.filter( p => p.active),
      lastError:  state.pricebooks.lastError,
      currentName: state.pricebook && state.pricebook.json ? state.pricebook.json.name : "Select",
      pricebookLoading: state.pricebook ? state.pricebook.isLoading : false
    }
  }
  
  const ActivePricebooksDropdown = connect(
    mapStateToProps
  )(PricebooksDropdown)


export  class CheckoutPage extends Component {


  async componentDidMount() {

    // load the Google SDK
    
    const script = document.createElement("script"); 
    script.src = "https://share.pricingsaas.com/plans/342ea39c-76b4-45f0-9d67-33f0b0fb4844/loader.js?psPricing=checkout";
    script.type = 'text/javascript';
    script.async = true; 
  
    document.body.appendChild(script);
  }
  

  render() {
     
    return (
      <div className="max-w-7xl bg-white m-auto pt-10 pb-20 border rounded-md ">
   
          <div id='psPricing'>
            checkout            
          </div>
        </div>) 
  }
}


export default connect(
  mapProductStateToProps 
)(CheckoutPage)
