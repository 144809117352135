import { API } from "aws-amplify";
import { store } from '../store';
import * as constants from '../constants'; 
import { HandleApiError, prepObjectForApiCall } from "../libs/apiHelper";
import * as pricebookSelector from '../libs/pricebookSelector'

export async function setupProductPlansEdit(productId) {

    store.dispatch(async (dispatch, getState) =>  {

      let state = getState();

      if (state.pricebook && state.pricebook.json) {

        const pricebookCopy =  pricebookSelector.clonePricebookJson(state.pricebook.json);
        pricebookSelector.prepPricebookPlansForEdit(pricebookCopy);

        const product = pricebookCopy.products.find(p => p.id === productId);
        const plans = pricebookCopy.plans.filter(p => p.active);

        if (!product) { throw new Error("Invalid productId"); }

        dispatch({ type: constants.PRODUCT_PLANS_EDIT, plans: plans, product, productId} );
        
      } else {
        throw new Error("cannot load view when pricebook state not set");
      }

    })
}

export async function updateValue(planId, id, value, type, object=null) {

    store.dispatch(async (dispatch, getState) =>  {
      // console.log(planId);
      // console.log(value);
      // console.log(id);
      // console.log(type);
      // console.log(object);

      const products = getState().pricebook.json.products;

      if (type === constants.FIELD_TYPE.BOOLEAN) {
        if (object === constants.OBJECT_TYPE.PRODUCT) {
          let product = products.find(p => p.id === id);
          dispatch({ type: constants.PRODUCT_PLANS_UPDATE_PRODUCT, planId, product, id, value} );
        }
        else if (object === constants.OBJECT_TYPE.ADDON) {
          let product = products.find(p => p.id === id);
          dispatch({ type: constants.PRODUCT_PLANS_UPDATE_ADDON, planId, product, id, value} );
        }
        else {
          dispatch({ type: constants.PRODUCT_PLANS_UPDATE_FEATURE, planId, id, value} );
        }
      } else if (type === constants.FIELD_TYPE.DROPDOWN) {
        dispatch({ type: constants.PRODUCT_PLANS_UPDATE_MS, planId, id, value} );
      } else {
        dispatch({ type: constants.PRODUCT_PLANS_UPDATE_VC, planId, id, value} );
      }
    })
}

export function saveProductPlansEdit(onSuccess) {

  return store.dispatch((dispatch, getState) => {

    const pricebookId = getState().pricebook.json.id; 
    const plans = getState().productPlansEdit.plans;
    const changedPlans = [];

    plans.forEach(plan => {

      if (pricebookSelector.hasPlanChanged(plan,getState().pricebook.json )) {
        changedPlans.push(prepObjectForApiCall(plan));
        console.log(`${plan.name} has did changed. Adding`);
      }
      else {
        console.log(`${plan.name} has not changed. Skipping`);
      }
    })

    if (changedPlans.length === 0) {
      console.log('nothing has changed. returning');
      onSuccess();
      return;
    }
    
    dispatch({ type: constants.REQUEST_MAPPINGS_SAVE, plans, pricebookId });

    let url = "/plans/batch/" + pricebookId;
    let myInit = {
        body: { plans : changedPlans }
    }

    console.log(myInit);
    API.put("plans", url, myInit).then(
      response => {
        dispatch({
         type: constants.RECEIVE_MAPPINGS_SAVE,
         plans : plans,
         receivedAt: Date.now()
       })
       if (onSuccess) {
         onSuccess(response);
       }
      },
      error => {
        HandleApiError(error);

        dispatch({
         type: constants.RECEIVE_MAPPINGS_SAVE_ERROR,
         error,
         receivedAt: Date.now()
       })
      }
    );
  });
}
