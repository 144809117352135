/* This example requires Tailwind CSS v2.0+ */
import { PencilIcon } from '@heroicons/react/solid'
import * as pricebookSelector from '../libs/pricebookSelector'
import ValueMetricDropdown from "../controls/valueMetricDropdownControl"
import React, { Component } from "react";
import { connect } from 'react-redux'  
import { loadPricebookEdit, savePricebookEdit, editPricebookField, editValueMetricField, removePricebookValueMetric, addPricebookValueMetric } from '../actions/pricebookEditActions.js';
import EditValueMetricsControl from '../controls/editValueMetricsControl'

export class PricebookDetailsPage extends Component {
    constructor(props) {
        super(props);
     
      }

      async componentDidMount() {

        let pricebookId = this.props.match.params.pricebookId;
        loadPricebookEdit(pricebookId);
      }

      render() {
          return (
              <> 
                <div className="max-w-7xl bg-white m-auto p-10 border rounded-md ">
                <div>
                  <div className="flex justify-between	">
        
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Editing {this.props.name}</h3>
                    <div>
                        <a
                          onClick={() => this.props.cancelEdit()}
                          className="font-medium px-6 text-gray-600 hover:text-gray-500"
                        >Cancel</a>          
                        <button
                          type="button"
                          onClick={() => this.props.saveEdit(() => this.props.cancelEdit())}
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                        >
                      Save
                    </button>
                    </div>
                  </div>
        
                  {/* <Button disabled={this.props.isBusy} size="lg" className="floatRight rightAlign btn btn-custom-default" onClick={() => this.props.saveEdit(this.props.cancelEdit)}>Save</Button>
                 */}
                </div>
                <div className="mt-5 border-t border-gray-200">

                <dl className="sm:divide-y sm:divide-gray-200">
                    {/* name */}
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Pricebook name</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                        <div className="flex  justify-start   text-center max-w-3xl ">
                            <input
                                type="text"
                                name="name"
                                value={this.props.name}
                                onChange={(event) => {this.props.editPricebookFieldHandler('name', event.target.value)}}
                                id="name"
                                className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                                placeholder="Type pricebook name"
                                />
                            <PencilIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                            
                        </dd>
                    </div>
                    {/* description */}
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Description</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="flex  justify-start   text-center max-w-3xl ">
                        <textarea
                                type="text"
                                name="description"
                                value={this.props.description}
                                onChange={(event) => {this.props.editPricebookFieldHandler('description', event.target.value)}}
                                id="description"
                                className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                                placeholder="Type pricebook description"
                                />
                            <PencilIcon className="h-5 w-5" aria-hidden="true" />
                            </div>
                        </dd>
                    </div>
                     {/* value metrics */}
                     <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Value Metrics</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="flex  justify-start w=full   text-center max-w-3xl ">
                        
                            <PricebookValueMetricsList />
                        </div>
                        </dd>
                    </div>
                </dl>
                    
                </div>
                </div>
            </>
          )
      }

}


const mapStateToProps = (state, ownProps) => {
        
    if (!state.pricebookEdit || !state.pricebookEdit.data || !state.pricebookEdit.data.valueMetrics) return {};
    // if (!state.pricebooksPage || !state.pricebook) return {};
  
    return  {  
        items: state.pricebookEdit.data.valueMetrics.filter(vm => vm.active),      
    }
  } 
  
  
  const mapDispatchToProps = dispatch => {
    return { 
      changeHandler: (id, name, value) => editValueMetricField(id, name, value),
      deleteHandler: (id) => removePricebookValueMetric(id),
      addHandler: () => addPricebookValueMetric()
    }
  }
  
  const PricebookValueMetricsList = connect( mapStateToProps, mapDispatchToProps )(EditValueMetricsControl);


const mapPricebookDispatchToProps = (state, ownProps) => {
    
    return {
        // dispatching plain actions
        editPricebookFieldHandler: (name, value) => editPricebookField(name, value),
        saveEdit: (callback) => savePricebookEdit(callback),
        cancelEdit: () => {ownProps.history.push('/pricebooks') }
    }
}

const mapPricebookStateToProps = state => {

  if (!state.pricebookEdit || !state.pricebookEdit.data) {
       return  {}
  }

  return  {
    name: state.pricebookEdit.data.name,
    pageName: state.pricebookEdit.pageName,
    valueMetrics: state.pricebookEdit.data.valueMetrics.filter(vm => vm.active),
    lastUpdated: state.pricebookEdit.data.lastUpdated,    
    description: state.pricebookEdit.data.description,
    label: state.pricebookEdit.data.label,
    loading: state.pricebookEdit.isLoading
  }
}

export default connect(
  mapPricebookStateToProps,
  mapPricebookDispatchToProps
)(PricebookDetailsPage)
 
 