import { API } from "aws-amplify";
import { store } from '../store';
import { createPricebook } from '../libs/factory'
import * as constants from '../constants';
import { HandleApiError, prepObjectForApiCall } from "../libs/apiHelper"; 
import * as pricebookSelector from '../libs/pricebookSelector'
import { createPricebookValueMetric } from '../libs/factory'
import { fetchPricebook } from './pricebookActions'
 
export function editPricebookField(name, value) {
      return store.dispatch({
      type: constants.EDIT_PRICEBOOK_FIELD,
      name,
      value
    });
}


export function setupDefaultPricebookEdit(active=true) {
  
  return store.dispatch((dispatch, getState) => { 
 
    const state = getState();
    
    if (!state.pricebooks  ) { return; }

    const pricebooks = state.pricebooks.items;

    if (!pricebooks) { return; }

    const rpricebooks = active ? pricebooks.filter(p => p.active) : pricebooks;

    const currentPricebook =  state.pricebook ? state.pricebook.json : null;
    // 
 
    if (!currentPricebook && rpricebooks.length > 0) {
      fetchPricebook(rpricebooks[0].id);
    }


  });

}


export function loadPricebookEdit(pricebookId) {

  return store.dispatch((dispatch, getState) => {
    dispatch({
      type: constants.SET_UP_EDIT_PRICEBOOK_START
    });

    let pricebook = null;
    let state = getState();
    let newPricebook = pricebookId === 'new';

    if (newPricebook) {

        pricebook = createPricebook();
    } else { 
      if (state.pricebook && state.pricebook.json && state.pricebook.json.id === pricebookId) {     
        pricebook =  pricebookSelector.clonePricebookJson( state.pricebook.json );
      } 
    }

    if (pricebook) { 
      dispatch({
        type: constants.SET_UP_EDIT_PRICEBOOK,
        pricebook : pricebook,
        new: newPricebook
      });
    } else {
      API.get("pricebooks", "/pricebooks/"+pricebookId).then(

          response => dispatch({
            type: constants.SET_UP_EDIT_PRICEBOOK,
            pricebook: response["data"], 
            new: newPricebook
          })
        ).catch( err => {
          console.log(err);
          HandleApiError(err);
          dispatch({
            type: constants.SET_UP_EDIT_PRICEBOOK_ERROR,
            error: err
          })
        }); 
    }

  });
}

export function cancelEditValueMetric() { 

  return store.dispatch((dispatch, getState) => {

    dispatch({
      type: constants.CANCEL_EDIT_PRICEBOOK_VALUEMETRIC
    });
  });
}
export function addPricebookValueMetric() { 

  return store.dispatch((dispatch, getState) => {

    const state = getState();
    if (state.pricebook && state.pricebook.json  ) { 

      const valueMetric = createPricebookValueMetric(state.pricebook.json.id)
      dispatch({
        type: constants.ADD_PRICEBOOK_VALUEMETRIC,
        value: valueMetric
      });
      }     
  });
}


export function editPricebookValueMetric(valueMetricId) { 

  return store.dispatch((dispatch, getState) => {

    console.log(valueMetricId);
    const state = getState();
    if (state.pricebookEdit && state.pricebookEdit.data && state.pricebookEdit.data.valueMetrics ) { 

      let valueMetric = state.pricebookEdit.data.valueMetrics.find(vm => vm.id === valueMetricId);

      if (valueMetric) { 
        dispatch({
          type: constants.EDIT_PRICEBOOK_VALUEMETRIC,
          valueMetric : {...valueMetric}
        });
      } else {
        console.log(`error: valueMetric with ${valueMetricId} id not found`);
      }

      }     
  });
}


export function removePricebookValueMetric(valueMetricId) {
  return store.dispatch({
  type: constants.REMOVE_PRICEBOOK_VALUEMETRIC_FIELD, 
  valueMetricId
});
}

export function editValueMetricField(id, name, value) {
  return store.dispatch({
  type: constants.EDIT_PRICEBOOK_VALUEMETRIC_FIELD,  
  valueMetricId: id,
  name,
  value
});
}
 

export function endEditValueMetric (valueMetricId) {
  return store.dispatch({
  type: constants.END_EDIT_PRICEBOOK_VALUEMETRIC,
  valueMetricId  
});
}
 
 
export function savePricebookEdit(onSuccess) {

  return store.dispatch((dispatch, getState) => {
    let state = getState();
    let pricebookData = state.pricebookEdit.data;
    // pricebookData.name = state.pricebookEdit.name;
    // pricebookData.description = state.pricebookEdit.description;
    // pricebookData.id = state.pricebookEdit.data.id;
    // pricebookData.tenantId = state.pricebookEdit.tenantId;

    // generic prep
    const editedPricebook = pricebookSelector.clonePricebookJson( pricebookData );  
    prepObjectForApiCall(pricebookData);

    delete pricebookData.plans;
    delete pricebookData.products;
    delete pricebookData.segmentsData;
    delete pricebookData.lastModified;


    dispatch({
      pricebookId: pricebookData.id,
      data: pricebookData,
      type: constants.REQUEST_PRICEBOOK_SAVE
    });

    let url = "/pricebooks/"+pricebookData.id;
    let myInit = {
        body: pricebookData
    }

    console.log("pricebook update url: " + url);
    console.log("pricebook description: " + pricebookData.description);
 
    API.put("pricebooks", url, myInit).then(
      response => {
        dispatch({
         type: constants.RECEIVE_PRICEBOOK_SAVE,
         pricebookId: pricebookData.id,
         pricebook: editedPricebook, //pricebookData,
         receivedAt: Date.now()
       })
       if (onSuccess) {
         onSuccess();
       }
      },
      error => {
        console.log("Error saving pricebook");
        console.log(error);

        HandleApiError(error);
        dispatch({
         type: constants.RECEIVE_PRICEBOOK_SAVE_ERROR,
         error,
         receivedAt: Date.now()
       })
      }
    );
  });
}
