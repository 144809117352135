import {
    REQUEST_USER,
    RECEIVE_USER,  
    RECEIVE_STORE_USER_GTOKEN, 
    RECEIVE_USER_SAVE,
    RECEIVE_EXPORT_ERROR,
    GLOBAL_LOGOUT,
    AUTHETNICATE_USER,
    SET_UP_EDIT_USER,
    EDIT_PROFILE_FIELD,
    RECEIVE_WORKSPACE,
    REQUEST_GOOGLE_REAUTH,
    USER_INITIATED,
    SAVE_PROFILE_EDIT
  
  } from '../constants'


const initData = { 
    isAuthenticated: false,
    lastError: ''
}

export default function user(
    state = initData,
    action
  ) {

    let tempUser = null;
    switch (action.type) {
      case GLOBAL_LOGOUT:
        return null; // { lastPricebookId : action.lastPricebookId}
      case AUTHETNICATE_USER:
        if (!action.authenticated) { 
            console.log('Looks like user logged out....');
            return null; 
        }
        return Object.assign({}, state, {
            isAuthenticated: action.authenticated
        })
      case USER_INITIATED:
        return Object.assign({}, state, {
          isInitiated: true
       })
      case REQUEST_GOOGLE_REAUTH:
        return Object.assign({}, state, {
            reqeustReauth: action.value
        })
      case RECEIVE_WORKSPACE:
          
        return Object.assign({}, state, { 
            workspace: action.workspace
        }) 
      case SET_UP_EDIT_USER:
        tempUser =  {...state.data};
        // delete tempUser.gToken;
        return Object.assign({}, state, { 
            editData: tempUser
        }) 
      case SAVE_PROFILE_EDIT: 
          return Object.assign({}, state, { 
              data: action.user,
              tenantId: action.user.tenantId
          }) 
      case EDIT_PROFILE_FIELD:
        tempUser = {...state.editData}   ;
        tempUser[action.name] = action.value;

        return {
          ...state,
          editData: tempUser
        };
      case RECEIVE_USER_SAVE:
        tempUser = {...state.editData} 
        return {
          ...state,
          data : tempUser,
          editData: {}
        };

      case RECEIVE_STORE_USER_GTOKEN:

          tempUser = {...state.data};
          tempUser.gToken = action.json.gToken;
          return Object.assign({}, state, { 
              data: tempUser,
              reqeustReauth: false
          })
      case RECEIVE_EXPORT_ERROR:
  
        let user = state.data;
        user.gToken = null;
  
        return Object.assign({}, state, {
          data: user
        })
      case REQUEST_USER:
        return Object.assign({}, state, {
          lastError: ''
        })
      case RECEIVE_USER:
        return Object.assign({}, state, {
          data: action.user,
          workspaces: action.workspaces,
          tenantId: action.user.tenantId,
          jwsToken: action.jwsToken,
          reqeustReauth: false,
          lastUpdated: action.receivedAt
        })
      default:
        return state
    }
  }
