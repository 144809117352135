import * as constants from '../constants';

export default function segmentEdit(
  state = { 
    isLoading: true,
    newAndUnsaved: false
  },
  action
) {
  const segment = state ? {...state.data} : null;

  switch (action.type) {
    case constants.GLOBAL_LOGOUT:
      return null; 

    case constants.SET_UP_EDIT_SEGMENT:
      return {
        ...state,
        data: action.segment,
        newAndUnsaved: action.newAndUnsaved,
        isLoading: false,
        label: "",
        isBusy: false,
        pageName: action.newAndUnsaved ? "Add Segment" : "Editing " + action.segment.name
      };

    case constants.EDIT_SEGMENT_FIELD:

      segment[action.name] = action.value;
      return {
        ...state,
        data: segment
      };

    case constants.RECEIVE_SEGMENT_SAVE:
      return {
        ...state,
        label: "",
        data: null,
        isLoading: false
      };
    case constants.RECEIVE_SEGMENT_SAVE_ERROR:
      return {
        ...state,
        label: "Error saving segment: " + action.error,
        isLoading: false
      };
    case constants.REQUEST_SEGMENT_UPDATE:
      segment[action.name] = action.value;
      return {
        ...state,
        label: "",
        isLoading: false,
        data: segment,
      };
    default:
      return state;
  }
}
