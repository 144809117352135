import {
    GLOBAL_LOGOUT, 
    EDIT_PRICING_FIELD,
    PRICING_HIDE_PLAN,
    PRICING_REMOVE_HIDE_PLAN,
    RESET_PRICING,
    MOVE_PRICING_ROW,
    HIDE_PRICING_ROW,
    ADD_PRICING_ROW,
    RENAME_PRICING_ROW,
    SET_UP_EDIT_PRICING,  
    RECEIVE_PRICING_ARCHIVE,
    CLEAR_ASSET_EDIT,
    RECEIVE_GET_PRICING,
    RECEIVE_PRICING_SAVE,
    RECEIVE_PRICING_CREATE,
    RECEIVE_GET_PRICING_ERROR, 
    RECEIVE_PRICING_CREATE_ERROR,
    RECEIVE_PRICEBOOK,
    REQUEST_GET_PRICING, 
    REQUEST_PRICING_REFRESH,
    INITIATE_NEW_PRICEBOOK_FLOW
  } from '../constants' 

import * as pricingHelper from '../libs/pricingHelper'

  
  const initData = {
    name: '',
    description: '',
    lastError: '' ,
    rows: [],
    isPricebookLoading : false
  }
  
  let tempPricing = null;
  
  export default function pricingEdit(
    state = initData,
    action
  ) {
    
    switch (action.type) {
  
    case RECEIVE_PRICEBOOK:
    case CLEAR_ASSET_EDIT:
    case RECEIVE_PRICING_ARCHIVE:
    case INITIATE_NEW_PRICEBOOK_FLOW:
    case GLOBAL_LOGOUT:
      return null;
    
    
    case EDIT_PRICING_FIELD: 
      tempPricing =  {...state.data};

      if (action.name === 'ctaConfig') {
        tempPricing [action.name] = pricingHelper.setCtaConfig(tempPricing [action.name], action.value.planId, action.value.ctaCaption, action.value.ctaUrl );
      }
      else {
       tempPricing [action.name] = action.value;
      }

      return Object.assign({}, state, {
        data: tempPricing
      });
    
    case PRICING_HIDE_PLAN: 
      tempPricing =  {...state.data};
      tempPricing.hiddenPlans.push({id : action.planId});
     
      return Object.assign({}, state, {
        data: tempPricing
      });

    case PRICING_REMOVE_HIDE_PLAN: 
      tempPricing =  {...state.data}; 
      tempPricing.hiddenPlans = tempPricing.hiddenPlans.filter(element => element.id !== action.planId);

      return Object.assign({}, state, {
        data: tempPricing
      }); 
    
    case RENAME_PRICING_ROW:
    case MOVE_PRICING_ROW:  
    case HIDE_PRICING_ROW:  
    case ADD_PRICING_ROW: 
    case RESET_PRICING: 
    //   tempPricing =  {...state.data};
    //   tempPricing.rowConfig  = action.rowConfig;

      return Object.assign({}, state, {
        rows: action.rowConfig
      });
    case SET_UP_EDIT_PRICING: 
      return Object.assign({}, state, {
        data: action.pricing,
        rows: action.pricing.rowConfig
      })


    case REQUEST_GET_PRICING: 
    case REQUEST_PRICING_REFRESH:  
      return Object.assign({}, state, { 
        fetchingPricing: true
      }) 
    case RECEIVE_PRICING_SAVE:
    case RECEIVE_PRICING_CREATE:  
    case RECEIVE_GET_PRICING:
      return Object.assign({}, state, 
       
        {  
          fetchingPricing: false,
          data: { ... action.pricing}
      })
    case RECEIVE_PRICING_CREATE_ERROR:
    case RECEIVE_GET_PRICING_ERROR:
      return Object.assign({}, state, { 
        fetchingPricing: false
      }) 
   

    default:
      return state
    }
  }
   