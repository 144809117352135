
import Amplify from 'aws-amplify';

const authConfig = {
    "aws_project_region": process.env.REACT_APP_REGION, 
    "aws_cognito_identity_pool_id": process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_REGION, 
    "aws_user_pools_id": process.env.REACT_APP_POOLS_ID, 
    "aws_user_pools_web_client_id": process.env.REACT_APP_WEB_CLIENT_ID,  
    "oauth": {
        "domain": process.env.REACT_APP_OAUTH_DOMAIN, 
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": process.env.REACT_APP_OAUTH_SIGNIN, 
        "redirectSignOut": process.env.REACT_APP_OAUTH_SIGNOUT, 
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
  };


const apiUrl = process.env.REACT_APP_API_URL; 
const apiRegion = process.env.REACT_APP_REGION; 

const apiConfig = {
    API: {
      endpoints: [
        {
          name: "pricebooks",
          endpoint: apiUrl,
          region: apiRegion
        },
        {
          name: "products",
          endpoint: apiUrl,
          region: apiRegion
        },
        {
          name: "plans",
          endpoint: apiUrl,
          region: apiRegion
        },
        {
          name: "users",
          endpoint: apiUrl,
          region: apiRegion
        },
        {
          name: "segments",
          endpoint: apiUrl,
          region: apiRegion
        },
        {
          name: "spreadsheets",
          endpoint: apiUrl,
          region: apiRegion
        }, 
        {
          name: "jobs",
          endpoint: apiUrl,
          region: apiRegion
        }, 
        {
          name: "pricing",
          endpoint: apiUrl,
          region: apiRegion
        }
  
      ]
    }
  }

  
export function configureAmplify(){

    console.log("Configuring Amplifyin using setting from: ", process.env.REACT_APP_STAGE);
    const config = { ...apiConfig,  ...authConfig};
    Amplify.configure(config);

}