
import * as factory from './factory' 
  
export function clonePricebookJson(json) {
    return JSON.parse(JSON.stringify(json));
}

export function getProductNameFromId(products, productId) {
 
    if (!products) { throw new Error("getProductNameFromId: products cannot be null. ")}; 
    if (!productId) { throw new Error("getProductNameFromId: productId  cannot be null. ")}; 

    const prod = products.find(p => p.id === productId);
    return prod ? prod.name : '';
    
}

export function getPlanProducts(pricebook, planId, activeOnly=true) {

    if (!pricebook) { throw new Error("getPlanProducts: Pricebook cannot be null. ")}; 
    if (!planId) { throw new Error("getPlanProducts: PlanId cannot be null. ")}; 
    if (!pricebook.plans) { throw new Error("getPlanProducts: Plans within pricebook cannot be null. ")}; 
    if (!pricebook.products) { throw new Error("getPlanProducts: Plans within pricebook cannot be null. ")}; 

    const planObject = pricebook.plans.find(p => p.id === planId);
    if (!planObject) { throw new Error("getPlanProducts: Plan with this id not found in pricebook: " + planId)}; 

    return getPlanProducts2(planObject, pricebook.products, activeOnly); 
}


export function getPlanProducts2(plan, products, activeOnly=true) {

    if (!plan) { throw new Error("getPlanProducts2: plan cannot be null. ")}; 
    if (!products) { throw new Error("getPlanProducts2: products cannot be null. ")};  

    const planObject = plan;
    const planProducts = [];

    planObject.products.forEach(productSlug => {

        if (!isProductAnAddon(planObject, productSlug.id)) { 

            const product = getProduct(products, productSlug.id);

            if (product && (product.active || !activeOnly)) {
                planProducts.push(product);
            }
        }
        
    });

    return planProducts;
}


export function getPlanProducts3(plan, products, activeOnly=true) {

    if (!plan) { throw new Error("getPlanProducts2: plan cannot be null. ")}; 
    if (!products) { throw new Error("getPlanProducts2: products cannot be null. ")};  

    const planObject = plan;
    const planProducts = [];

    planObject.products.forEach(productSlug => { 

        const product = getProduct(products, productSlug.id);

        if (product.active || !activeOnly) {
            planProducts.push(product);
        } 
        
    });

    return planProducts;
}


export function getPlanAddons2(plan, products, activeOnly=true) {

    if (!plan) { throw new Error("getPlanProducts2: plan cannot be null. ")}; 
    if (!products) { throw new Error("getPlanProducts2: products cannot be null. ")};  

    const planObject = plan;
    const planProducts = [];

    planObject.products.forEach(productSlug => {

        if (isProductAnAddon(planObject, productSlug.id)) { 

            const product = getProduct(products, productSlug.id);

            if (product && (product.active || !activeOnly)) {
                planProducts.push(product);
            }
        }
        
    });

    return planProducts;
}


export function getAvailableProducts (plan, products, activeOnly=true) {

    const availableProducts = [];
    const planProducts = getPlanProducts3(plan, products, activeOnly);

    products.forEach(product => {
        if (!planProducts.find(p => p.id === product.id)) {
            availableProducts.push(product);
        }
    })

    return availableProducts;

}


export function getAvailableProductsForAddons (plan, products, activeOnly=true) {

    const availableProducts = [];
    const planProducts = getPlanProducts3(plan, products, activeOnly);

    products.forEach(product => {
        if (!planProducts.find(p => p.id === product.id) || isProductAnAddon (plan, product.id)) { // not added anywhere or an addon
            availableProducts.push(product);
        }
    })

    return availableProducts;

}

export function getPlanAddons(pricebook, planId, activeOnly=true) {
    if (!pricebook) { throw new Error("getPlanAddons: Pricebook cannot be null. ")}; 
    if (!planId) { throw new Error("getPlanAddons: PlanId cannot be null. ")}; 
    if (!pricebook.plans) { throw new Error("getPlanAddons: Plans within pricebook cannot be null. ")}; 
    if (!pricebook.products) { throw new Error("getPlanAddons: Plans within pricebook cannot be null. ")}; 

    const planObject = pricebook.plans.find(p => p.id === planId);
    if (!planObject) { throw new Error("getPlanAddons: Plan with this id not found in pricebook: " + planId)}; 

    const planAddons = [];

    
    planObject.products.forEach(productSlug => {
 

        if (isProductAnAddon(planObject, productSlug.id)) { 

            const product = getProduct(pricebook.products, productSlug.id);

            if (product.active || !activeOnly) {
                planAddons.push(product);
            }
        }
        
    });

    return planAddons;

}


export function getProduct(products, productId) {

    if (!products) { throw new Error("getProduct: products cannot be null. ")}; 
    if (!productId) { throw new Error("getProduct: productId cannot be null. ")};   

    const product = products.find( p => p.id === productId);

    // if (!product) { throw new Error("getProduct: product not found: " + productId)};   
    if (!product) { console.log("ERROR, product not found", productId)};   

    return product;

}


export function isProductAnAddon(plan, productId) {

    if (!plan) { throw new Error("isProductAnAddon: plan cannot be null. ")}; 
    if (!productId) { throw new Error("isProductAnAddon: productId cannot be null. ")};  
    if (!plan.products) { throw new Error("isProductAnAddon: plan.products cannot be null. ")};  
    if (!plan.addonCharges) { throw new Error("isProductAnAddon: plan.addonCharges cannot be null. ")};  
 
 
    let addonCharge = plan.addonCharges.find(addonCharge => addonCharge.addon && addonCharge.active && addonCharge.addonProductRefId === productId) ? true : false;
    return addonCharge;
}


export function getPoductAddonCharge(plan, productId) {

    if (!plan) { throw new Error("getPoductAddonCharge: plan cannot be null. ")}; 
    if (!productId) { throw new Error("getPoductAddonCharge: productId cannot be null. ")};  
    if (!plan.products) { throw new Error("getPoductAddonCharge: plan.products cannot be null. ")};  
    if (!plan.addonCharges) { throw new Error("getPoductAddonCharge: plan.addonCharges cannot be null. ")};  
 
 
    let addonCharge = plan.addonCharges.find(addonCharge => addonCharge.addonProductRefId === productId);
    return addonCharge;
}


export function getPoductAddonChargeDiscount(plan, chargeId) {

    console.log('attempting to get discount for charge ', chargeId);
    if (!plan) { throw new Error("getPoductAddonChargeDiscount: plan cannot be null. ")}; 
    if (!chargeId) { throw new Error("getPoductAddonChargeDiscount: chargeId cannot be null. ")};   
    if (!plan.discounts) { throw new Error("getPoductAddonChargeDiscount: plan.discounts cannot be null. ")};  
 
    let addonChargeDiscount = plan.discounts.find(addonChargeDiscount => addonChargeDiscount.chargeId === chargeId);
   
    return addonChargeDiscount;
}



export function getProductIncludedFeatures(product, activeOnly=true) {

    if (!product) { throw new Error("getProductIncludedFeatures: product cannot be null. ")};   
    if (!product.features) { throw new Error("getProductIncludedFeatures: product.features cannot be null. ")};   

    const includedFeatures = [];

    product.features.forEach(feature => {
        if ((feature.active || !activeOnly) && !feature.toggable) {
            includedFeatures.push(feature);
        }
    })

    return includedFeatures;

}

export function getProductToggableFeatures(product, activeOnly=true) {

    if (!product) { throw new Error("getProductToggableFeatures: product cannot be null. ")};   
    if (!product.features) { throw new Error("getProductToggableFeatures: product.features cannot be null. ")};   

    const toggableFeatures = [];

    product.features.forEach(feature => {
        if ((feature.active || !activeOnly) && feature.toggable) {
            toggableFeatures.push(feature);
        }
    })

    return toggableFeatures;

}

export function isProductIncluded(plan, productId) {

    if (!plan) { throw new Error("isProductIncluded: plan cannot be null. ")};   
    if (!productId) { throw new Error("isProductIncluded: productId cannot be null. ")};  

    return plan.products.find(p => p.id === productId);

}

export function getPlanProductToggableFeatures(plan, product) {

    if (!product) { throw new Error("getPlanProductToggableFeatures: product cannot be null. ")};    
    if (!plan) { throw new Error("getPlanProductToggableFeatures: plan cannot be null. ")};    

    const productToggableFeatures = getProductToggableFeatures(product);

    const toggableFeatures = [];
    

    const baseCharge = getBaseCharge(plan); 

    productToggableFeatures.forEach(feature => {  
        const productToggableFeature =  (baseCharge.toggableFeatures.find(tf => tf.id === feature.id));
       
        if (productToggableFeature) {
            toggableFeatures.push(productToggableFeature);
        }
    })

    return toggableFeatures;

}


export function getChargeProductToggableFeatures(charge, product) {

    if (!product) { throw new Error("getChargeProductToggableFeatures: product cannot be null. ")};    
    if (!charge) { throw new Error("getChargeProductToggableFeatures: charge cannot be null. ")};    

    const productToggableFeatures = getProductToggableFeatures(product);

    const toggableFeatures = [];

    productToggableFeatures.forEach(feature => {  
        const productToggableFeature =  (charge.toggableFeatures.find(tf => tf.id === feature.id));
       
        if (productToggableFeature) {
            toggableFeatures.push(productToggableFeature);
        }
    })

    return toggableFeatures;

}



export function getPlanProductValueChargeFromValueMetricId(plan, valueMetricId) {

    if (!valueMetricId) { throw new Error("getPlanProductValueChargeFromValueMetricId: valueMetricId cannot be null. ")};    
    if (!plan) { throw new Error("getPlanProductValueChargeFromValueMetricId: plan cannot be null. ")};    

    return plan.valueCharges.find(vc => vc.valueMetric.id === valueMetricId);


}


export function getPlanProductValueCharge(plan, valueChargeId) {

    if (!valueChargeId) { throw new Error("getPlanProductValueCharge: valueChargeId cannot be null. ")};    
    if (!plan) { throw new Error("getPlanProductValueCharge: plan cannot be null. ")};    

    return plan.valueCharges.find(vc => vc.id === valueChargeId);

}


export function getPlanProductToggableFeature(plan, featureId) {

    if (!featureId) { throw new Error("getPlanProductToggableFeature: featureId cannot be null. ")};    
    if (!plan) { throw new Error("getPlanProductToggableFeature: plan cannot be null. ")};    

    
    const baseCharge = getBaseCharge(plan);
    return baseCharge.toggableFeatures.find(tf => tf.id === featureId);

    // return plan.toggableFeatures.find(tf => tf.id === featureId);

}
 
export function selectPlanProductMultioption(plan, multiOptionId, selectIndex) {

    const multiSelects = getPlanMultiSelects(plan);
    if (!multiOptionId) { throw new Error("selectPlanProductMultioption: multiOptionId cannot be null. ")};    
    if (!plan) { throw new Error("selectPlanProductMultioption: plan cannot be null. ")};    
    // if (!selectIndex) { throw new Error("selectPlanProductMultioption: selectIndex cannot be null. ")};    
    if (!multiSelects) { throw new Error("selectPlanProductMultioption: plan.multiSelects are not initalized! ")}; 

    let multiSelect = multiSelects.find( ms => ms.multiOptionId === multiOptionId); 


    console.log(multiSelect);

    if (!multiSelect) {
        multiSelects.push(factory.createPlanMultiSelect(multiOptionId, parseInt(selectIndex)));
    } else {
        multiSelect.selectIndex = parseInt(selectIndex); 
    } 
}

export function selectChargeProductMultioption(charge, multiOptionId, selectIndex) {
    if (!multiOptionId) { throw new Error("selectChargeProductMultioption: multiOptionId cannot be null. ")};    
    if (!charge) { throw new Error("selectChargeProductMultioption: charge cannot be null. ")};    
    if (!charge.multiSelects) { throw new Error("selectChargeProductMultioption: charge.multiSelects are not initalized! ")}; 

    let multiSelect = charge.multiSelects.find( ms => ms.multiOptionId === multiOptionId);

    if (!multiSelect) {
        charge.multiSelects.push(factory.createPlanMultiSelect(multiOptionId, parseInt(selectIndex)));
    } else {
        multiSelect.selectIndex = parseInt(selectIndex);
    }
}

export function addPlanProductToggableFeature(plan, featureId) {

    if (!featureId) { throw new Error("addPlanProductToggableFeature: featureId cannot be null. ")};    
    if (!plan) { throw new Error("addPlanProductToggableFeature: plan cannot be null. ")};    


    const baseCharge = getBaseCharge(plan);
    baseCharge.toggableFeatures.push({'id' : featureId}); 
    plan.toggableFeatures.push({'id' : featureId}); 

}


export function removePlanProductToggableFeature(plan, featureId) {

    if (!featureId) { throw new Error("removePlanProductToggableFeature: featureId cannot be null. ")};    
    if (!plan) { throw new Error("removePlanProductToggableFeature: plan cannot be null. ")};    

    const baseCharge = getBaseCharge(plan);
    baseCharge.toggableFeatures = baseCharge.toggableFeatures.filter(tf => tf.id !== featureId); 

    plan.toggableFeatures = plan.toggableFeatures.filter(tf => tf.id !== featureId); 
}


export function addChargeProductToggableFeature(charge, featureId) {

    if (!featureId) { throw new Error("addChargeProductToggableFeature: featureId cannot be null. ")};    
    if (!charge) { throw new Error("addChargeProductToggableFeature: charge cannot be null. ")};    

    charge.toggableFeatures.push({'id' : featureId}); 

}


export function removeChargeProductToggableFeature(charge, featureId) {

    if (!featureId) { throw new Error("removeChargeProductToggableFeature: featureId cannot be null. ")};    
    if (!charge) { throw new Error("removeChargeProductToggableFeature: charge cannot be null. ")};    

    charge.toggableFeatures = charge.toggableFeatures.filter(tf => tf.id !== featureId); 
}

export function editPlanValueCharge(plan, name, value) {
   
    console.log(name, value);

    // Get plan valueCharge (which has productrefid set to null)
 
    if (!plan.valueCharges) {
        plan.valueCharges = [];   
    }

    const valueCharge = plan.valueCharges.find(vc => !vc.addonProductRefId); // plan.valueCharges[0];
    if (!valueCharge) { plan.valueCharges.push(factory.createPlanValueCharge()) }
    
    console.log(valueCharge);
    if (name === 'valueMetricId') {
        valueCharge.valueMetric = { id : value};
    } else {
        valueCharge[name] = value;
    }
    
}


export function getPlanValueChargeBandPrice(plan) {
     
    const valueCharge = plan.valueCharges.find(vc => !vc.addonProductRefId); // plan.valueCharges[0];
    
    return valueCharge.bandPrices;
    
}
export function addPlanValueChargeBandPrice(plan, bandPrice) {
    
    // Get plan valueCharge (which has productrefid set to null)
 
    if (!plan.valueCharges) {
        plan.valueCharges = [];   
    }

    let valueCharge = plan.valueCharges.find(vc => !vc.addonProductRefId); // plan.valueCharges[0];
    if (!valueCharge) {
        valueCharge = factory.createPlanValueCharge();
        plan.valueCharges.push(valueCharge);     
    }

    valueCharge.bandPrices.push(bandPrice)
    
}

export function removePlanValueChargeBandPrice(plan, index) {

    if (!plan.valueCharges) {
        plan.valueCharges = [];   
    }

    const valueCharge = plan.valueCharges.find(vc => !vc.addonProductRefId); // plan.valueCharges[0];
    console.log('valueCharge.bandPrices.length', valueCharge.bandPrices.length)
    console.log('index', index)

    if (valueCharge && valueCharge.bandPrices.length > index ) {
        console.log('in splice', valueCharge.bandPrices.length)

        valueCharge.bandPrices.splice(index, 1);
        console.log('in splice after', valueCharge.bandPrices.length)
    }
    
}



export function editPlanProductValueCharge(plan, valueChargeId, name, value) {
 
    if (!plan) { throw new Error("editPlanProductValueCharge: plan cannot be null. ")};   
    if (!valueChargeId) { throw new Error("editPlanProductValueCharge: valueChargeId cannot be null. ")};   
    if (!name) { throw new Error("editPlanProductValueCharge: name cannot be null. ")};   
    // if (!value) { throw new Error("editPlanProductValueCharge: value cannot be null. ")};    

    let valueCharge = plan.valueCharges.find(vc => vc.id === valueChargeId);
    
    valueCharge[name] = value;
}

// Go through all the products - ensure the plan has proper defaults for every product (value charges and toggable features)
export function prepPricebookPlansForEdit(pricebook) {
    if (!pricebook) { throw new Error("prepPricebookPlansForEdit: pricebook cannot be null. ")};    
    if (!pricebook.plans) { throw new Error("prepPricebookPlansForEdit: pricebook.plans cannot be null. ")};    
    if (!pricebook.products) { throw new Error("prepPricebookPlansForEdit: pricebook.products cannot be null. ")};    

    pricebook.plans.forEach(plan => prepPlanForEdit(plan, pricebook.products,  pricebook));
}

function getPlanMultiSelects(plan) {
    
    const baseCharge = getBaseCharge(plan);
    return baseCharge.multiSelects;
    
}
export function prepPlanForEdit(plan, products, pricebook) {

    // console.log('in prepPlanForEdit');

    if (!products) { throw new Error("prepPlanForEdit: products cannot be null. ")};    
    if (!plan) { throw new Error("prepPlanForEdit: plan cannot be null. ")};  
 
    // Create base charge discount if needed
    const baseCharge = getBaseCharge(plan);
    let baseChargeDiscout = plan.discounts.find (d => d.chargeId === baseCharge.id);
    if (!baseChargeDiscout) { baseChargeDiscout = factory.createChargeDiscount(plan.id, baseCharge.id, false); }
    
    let discounts = [baseChargeDiscout];

    plan.tenantId = pricebook.tenantId;


     // VALUE CHARGES:

    // ALL STORED IN PLAN.VALUEMETRICS?
    //
    // PLAN LEVEL VALUE CHARGE => ADDON PRODUCT ID === NULL
    // >> VALUEMETRIC CAN BE NULL
    // >> TO KEEP THINGS SIMPLE - EVERY PLAN SHOULD HAVE ONE!
    // >> VALIDATE THAT EACH PRODUCT HAS A VM THAT ALIGNS WITH VALUECHARGE
    // VALUE CHARGES THAT APPLY TO ADDONS === PRODUCTID
    // >> 

    if (!plan.valueCharges || !plan.valueCharges.find(vc => !vc.addonProductRefId)) {  
        plan.valueCharges.push(factory.createPlanValueCharge());                    
    }  

    products.forEach(product => {

        product.multiOptions.forEach(mo => {
            
            const multiSelects = getPlanMultiSelects(plan);

            if (mo.active && !multiSelects.find(ms => ms.multiOptionId === mo.id)) {
                multiSelects.push(factory.createPlanMultiSelect(mo.id, 0));
            }
        })
        // populate plan Value Charges with defaults if one does not exist
        product.valueMetrics.forEach(prodVM => {

            const vm = pricebook.valueMetrics.find( pVM => pVM.id === prodVM.id);
            // console.log("prep for plan edit prep for plan editprep for plan editprep for plan editprep for plan editprep for plan edit");
            // console.log(vm);
            if (vm) {

                prodVM.unit = vm.unit;

                // // 
                // if (!plan.valueCharges.find(vc => (vc.valueMetric.id === vm.id))) { // && !vc.createAddonValueCharge
                //     // plan.valueCharges.push(factory.createPlanValueCharge(vm.id, product.id));    
                //     plan.valueCharges.push(factory.createPlanValueCharge(vm.id, null));                    
                // }  

                // console.log('adding a value charge to an addon!!!!!!')
                // console.log(plan.addonCharges)
                
                plan.addonCharges.forEach(addonCharge => {

                        if (addonCharge.active) {
                            let valueCharge = plan.valueCharges.filter(vc => vc.valueMetric).find( vc => vc.valueMetric.id === vm.id  && vc.addonChargeRefId === addonCharge.id);
                            if (!valueCharge) {
                                console.log('adding a value charge to an addon!!!!!!', addonCharge.id,vm.id )
                                console.log([...plan.valueCharges]);
                                valueCharge = factory.createAddonValueCharge(vm.id, addonCharge.id, product.id);
                                plan.valueCharges.push(valueCharge);
                            }
                        }
                    })
            }
            // let valueCharge = addonCharge.valueCharges.find( vc => vc.addonChargeRefId);
            // if (!valueCharge) {
            //     valueCharge = factory.createAddonValueCharge())
            // }
        })

        // there could be multiple charges that need a discount or a value charge init
        let addonCharges = plan.addonCharges.filter (a => a.addonProductRefId === product.id);
        if (!addonCharges) { 
            let addonCharge = factory.createAddonCharge(plan.id, product.id, false); 
            plan.addonCharges.push(addonCharge);
            addonCharges.push(addonCharge);
        } 
        
        addonCharges.forEach(addonCharge => {
            let discount = plan.discounts.find (d => d.chargeId === addonCharge.id);
            if (!discount) { 
                discount = factory.createChargeDiscount(plan.id, addonCharge.id, false); 
             } 
            discounts.push(discount);

            
         }) 
 
    })

    plan.discounts = discounts; 
 
    return plan;
}

export function getPlanProductValueCharges(plan, product) {

    if (!product) { throw new Error("getPlanProductValueCharges: product cannot be null. ")};    
    if (!plan) { throw new Error("getPlanProductValueCharges: plan cannot be null. ")};    


    const valueMetrics = product.valueMetrics;
    const valueCharges = [];
    

    valueMetrics.forEach( valueMetric => {

        if (valueMetric.active) {
            let valueCharge = plan.valueCharges.find(vc => vc.valueMetric.id === valueMetric.id);
            if (!valueCharge) { valueCharge = factory.createPlanValueCharge(valueMetric.id, product.id); }
    
            valueCharge.valueMetric = valueMetric;
            valueCharges.push(valueCharge); 
        } 
    })

    return valueCharges; 

}


export function getPlanAddonValueCharges(plan, charge, product) {

    if (!product) { throw new Error("getPlanChargeProductValueCharges: product cannot be null. ")};    
    if (!plan) { throw new Error("getPlanChargeProductValueCharges: plan cannot be null. ")};    
    if (!charge) { throw new Error("getPlanChargeProductValueCharges: charge cannot be null. ")};    
    
    // console.log(charge);
    // if (!charge.addonChargeRefId) {
    //     return [];
    // }

    const valueMetrics = product.valueMetrics;
    const valueCharges = [];
    
    valueMetrics.forEach( valueMetric => {

        // if (valueMetric.active) {
            // console.log(plan.valueCharges);
            let valueCharge = plan.valueCharges.find(vc => (vc.valueMetric.id === valueMetric.id && vc.addonChargeRefId === charge.id));
            if (!valueCharge) { 
                valueCharge = factory.createAddonValueCharge(valueMetric.id, charge.id, product.id);  
                // console.log('value charge NOTNOTNOT found!'
            } else {
                // console.log(`value charge found! ${charge.id} matched vc ${valueCharge.addonChargeRefId} `);
                
            }
    
            valueCharge.valueMetric = valueMetric;
            valueCharges.push(valueCharge); 
        // } 
    })

    return valueCharges; 

}

export function getBaseCharge(plan) {
    if (!plan) { throw new Error("getBaseCharge: plan cannot be null. ")};    
    if (!plan.charges){ throw new Error("getBaseCharge: plan.charges cannot be null. ")};    

    // hard coded to be always the first charge in charges array

    let baseCharge = null;
    if (plan.charges.length > 0) {

        baseCharge = plan.charges[0];
        baseCharge.active = true;
    } else {
        baseCharge = factory.createBaseCharge(plan.id)
        plan.charges.push(baseCharge);
    }

    return baseCharge;
}

export function getBaseDiscount(plan) {
    if (!plan) { throw new Error("getBaseCharge: plan cannot be null. ")};    
    if (!plan.discounts){ throw new Error("getBaseCharge: plan.discounts cannot be null. ")};    

    const baseCharge = getBaseCharge(plan);
    let baseDiscount = plan.discounts.find(d => (d.chargeId === baseCharge.id));
    
    if (baseDiscount ) {
        // baseDiscount.active = true;
    } else { 
        baseDiscount = factory.createChargeDiscount(plan.id, baseCharge.id);
        baseDiscount.active = false;
        plan.discounts.push(baseDiscount);
    }

    return baseDiscount;
}

// EDIT FUNCTIONS

export function addProductToPlan(plan, productId) {

    if (!plan) { throw new Error("addProductToPlan: plan cannot be null. ")};   
    if (!productId) { throw new Error("addProductToPlan: productId cannot be null. ")};  

    // Make sure we don't have any duplicates
    if (!plan.products.find(p => p.id === productId)) { 
        plan.products.push({id: productId});
    }

    // Check if there are any addon charges 
    const addonCharge = plan.addonCharges.find(a => a.addonProductRefId === productId && a.active);
    if (addonCharge) { addonCharge.active = false; }
  
}

export function addProductAddonToPlan(plan, productId, products) {

    if (!plan) { throw new Error("addProductAddonToPlan: plan cannot be null. ")};   
    if (!productId) { throw new Error("addProductAddonToPlan: productId cannot be null. ")};  

    // Make sure we don't have any duplicates
    if (!plan.products.find(p => p.id === productId)) { 
        plan.products.push({id: productId});
    }

    // get product object
    const product = products.find(p => p.id === productId);

    // Check if there are any addon charges 
    const addonCharge = plan.addonCharges.find(a => a.addonProductRefId === productId);
    if (addonCharge && !addonCharge.active) { // re-purpose old add-ons
        addonCharge.active = true; 
    } else {
        let addonCharge = factory.createAddonCharge(plan.id, productId);
        plan.addonCharges.push(addonCharge);
        plan.valueCharges = plan.valueCharges.concat(getPlanAddonValueCharges(plan, addonCharge, product));
        plan.discounts.push(factory.createChargeDiscount(plan.id, addonCharge.id, false));
    } 
}


export function togglePlanProductAddon(plan, productId, value) {

    if (!plan) { throw new Error("togglePlanProductAddon: plan cannot be null. ")};   
    if (!productId) { throw new Error("togglePlanProductAddon: productId cannot be null. ")};  

    // Check if there are any addon charges 
    // let addonCharge = plan.addonCharges.find(a => a.addonProductRefId === productId && a.active);
    let addonCharge = plan.addonCharges.find(a => a.addonProductRefId === productId );
    if (addonCharge) {
        addonCharge.active = value; 
    } else { 
        if (value) {
            addonCharge = factory.createAddonCharge(plan.id, productId);
            plan.addonCharges.push(addonCharge);
        }
        //throw new Error("removeProductAddonFromPlan: addon charge does not exist within plan for this product " + productId);
    }

    plan.addonCharges = plan.addonCharges.map(a => a.id === addonCharge.id ? addonCharge : a);
}

export function removeProductAddonFromPlan(plan, productId) {

    if (!plan) { throw new Error("removeProductAddonFromPlan: plan cannot be null. ")};   
    if (!productId) { throw new Error("removeProductAddonFromPlan: productId cannot be null. ")};  

    // Make sure we don't have any duplicates
    if (!plan.products.find(p => p.id === productId)) { 
        throw new Error("removeProductAddonFromPlan: productId does not exist within plan. " + productId);
    } 

    plan.products = plan.products.filter( p => (p.id !== productId) ); 

    // Check if there are any addon charges 
    const addonCharge = plan.addonCharges.find(a => a.addonProductRefId === productId && a.active);
    if (addonCharge) {
        addonCharge.active = false; 
    } else { 
        //throw new Error("removeProductAddonFromPlan: addon charge does not exist within plan for this product " + productId);
    }
}


export function removeAllAddonChargesFromPlan(plan, productId) {

    if (!plan) { throw new Error("removeProductAddonFromPlan: plan cannot be null. ")};   
    if (!productId) { throw new Error("removeProductAddonFromPlan: productId cannot be null. ")};  

    // Make sure we don't have any duplicates
    if (!plan.products.find(p => p.id === productId)) { 
        throw new Error("removeProductAddonFromPlan: productId does not exist within plan. " + productId);
    } 

     // Check if there are any addon charges 
     const addonCharges = plan.addonCharges.filter(a => a.addonProductRefId === productId && a.active);

    
     if (addonCharges) {
        addonCharges.forEach(addonCharge => { 
         addonCharge.active = false; 
        })
     }  

    // // Check if there are any addon charges 
    // const addonCharge = plan.addonCharges.find(a => a.addonProductRefId === productId && a.active);
    // if (addonCharge) {
    //     addonCharge.active = false; 
    // } else { 
    //     //throw new Error("removeProductAddonFromPlan: addon charge does not exist within plan for this product " + productId);
    // }
}

export function removeAddonChargeFromPlan(plan, chargeId) {

    if (!plan) { throw new Error("removeAddonChargeFromPlan: plan cannot be null. ")};   
    if (!removeAddonChargeFromPlan) { throw new Error("removeAddonChargeFromPlan: chargeId cannot be null. ")};  

    const addonCharge = plan.addonCharges.find(p => p.id === chargeId);
    // Make sure we don't have any duplicates
    if (!addonCharge) { 
        throw new Error("removeAddonChargeFromPlan: productId does not exist within plan. " + chargeId);
    } 

    addonCharge.active = false;

    // are there any more active addon charges?
    let activeAddonCharges =  plan.addonCharges.filter( p => p.active && (p.addonProductRefId === addonCharge.addonProductRefId ) ); 


    console.log(activeAddonCharges);
    if (activeAddonCharges.length === 0) {
        plan.products = plan.products.filter( p => (p.id !== addonCharge.addonProductRefId ) ); 
    }

    // // Check if there are any addon charges 
    // const addonCharge = plan.addonCharges.find(a => a.addonProductRefId === productId && a.active);
    // if (addonCharge) {
    //     addonCharge.active = false; 
    // } else { 
    //     //throw new Error("removeProductAddonFromPlan: addon charge does not exist within plan for this product " + productId);
    // }
}


export function removeProductFromPlan(plan, productId) {

    if (!plan) { throw new Error("removeProductFromPlan: plan cannot be null. ")};   
    if (!productId) { throw new Error("removeProductFromPlan: productId cannot be null. ")};  

    // Make sure we don't have any duplicates
    if (!plan.products.find(p => p.id === productId)) { 
        throw new Error("removeProductFromPlan: productId does not exist within plan. " + productId);
    } 

    plan.products = plan.products.filter( p => (p.id !== productId) ); 
  
}


export function removeBaseDiscount(plan) {

    if (!plan) { throw new Error("removeBaseDiscount: plan cannot be null. ")};   
     
    const baseDiscount = getBaseDiscount(plan);
    baseDiscount.active = false;

    return baseDiscount; 
}

export function hasPlanChanged(plan, pricebook) {

    if (!plan) { throw new Error("hasPlanChanged: plan cannot be null. ")};   
    if (!pricebook) { throw new Error("hasPlanChanged: pricebook cannot be null. ")};  

    const oldPlan = pricebook.plans.find(p => p.id === plan.id);
    if (!oldPlan) { throw new Error("hasPlanChanged: oldPlan cannot be null. ")};   

    return JSON.stringify(plan).localeCompare(JSON.stringify(oldPlan)) !== 0;    
}