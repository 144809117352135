import { store } from '../store';
import {  REQUEST_API_ERROR } from '../constants';


export function prepObjectForApiCall(object) {
    if (object === null) { throw "cannot send null object to the server"}
    delete object.lastModified;
  
    return object;
  }
  
  export async function HandleError(message, details) {
    store.dispatch((dispatch, getState) => {

        dispatch({
            type: REQUEST_API_ERROR,  
            message,
            details
        });

    });
}

export async function HandleApiError(error, errorCode=REQUEST_API_ERROR) {

    let message = '';
    let details = '';
    
     if (error.response) {
        // client received an error response (5xx, 4xx)
        if (error.response.data) {

            if (error.response.data.message && error.response.data.details) {
                message = error.response.data.message;
                details =  error.response.data.details;  
            } else if (error.response.data.message && error.response.data.error) {
                message = error.response.data.message;
                details =  JSON.stringify(error.response.data.error);  
            } else if (error.response.data.message) {  
                message = error.response.data.message;
                details = error.response.data;  
            } else {
                message = 'Error calling API. Error occured on the server side.';
                details = error.response.data;  
            }

        } else {
            details = error.toString();
            console.log(error);
        }
        
      } else if (error.request) {
        // client never received a response, or request never left
        message = 'Error calling Api. Never got a response from the server.';
        details = 'This most likley means you didn not set your Amplify IAM role permissions to have have access to the endpoint. Or that you have misconfigured the Gateway API signature (client). Or you misconfired handler function mapping on the server (serverless.yml).';

      } else {
        message = 'Generic error calling api';
        details = error.toString();
      }

      console.log("Error call Api. Got the following response from the server:");
      console.log(message);
      console.log(details);

      store.dispatch((dispatch, getState) => {

                dispatch({
                    type: errorCode,  
                    message,
                    details
                });

            });
} 