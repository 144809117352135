import * as constants from '../constants';
import {  createChargeDiscount  } from "../libs/factory"; 
import * as pricebookSelector from '../libs/pricebookSelector'

export default function planEdit(
  state = {
    status: null,
    plan: null,
    newAndUnsaved: false,
    showSelector: false,
    configuratorProduct: null,
    showConfigurator: false,
    productSelector: true,
    products: null,
    changed: null,
  },
  action
) {
  const thePlan = state ? { ...state.plan } : {};
  thePlan.lastUpdated = Date.now();

  let productId = null;
  let chargeId = null;
  let discount = null; 
  let charge = null;
  let bandPrices  = null;
  
  switch (action.type) {
    case constants.GLOBAL_LOGOUT:
    case constants.RECEIVE_PRICEBOOK:
    case constants.CLEAR_EDIT:
    case constants.RECEIVE_PRICEBOOK:
      return null;
    case constants.EDIT_PLAN_PENDING:
      // const newPlan = { ...state.plan };
      // newPlan[action.fieldName] = action.fieldValue;
      return {
        ...state,
        status: constants.EDIT_PLAN_PENDING,
      };
    case constants.EDIT_PLAN_FIELD: 
      thePlan[action.name] = action.value;
      return {
        ...state,
        changed: true,
        plan: thePlan,
      };
    case constants.RECEIVE_PLAN_SAVE:
      return {
        ...state,
        products: null,
        plan: null,
      };

    case constants.SHOW_PRODUCT_CONFIGURATOR:
      return {
       ...state,
       configuratorProduct : action.product,
       configuratorAddonCharge : action.addonCharge,
       configuratorAddonChargeDiscount : action.addonChargeDiscount,
       showConfigurator : action.show
     };
    case constants.SHOW_PRODUCT_SELECTOR:

    return action.show ?
         {
          ...state,
          showSelector : action.show,
          productSelector : action.productSelector
          }
        :
          {
           ...state,
           showSelector : action.show
         };

    case constants.EDIT_CHARGE_FIELD: 

      pricebookSelector.getBaseCharge(thePlan)[action.name] = action.value; 

      return {
        ...state,
        changed: true,
        plan: thePlan,
      };
    case constants.EDIT_ADDON_FIELD: 

      const addonId = action.addonId;

      thePlan.addonCharges.forEach(addon => {
        if (addon.id === addonId ) {
          addon[action.name] = action.value;

        }
      });

      return {
        ...state,
        changed: true,
        plan: thePlan,
      };
    case constants.EDIT_PLAN_ADDON_FIELD: 

      if (action.name === 'checked') {
        productId = action.productId;
        chargeId = action.chargeId;
        if (action.value) {
          pricebookSelector.addProductAddonToPlan(thePlan, productId, state.products);
        } else {
          pricebookSelector.removeAddonChargeFromPlan(thePlan, chargeId);
        }
      } else {
        //throw new Error("unsupported action")
      }

      return {
        ...state,
        changed: true,
        plan: thePlan,
      };

    case constants.EDIT_PLAN_PRODUCT_FIELD: 

      if (action.name === 'checked') {
        productId = action.productId;
        if (action.value) {
          pricebookSelector.addProductToPlan(thePlan, productId);
        } else {
          pricebookSelector.removeProductFromPlan(thePlan, productId);
        }
      } else {
        //throw new Error("unsupported action")
      }

      return {
        ...state,
        changed: true,
        plan: thePlan,
      };
     
      // theProducts.forEach(product => {
      //   if (product.id === productId ) {
      //     product[action.name] = action.value; 

      //     if (action.name === 'checked' && !action.value) {
      //       product.addonCharge.active = action.value;
      //       product.addonDiscount.active = action.value;
      //     }
      //   }
      // });

      // return {
      //   ...state,
      //   changed: true,
      //   products: theProducts,
      // };

    case constants.EDIT_CHARGE_DISCOUNT_FIELD:
      const discountId = action.discountId;
      discount = createChargeDiscount(null, discountId);

      let baseDiscount = pricebookSelector.getBaseDiscount(thePlan);

      if (baseDiscount.id === discountId) {
        discount = baseDiscount;
      } else {
        thePlan.discounts.forEach(d => {
          if (d.id === discountId ) { discount = d; }
        });

      }

      if (action.name === "remove") {
        discount.active = false;
      }
      else if (action.name === "add") {
        discount.active = true;
      }
      else {
        discount[action.name] = action.value;
      }

      return {
        ...state,
        changed: true,
        plan: thePlan,
      };

    case constants.TOGGLE_PRODUCT_FEATURE:

    if (action.value) {
      pricebookSelector.addPlanProductToggableFeature(thePlan, action.featureId);
    } else  {
      pricebookSelector.removePlanProductToggableFeature(thePlan, action.featureId);
    } 

      return {
        ...state,
        changed: true,
        plan: thePlan,
      };

    case constants.SELECT_PRODUCT_MULTIOPTION:

      if (action.charge) { 
        pricebookSelector.selectChargeProductMultioption(action.charge, action.multiOptionId, action.selectIndex);
      } else { 
        pricebookSelector.selectPlanProductMultioption(thePlan, action.multiOptionId, action.selectIndex);
      }     
      
        return {
          ...state,
          changed: true,
          plan: thePlan,
        };
    
    case constants.TOGGLE_CHARGE_FEATURE:

      charge = thePlan.addonCharges.find(ac => ac.id === action.charge.id);
      charge = charge ? charge : thePlan.charges.find(ac => ac.id === action.charge.id);

      if (action.value) {
        pricebookSelector.addChargeProductToggableFeature(charge, action.featureId);
      } else  {
        pricebookSelector.removeChargeProductToggableFeature(charge, action.featureId);
      } 
  
      console.log(charge);

      return {
        ...state,
        changed: true,
        plan: thePlan,
      };

    case constants.EDIT_VALUECHARGE_FIELD:

      if (action.name==='unlimited') {
        pricebookSelector.editPlanProductValueCharge(thePlan, action.chargeId, 'unitsInBase', action.value ?  -1 : 0);   
      }
      pricebookSelector.editPlanProductValueCharge(thePlan, action.chargeId, action.name, action.value);
      return {
        ...state,
        changed: true,
        plan: thePlan,
      }; 
    
    case constants.EDIT_PLAN_VALUECHARGE_BAND_FIELD:
      bandPrices = pricebookSelector.getPlanValueChargeBandPrice(thePlan, action.bandPrice);      

      if (bandPrices.length > action.index) {
        bandPrices[action.index][action.name] = action.value;
      }

      return {
        ...state,
        changed: true,
        plan: thePlan,
      }; 

    case constants.ADD_PLAN_VALUECHARGE_BAND_FIELD:

      pricebookSelector.addPlanValueChargeBandPrice(thePlan, action.bandPrice);      

      return {
        ...state,
        changed: true,
        plan: thePlan,
      }; 

    case constants.REMOVE_PLAN_VALUECHARGE_BAND_FIELD:

        pricebookSelector.removePlanValueChargeBandPrice(thePlan, action.index);      
  
        return {
          ...state,
          changed: true,
          plan: thePlan,
        }; 
  

    

    case constants.EDIT_PLAN_VALUECHARGE_FIELD:

        // if (action.name==='unlimited') {
        //   pricebookSelector.editPlanProductValueCharge(thePlan, action.chargeId, 'unitsInBase', action.value ?  -1 : 0);   
        // }
        // pricebookSelector.editPlanProductValueCharge(thePlan, action.chargeId, action.name, action.value);
        
        pricebookSelector.editPlanValueCharge(thePlan, action.name, action.value);

        return {
          ...state,
          changed: true,
          plan: thePlan,
        }; 

    case constants.SET_UP_EDIT_PLAN:

        return {
        ...state,
        changed: false,
        plan: action.plan,
        newAndUnsaved: action.newAndUnsaved,
        showConfigurator: false, 
        pageName: action.newAndUnsaved ? "Add Plan" : "Editing " + action.plan.name,
        products: action.products
      }; 
    case constants.EDIT_PLAN_SUCCESS:
      return {
        ...state,
        changed: false,
        plan: action.form,
        status: constants.EDIT_PLAN_SUCCESS,
      };
    default:
      return state;
  }
}
 