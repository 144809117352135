import { API } from "aws-amplify";
import { store } from '../store';
import * as constants from '../constants'; 
import { HandleApiError } from "../libs/apiHelper"; 
   
export function saveProfileEdit(callback) 
{
  return store.dispatch((dispatch, getState) => {

    const state = getState();

    const currentTenantId = state.user.tenantId;
    const selectedTenantId = state.userEdit.workspaceId;

    console.log('currentTenantId', currentTenantId);
    console.log('selectedTenantId', selectedTenantId);

    if (currentTenantId && selectedTenantId && selectedTenantId != currentTenantId) {
      
      console.log('changing workspace');
      const url = "/users/workspace/" + selectedTenantId;
    
      API.put("users", url).then(
          response => {
            dispatch({ 
              type: constants.SAVE_PROFILE_EDIT, 
              user: response["data"]
            }); 

            if (callback) { callback(); } 
          },
          error => {
            HandleApiError(error)
            dispatch({
            type: constants.SAVE_PROFILE_EDIT_ERROR,
            error,
            receivedAt: Date.now()
          })
          }
        );
    }

  

  });
}

export function setWorkspaceId( tenantId) 
{

  console.log(tenantId);
  return store.dispatch((dispatch, getState) => {

    dispatch({
      type: constants.SET_WORKSPACE_ID,
      tenantId
    });

  });
}

export function loadProfileEdit( ) 
{
     
  console.log("loadProfileEdit");

  return store.dispatch((dispatch, getState) => {

    
    dispatch({
      type: constants.SET_UP_EDIT_PROFILE,
      tenantId : getState().user.tenantId
    });

 
    let state = getState(); 
     
  });
} 

