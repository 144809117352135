/* This example requires Tailwind CSS v2.0+ */ 
import NumberFormat from 'react-number-format';
import {formatCurrency} from "../libs/helper"
import { PencilIcon } from '@heroicons/react/solid'
import   {  useState } from 'react';
 
export default function EditChargeValue({currentValue, oneTime,  onChange}) {


  const [valueFrequency, setValueFrequency] = useState('monthlyValue'); 
  const monthlyValue =  currentValue;
  const yearlyValue = currentValue * 12;
  
  return (
    <>
     <div className="flex  justify-start   text-center w-full ">
     <NumberFormat
        id="amountControl"
        thousandSeparator={true}
        className="shadow-sm focus:ring-indigo-500  w-20 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                    
        prefix={'$'}
        value={monthlyValue}
        decimalScale={2}
        isAllowed={values => { const {formattedValue, value} = values; return value >= 0 &&  value <= 100000000} } 
        onValueChange={values => {
          
          const {formattedValue, value} = values;
          onChange(valueFrequency, value);
          
        }}
      /> 
      <PencilIcon className="h-5 w-5 mr-6 text-gray-600" aria-hidden="true" />
      { !oneTime &&
        <>per month ({formatCurrency(yearlyValue)} per year )</>
      }
      </div>
                  
    </>
  )
}
