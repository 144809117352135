

import React, { Component } from "react";
import { connect } from 'react-redux' 

import ListDetailsView from '../controls/listDetailsViewControl'
import { pricebooksObjectsToRows, pricebookObjectsToRowDetails, pricebookObjectToDetails } from  "../libs/selectors" 
import { fetchPricebooks } from "../actions/pricebookActions"
import { canAddPricebook } from "../libs/membership"
import { fetchPricebook } from "../actions/pricebookActions" 
import { setupDefaultPricebookEdit } from "../actions/pricebookEditActions"

const mapStateToDynamicTableProps = (state) => {
 
    if (!state.pricebooks || !state.pricebook || !state.pricebook.json) return {isLoading: true, rows : []};
   
    return  { 
      addItemCaption: 'Add Pricebook',
      //addItemUrl: canAddPricebook(state.user, state.pricebooks) ? '/new' : '/checkout', 
      addItemUrl: '/new',
      isLoading: false, //state.pricebook.isPricebookLoading,  
      headers: ['State', 'Pricebook'],
      rows : pricebooksObjectsToRows(state.pricebooks.items),
      details: pricebookObjectsToRowDetails(state.pricebooks.items),
      selectedDetails: pricebookObjectToDetails(state.pricebook.json, state.pricebook.isPricebookLoading),
    } 
  } 
  
  
  const mapDispatchToProps = dispatch => {
    return { 
      loadDetailsCallback: (id) => fetchPricebook(id)
    }
  }

  const PlansListDetailsView = connect(
    mapStateToDynamicTableProps,mapDispatchToProps )(ListDetailsView)

export class PricebooksPage extends Component {
    constructor(props) {
        super(props);
     
      }

      async componentDidMount() {

        fetchPricebooks(setupDefaultPricebookEdit);
        // setupDefaultPricebookEdit();
      }

      render() { 
          
        return <PlansListDetailsView/>
      }

}


export default PricebooksPage