import { API } from "aws-amplify";
import { store } from '../store';
import * as constants from '../constants';
import { HandleApiError } from "../libs/apiHelper";
import { fetchPricingPages } from "./pricingActions"; 

export function receivePricebookError(error) {
    return {
      type: constants.RECEIVE_PRICEBOOKS_ERROR,
      error
    }
  }

export function requestPricebooks() { 
  return {
    type: constants.REQUEST_PRICEBOOKS
  }
}

export function receivePricebooks(json) {
  return {
    type: constants.RECEIVE_PRICEBOOKS,
    pricebooks: json,
    receivedAt: Date.now()
  }
}

export function refereshPricebooks() {
  console.log('refershing pricebooks...')
  fetchPricebook(null, true);
  fetchPricebooks();
}


export function fetchPricebooks(callback) {
  store.dispatch((dispatch, getState) => {
        dispatch(requestPricebooks())

        console.log('fetching pricebooks')
        // return API.get("pricebooks", "/pricebooks", { body: { tenantId : getState().user.tenantId } }).then(
        return API.get("pricebooks", "/pricebooks").then(
          response => {dispatch(receivePricebooks(response["data"] ))
          if (callback) { callback(response["data"])} },
          error  => {
            HandleApiError(error);  
            dispatch(receivePricebookError(error));
          }
        ) 
      })
  
  }

export function fetchPricebook(pricebookId, forceRefresh=false) {

  store.dispatch((dispatch, getState) => {


    if (!forceRefresh && getState().pricebook && getState().pricebook.json && getState().pricebook.json.id === pricebookId) {

        console.log("Loading pricebook from cache.", pricebookId) 
        dispatch({ type: constants.RECEIVE_PRICEBOOK_FROM_CACHE });
        return;
    }

    if (forceRefresh &&  getState().pricebook && !pricebookId) {
      pricebookId = getState().pricebook.json.id;
    }

    if (!pricebookId) { return; } 
 
    // First dispatch: the app state is updated to inform
    // that the API call is starting.
    dispatch({ type: constants.REQUEST_PRICEBOOK, pricebookId });


    // API.get("pricebooks", "/pricebooks/"+pricebookId).then(

    //     response => dispatch({
    //       type: constants.RECEIVE_PRICEBOOK,
    //       pricebook: response["data"],
    //       sheetUrl: getUrl(response["data"].lastExportId),
    //       receivedAt: Date.now()
    //     },
    //     error  => {
    //       HandleApiError(error);   
    //     }) 

        
    //   )   

    API.get("pricebooks", "/pricebooks/"+pricebookId).then(
      response => dispatch({
        type: constants.RECEIVE_PRICEBOOK ,
        pricebook: response["data"],
        pricebookId
      }),
      error =>{ 
        console.log(error);
        dispatch({ type: constants.REQUEST_PRICEBOOK_ERROR, pricebookId });
        HandleApiError(error);
      }
    ) 


    API.get("pricebooks", "/pricebooks/revenue/"+pricebookId).then(
        response => dispatch({
          type: constants.RECEIVE_PRICEBOOK_REVENUE,
          revenue: response["data"],
          pricebookId
        }),
        error =>{ 
          console.log(error);
          HandleApiError(error);
        }
      )
    })    

    fetchPricingPages(pricebookId);
}

function getUrl(lastExportId) {

  if (lastExportId) {
      return 'https://docs.google.com/spreadsheets/d/' + lastExportId;
  }

  return "";
}

export async function refreshPricebook(pricebookId) {

  store.dispatch( async (dispatch, getState) => {
    let state = getState();

    // let pricebookId = state.pricebook ? state.pricebook.id : null;
    if (!pricebookId) {
      console.log('cannot refresh pricebook as pricebook.id is null');
       return;
     }

    console.log('refreshing pricebook xxx ', pricebookId);

    dispatch({ type: constants.REQUEST_PRICEBOOK, pricebookId });

    API.get("pricebooks", "/pricebooks/"+pricebookId).then(
      response => dispatch({
        type: constants.RECEIVE_PRICEBOOK,
        pricebook: response["data"],
        pricebookId,
        sheetUrl: getUrl(response["data"].lastExportId),
        receivedAt: Date.now()
      },
      error  => {

        dispatch({ type: constants.REQUEST_PRICEBOOK_ERROR, pricebookId });
        HandleApiError(error);
      })
    );


    API.get("pricebooks", "/pricebooks/revenue/"+pricebookId).then(
      response => dispatch({
        type: constants.RECEIVE_PRICEBOOK_REVENUE,
        revenue: response["data"]
      }),
      error =>{ 
        console.log(error);
        HandleApiError(error);
      }
    )


  });
}

export function archivePricebook(pricebookId, archive=true) {

  store.dispatch( (dispatch, getState) => {

   dispatch({ pricebookId: pricebookId, type: constants.REQUEST_PRICEBOOK_ARCHIVE, archived: archive  });

    let url = (archive) ? "/pricebooks/archive/"+pricebookId : "/pricebooks/unarchive/"+pricebookId;

    API.put("pricebooks", url).then(
      response => dispatch({
        type: constants.RECEIVE_PRICEBOOK_ARCHIVE,
        pricebookId: pricebookId,
        archived: archive,
        receivedAt: Date.now()
      }),
      error  => {
        HandleApiError(error);
        dispatch({
          type: constants.RECEIVE_PRICEBOOK_ARCHIVE_ERROR,
          error,
          receivedAt: Date.now()
        })   
      }) 
  });
}


export function clonePricebook(pricebookId) {

  store.dispatch( (dispatch, getState) => {

   dispatch({ pricebookId: pricebookId, type: constants.REQUEST_PRICEBOOK_CLONE  });

    let url = "/pricebooks/clone/"+pricebookId;

    API.put("pricebooks", url).then(
      response => dispatch({
        type: constants.RECEIVE_PRICEBOOK_CLONE,
        pricebook: response["data"],
        receivedAt: Date.now()
      }),
      error  => {
        HandleApiError(error); 
      
        dispatch({
          type: constants.RECEIVE_PRICEBOOK_CLONE_ERROR,
          error,
          receivedAt: Date.now()
        })
      }
    )
  });
}



export function importPricebook(pricebookText) {

  store.dispatch( (dispatch, getState) => {


    const pricebookJson = JSON.parse(pricebookText);
    const url = "/pricebooks/import";
    const myInit = { body: pricebookJson }


   dispatch({ pricebookJson: pricebookJson, type: constants.REQUEST_PRICEBOOK_IMPORT  });

    
    API.put("pricebooks", url, myInit).then(
      response => dispatch({
        type: constants.RECEIVE_PRICEBOOK_IMPORT,
        pricebook: response["data"],
        receivedAt: Date.now()
      }),
      error  => {
        HandleApiError(error); 
      
        dispatch({
          type: constants.RECEIVE_PRICEBOOK_IMPORT_ERROR,
          error,
          receivedAt: Date.now()
        })
      }
    )
  });
}


export function fetchActuals(pricebookId) {

  store.dispatch( (dispatch, getState) => {


   const pricebookId = getState().pricebook.json.id;
   dispatch({ pricebookId: pricebookId, type: constants.REQUEST_SPREADSHEET_REFRESH  });

   console.log('calling with');

   if (!pricebookId) { console.log("ERROR: pricebookId for current pricebook not set. ");}
   let url =  "/jobs/fetch/" + pricebookId;

    API.put("spreadsheets", url).then(
      response => dispatch({
        type: constants.REQUEST_FETCH_ACTUALS, 
        receivedAt: Date.now()
      }),
      error => {
          HandleApiError(error);
          dispatch({
            type: constants.RECEIVE_FETCH_ACTUALS_ERROR,
            error,
            receivedAt: Date.now()
          })
        }
      )
  });
}
