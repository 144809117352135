import React from 'react'
import PropTypes from 'prop-types' 
import { formatFrequency } from "../libs/formatHelpers"
import NumberFormat from 'react-number-format';
import Dropdown from "./dropdownControl"
import {  PencilIcon } from '@heroicons/react/solid'
import {  CubeIcon } from '@heroicons/react/outline'

const EditValueCharge = ({ 
    unitsInBase,
    unlimited,
    unit,
    extra,
    unitIncrementPrice,
    unitIncrements,
    frequency,
    id,
    changeFieldHandler
  }) => (
    <> 

    <div className="flex  justify-start flex-wrap	 "> 
    
    <div className="flex  justify-start"><CubeIcon className="h-5 w-5 mr-6 text-gray-500" aria-hidden="true" />{unit} -&nbsp;</div>
    <div className="flex  justify-start flex-wrap	flex-col ">
    
    <div className="flex justify-start">
    &nbsp;
    <Dropdown  
          items={[{name:'Unlimited', key: true}, {name:'Includes', key: false}]}
          currentItem={((unlimited) ? "Unlimited" : "Includes")}
          onSelect={(key)=> changeFieldHandler(id, 'unlimited', !unlimited)}/> 
&nbsp;&nbsp;
    { !unlimited && 
      <div className="flex justify-start">
      <div className="flex  justify-start w-24 text-center  ">      
          <NumberFormat
            thousandSeparator={true}
            className="text-center mx-2 shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"                
            hidden={unlimited}
            value={unitsInBase}
            decimalScale={0}
            isAllowed={values => { const {formattedValue, value} = values; return value >= 0 &&  value <= 100000000} }
            onValueChange={values => {
              const {formattedValue, value} = values;
              changeFieldHandler(id, 'unitsInBase', value)
            }}
          /> 
      </div>

      <PencilIcon className="h-5 w-5 mr-6 text-gray-600" aria-hidden="true" />
      <div className="mx-2">{unit}</div>
      </div>
     }
    </div>

    { !unlimited &&
       
    <>
       <div className="flex justify-start my-3 flex-wrap">  
     
        <Dropdown  
              items={[{name:'with extra', key: true}, {name:'with no extra', key: false}]}
              currentItem={extra ? "with extra" : "with no extras"}
              onSelect={(key)=> changeFieldHandler(id, 'extra', !extra)}/> 
   
        { extra &&
          <>  

            <div className="flex  justify-start w-24 text-center  ">   
              <NumberFormat
                thousandSeparator={true}
                className="text-center mx-2 shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"                
                hidden={(unlimited) || !extra }
                value={unitIncrements}
                decimalScale={0}
                isAllowed={values => { const {formattedValue, value} = values; return value >= 0 &&  value <= 100000000} }
                onValueChange={values => {
                  const {formattedValue, value} = values;
                  changeFieldHandler(id, 'unitIncrements', value)
                }}
              />
            </div>
            <PencilIcon className="h-5 w-5 mr-6 text-gray-600" aria-hidden="true" />

            <div className="mx-2">{unit} for</div>

            <div className="flex  justify-start w-24 text-center  ">     
              <NumberFormat
                thousandSeparator={true}
                className="text-center mx-2 shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"                
                prefix="$"
                hidden={(unlimited) || !extra }
                value={unitIncrementPrice}
                decimalScale={2}
                isAllowed={values => { const {formattedValue, value} = values; return value >= 0 &&  value <= 100000000} }
                onValueChange={values => {
                  const {formattedValue, value} = values;
                  changeFieldHandler(id, 'unitIncrementPrice', value)
                }}
                />
            </div>
            <PencilIcon className="h-5 w-5 mr-6 text-gray-600" aria-hidden="true" />
            <div className="mx-2">per</div>
            
            
            <Dropdown  
                  items = {[{name:'Yearly', key: 'annual'}, {name:'Monthly', key: 'monthly'}, {name:'One Time', key: 'onetime'}]}               
                  currentItem={formatFrequency(frequency)}
                  onSelect={(key)=>  changeFieldHandler(id, 'frequency', key) }/>  
          </>
          
        } 

      </div>  
    </>

    }
    </div>
    </div>
    </>

)

EditValueCharge.propTypes = {
  active: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  frequency: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
}

export default EditValueCharge
 