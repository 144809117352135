import React, { useState }   from "react";  
import { FIELD_TYPE } from '../constants'; 
import { sortRows, filterActive, filterKeyword } from  "../libs/helper"
import SplitButton from "./splitButtonControl"

const isActive = (row) => { 

  if (!row) { return false; }
  const attribtues = row.find(col => col.type === FIELD_TYPE.ATTRIBUTES);
  if (!attribtues) { return false; }
  return attribtues.active;
}


const getRowObjectId = (row) => { 

  if (!row || !Array.isArray(row)) { return false; }

  
  const attribtues = row.find(col => col.type === FIELD_TYPE.ATTRIBUTES);
  if (!attribtues) { return null; }
  return attribtues.id;
}



const isLoadingCheck = (row) => { 
 
  if (!row) { return false; }
  const attribtues = row.find(col => col.type === FIELD_TYPE.ATTRIBUTES);
  if (!attribtues) { return false; }
  return attribtues.loading;
}



function DynamicTableControl ({
    headers=[],
    rows=[],
    details=[],
    filter,
    activeOnly,
    activeObjectId,
    onSelect}
    ) { 

  
        const [order, setOrder] = useState(0);
        const [ascending, setOrderDirection] = useState(true); 
        const [indexRow, setIndex] = useState(0);
        const filtererRows = filterKeyword(rows, filter); 
        let modifiedRows = filterActive(sortRows(filtererRows, order, ascending), activeOnly); 
 
        const filtereDetails = filterKeyword(details, filter); 
        let modifiedDetails = filterActive(sortRows(filtereDetails, order, ascending), activeOnly); 

        if (!modifiedRows) { modifiedRows = [] }  


        const indexOfObjectId = modifiedRows.findIndex(row => getRowObjectId(row) === activeObjectId);



        // figure out index row based on current pricebook 
      
        return (
            <>
            <div className="hidden lg:flex -my-2  overflow-auto sm:-mx-6 lg:-mx-4w	">              
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {headers.map((header, index) => (
                           <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              onClick={() => { setOrder(index); setOrderDirection(order === index ? !ascending : true) } }
                            >
                              {header}
                            </th>
                        ))} 
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {modifiedRows.map((row, index) => (
                        <tr key={index} 
                          className={ indexOfObjectId === index ? "bg-gray-200" : "hover:bg-gray-50" }
                          onClick={() => {setIndex(index); onSelect(row.find(r => r.type === FIELD_TYPE.ATTRIBUTES).id)}}> 
                          {row.map((cell, colIndex) => (
                          <React.Fragment key={colIndex}>
                              { (cell.type === FIELD_TYPE.TEXT || cell.type === FIELD_TYPE.NUMBER || cell.type === FIELD_TYPE.CURRENCY) &&                      
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900">{cell.value}</div>                           
                                </td> 
                              } 
                              { (cell.type === FIELD_TYPE.LINK_BUTTON || cell.type === FIELD_TYPE.LINK || cell.type === FIELD_TYPE.NEW_TAB_LINK) &&                      
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900">{cell.value}</div>                           
                                </td> 
                              } 
                              { (cell.type === FIELD_TYPE.PILL) &&                                                      
                                <td className="px-6 py-4 ">
                                  
                                { cell.loading ? 
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                    {cell.value}
                                  </span> :
                                  cell.positive ?
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    {cell.value}
                                  </span> :
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                    {cell.value}
                                  </span>
                                }
                                </td>
                              } 
                               { (cell.type === FIELD_TYPE.BUTTON) &&                      
                                
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                  <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                    {cell.value}
                                  </a>                        
                                </td> 
                              } 
                          </React.Fragment>   
                           ))}                       
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            
            {/* small list */}
            <div className="lg:hidden md:block sm:block bg-gray-100  overflow-hidden sm:rounded-md">
                <ul role="list" className="divide-y divide-gray-200">
                 
                   {modifiedDetails.map((row, index) => (
                      <li key={index}>                        
                        <div className="lg:hidden sm:block md:block  bg-white px-4 py-5  mb-5 shadow sm:rounded-lg sm:px-6  "> 
                    
                          <div className="flex items-start justify-between mb-4 ">      
                               { isLoadingCheck(row) ?  
                                    <>
                                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                      Loading...
                                      </span> 
                                    </>
                                  : 
                                  <>
                                    { isActive(row) ? 
                                        <><span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                            Active
                                        </span> 
                                        </>
                                        :                
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                            Inactive
                                        </span>
                                    }      
                                  </>   
                                }      
                              <SplitButton actions={row.find(entry => entry.type === FIELD_TYPE.BUTTON_GROUP)} /> 
                          </div>
                          <hr/>
                          <br/>
                          {row.map((detailsEntry, index) => (
                              
                              <React.Fragment key={index}>
                                  
                              {detailsEntry.type === FIELD_TYPE.TEXT && 
                                  <div className="mt-6 flow-root">
                                      {detailsEntry.value} 
                                  </div>
                              }    
                              {detailsEntry.type === FIELD_TYPE.HEADER && 
                                  <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                                      {detailsEntry.value}
                                  </h2>
                              }   
                              {detailsEntry.type === FIELD_TYPE.SECTION && 
                                  <div className="mt-6 flow-root text-sm  text-gray-500">
                                    {detailsEntry.value} 
                                </div>
                              }  
                              {detailsEntry.type === FIELD_TYPE.LIST &&  
                                  <ul role="list" className="ml-8 list-disc divide-gray-200">
                                      {detailsEntry.value.map((entry, index) => (
                                          <li key={index}>
                                              {entry}
                                          </li>
                                      ))}
                                  </ul>
                              }  
                              </React.Fragment>                                                                  
                          ))} 
                  
                        </div> 
                      </li>
                    ))}                  
                </ul>
              </div>
            </>
        )

    }



export default (DynamicTableControl);