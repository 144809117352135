/* This example requires Tailwind CSS v2.0+ */
import { PencilIcon } from '@heroicons/react/solid'
import * as actions from '../actions/productEditActions'; 
import * as pricebookSelector from '../libs/pricebookSelector'
import ValueMetricDropdown from "../controls/valueMetricDropdownControl"
import NestedListControl from "../controls/multiOptionsControl"
import { featureObjectsToRows, valueMetricObjectsToRows,multiOptionObjectsToRows, getValueMetricName } from  "../libs/selectors" 
import React, { Component } from "react";
import { connect } from 'react-redux' 
import EditFeaturesControl from "../controls/editFeaturesControl"
import TabControl from "../controls/tabControl"  

const mapDispatchToMultiOptionListProps = dispatch => {
  return {
    changeHandler: (vmId, value) => actions.editMultiOptionField(vmId, 'name', value),      
    tooltipHandler: (vmId, value) => actions.editMultiOptionField(vmId, 'tooltip', value),
    deleteHandler: (vmId) => actions.editMultiOptionField(vmId, 'active', false),
    reorderHandler: (from, to) => actions.reorderMultiOptionHandler(from, to),
    addHandler: () => actions.addProductMultiOption(),
    addOptionHandler: (id) => actions.addProductMultiOptionOption(id),
    deleteOptionHandler: (id, index) => actions.deleteProductMultiOptionOption(id, index),
    editOptionHandler: (id, index, value) => actions.editMultiOptionOptionField(id,index,value)
  }
}

const mapStateToMultiOptionListProps = (state, ownProps) => {

if (!state.productEdit || !state.productEdit.data || !state.productEdit.data.multiOptions) { return {} }

return  {
  items: multiOptionObjectsToRows(state.productEdit.data.multiOptions),
  label: 'Multi Option(s)',
  buttonLabel: 'Add Multi Option',
  placeholder: 'Add new multi option e.g. Support type: Basic : Standard : Premium'
}
}

const MultiOptionsList = connect(
mapStateToMultiOptionListProps,
mapDispatchToMultiOptionListProps)
(NestedListControl)

 
const mapDispatchToValueMetricDropdownProps = (state, ownProps) => {

  return  {
     selectHandler: (valueMetricId) => actions.selectValueMetric(valueMetricId)
   }
}


const mapStateToValueMetricDropdownProps = (state, ownProps) => {

if (!state.pricebook || !state.pricebook.json || !state.productEdit || !state.productEdit.data) { return ; }

return  {
 valueMetrics : state.pricebook.json.valueMetrics.filter(vm => vm.active),
 currentName : getValueMetricName(state.pricebook.json.valueMetrics, state.productEdit.data.valueMetrics)
}
}

const MyValueMetricDropdown = connect(
 mapStateToValueMetricDropdownProps,
 mapDispatchToValueMetricDropdownProps )
 (ValueMetricDropdown)


const mapStateToFeaturesListProps = (state, ownProps) => {

  if (!state.productEdit || !state.productEdit.data) { return {} }

  const toggableFeatures = pricebookSelector.getProductToggableFeatures(state.productEdit.data);

  return  {
    items: featureObjectsToRows(toggableFeatures, true),
    label: 'Toggleable Feature(s)',
    buttonLabel: 'Add Feature'
  }
}


const mapDispatchToFeaturesListProps = dispatch => {
  return {
    changeHandler: (featureId, value) => actions.editFeatureField(featureId, 'name', value),
    tooltipHandler: (featureId, value) => actions.editFeatureField(featureId, 'tooltip', value),
    reorderHandler: (from, to) => actions.reorderFeature(from, to, true),
    deleteHandler: (featureId) => actions.editFeatureField(featureId, 'active', false),
    addHandler: () => actions.addProductFeature(true)
  }
}

const FeaturesList = connect(
  mapStateToFeaturesListProps,
  mapDispatchToFeaturesListProps  )
  (EditFeaturesControl)



const mapStateToIncludedFeaturesListProps = (state, ownProps) => {

  if (!state.productEdit|| !state.productEdit.data)  { return {} }

  const includedFeatures = pricebookSelector.getProductIncludedFeatures(state.productEdit.data);

  return  {
    items: featureObjectsToRows(includedFeatures, false),
    label: 'Included Feature(s)',
    buttonLabel: 'Add Feature'
  }
}

const mapDispatchToIncludedFeaturesListProps = dispatch => {
  return {
    changeHandler: (featureId, value) => actions.editFeatureField(featureId, 'name', value),
    tooltipHandler: (featureId, value) => actions.editFeatureField(featureId, 'tooltip', value),
    deleteHandler: (featureId) => actions.editFeatureField(featureId, 'active', false),
    reorderHandler: (from, to) => actions.reorderFeature(from, to, false),
    addHandler: () => actions.addProductFeature(false)
  }
} 

const IncludedFeaturesList = connect(
  mapStateToIncludedFeaturesListProps,
  mapDispatchToIncludedFeaturesListProps  )
  (EditFeaturesControl)


export const TABS = {
  CONFIG : 'Configuration',
  TOOLTIPS : 'Tooltips'
};

 

export class ProductDetailsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
          currentTab: TABS.CONFIG 
        };
     
      }

      async componentDidMount() {

        let productId = this.props.match.params.productId;
        actions.loadProductEdit(productId);
 
        
      }

      render() {
          
        return (
        <div className="max-w-7xl bg-white m-auto p-10 border rounded-md ">
        <div>
          <div className="flex justify-between	">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Editing {this.props.name}</h3>
            <div>
                <a
                  onClick={() => this.props.cancelEdit()}
                  className="font-medium px-6 text-gray-600 hover:text-gray-500"
                >Cancel</a>          
                <button
                  type="button"
                  onClick={() => this.props.saveEdit(() => this.props.cancelEdit())}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
              Save
            </button>
            </div>
          </div>

          {/* <Button disabled={this.props.isBusy} size="lg" className="floatRight rightAlign btn btn-custom-default" onClick={() => this.props.saveEdit(this.props.cancelEdit)}>Save</Button>
         */}
        </div>

        <div className="mt-5"><TabControl tabs={[{name: TABS.CONFIG}, {name: TABS.TOOLTIPS}]} currentTab={this.state.currentTab} changeHandler={(tab) => this.setState({currentTab: tab})}/></div>
        { this.state.currentTab === TABS.CONFIG ?
            this.getConfiguration()
            :
            this.getTooltips() 
        }

      </div>)
      }

      getTooltips() {
        
        if (!this.props.tooltipEntries) return <></>
        
        return (
          <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
            { this.props.tooltipEntries.map(entry => (
              
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">{entry.name}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"> 
                <div className="flex  justify-start   text-center max-w-3xl ">
                  <input
                        type="text"
                        name="name"
                        value={entry.value}
                        onChange={(event) => {this.props.tooltipHandler(entry.type, entry.id, event.target.value)}}
                        id="name"
                        className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                        placeholder="Type tooltip"
                        />
                      <PencilIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                    
                  </dd>
                </div> 
            ))} 
          </dl>
          </div>
        )
      }

      getConfiguration() {
        return (
            <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Product name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                  <div className="flex  justify-start   text-center max-w-3xl ">
                <input
                      type="text"
                      name="name"
                      value={this.props.name}
                      onChange={(event) => {this.props.editProductFieldHandler('name', event.target.value)}}
                      id="name"
                      className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                      placeholder="Type product name"
                      />
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                  
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Description</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex  justify-start   text-center max-w-3xl ">
                <textarea
                      type="text"
                      name="description"
                      value={this.props.description}
                      onChange={(event) => {this.props.editProductFieldHandler('description', event.target.value)}}
                      id="description"
                      className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                      placeholder="Type product description"
                      />
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Value Metric</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><MyValueMetricDropdown/></dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Multi Options</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <MultiOptionsList/>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">On/Off Features</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <FeaturesList/>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Included Features</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <IncludedFeaturesList/>
                </dd>
              </div>
          
            </dl>
          </div>
      )
    }

}


const mapProductDispatchToProps = (state, ownProps) => {
  return {
    // dispatching plain actions
    editProductFieldHandler: (name, value) => actions.editProductField(name, value),
    
    tooltipHandler: (type, entryId, value) => {
      if (type === TOOLTIPTYPE.FEATURE) {actions.editFeatureField(entryId, 'tooltip', value)}
      else if (type === TOOLTIPTYPE.MULTIOPTION) {actions.editMultiOptionField(entryId, 'tooltip', value)}
      else { console.log("ERROR - unkonwn tooltip type")}
    },
    saveEdit: (callback) => actions.saveProductEdit(callback) ,
    cancelEdit: () => {ownProps.history.push('/products') }
      
  }
}


export const TOOLTIPTYPE = {
  FEATURE : 'feature',
  MULTIOPTION : 'multiOption'
};

 

const mapProductStateToProps = state => {

  if (!state.productEdit || !state.productEdit.data || !state.pricebook.json) {
       return  {}
  }

  let tooltipEntries = [];

  const multiOptions = state.productEdit.data.multiOptions;
  const features = state.productEdit.data.features;

  features.forEach(feature => {
    tooltipEntries.push({name: feature.name, type: TOOLTIPTYPE.FEATURE, id: feature.id, value: feature.tooltip})
  })

  multiOptions.forEach(multiOption => {
    tooltipEntries.push({name: multiOption.name, type: TOOLTIPTYPE.MULTIOPTION, id: multiOption.id, value: multiOption.tooltip})
  })
  
  
  // state.productEdit.data.multiOptions
  return  {
    name: state.productEdit.data ? state.productEdit.data.name : "",
    pageName: state.productEdit.pageName,
    description: state.productEdit.data ? state.productEdit.data.description : "",
    product: state.productEdit.data ? state.productEdit.data : {features : [] },
    products: state.pricebook.json.products,
    isBusy: state.productEdit.isBusy,
    tooltipEntries 
    
  }
}

export default connect(
  mapProductStateToProps,
  mapProductDispatchToProps
)(ProductDetailsPage)
 
 