import { Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
  const { tokens } = useTheme();

  return (
    <>
      <div className="p-4 bg-white border-2 mb-2 border-gray-200 ">
       
        <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">PricingSaaS.com</h1>
        <p className="mt-6 text-center text-gray-900">Pricing optimization made easy</p> 
      </div>
    </>
    
  );
}
