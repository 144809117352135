import React from 'react'
import ValueMetricDropdown from "./valueMetricDropdownControl"
import Dropdown from "./dropdownControl"
import NumberFormat from 'react-number-format';
 
import { PencilIcon, XIcon } from '@heroicons/react/solid'
/* This example requires Tailwind CSS v2.0+ */ 
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const PlanValueCharge = ({ valueMetrics, 
  valueMetricName, 
  unitsInBase, unitIncrements, 
  unitIncrementPrice,  
  billingFrequency,
  tieredPrices=[], 
  tieredPricingEnabled=false,
  changeHandler, 
  addBandPriceHandler, 
  removeBandPriceHandler,
  bandChangeHandler }) => (
  
    <div className="flex justify-start flex-col w-full">
      <div className="flex justify-start ">
      <ValueMetricDropdown valueMetrics={valueMetrics} currentName={valueMetricName} selectHandler={(id) => changeHandler('valueMetricId', id)} />
      </div>
      { valueMetricName && 
      <>
      <div className="flex justify-start my-2 w-full">
      <div>
      This plan&nbsp;
          <Dropdown 
            currentItem={unitsInBase == -1 ? 'has unlimited ' + valueMetricName: 'comes with'}             
            items={[{name: 'limited', key: 1}, {name: 'unlimited', key: -1}] }
            onSelect={(eventKey) => changeHandler('unitsInBase', eventKey) }
            />
      </div>
      { unitsInBase != -1 ?
      <>
        <NumberFormat
          id="amountControl"
          className="text-center	 shadow-sm mx-6 focus:ring-indigo-500  focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
        
          thousandSeparator={true}
          value={unitsInBase}
          decimalScale={2}
          onValueChange={values => {
            const {formattedValue, value} = values;
            changeHandler('unitsInBase', value)
          }}
        />

        <PencilIcon className="h-5 w-5 my-auto " aria-hidden="true" />
        <div className="mx-6">{valueMetricName}</div>
        </>
        :
          <>&nbsp;</>
        }
        </div>

        <div className="flex justify-start my-2 w-full">
        { unitsInBase != -1 &&
        <>
        <div>
        <Dropdown 
            currentItem={unitIncrements == -1 ? 'With no additional ' + valueMetricName : 'With additional '}             
            items={[{name: 'with additional', key: 1}, {name: 'no additional', key: -1}] }
            onSelect={(eventKey) => changeHandler('unitIncrements', eventKey) }
            />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </div>

       { unitIncrements != -1 && 
       <>
        <NumberFormat
          id="amountControl"
          className="text-center	 shadow-sm mx-6 focus:ring-indigo-500  focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
        
          thousandSeparator={true}
          value={unitIncrements}
          decimalScale={2}
          onValueChange={values => {
            const {formattedValue, value} = values;
            changeHandler('unitIncrements', value)
          }}
        />
        

        <PencilIcon className="h-5 w-5 my-auto " aria-hidden="true" />
        <div className="mx-6">{valueMetricName} for</div>
        
        <NumberFormat
            id="unitIncrementPrice"
            className="text-center shadow-sm  focus:ring-indigo-500  focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"      
            thousandSeparator={true}
            prefix="$"
            value={unitIncrementPrice}
            decimalScale={2}
            onValueChange={values => {
              const {formattedValue, value} = values;
              changeHandler('unitIncrementPrice', value)
            }}

          />
        
        <PencilIcon className="h-5 w-5 my-auto " aria-hidden="true" />&nbsp;per {billingFrequency}
        </>}
        </>}
        </div>
        { unitsInBase != -1  && unitIncrements != -1 &&
        <>
        <Switch.Group as="div" className="flex mt-6 items-center">
        <Switch
          checked={tieredPricingEnabled}
          onChange={(value) => changeHandler('tieredPricingEnabled', value) }
          className={classNames(
            tieredPricingEnabled ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              tieredPricingEnabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-gray-900">Tiered Pricing </span>  
        </Switch.Label>
      </Switch.Group>
      </>}
      {tieredPricingEnabled  && unitsInBase != -1 && unitIncrements != -1 && 
        <>
        <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200 mt-2">
            { tieredPrices.map( (tier, index) => 
                (<>
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <NumberFormat
                        id={index + "upperLimit"}
                        className=" mr-2	 shadow-sm mx-6 focus:ring-indigo-500  focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                      
                        thousandSeparator={true}
                        value={tier.upperLimit}
                        decimalScale={2}
                        onValueChange={values => {
                          const {formattedValue, value} = values; 
                          bandChangeHandler(index, 'upperLimit', value)
                        }}
                      />
                      <NumberFormat
                          id={index + "bandPrice"}
                          className="shadow-sm  focus:ring-indigo-500  focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"      
                          thousandSeparator={true}
                          prefix="$"
                          value={tier.price}
                          decimalScale={2}
                          onValueChange={values => {
                            const {formattedValue, value} = values;
                            bandChangeHandler(index, 'price', value)
                          }}

                        />
                      
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      
                      <XIcon className="h-5 w-5 text-gray-600" aria-hidden="true" onClick={() => removeBandPriceHandler(index) } />
                    </div>
                  </li>

                  
                </>)
            )}
                  
                  
        </ul>
      
        <div className="flex justify-start my-2 w-full">
          <a
            onClick={() => addBandPriceHandler() }
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >Add Price Band</a>       
        </div>
        </>
        }
        </>
        }
        
      </div> 
      
)

export default PlanValueCharge
