import React, { Fragment, useState }   from "react";  
import { FIELD_TYPE } from '../constants'; 
import DynamicTableControl from "./dynamicTableControl"
import SplitButton from "./splitButtonControl"
import { Menu, Transition } from '@headlessui/react' 
import { ChevronDownIcon, FilterIcon } from '@heroicons/react/solid' 
import {  ClipboardCopyIcon } from '@heroicons/react/solid'


import { 
    SearchIcon
  } from '@heroicons/react/solid'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const isActive = (row) => { 

  if (!row || !Array.isArray(row)) { return false; }
  const attribtues = row.find(col => col.type === FIELD_TYPE.ATTRIBUTES);
  if (!attribtues) { return false; }
  return attribtues.active;
}


const getRowObjectId = (row) => { 

  if (!row || !Array.isArray(row)) { return false; }

  
  const attribtues = row.find(col => col.type === FIELD_TYPE.ATTRIBUTES);
  if (!attribtues) { return null; }
  return attribtues.id;
}




const isLoadingCheck = (row) => { 
 
  try {
    if (!row || !Array.isArray(row)) { return false; }
    const attribtues = row.find(col => col.type === FIELD_TYPE.ATTRIBUTES);
    if (!attribtues) { return false; }
    return attribtues.loading;
  } catch (e) {
    console.log('error');
    console.log(row);
  }
  
}




const getEntryState = (row) => { 
 
  if (!row) { return '?'; }
  const attribtues = row.find(col => col.type === FIELD_TYPE.ATTRIBUTES);
  if (!attribtues) { return '?'; }
  return attribtues.state;

}

const sortOptions = [
  { name: 'Active'  },
  { name: 'All'  }
]

const filterHTML =  (sortOptionsIndex, callback) => { return  ( 
  <div className="col-start-1 row-start-1 py-4 ">
    <div className="flex justify-start max-w-7xl">
      <Menu as="div" className="relative inline-block">
        <div className="flex">
          <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
            {sortOptions[sortOptionsIndex].name}
            <ChevronDownIcon
              className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {sortOptions.map((option, index) => (
                <Menu.Item key={option.name}>
                  {({ active }) => (
                    <a
                      onClick={() => callback(index)}
                      className={classNames(
                        index === sortOptionsIndex ? 'font-medium text-gray-900' : 'text-gray-500',
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {option.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  </div>
  )}

    

function ListDetailsView ({
    headers=[],
    rows=[],
    details=[],
    selectedDetails,
    isLoading,
    addItemCaption,
    addItemUrl,
    loadDetailsCallback}) {

    // const [selectedDetails, setDetails] = useState(details.length > 0 && isActive(details[0])? details[0] : null );

    const [activeOnly, setActiveOnly] = useState(0);
    const [filter, setFilter] = useState('');

    if (isLoading) { return <div className="text-center text-gray-900 w-full m-auto">Loading ...</div>}

    return <> 
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5  ">
            <div className="flex-shrink-0">
            <div className="max-w-lg w-full lg:max-w-xs">
                <label htmlFor="search" className="sr-only">
                  Filter
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    id="search"
                    name="search"
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
                    placeholder="Filter on anything"
                    type="search"
                    onChange={(e) => { setFilter(e.target.value) } }

                  />
                </div>
              </div>
            </div>       
            {/* <span class="h-6 w-px bg-gray-200" aria-hidden="true"></span> */}
            <FilterIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            {filterHTML(activeOnly, setActiveOnly)}
            
          </div>
          <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          
            <a
              type="button"
              href={addItemUrl}
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            >
              {addItemCaption}
            </a>
          </div>
         </div>

    <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Products Table*/}            
            <section> 
              <DynamicTableControl rows={rows} details={details} headers={headers} filter={filter} 
                activeObjectId={getRowObjectId(selectedDetails)}
                activeOnly={activeOnly === 0} onSelect={(rowId) => 
                { 
                  // fetchPricebook(rowId);
                  {loadDetailsCallback && loadDetailsCallback(rowId);}
                  // setDetails(details.find(d => d.find(de => de.type === FIELD_TYPE.ATTRIBUTES && de.id === rowId)));
                }}/>        
            </section>
          </div>
          {/* Details Section */}   
          { selectedDetails &&
          <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
            {selectedDetails ?
            <div className="hidden lg:block  bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6  ">
                
                <div className="flex items-start justify-between mb-4 ">
                    {/* { console.log( selectedDetails) } */}

                    {/* {getEntryState(selectedDetails)} */}
                    { isLoadingCheck(selectedDetails) ?  
                        <>
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                          Loading...
                          </span> 
                        </>
                      : 
                      <>
                        { isActive(selectedDetails) ? 
                            <><span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                            </span> 
                            </>
                            :                
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                Inactive
                            </span>
                        }      
                      </>   
                    }         
                     <SplitButton actions={selectedDetails.find(entry => entry.type === FIELD_TYPE.BUTTON_GROUP)} /> 
                        
                </div>
                <hr/>
                <br/>  
              
                {selectedDetails.map((detailsEntry, index) => (

                  // this should be passed via a param 
                    
                     <React.Fragment key={index}>
                         
                     {detailsEntry.type === FIELD_TYPE.TEXT && 
                        <div className="flow-root  text-gray-700 ">
                            {detailsEntry.value} 
                        </div>
                     }    
                      {detailsEntry.type === FIELD_TYPE.LINK && 
                        <div className="flow-root  text-blue-900 hover:text-blue-600 ">
                            <a href={detailsEntry.url}  target={detailsEntry.newTab ?"_blank" : "_self"} >{detailsEntry.value}</a> 
                        </div>
                     }     
                     {detailsEntry.type === FIELD_TYPE.SPACER && 
                      <div className="flow-root  text-blue-900 hover:text-blue-600 ">
                          &nbsp;
                      </div>
                    } 
                     {detailsEntry.type === FIELD_TYPE.SCRIPT && 
                        <>                          
                            <div className="flow-root resize-y text-sm h-16 text-gray-700 border border-gray-300 overflow-hidden bg-gray-100 p-2">
                                <code>
                                {detailsEntry.value}                              
                                </code>
                            </div> 
                            <div className="mt-2 m-auto inline-flex align-middle  text-blue-900 hover:text-blue-600 " onClick={() => navigator.clipboard.writeText(detailsEntry.value)}>                             
                            <ClipboardCopyIcon className="h-5 w-5 mr-2 text-blue-900 " aria-hidden="true" />
                            <button class="btn ">
                               Copy to clipboard
                            </button>                               
                            </div>
                        </>
                     }     
                     {detailsEntry.type === FIELD_TYPE.HEADER && 
                        <h2 id="timeline-title" className="text-lg font-medium text-gray-800 mt-3">
                            {detailsEntry.value}
                        </h2>
                     } 
                      {detailsEntry.type === FIELD_TYPE.SECTION && 
                          <div className="mt-6 flow-root text-sm text-gray-500 "> 
                            {detailsEntry.value} 
                        </div>
                      }  
                    {detailsEntry.type === FIELD_TYPE.LIST &&  
                        <ul role="list" className="ml-8 list-disc divide-gray-200 text-gray-700">
                            {detailsEntry.value.map((entry, index) => (
                                <li key={index}>
                                    {entry}
                                </li>
                            ))}
                        </ul>
                    }  
                     </React.Fragment>                                                                  
                ))} 
             
              
            </div>
            :
            <></>
            }
          </section>
          }
        </div>
        </>
} 

export default (ListDetailsView);