

import React, { Component } from "react";
import { connect } from 'react-redux' 

import ListDetailsView from '../controls/listDetailsViewControl'
import { productObjectsToRows, productObjectsToRowDetails, productObjectsToDetails } from  "../libs/selectors"
import { loadProductEdit, setupDefaultProductEdit } from "../actions/productEditActions"

const mapStateToDynamicTableProps = (state) => {
 
    if (!state.pricebook || !state.pricebook.json) return {};
   
    console.log(state.pricebook.isPricebookLoading)
    return  { 
      addItemCaption: 'Add Product',
      addItemUrl: '/products/details/new',
      isLoading: state.pricebook.isPricebookLoading, 
      headers: ['Status', 'Product', 'Value Metric', 'Multi-Options', 'Features'],
      rows: productObjectsToRows(state.pricebook.json.products),
      details: productObjectsToRowDetails(state.pricebook.json.products), 
      selectedDetails: productObjectsToDetails(state.productEdit ? state.productEdit.data : null),
    } 
  } 


  const mapDispatchToProps = dispatch => {
    return { 
      loadDetailsCallback: (id) => loadProductEdit(id)
    }
  }
  
  
  const ProductsListDetailsView = connect(
    mapStateToDynamicTableProps, mapDispatchToProps )(ListDetailsView)

export class ProductsPage extends Component {
    constructor(props) {
        super(props);
     
      }


      async componentDidMount() {
         
        setupDefaultProductEdit();
      }

      render() {
          
        return <ProductsListDetailsView/>
      }

}


export default ProductsPage