import React, { Component } from "react";
import { PencilIcon } from '@heroicons/react/solid'
import Dropdown from "../controls/dropdownControl"

import ProductSelectorControl from "../controls/productSelectorControl"

import EditChargeValue from "../controls/editChargeValueControl"
import {formatFrequency2, getFrequency } from "../libs/formatHelpers" 
import { connect } from 'react-redux'
import { 
  savePlanEdit,
  editPlanField,
  editChargeField, 
  editPlanValueChargeField,
  addPlanValueChargeBandField,
  removePlanValueChargeBandField,
  editPlanValueChargeBandField,
  editChargeDiscountField,
  editPlanProductField,
  editPlanAddonField, 
  showProductSelector,
  setupPlanEdit,
  showProductConfigurator
} from '../actions/planEditActions' 
import EditDiscount from '../controls/editDiscountControl'  
import AddonSelectorControl from "../controls/addonSelectorControl"
import PlanValueCharge from "../controls/planValueChargeControl"
import * as pricebookSelector from '../libs/pricebookSelector'
import {  getValueMetricName2 } from  "../libs/selectors" 
import { FREQUENCIES } from "../constants";


  const mapStatePlanValueChargeProps = (state, ownProps) => {

    if (!state.pricebook || !state.pricebook.json || 
        !state.planEdit || !state.planEdit.plan || !state.planEdit.plan.valueCharges || !state.planEdit.plan.valueCharges[0]) { return }

    const valueMetrics = state.pricebook.json.valueMetrics.filter(vm => vm.active);
    const valueCharge = state.planEdit.plan.valueCharges.find(vc => !vc.addonProductRefId);
 
    if (!valueCharge) { return; } 
    
     return  {
      valueMetrics : valueMetrics,
      valueMetricName : getValueMetricName2(valueMetrics, valueCharge.valueMetric),
      unitsInBase: valueCharge.unitsInBase,
      unitIncrements: valueCharge.unitIncrements, 
      unitIncrementPrice: valueCharge.unitIncrementPrice,
      billingFrequency: getFrequency(ownProps.parentCharge.frequency),
      tieredPricingEnabled: valueCharge.tieredPricingEnabled,
      tieredPrices: valueCharge.bandPrices, //[{upperLimit : 100, price: 6.99 }, {upperLimit : 100, price: 6.99 }]
      lastUpdated : state.planEdit.plan.lastUpdated
    }
  }

  const mapDispatchPlanValueChargeProps = dispatch => {
    return {  
      changeHandler: (name, value) => editPlanValueChargeField(name, value), 
      bandChangeHandler: (index,name,value) => editPlanValueChargeBandField(index,name,value),
      addBandPriceHandler: () => addPlanValueChargeBandField(),
      removeBandPriceHandler: (index) => removePlanValueChargeBandField(index)
      
    }
  }


  const MyPlanValueCharge = connect(
    mapStatePlanValueChargeProps,
    mapDispatchPlanValueChargeProps )(PlanValueCharge)


  const mapStateToBasechargeProps = (state, ownProps) => {

    if (!ownProps.charge) { return {}}

    return  {
      id : ownProps.charge.id, // Charge Object id
      active : ownProps.charge.active,
      hidden : ownProps.hidden,
      price : ownProps.charge.price,
      frequency : ownProps.charge.frequency,
      discountActive : ownProps.discount.active,
      discountValue : ownProps.discount.value,
      discountType : ownProps.discount.type,
      discountDuration : ownProps.discount.duration,
      discountId : ownProps.discount.id,
      discount: ownProps.discount
    }
  }

  const mapDispatchTooBasechargeProps = dispatch => {
    return {
      // dispatching plain actions
      editChargeFieldHandler: (chargeId, name, value) => editChargeField(chargeId, name, value),
      editChargeDiscountFieldHandler: (discountId, name, value) => editChargeDiscountField(discountId, name, value)
    }
  }

  const BaseCharge = connect(
    mapStateToBasechargeProps,
    mapDispatchTooBasechargeProps )(EditDiscount)


    const mapStateToBasechargeValueProps = (state, ownProps) => {

      if (!ownProps.charge) { return {}}

      const monthlyPrice = (ownProps.charge.monthlyPrice === null ||ownProps.charge.monthlyPrice === undefined) ? 
        (ownProps.charge.frequency === 'annual' ? ownProps.charge.price / 12 : ownProps.charge.price) : 
        (ownProps.charge.monthlyPrice);

      return  {
        id : ownProps.charge.id, // Charge Object id
        active : ownProps.charge.active,
        hidden : ownProps.hidden,
        currentValue : monthlyPrice,
        oneTime : ownProps.charge.frequency === FREQUENCIES.ONETIME
        
      }
    }
  
    const mapDispatchTooBasechargeValueProps = (dispatch, ownProps) => {


      return {
        // dispatching plain actions
        onChange: (name, value) => {

          const price = ownProps.charge.frequency === 'annual' ? value * 12 : value;
          
 
          editChargeField(ownProps.charge.id, 'monthlyPrice', value);
          editChargeField(ownProps.charge.id, 'price', price);
        }, 
      }
    }
  
    const BaseChargeValue = connect(
      mapStateToBasechargeValueProps,
      mapDispatchTooBasechargeValueProps )(EditChargeValue)
  

  const mapStateToFrequencyeProps = (state, ownProps) => {

    console.log(ownProps);
    if (!ownProps.charge) { return { items: [], currentItem: ''} }

    return  {
      items: [
        {name:'Yearly', key:  FREQUENCIES.YEARLY}, 
        {name:'Monthly', key: FREQUENCIES.MONTHLY}, 
        {name:'One Time', key: FREQUENCIES.ONETIME}, 
        {name:'Free', key:  FREQUENCIES.FREE}, 
        {name:'Custom', key: FREQUENCIES.CUSTOM}],
      currentItem: formatFrequency2(ownProps.charge) 
    }
  }

  const mapDispatchToFrequencyeProps = (dispatch,ownProps) => {
    return {
      // dispatching plain actions
      onSelect: (eventKey, event) => {

        if (ownProps.charge.monthlyPrice) {
          const price = (eventKey === 'annual' ? ownProps.charge.monthlyPrice * 12 : ownProps.charge.monthlyPrice);
          editChargeField(ownProps.charge.id, 'price', price)
        }
        editChargeField(ownProps.charge.id, 'frequency', eventKey)
      }
    }
  }

  const BillingFreuqncy = connect(
    mapStateToFrequencyeProps,
    mapDispatchToFrequencyeProps )(Dropdown)

 
  const mapStateToAddonListProps = (state) => {
 
    if (!state.planEdit || !state.planEdit.plan) return <></>
    
    const planAddons = pricebookSelector.getPlanAddons2(state.planEdit.plan, state.planEdit.products, true);
    const discounts = state.planEdit.plan.discounts;
    const availableProducts = pricebookSelector.getAvailableProductsForAddons(state.planEdit.plan, state.planEdit.products, true);
    const addonCharges = state.planEdit.plan.addonCharges.filter( addon => addon.active ) ;
    const valueMetrics = state.pricebook.json.valueMetrics.filter(vm => vm.active);
    
    return  {
      valueMetrics, 
      addonCharges,
      discounts,
      selectedProducts: planAddons, 
      availableProducts: availableProducts,
      products: state.planEdit.products,
      plan: state.planEdit.plan,
      label: 'Add-ons' 

    }
  }

  const mapDispatchToAddonListProps = dispatch => {
    return {
      // dispatching plain actions
      showSelectorHandler: () => showProductSelector(true, false),
      showConfiguratorHandle: (product, addonChargeId) => showProductConfigurator(true,product, true, addonChargeId),
      removeProductHandler: (productId, chargeId) => editPlanAddonField(productId, 'checked', false, chargeId), 
      editPlanProductFieldHandler: (productId, name, value) => editPlanAddonField(productId, name, value)
    }
  }
   
  const PlanAddonList = connect(
    mapStateToAddonListProps,
    mapDispatchToAddonListProps )(AddonSelectorControl)

    // product plan

  const mapStateToPlanProductProps = (state) => {

    if (!state.planEdit || !state.planEdit.plan) { return {}}

    // delete once refactored
     const discounts = state.planEdit.plan.discounts;
     const addonCharges = state.planEdit.plan.addonCharges.filter( addon => addon.active ) ;

    const planProducts = pricebookSelector.getPlanProducts2(state.planEdit.plan, state.planEdit.products, true);
    const availableProducts = pricebookSelector.getAvailableProducts(state.planEdit.plan, state.planEdit.products, true);

    
    return  {
      addonCharges,
      discounts,
      selectedProducts: planProducts, 
      availableProducts: availableProducts,
      products: state.planEdit.products,
      plan: state.planEdit.plan,
      label: 'Products' 

    }
  }

  const mapDispatchToPlanProductProps = dispatch => {
    return {
      // dispatching plain actions
      showSelectorHandler: () => showProductSelector(true, false),
      showConfiguratorHandle: (product, addonChargeId) => showProductConfigurator(true,product, true, addonChargeId),
      removeProductHandler: (productId) => editPlanProductField(productId, 'checked', false), 
      editPlanProductFieldHandler: (productId, name, value) => editPlanProductField(productId, name, value)
      
    }
  }

  const PlanProductList = connect(
    mapStateToPlanProductProps,
    mapDispatchToPlanProductProps )(ProductSelectorControl)

 
 


const mapStateToProps2 = (state, ownProps)  => {

  if (!state.planEdit || !state.planEdit.plan) {
      return  {
        loading: true
      }
  } 

  const free = pricebookSelector.getBaseCharge(state.planEdit.plan).frequency === FREQUENCIES.FREE;
  const custom = pricebookSelector.getBaseCharge(state.planEdit.plan).frequency === FREQUENCIES.CUSTOM;
 
  return  {
    label: 'Products',
    info: '',
    loading: false,
    pageName: state.planEdit.pageName,
    name: state.planEdit.plan.name,
    description: state.planEdit.plan.description,
    free,
    custom,
    baseCharge: pricebookSelector.getBaseCharge(state.planEdit.plan),
    baseDiscount: pricebookSelector.getBaseDiscount(state.planEdit.plan),
    currency: pricebookSelector.getBaseCharge(state.planEdit.plan).currency,
    products: state.planEdit.products,
  }
}

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    // dispatching plain actions
    editPlanFieldHandler: (name, value) => editPlanField(name, value), 
    editChargeFieldHandler: (chargeId, name, value) => editChargeField(chargeId, name, value),

    saveEdit: (callback) => savePlanEdit(callback),
   
    cancelEdit: () => {ownProps.history.push('/plans') }
  }
}

export class PlanEditor extends Component {
  constructor(props) {
    super(props); 
  }  

  async componentDidMount() {

    let planId = this.props.match.params.planId;
    setupPlanEdit(planId);
  }

  render() {
    return <>
    <div className="max-w-7xl bg-white m-auto p-10 border rounded-md ">
        <div>
          <div className="flex justify-between	">

            <h3 className="text-lg leading-6 font-medium text-gray-900">{this.props.pageName}</h3>
            <div>
                <a
                  onClick={() => this.props.cancelEdit()}
                  className="font-medium px-6 text-gray-600 hover:text-gray-500"
                >Cancel</a>          
                <button
                  type="button"
                  onClick={() => this.props.saveEdit(() => this.props.cancelEdit())}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
              Save
            </button>
            </div>
          </div>

          <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Plan name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                <div className="flex  justify-start   text-center max-w-3xl ">
              <input
                    type="text"
                    name="name"
                    value={this.props.name}
                    onChange={(event) => {this.props.editPlanFieldHandler('name', event.target.value)}}
                    id="name"
                    className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder="Type plan name"
                    />
                   <PencilIcon className="h-5 w-5" aria-hidden="true" />
                   </div>
                 
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="flex  justify-start   text-center max-w-3xl ">
              <textarea
                    type="text"
                    name="description"
                    value={this.props.description}
                    onChange={(event) => {this.props.editPlanFieldHandler('description', event.target.value)}}
                    id="description"
                    className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder="Type product description"
                    />
                   <PencilIcon className="h-5 w-5" aria-hidden="true" />
                   </div>
              </dd>
            </div> 

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Billing Frequency</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="flex  justify-start   text-center max-w-3xl ">
                <BillingFreuqncy charge={this.props.baseCharge}/> 
              </div>
              </dd>
            </div>


            {/* {!this.props.free && !this.props.custom && */}
            {!this.props.custom &&

              <>
               <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Charge</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex  justify-start   text-center max-w-3xl "> 
                    <BaseChargeValue charge={this.props.baseCharge}  />              
                </div> 
                </dd>
              </div> 
              
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Currency</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex  justify-start   text-center max-w-3xl ">                   
                <div className="flex  justify-start   text-center max-w-3xl ">
                    <input
                        type="text"
                        name="currency"
                        value={this.props.currency}
                        onChange={(event) => {
                          this.props.editChargeFieldHandler(this.props.baseCharge.id, 'currency', event.target.value)}}
                        
                        id="currency"
                        className="shadow-sm focus:ring-indigo-500 w-20 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                        placeholder="USD"
                        />
                    <PencilIcon className="h-5 w-5  text-gray-600" aria-hidden="true" />
                   </div>
                </div> 
                </dd>
              </div> 
              
             
              </>
            } 


            {/* {!this.props.free && !this.props.custom && */}
            {!this.props.custom &&
            <>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Discount</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="flex  justify-start   text-center max-w-3xl ">
              <BaseCharge charge={this.props.baseCharge} discount={this.props.baseDiscount} />
                </div>
              </dd>
            </div>

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Value Metric</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="flex  justify-start   text-center max-w-3xl ">
              
              
           <MyPlanValueCharge parentCharge={this.props.baseCharge}/>
                </div>
              </dd>
            </div>
            </>
            } 

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Products</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="flex  justify-start   text-center max-w-3xl ">
              
              
                <PlanProductList/>
                </div>
              </dd>
            </div>

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Add-ons</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="flex  justify-start   text-center max-w-3xl ">
              
              
                <PlanAddonList/>
                </div>
              </dd>
            </div>
            </dl>
            </div>

            
        </div>
    </div>
   
    </>
  } 
}

export default connect(
  mapStateToProps2,
  mapDispatchToProps
)(PlanEditor)
