import React from 'react' 
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, XIcon } from '@heroicons/react/solid'
 
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function StandardDropdown({items, value, selectHandler}) {
  if (!value) { value = "No value metric selected"}
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex  w-full rounded-md shadow-sm  py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          {value}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            
          <Menu.Item>
            <Menu.Button className="inline-flex justify-start text-left ">                      
              <div onClick={() => selectHandler(null)} className="inline-flex justify-start text-left text-gray-700 block  py-4 text-sm  w-56 border-b-2 border-gray-200 hover:bg-gray-200" >
             <XIcon className="mr-1 ml-2   h-5 w-5 text-gray-500" aria-hidden="true" />No Value Metric
              </div>
            </Menu.Button>                    
          </Menu.Item>
          <div className="py-1">
            {items.map(item => 
              <Menu.Item >
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => selectHandler(item.id)}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {item.name}
                </a>
              )}
              </Menu.Item>              
            )}            
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const ValueMetricDropdown = ({ valueMetrics, currentName, disabled, selectHandler }) => (
  StandardDropdown({items : valueMetrics ? valueMetrics : [], value:currentName, selectHandler })
 
)

export default ValueMetricDropdown
