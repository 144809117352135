 
import React, {  useState } from 'react';

export default function SigninContent({callback}) {

  const [image, setImage] = useState('google2.png');

  return (
    <> 
      <div className="min-h-full flex items-center justify-center ">
        <div className="max-w-md w-full">
          <div>
            {/* <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            />          
            <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">PricingSaaS.com</h1>
            <p className="mt-6 text-center text-gray-900">Optimize your SaaS pricing and payment funnel</p> */}
             <div className="min-h-full flex items-center justify-center py-6 " >
                <img
                alt="login"
                onMouseOver={()=> setImage( "/google3.png") }
                onMouseOut={()=> setImage("/google2.png") }
                onClick={() => callback()} 
                src={image}/>
            </div>
            <hr/>
          </div>
        </div>
      </div>
    </>
  )
}
