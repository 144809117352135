
import { connect } from 'react-redux' 
import React,{ useState } from 'react' 
import { toggleProductFeature, toggleChargeFeature } from '../actions/planEditActions'
import * as pricebookSelector from '../libs/pricebookSelector'

const EditChargeFeatures = ({
    charge, 
    featureList,
    selectedFeatures=[],
    allFeatures,
    plan, 
    toggleAddonFeature,
    toggleChargeFeature
 }) => {
   
    let label = '';
    if (!selectedFeatures || selectedFeatures.length === 0) {
        label = 'no optional features';
    } else {
        label = allFeatures ? 'all optional features' : `${selectedFeatures.length} optional feautre ${selectedFeatures.length > 1 ? 's': ''}`;
    }
 
    
    if (featureList.length === 0) return <></>

    return (
    <>
    <div className="w-full flex flex-col flex-start">

        <div className="text-gray-400 text-xs flex flex-start">Features</div>
        <div>
        {featureList.map((feature, index) => (
                <div className="w-full flex flex-start m-auto">
                    
                    <div className="flex flex-start my-1 text-left">
                    
                     {selectedFeatures.find(sFeature => sFeature.id === feature.id) ?
                            <input type="checkbox"  id={charge.id + feature.id} name={feature.id}
                            className="my-auto"
                            onClick={(event)=> {
                                toggleAddonFeature(plan, feature.id, false);
                                toggleChargeFeature(charge, feature.id, false);
                            }}
                            checked/>
                        :
                            <input type="checkbox"   id={charge.id + feature.id} name={feature.id}
                            className="my-auto"
                            onClick={(event)=> {
                                toggleAddonFeature(plan, feature.id, true);
                                toggleChargeFeature(charge, feature.id, true);
                            }}
                            />
                     }
                     
                    <div className="ml-2 ">{feature.name}</div>
                     </div>   
                 
                 </div>
        ))} 
        </div>        
       
    </div>
    </>)
}


const mapStateToEditAddonProps = (state, ownProps) => {


    const productToggableFeatures = pricebookSelector.getProductToggableFeatures( ownProps.product ); 
    const planToggableFeatures = pricebookSelector.getChargeProductToggableFeatures( ownProps.charge, ownProps.product );

    const featureList = productToggableFeatures.map( (pTF) => { return { id: pTF.id, name: pTF.name}});
    const selectedFeatures = [...planToggableFeatures.map( (pTF) => { return { id: pTF.id }})];

    return  {
        allFeatures:  planToggableFeatures.length === productToggableFeatures.length,
        featureList : featureList,
        charge: ownProps.charge,
        plan : ownProps.plan,
        selectedFeatures : selectedFeatures
    }
  }
  
  const mapDispatchToEditAddonProps = dispatch => {
    return {
        toggleAddonFeature: (plan, featureId, value) => toggleProductFeature(plan, featureId, value),
        toggleChargeFeature: (charge, featureId, value) => toggleChargeFeature(charge, featureId, value)
        
    }
  }
  
  
  export default connect(
    mapStateToEditAddonProps,
    mapDispatchToEditAddonProps )(EditChargeFeatures)
  

