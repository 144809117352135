import { Heading, useTheme } from "@aws-amplify/ui-react";
import { Auth, Hub } from 'aws-amplify';
import SigninContent from '../controls/signinControl'

export function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
     <SigninContent callback={() => Auth.federatedSignIn({ provider: 'Google' })}/> 
    </Heading>
  );
}
