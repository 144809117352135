import React from "react";
import {
  ChromePicker,
  SketchPicker,
  PhotoshopPicker,
  GithubPicker,
  TwitterPicker,
  SwatchesPicker,
  CompactPicker
} from "react-color";
 
import { PencilIcon } from '@heroicons/react/solid'

export class ColorControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false, 
      color: {
        r: "0",
        g: "9",
        b: "153",
        a: "1"
      }
    };
  }
  onHandleShowColorPicker = () => {
    this.setState({ displayColorPicker: true });
  };
  onHandleCloseColorPicker = () => {
    this.setState({ displayColorPicker: false });
  };

  onChangeColorPicker = color => {
    this.setState({ color: color.rgb, changeColor: color.hex });
    this.props.onColorChange(color);

    
  };

  generatePickerByType = (type, color) => {
    if (type === "Chrome") {
      return (
        <ChromePicker
          color={color}
          onChange={this.onChangeColorPicker}
        />
      );
    } else if (type === "Sketch") {
      return (
        <SketchPicker
          color={color}
          onChange={this.onChangeColorPicker}
        />
      );
    } else if (type === "Photoshop") {
      return (
        <PhotoshopPicker
          color={color}
          onChange={this.onChangeColorPicker}
        />
      );
    } else if (type === "Github") {
      return (
        <GithubPicker
          color={color}
          onChange={this.onChangeColorPicker}
        />
      );
    } else if (type === "Twitter") {
      return (
        <TwitterPicker
          color={color}
          onChange={this.onChangeColorPicker}
        />
      );
    } else if (type === "Swatches") {
      return (
        <SwatchesPicker
          color={color}
          onChange={this.onChangeColorPicker}
        />
      );
    } else if (type === "Compact") {
      return (
        <CompactPicker
          color={color}
          onChange={this.onChangeColorPicker}
        />
      );
    } else {
      return (
        <SketchPicker
          color={color}
          onChange={this.onChangeColorPicker}
        />
      );
    }
  };

  render() {
 
    return (
      <div >
        
        <div style={this.props.labelStyle || this.props.defaultLabelStyle} />
        {/* <div
          style={{ backgroundColor: this.props.color }}
          className={"color-picker-color-background"}
        /> */}
        <div className={"color-text-with-popup"}>
        <div className="flex  justify-start">
          <div 
                className="border border-gray-300"
                onClick={() => this.onHandleShowColorPicker()}
                style={{ 
                    width: 40,
                    height: 20,
                    backgroundColor: this.props.color 
                }}
          ></div>
          <input
            readOnly 
            className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md ml-6" 
            type="text"
            name="color-picker-text"
            value={this.props.color}
            onClick={() => this.onHandleShowColorPicker()}
          />
          <PencilIcon className="h-5 w-5" aria-hidden="true" 
            onClick={() => this.onHandleShowColorPicker()} />
          {this.state.displayColorPicker && (
            <div className={"color-picker-palette"}>
              <div
                className={"color-picker-cover"}
                onClick={() => this.onHandleCloseColorPicker()}
              />
              {this.generatePickerByType(this.props.pickerType || "Chrome", this.props.color)}
            </div>
          )}
         
        </div>
        </div>
      </div>
    );
  }
}

ColorControl.defaultProps = {
  defaultTitle: "Color Picker",
  defaultLabelStyle: {
    paddingBottom: "7px",
    fontSize: "11px"
  },
  defaultColorTextBoxStyle: {
    height: "35px",
    border: "none",
    borderBottom: "1px solid lightgray",
    paddingLeft: "35px"
  }
};

export default ColorControl;