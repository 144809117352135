import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppMain from './AppMain'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux' 
import { PersistGate } from 'redux-persist/integration/react'
import { store,persistor} from './store'; 
 
ReactDOM.render(
  <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <Router>
     <AppMain />
   </Router>
 </PersistGate>
 </Provider>,
 document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
