import React from "react";  
import * as constants from '../constants'; 
import Dropdown from './dropdownControl'

function getControl(type, object, value, options, id, planId, disabled, valueChangeHandler) {
  let retValue = '';

  switch(type) {
     case constants.FIELD_TYPE.BOOLEAN:
       return <div className="pl-5"><input disabled={disabled} type="checkbox" checked={value} id={"check_" + id} value="Bike" onClick={() => valueChangeHandler(planId, id, !value, type, object)}/></div>
    case constants.FIELD_TYPE.DROPDOWN:
      return <Dropdown                       
                items={options.map( (option, optionIndex) => { return {...option, key:optionIndex}})} 
                currentItem={value}
                onSelect={(optionIndex)=> valueChangeHandler(planId, id, optionIndex, type, object) }/> 
              
    case constants.FIELD_TYPE.READONLY:
       return value;
   default:
     return
    //  return <div className="m-auto"><Form.Control className="controlTextNarrow" disabled={disabled}  type="text" placeholder="Enter unit" onChange={(event) => { valueChangeHandler(planId, id, event.target.value, type, object)} } autoFocus value={value}/></div>
   }
}

function EditCellControl ({
    type,
    value,
    options=[],
    id,
    planId,
    object,
    disabled,
    valueChangeHandler}) {
    return (
        <>
        {getControl(type, object, value, options, id, planId, disabled, valueChangeHandler)}
        </>)
}

export default (EditCellControl);
