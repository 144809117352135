import {
    
    GLOBAL_LOGOUT,
    SET_WORKSPACE_ID, 
    SET_UP_EDIT_PROFILE
  
  } from '../constants'


const initData = { 
    workspaceId : null
}

export default function userEdit(
    state = initData,
    action
  ) {

     
    switch (action.type) {
      case GLOBAL_LOGOUT:
        return null; // { lastPricebookId : action.lastPricebookId}
      
      case SET_UP_EDIT_PROFILE: 
        return Object.assign({}, state, {          
          workspaceId: action.tenantId
        })
      case SET_WORKSPACE_ID:
        return Object.assign({}, state, {          
          workspaceId: action.tenantId
        })
      default:
        return state
    }
  }
