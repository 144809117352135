
export const GLOBAL_LOGOUT = 'GLOBAL_LOGOUT'
export const CLEAR_EDIT = 'CLEAR_EDIT'
export const CLEAR_PRODUCT_EDIT = 'CLEAR_PRODUCT_EDIT'
export const CLEAR_ASSET_EDIT = 'CLEAR_ASSET_EDIT'
export const INIT = 'INIT'
export const AUTHETNICATE_USER = 'AUTHETNICATE_USER'
export const USER_INITIATED = 'USER_INITIATED'
export const REQUEST_API_CALL = 'REQUEST_API_CALL'
export const REQUEST_API_SUCCESS = 'REQUEST_API_SUCCESS'
export const REQUEST_API_ERROR = 'REQUEST_API_ERROR'
export const INFO_DISMISS_NOTIFICATION = 'INFO_DISMISS_NOTIFICATION'

export const UPDATE_SEGMENT_START = 'UPDATE_SEGMENT_START'
export const UPDATE_SEGMENT_ERROR = 'UPDATE_SEGMENT_ERROR'
export const UPDATE_SEGMENT_SAVE = 'UPDATE_SEGMENT_SAVE'
export const SET_UP_EDIT_SEGMENT = 'SET_UP_EDIT_SEGMENT'
export const SET_UP_EDIT_SEGMENT_ERROR = 'SET_UP_EDIT_SEGMENT_ERROR'


export const SET_WORKSPACE_ID = 'SET_WORKSPACE_ID'
export const SAVE_PROFILE_EDIT = 'SAVE_PROFILE_EDIT'
export const CLEAR_WORKSPACE_CACHE_PRICEBOOK = 'CLEAR_WORKSPACE_CACHE_PRICEBOOK'
export const SAVE_PROFILE_EDIT_ERROR = 'SAVE_PROFILE_EDIT_ERROR'


export const SET_UP_EDIT_PRICING = 'SET_UP_EDIT_PRICING'
export const EDIT_PRICING_FIELD = 'EDIT_PRICING_FIELD'
export const MOVE_PRICING_ROW = 'MOVE_PRICING_ROW'
export const HIDE_PRICING_ROW = 'HIDE_PRICING_ROW'
export const RENAME_PRICING_ROW = 'RENAME_PRICING_ROW'
export const ADD_PRICING_ROW = 'ADD_PRICING_ROW'
export const RESET_PRICING = 'RESET_PRICING'


export const PRICING_HIDE_PLAN = 'PRICING_HIDE_PLAN'
export const PRICING_REMOVE_HIDE_PLAN = 'PRICING_REMOVE_HIDE_PLAN'


export const SHOW_PRODUCT_SELECTOR = 'SHOW_PRODUCT_SELECTOR'
export const SHOW_PRODUCT_CONFIGURATOR = 'SHOW_PRODUCT_CONFIGURATOR'
export const REQUEST_GOOGLE_REAUTH = 'REQUEST_GOOGLE_REAUTH'
export const EDIT_PROFILE_FIELD = 'EDIT_PROFILE_FIELD'

export const SET_UP_EDIT_SEGMENT_START = 'SET_UP_EDIT_SEGMENT_START'
export const REQUEST_SEGMENT_UPDATE = 'REQUEST_SEGMENT_UPDATE'
export const RECEIVE_SEGMENT_SAVE_ERROR = 'RECEIVE_SEGMENT_SAVE_ERROR'
export const EDIT_SEGMENT_FIELD = 'EDIT_SEGMENT_FIELD'
export const RECEIVE_SEGMENT_SAVE = 'RECEIVE_SEGMENT_SAVE'
export const SET_UP_SEGMENTS_START = 'SET_UP_SEGMENTS_START'
export const SET_UP_SEGMENTS_DONE = 'SET_UP_SEGMENTS_DONE'
export const SET_UP_EXPORT_START = 'SET_UP_EXPORT_START'
export const SET_UP_EXPORT_DONE = 'SET_UP_EXPORT_DONE'
export const EXPORT_TOGGLE_SEGMENTS_SHEET = 'EXPORT_TOGGLE_SEGMENTS_SHEET'
export const EXPORT_NAME_SEGMENTS_SHEET = 'EXPORT_NAME_SEGMENTS_SHEET'
export const EXPORT_UPDATE_OPTION_SHEET = 'EXPORT_UPDATE_OPTION_SHEET'
export const EXPORT_UPDATE_NAME_SHEET = 'EXPORT_UPDATE_NAME_SHEET'
export const EXPORT_UPDATE_NAME_SPREADSHEET = 'EXPORT_UPDATE_NAME_SPREADSHEET'
export const EXPORT_FORM_ERROR = 'EXPORT_FORM_ERROR'
export const EXPORT_START_API_CALL = 'EXPORT_START_API_CALL'
export const EXPORT_FAIL_API_CALL = 'EXPORT_FAIL_API_CALL'
export const EXPORT_END_API_CALL = 'EXPORT_END_API_CALL'
export const EXPORT_FORM_INFO = 'EXPORT_FORM_INFO'
export const EXPORT_STEPS = 'EXPORT_STEPS'
export const EXPORT_SHOW_GOOGLE_OAUTH = 'EXPORT_SHOW_GOOGLE_OAUTH'
export const RECEIVE_EXPORT_SHEET_START = 'RECEIVE_EXPORT_SHEET_START'
export const RECEIVE_EXPORT_SHEET_END = 'RECEIVE_EXPORT_SHEET_END'
export const RECEIVE_IMPORT_SHEET_START = 'RECEIVE_IMPORT_SHEET_START'
export const RECEIVE_IMPORT_SHEET_END = 'RECEIVE_IMPORT_SHEET_END'
export const EXPORT_CLEAR_ERROR = 'EXPORT_CLEAR_ERROR'
export const EXPORT_CLEAR_INFO = 'EXPORT_CLEAR_INFO'
export const EXPORT_HIDE_MODAL = 'EXPORT_HIDE_MODAL'
export const EXPORT_SHOW_MODAL = 'EXPORT_SHOW_MODAL'
export const SET_EXPORT_STATE = 'SET_EXPORT_STATE'
export const EXPORT_HIDE_ACCORDIAN = 'EXPORT_HIDE_ACCORDIAN'
export const EXPORT_SHOW_ACCORDIAN = 'EXPORT_SHOW_ACCORDIAN'
export const SELECT_VARIANT_SEGMENT = 'SELECT_VARIANT_SEGMENT'
export const SELECT_VARIANT_METRIC = 'SELECT_VARIANT_METRIC'
export const SAVE_VARIANT_EDIT = 'SAVE_VARIANT_EDIT'
export const MODIFY_VARIANT_NAME = 'MODIFY_VARIANT_NAME'
export const ADD_VARIANT = 'ADD_VARIANT'
export const EDIT_VARIANT = 'EDIT_VARIANT'
export const DELETE_VARIANT = 'DELETE_VARIANT'
export const CANCEL_VARIANT_EDIT = 'CANCEL_VARIANT_EDIT'
export const UPDATE_EXPORT_HISTORICAL = 'UPDATE_EXPORT_HISTORICAL'
export const UPDATE_EXPORT_FORECAST = 'UPDATE_EXPORT_FORECAST'
export const EXPORT_CREATE_NEW = 'EXPORT_CREATE_NEW'
export const EXPORT_CREATE_NEW_NAME = 'EXPORT_CREATE_NEW_NAME'
export const RESET_EXPORT_CONFIG =  'RESET_EXPORT_CONFIG'
export const PRODUCT_PLANS_EDIT =  'PRODUCT_PLANS_EDIT'
export const PRODUCT_PLANS_UPDATE_FEATURE =  'PRODUCT_PLANS_UPDATE_FEATURE'
export const PRODUCT_PLANS_UPDATE_VC =  'PRODUCT_PLANS_UPDATE_VC'
export const PRODUCT_PLANS_UPDATE_PRODUCT = 'PRODUCT_PLANS_UPDATE_PRODUCT'
export const PRODUCT_PLANS_UPDATE_ADDON = 'PRODUCT_PLANS_UPDATE_ADDON'
export const PRODUCT_PLANS_UPDATE_MS = 'PRODUCT_PLANS_UPDATE_MS'


export const RECEIVE_MAPPINGS_SAVE_ERROR =  'RECEIVE_MAPPINGS_SAVE_ERROR'
export const RECEIVE_MAPPINGS_SAVE =  'RECEIVE_MAPPINGS_SAVE'
export const REQUEST_MAPPINGS_SAVE =  'REQUEST_MAPPINGS_SAVE'

export const SET_TABLE_CONTROL_INDEX =  'SET_TABLE_CONTROL_INDEX'
export const SET_TABLE_CONTROL_ORDER =  'SET_TABLE_CONTROL_ORDER'
export const SET_TABLE_ACTIVE_ONLY =  'SET_TABLE_ACTIVE_ONLY'
export const SET_TABLE_FILTER =  'SET_TABLE_FILTER'

export const RECEIVE_PSEVENTS_ERROR = 'RECEIVE_PSEVENTS_ERROR'
export const RECEIVE_PSEVENTS = 'RECEIVE_PSEVENTS'
export const REQUEST_PSEVENTS = 'REQUEST_PSEVENTS'



export const EXPORT_FORM_STATE = {
  INITALIZING: 'INITALIZING',
  NORMAL: 'NORMAL',
  PRICEBOOK_SELECTION: 'PRICEBOOK_SELECTION',
  REQUIRES_AUTHENTICATION: 'REQUIRES_AUTHENTICATION',
  REQUIRES_REAUTHENTICATION: 'REQUIRES_REAUTHENTICATION',
  REQUIRES_SPREADSHEET: 'REQUIRES_SPREADSHEET',
  REQUIRES_POPULATING: 'REQUIRES_POPULATING',
  NEW_SPREADSHEET: 'NEW_SPREADSHEET',
  BUSY: 'BUSY'
};


export const PAGES = {
  PRODUCTS : 'Products',
  DETAILS: 'Details',
  DASHBOARD : 'Dashboard',
  NEW : 'New',
  PROFILE : 'Profile',
  PLANS : 'Plans',
  PRICING : 'Pricing',
  SEGMENTS : 'segments',
  SHEETS : 'export',
  PRICEBOOKS : 'Pricebooks',
  SPREADSHEETS : 'spreadsheets',
  ASSETS : 'Assets',
  UNKNOWN : 'Unknown'
}


export const FREQUENCIES = {
  ALL : 'all',
  FREE : 'free',
  CUSTOM: 'custom',
  MONTHLY : 'monthly',
  ONETIME : 'onetime',
  YEARLY : 'annual',
  QUARTERLY : 'quaterly' 
}


export const TEXT_SIZE = {
  NORMAL : 'normal',
  LARGE: 'large',
  XLARGE: 'xlarge'
};


export const FIELD_TYPE = {
  BOOLEAN : 'boolean',
  HEADER : 'header',
  STATE : 'state',
  TEXT : 'text',
  READONLY : 'readonly',
  DROPDOWN : 'dropdown',
  LINK : 'link',
  LIST : 'list',
  SECTION : 'section',
  SPACER : 'spacer',
  SCRIPT : 'script',
  LINK_BUTTON : 'linkButton',
  BUTTON_GROUP : 'buttonGroup',
  DOLLAR : 'dollar',
  NUMBER : 'number',
  CURRENCY : 'currency',
  ATTRIBUTES : 'attributes',
  BUTTON : 'button',
  PILL : 'pill',
  NEW_TAB_LINK : 'newTab'
}


// export const FeatureState = {
//   CHECKED: 'CHECKED',
//   UNCHECKED: 'UNCHECKED',
//   ALWAYS_ON: 'ALWAYS_ON'
// }


export const OBJECT_TYPE = {
  PRODUCT : 'product',
  ADDON : 'included',
  PLAN : 'plan'
}


export const FORECAST_METRICS = {
  ARPU: 'ARPU',
  NEW: 'New',
  CHURN: 'Churn',
  REACTIVATIONS: 'Reactivated'
};


export const SPREADSHEET_TYPE = {
  REVENUE: 'revenue',
  SPEC: 'spec'
};

export const SHET_TYPE = {
  SEGMENTS: 'Segments',
  PLANS: 'Plans',
  SUMMARY: 'Plans Summary',
  PRODUCTS: 'Products',
  DASHBOARD: 'Dashboard',
  BASELINE: 'Baseline',
  FORECAST: 'Forecast',
  MOD: 'Mod',
  DELTA : 'Delta',
  PRICELIST : 'Pricelist', 
  ACTUALS : 'Actuals', 
  REVENUE : "Revenue"
};
 

export const MODEL_SHEETS = {
  SEGMENTS: 'Segments',
  PLANS: 'Plans',
  SUMMARY: 'Plans Summary',
  PRODUCTS: 'Products',
  DASHBOARD: 'Dashboard',
  BASELINE: 'Baseline',
  MOD: 'Mod',
  DELTA : 'Delta'
};


export const PLAN_ORDER = {
  DEFAULT : 'default',
  ASCENDING: 'ascending',
  DECENDING: 'decending'
};



export const EDIT_PLAN_SUCCESS = 'EDIT_PLAN_SUCCESS'
export const CHANGE_PLAN = 'CHANGE_PLAN'
export const CHANGE_PLAN_CHARGE = 'CHANGE_PLAN_CHARGE'
export const SET_UP_EDIT_PLAN = 'SET_UP_EDIT_PLAN'
export const SAVE_PLAN_EDIT = 'SAVE_PLAN_EDIT'
export const EDIT_PLAN_PENDING = 'EDIT_PLAN_PENDING'
export const EDIT_PLAN_FIELD = 'EDIT_PLAN_FIELD'
export const EDIT_CHARGE_FIELD = 'EDIT_CHARGE_FIELD'
export const EDIT_VALUECHARGE_FIELD = 'EDIT_VALUECHARGE_FIELD'
export const EDIT_PLAN_VALUECHARGE_FIELD = 'EDIT_PLAN_VALUECHARGE_FIELD'

export const ADD_PLAN_VALUECHARGE_BAND_FIELD = 'ADD_PLAN_VALUECHARGE_BAND_FIELD'
export const REMOVE_PLAN_VALUECHARGE_BAND_FIELD = 'REMOVE_PLAN_VALUECHARGE_BAND_FIELD'
export const EDIT_PLAN_VALUECHARGE_BAND_FIELD = 'EDIT_PLAN_VALUECHARGE_BAND_FIELD'

export const EDIT_CHARGE_DISCOUNT_FIELD = 'EDIT_CHARGE_DISCOUNT_FIELD'
export const EDIT_ADDON_FIELD = 'EDIT_ADDON_FIELD'
export const EDIT_PLAN_PRODUCT_FIELD = 'EDIT_PLAN_PRODUCT_FIELD'
export const EDIT_PLAN_ADDON_FIELD = 'EDIT_PLAN_ADDON_FIELD'
export const TOGGLE_PRODUCT_FEATURE = 'TOGGLE_PRODUCT_FEATURE'
export const TOGGLE_CHARGE_FEATURE = 'TOGGLE_CHARGE_FEATURE'
export const SELECT_PRODUCT_MULTIOPTION = 'SELECT_PRODUCT_MULTIOPTION'

export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER'
export const SELECT_PRODUCT = 'SELECT_PRODUCT'
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const RECEIVE_PRODUCTS_ERROR = 'RECEIVE_PRODUCTS_ERROR'
export const SELECT_PLAN = 'SELECT_PLAN'
export const REQUEST_PLANS = 'REQUEST_PLANS'
export const RECEIVE_PLANS = 'RECEIVE_PLANS'

export const SET_UP_EDIT_PRICEBOOK = 'SET_UP_EDIT_PRICEBOOK'
export const SET_UP_EDIT_PRICEBOOK_ERROR = 'SET_UP_EDIT_PRICEBOOK_ERROR'
export const PRICEBOOK_VIEW_SORT = 'PRICEBOOK_VIEW_SORT'
export const RECEIVE_PRICEBOOK_EDIT = 'RECEIVE_PRICEBOOK_EDIT'
export const EDIT_PRICEBOOK_FIELD = 'EDIT_PRICEBOOK_FIELD'
export const RECEIVE_PRICEBOOK_SAVE = 'RECEIVE_PRICEBOOK_SAVE'
export const RECEIVE_PRICEBOOK_SAVE_ERROR = 'RECEIVE_PRICEBOOK_SAVE_ERROR'
export const REQUEST_PRICEBOOK_SAVE = 'REQUEST_PRICEBOOK_SAVE'
export const SET_UP_EDIT_PRICEBOOK_START = 'SET_UP_EDIT_PRICEBOOK_START'

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const SET_UP_EDIT_PRODUCT = 'SET_UP_EDIT_PRODUCT'
export const SET_UP_EDIT_PRODUCT_ERROR = 'SET_UP_EDIT_PRODUCT_ERROR'
export const RECEIVE_PRODUCT_EDIT = 'RECEIVE_PRODUCT_EDIT'
export const EDIT_PRODUCT_FIELD = 'EDIT_PRODUCT_FIELD'
export const REQUEST_PRODUCT_SAVE = 'REQUEST_PRODUCT_SAVE'
export const RECEIVE_PRODUCT_SAVE = 'RECEIVE_PRODUCT_SAVE'
export const RECEIVE_PRODUCT_SAVE_ERROR = 'RECEIVE_PRODUCT_SAVE_ERROR'
export const SET_UP_EDIT_USER = 'SET_UP_EDIT_USER'
export const REQUEST_USER_SAVE = 'REQUEST_USER_SAVE'
export const RECEIVE_USER_SAVE = 'RECEIVE_USER_SAVE'
export const RECEIVE_USER_SAVE_ERROR = 'RECEIVE_USER_SAVE_ERROR'
export const SET_UP_EDIT_PRODUCT_START = 'SET_UP_EDIT_PRODUCT_START'
export const SET_UP_EDIT_PROFILE  = 'SET_UP_EDIT_PROFILE'
export const SET_UP_EDIT_PROFILE_ERROR = 'SET_UP_EDIT_PROFILE_ERROR';
export const EDIT_PRODUCT_FEATURE_FIELD = 'EDIT_PRODUCT_FEATURE_FIELD'
export const ADD_PRODUCT_FEATURE = 'ADD_PRODUCT_FEATURE'
export const EDIT_PRICEBOOK_VALUEMETRIC_FIELD = 'EDIT_PRICEBOOK_VALUEMETRIC_FIELD'
export const REMOVE_PRICEBOOK_VALUEMETRIC_FIELD = 'REMOVE_PRICEBOOK_VALUEMETRIC_FIELD'
export const END_EDIT_PRICEBOOK_VALUEMETRIC = 'END_EDIT_PRICEBOOK_VALUEMETRIC' 
export const SELECT_PRICEBOOK_VALUEMETRIC = 'SELECT_PRICEBOOK_VALUEMETRIC'
export const EDIT_PRODUCT_VALUEMETRIC_FIELD = 'EDIT_PRODUCT_VALUEMETRIC_FIELD'
export const EDIT_PRODUCT_MULTIOPTION_FIELD = 'EDIT_PRODUCT_MULTIOPTION_FIELD'
export const EDIT_PRODUCT_MULTIOPTION_OPTION_FIELD = 'EDIT_PRODUCT_MULTIOPTION_OPTION_FIELD'
export const ADD_PRODUCT_VALUEMETRIC = 'ADD_PRODUCT_VALUEMETRIC'
export const ADD_PRICEBOOK_VALUEMETRIC = 'ADD_PRICEBOOK_VALUEMETRIC'
export const CANCEL_EDIT_PRICEBOOK_VALUEMETRIC = 'CANCEL_EDIT_PRICEBOOK_VALUEMETRIC'
export const EDIT_PRICEBOOK_VALUEMETRIC = 'EDIT_PRICEBOOK_VALUEMETRIC'
export const ADD_PRODUCT_MULTIOPTION = 'ADD_PRODUCT_MULTIOPTION'
export const ADD_PRODUCT_MULTIOPTION_OPTION = 'ADD_PRODUCT_MULTIOPTION_OPTION'
export const DELETE_PRODUCT_MULTIOPTION_OPTION = 'DELETE_PRODUCT_MULTIOPTION_OPTION'
export const REORDER_PRODUCT_FEATURE = 'REORDER_PRODUCT_FEATURE'
export const REORDER_PRODUCT_VALUEMETRIC = 'REORDER_PRODUCT_VALUEMETRIC'
export const REORDER_PRODUCT_MULTIOPTION = 'REORDER_PRODUCT_MULTIOPTION'

export const REQUEST_PRICEBOOKS = 'REQUEST_PRICEBOOKS'
export const RECEIVE_PRICEBOOKS = 'RECEIVE_PRICEBOOKS'
export const RECEIVE_PRICEBOOKS_ERROR = 'RECEIVE_PRICEBOOKS_ERROR'


export const INITIATE_NEW_PRICEBOOK_FLOW = 'INITIATE_NEW_PRICEBOOK_FLOW'
export const REQUEST_PRICEBOOK_CREATE = 'REQUEST_PRICEBOOK_CREATE'
export const RECEIVE_PRICEBOOK_CREATE_ERROR = 'RECEIVE_PRICEBOOK_CREATE_ERROR'

export const RECEIVE_SAMPLE_PRICEBOOKS = 'RECEIVE_SAMPLE_PRICEBOOKS'
export const REQUEST_SAMPLE_PRICEBOOKS = 'REQUEST_SAMPLE_PRICEBOOKS'
export const RECEIVE_SAMPLE_PRICEBOOKS_ERROR = 'RECEIVE_SAMPLE_PRICEBOOKS_ERROR';

export const SELECT_SAMPLE_PRICEBOOK_TEMPLATE = 'SELECT_SAMPLE_PRICEBOOK_TEMPLATE';
export const SET_NEW_PRICEBOOK_NAME = 'SET_NEW_PRICEBOOK_NAME';
export const SET_NEW_PRICEBOOK_PROP = 'SET_NEW_PRICEBOOK_PROP';
export const ADD_NEW_PRICEBOOK_PLAN = 'ADD_NEW_PRICEBOOK_PLAN';
export const REMOVE_NEW_PRICEBOOK_PLAN = 'REMOVE_NEW_PRICEBOOK_PLAN';
export const REORDER_NEW_PRICEBOOK_PLAN = 'REORDER_NEW_PRICEBOOK_PLAN';

export const SELECT_PRICEBOOK = 'SELECT_PRICEBOOK'

export const SET_CURRENT_PRICEBOOK_PLAN = 'SET_CURRENT_PRICEBOOK_PLAN'
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT'


export const REQUEST_PRICEBOOK = 'REQUEST_PRICEBOOK'
export const REQUEST_PRICEBOOK_ERROR = 'REQUEST_PRICEBOOK_ERROR'
export const RECEIVE_PRICEBOOK = 'RECEIVE_PRICEBOOK'
export const RECEIVE_PRICEBOOK_REVENUE = 'RECEIVE_PRICEBOOK_REVENUE';
export const RECEIVE_PRICEBOOK_ERROR = 'RECEIVE_PRICEBOOK_ERROR'
export const RECEIVE_PRICEBOOK_FROM_CACHE = 'RECEIVE_PRICEBOOK_FROM_CACHE'
export const CLEAR_PRICEBOOKVIEW = 'CLEAR_PRICEBOOKVIEW'

export const REQUEST_USER = 'REQUEST_USER'
export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_WORKSPACE = 'RECEIVE_WORKSPACE'
export const RECEIVE_WORKSPACE_USER = 'RECEIVE_WORKSPACE_USER'
export const REQUEST_EXPORT = 'REQUEST_EXPORT'
export const RECEIVE_EXPORT = 'RECEIVE_EXPORT'
export const RECEIVE_EXPORT_ERROR = 'RECEIVE_EXPORT_ERROR'

export const REQUEST_STORE_USER_GTOKEN = 'REQUEST_STORE_USER_GTOKEN'
export const RECEIVE_STORE_USER_GTOKEN = 'RECEIVE_STORE_USER_GTOKEN'
export const RECEIVE_STORE_USER_GTOKEN_ERROR = 'RECEIVE_STORE_USER_GTOKEN_ERROR'


export const REQUEST_PRICEBOOK_CLONE = 'REQUEST_PRICEBOOK_CLONE'
export const RECEIVE_PRICEBOOK_CLONE = 'RECEIVE_PRICEBOOK_CLONE'
export const RECEIVE_PRICEBOOK_CLONE_ERROR = 'RECEIVE_PRICEBOOK_CLONE_ERROR'

export const REQUEST_PRICEBOOK_IMPORT = 'REQUEST_PRICEBOOK_IMPORT'
export const RECEIVE_PRICEBOOK_IMPORT = 'RECEIVE_PRICEBOOK_IMPORT'
export const RECEIVE_PRICEBOOK_IMPORT_ERROR = 'RECEIVE_PRICEBOOK_IMPORT_ERROR'


export const REQUEST_PRICING_CLONE = 'REQUEST_PRICING_CLONE'
export const RECEIVE_PRICING_CLONE = 'RECEIVE_PRICING_CLONE'
export const RECEIVE_PRICING_CLONE_ERROR = 'RECEIVE_PRICING_CLONE_ERROR'


export const ADD_PLAN = 'ADD_PLAN'
export const REQUEST_PLAN_CLONE = 'REQUEST_PLAN_CLONE'
export const RECEIVE_PLAN_CLONE = 'RECEIVE_PLAN_CLONE'
export const RECEIVE_PLAN_CLONE_ERROR = 'RECEIVE_PLAN_CLONE_ERROR'

export const REQUEST_PRICEBOOK_ARCHIVE = 'REQUEST_PRICEBOOK_ARCHIVE'
export const RECEIVE_PRICEBOOK_ARCHIVE = 'RECEIVE_PRICEBOOK_ARCHIVE'
export const RECEIVE_PRICEBOOK_ARCHIVE_ERROR = 'RECEIVE_PRICEBOOK_ARCHIVE_ERROR'

export const REQUEST_PRICING_ARCHIVE = 'REQUEST_PRICING_ARCHIVE'
export const RECEIVE_PRICING_ARCHIVE = 'RECEIVE_PRICING_ARCHIVE'
export const RECEIVE_PRICING_ARCHIVE_ERROR = 'RECEIVE_PRICING_ARCHIVE_ERROR'

export const REQUEST_PLAN_ARCHIVE = 'REQUEST_PLAN_ARCHIVE'
export const RECEIVE_PLAN_ARCHIVE = 'RECEIVE_PLAN_ARCHIVE'
export const RECEIVE_PLAN_ARCHIVE_ERROR = 'RECEIVE_PLAN_ARCHIVE_ERROR'

export const REQUEST_PRODUCT_ARCHIVE = 'REQUEST_PRODUCT_ARCHIVE'
export const RECEIVE_PRODUCT_ARCHIVE = 'RECEIVE_PRODUCT_ARCHIVE'
export const RECEIVE_PRODUCT_ARCHIVE_ERROR = 'RECEIVE_PRODUCT_ARCHIVE_ERROR'


export const REQUEST_SEGMENT_ARCHIVE = 'REQUEST_SEGMENT_ARCHIVE'
export const RECEIVE_SEGMENT_ARCHIVE = 'RECEIVE_SEGMENT_ARCHIVE'
export const RECEIVE_SEGMENT_ARCHIVE_ERROR = 'RECEIVE_SEGMENT_ARCHIVE_ERROR'

export const REQUEST_SPREADSHEET_ARCHIVE = 'REQUEST_SPREADSHEET_ARCHIVE'
export const RECEIVE_SPREADSHEET_ARCHIVE = 'RECEIVE_SPREADSHEET_ARCHIVE'
export const RECEIVE_SPREADSHEET_ARCHIVE_ERROR = 'RECEIVE_SPREADSHEET_ARCHIVE_ERROR'

export const REQUEST_SPREADSHEET_REFRESH = 'REQUEST_SPREADSHEET_REFRESH'
export const RECEIVE_SPREADSHEET_REFRESH = 'RECEIVE_SPREADSHEET_REFRESH'
export const RECEIVE_SPREADSHEET_REFRESH_ERROR = 'RECEIVE_SPREADSHEET_REFRESH_ERROR'


export const REQUEST_PRICING_REFRESH = 'REQUEST_PRICING_REFRESH'
export const RECEIVE_PRICING_REFRESH = 'RECEIVE_PRICING_REFRESH'
export const RECEIVE_PRICING_REFRESH_ERROR = 'RECEIVE_PRICING_REFRESH_ERROR'


export const REQUEST_FETCH_PRICING_PAGES = 'REQUEST_FETCH_PRICING_PAGES'
export const RECEIVE_FETCH_PRICING_PAGES = 'RECEIVE_FETCH_PRICING_PAGES'
export const RECEIVE_FETCH_PRICING_PAGES_ERROR = 'RECEIVE_FETCH_PRICING_PAGES_ERROR'

export const REQUEST_FETCH_ACTUALS = 'REQUEST_FETCH_ACTUALS'
export const RECEIVE_FETCH_ACTUALS = 'RECEIVE_FETCH_ACTUALS'
export const RECEIVE_FETCH_ACTUALS_ERROR = 'RECEIVE_FETCH_ACTUALS_ERROR'


export const REQUEST_PLAN_UPDATE = 'REQUEST_PLAN_UPDATE'
export const RECEIVE_PLAN_SAVE = 'RECEIVE_PLAN_SAVE'
export const RECEIVE_PLAN_SAVE_ERROR = 'RECEIVE_PLAN_SAVE_ERROR'

export const REQUEST_PRODUCT_UPDATE = 'REQUEST_PRODUCT_UPDATE'
export const RECEIVE_PRODUCT_UPDATE = 'RECEIVE_PRODUCT_UPDATE'
export const RECEIVE_PRODUCT_UPDATE_ERROR = 'RECEIVE_PRODUCT_UPDATE_ERROR'

export const REQUEST_PRICEBOOK_UPDATE = 'REQUEST_PRICEBOOK_UPDATE'
export const RECEIVE_PRICEBOOK_UPDATE = 'RECEIVE_PRICEBOOK_UPDATE'
export const RECEIVE_PRICEBOOK_UPDATE_ERROR = 'RECEIVE_PRICEBOOK_UPDATE_ERROR'
export const SET_PRICEBOOK_CLONE_MODAL = 'SET_PRICEBOOK_CLONE_MODAL'
export const SET_PRICEBOOK_CLONE_NAME = 'SET_PRICEBOOK_CLONE_NAME'
export const SET_PRODUCTS_FILTER = 'SET_PRODUCTS_FILTER'
export const SET_PRICEBOOK_PLAN_FILTER = 'SET_PRICEBOOK_PLAN_FILTER'
export const SET_PRICEBOOK_PLAN_CLONE = 'SET_PRICEBOOK_PLAN_CLONE'
export const SET_PRICEBOOKS_FILTER = 'SET_PRICEBOOKS_FILTER'

export const SET_UP_EDIT_SPREADSHEET_START = 'SET_UP_EDIT_SPREADSHEET_START'
export const SET_UP_EDIT_SPREADSHEET = 'SET_UP_EDIT_SPREADSHEET'
export const EDIT_SPREADSHEET_FIELD = 'EDIT_SPREADSHEET_FIELD'
export const UPDATE_SPREADSHEET_START = 'UPDATE_SPREADSHEET_START'
export const UPDATE_SPREADSHEET_DONE = 'UPDATE_SPREADSHEET_DONE'
export const UPDATE_SPREADSHEET_ERROR = 'UPDATE_SPREADSHEET_ERROR'
export const RECEIVE_SPREADSHEET_SAVE = 'RECEIVE_SPREADSHEET_SAVE'
export const RECEIVE_SPREADSHEET_SAVE_ERROR = 'RECEIVE_SPREADSHEET_SAVE_ERROR'
export const REQUEST_SPREADSHEET_UPDATE = 'REQUEST_SPREADSHEET_UPDATE'

export const REQUEST_GET_SPREADSHEETS = 'REQUEST_GET_SPREADSHEETS'
export const RECEIVE_GET_SPREADSHEETS = 'RECEIVE_GET_SPREADSHEETS'
export const RECEIVE_GET_SPREADSHEETS_ERROR = 'RECEIVE_GET_SPREADSHEETS_ERROR'



export const REQUEST_GET_PRICING = 'REQUEST_GET_PRICING'
export const RECEIVE_GET_PRICING = 'RECEIVE_GET_PRICING'
export const RECEIVE_GET_PRICING_ERROR = 'RECEIVE_GET_PRICING_ERROR'

export const RECEIVE_PRICING_SAVE = 'RECEIVE_PRICING_SAVE'
export const RECEIVE_PRICING_SAVE_ERROR = 'RECEIVE_PRICING_SAVE_ERROR'

export const REQUEST_PRICING_CREATE = 'REQUEST_PRICING_CREATE'
export const RECEIVE_PRICING_CREATE = 'RECEIVE_PRICING_CREATE'
export const RECEIVE_PRICING_CREATE_ERROR = 'RECEIVE_PRICING_CREATE_ERROR'


export const REQUEST_GET_SPREADSHEETS_SPECS = 'REQUEST_GET_SPREADSHEETS_SPECS'
export const RECEIVE_GET_SPREADSHEETS_SPECS = 'RECEIVE_GET_SPREADSHEETS_SPECS'
export const RECEIVE_GET_SPREADSHEETS_SPECS_ERROR = 'RECEIVE_GET_SPREADSHEETS_SPECS_ERROR'

export const REQUEST_GET_PRICING_SPECS = 'REQUEST_GET_PRICING_SPECS'
export const RECEIVE_GET_PRICING_SPECS = 'RECEIVE_GET_PRICING_SPECS'
export const RECEIVE_GET_PRICING_SPECS_ERROR = 'RECEIVE_GET_PRICING_SPECS_ERROR'

export const PRICEBOOK_VIEW_SORTBY = {
  NAME: 'Name',
  PRICE: 'Price',
  BILLINGS: 'Billings',
  PRODUCTS : 'Products',
  ADDONS : 'Addons'
};


export const ROLES = {
  ADMIN: 'admin' 
};