import React  from "react"; 
import ReactDragListView from 'react-drag-listview';
import {   XIcon } from '@heroicons/react/solid'
import { CheckCircleIcon } from '@heroicons/react/outline'

function NestedListControl ({label='', items=[], buttonLabel='', placeholder='', changeHandler, deleteHandler, addHandler, addOptionHandler,  editOptionHandler, deleteOptionHandler, tooltipHandler=null, reorderHandler=null}) {
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (reorderHandler) {
        reorderHandler(fromIndex, toIndex);
      } 
    },
    nodeSelector: 'li',
    handleSelector: 'a',
    lineClassName: 'dragLine'
  };

  return <> 
            <div className="w-full">
              <ReactDragListView {...dragProps}>
                <ol>
                  {items.map((item, index) => (
                    <>
                    <li key={index}>
                    
                      <div className="flex justify-between	">

                      <div className="flex justify-start text-center min-w-600 w-full ">
                        <input
                          type="text"
                          name="name"
                          value={item.title}
                          onChange={(event) => {changeHandler(item.id, event.target.value)}}
                          id="name"
                          className="  shadow-sm focus:ring-indigo-500  w-full mr-5 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                          placeholder="Type name"
                          />
                      </div>
                      <a
                              onClick={() => deleteHandler(item.id)}
                              className="font-medium text-indigo-600 hover:text-indigo-500"
                            >Remove</a>
                            </div>
                    </li>
                    { item.options.map((optionItem, index) => (
                        <> 
                          <li>
                          <div className="flex justify-start text-center  my-4">
                            <CheckCircleIcon  className="h-5 w-5 mx-3 text-gray-600" aria-hidden="true" />
                            <input
                              type="text"
                              name="name"
                              value={optionItem.name}
                              onChange={(event) => {editOptionHandler(item.id, index, event.target.value)}}
                              id="name"
                              className="w-full shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md" 
                              placeholder="Enter option"
                              />
                            <XIcon className="h-5 w-5 text-gray-600" aria-hidden="true" onClick={() => deleteOptionHandler(item.id, index)} />
                          </div>                          
                         
                          </li>
                        </>
                        )   
                    )}
                    <li>

                          <div className="flex justify-start text-center  my-4">
                            <CheckCircleIcon  className="h-5 w-5 mx-3 text-gray-600" aria-hidden="true" />
                            <a
                              onClick={() => addOptionHandler(item.id)}
                              className="font-medium text-indigo-600 hover:text-indigo-500"
                            >Add Option</a>                           
                          </div>    
                    </li>
                    </>
                  ))}
                  
                </ol>
              </ReactDragListView>
              <div className="mt-10">
              <a
                 onClick={() => addHandler()}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >Add Multi Option</a>
              </div>
               
            </div>
        </>
}

export default (NestedListControl);
