 
import { connect } from 'react-redux' 
import React from 'react'  
import { toggleProductFeature, toggleChargeFeature, selectProductMultiOption } from '../actions/planEditActions'
import Dropdown from "./dropdownControl"
import { CheckCircleIcon } from '@heroicons/react/outline'

const getCurrentItem = (multiOption, multiSelects) => {

    const multiSelect = multiSelects.find(ms => ms.multiOptionId === multiOption.id);

    if (!multiSelect) { console.log("ERROR - multiselect not found!!"); return "No option selected"; }

    const multiSelectIndex = multiSelect.selectIndex;
    const multiOptionName = multiOption.options[multiSelectIndex] ? multiOption.options[multiSelectIndex].name : "";

    return multiOptionName;
    
}

const EditChargeMultiOptions = ({
    
    multiOptions,
    multiSelects, 
    charge,
    selectProductMultiOption,
 }) => {
 
    const activeMultiOptions = multiOptions.filter( mo => mo.active);

    if (activeMultiOptions.length === 0) return <></>

    return (
    <>
    <div className="w-full flex flex-col flex-start">
         <div className="text-gray-400 text-xs flex flex-start">Multi Options</div>
         <div>

        {activeMultiOptions.map((multiOption, index) => (
                
                <div className="flex flex-start flex-col  my-2">

                    <div className="grid grid-flow-col auto-cols-max">
                    <div className="flex flex-start text-left">
                   
                    <div className="w-60 ">{multiOption.name}</div>&nbsp;
                    </div>
                     <CheckCircleIcon  className="h-5 w-5 mr-3 text-gray-600" aria-hidden="true" />
                    <div className="w-full flex flex-start"> 
                    <Dropdown 
                        
                        items={multiOption.options.map( (option, optionIndex) => { return {...option, key:optionIndex}})} 
                        currentItem={getCurrentItem(multiOption, multiSelects)}
                        onSelect={(optionIndex)=> selectProductMultiOption(multiOption.id, optionIndex, charge)}/> 

                    </div>
                    
                    </div>
 
                     
                 </div>
        ))} 
        </div>        
       
    </div>
    </>)
}


const mapStateToEditAddonProps = (state, ownProps) => {
 
    let multiSelects = [];

    if (ownProps.charge &&  ownProps.charge.multiSelects) {
        multiSelects =  ownProps.charge.multiSelects;
    } else {
        multiSelects =  ownProps.plan.multiSelects; 
    } 

    return  {
        multiSelects, 
        multiOptions : ownProps.product.multiOptions,
        charge: ownProps.charge
    }
  }
  
  const mapDispatchToEditAddonProps = dispatch => {
    return {
        selectProductMultiOption: (multiOptionId, selectIndex, charge)=> selectProductMultiOption(multiOptionId, selectIndex, charge),
        toggleAddonFeature: (plan, featureId, value) => toggleProductFeature(plan, featureId, value),
        toggleChargeFeature: (charge, featureId, value) => toggleChargeFeature(charge, featureId, value),
        
    }
  }
   
  export default connect(
    mapStateToEditAddonProps,
    mapDispatchToEditAddonProps )(EditChargeMultiOptions)
  