
import React, { useState }   from "react";  
 

const layouts = [
    {
      name: 'Tiered',
      keyword: 'tiered',
      svg:  <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-blue-600 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
  </svg>
    },
    {
    name: 'Per User',
        keyword: 'user',
        svg: <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
    },
    {
    name: 'Usage',
        keyword: 'usage',
        svg: 
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clip-rule="evenodd" />
      </svg>
    },

     
  ]
 
function getLayouts(templates) {

    const layoutTemplates = layouts;

    layouts.forEach(layout => {

         const template = templates.find( t => t.name.toLowerCase().includes(layout.keyword));

         if (template) {
             layout.templateId = template.id;
         } else {
            layout.templateId = null;
         }
    })

    return layoutTemplates;

}

export default function PricingTypeControl({templates, currentTemplateId, changeHandler}) {
 
    const [currentIndex, setIndex] = useState(-1);
    const templateLayouts = getLayouts(templates);

    const currentTemplateIndex = currentTemplateId ? templateLayouts.findIndex(t => t.templateId === currentTemplateId) : -1;

 
    return ( 
        <> 
           <div className="bg-white ">
               
            <div className="mx-auto py-12 px-4 w-full  ">
                <div className="space-y-12">        
                <ul
                    role="list"
                    className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
                >
                    {templateLayouts.map((layout, index) => (
                    <li key={layout.name}>
                        <div className="space-y-4 flex justify-center">
                        <div
                            onClick={() => { setIndex(index); changeHandler(layout.templateId) }} 
                            className={`aspect-w-3 aspect-h-2 object-cover shadow-lg rounded-lg p-20 ${currentTemplateIndex === index ? "bg-gray-300" : "hover:bg-gray-100" }`}>
                            
                            <div className="p-2 text-3xl  flex justify-between  "> 
                            <div className='px-2'>{layout.svg}</div>
                            <div>{layout.name}</div>
                            </div>
                        </div>
                        </div>
                        
                    </li>
                    ))}
                </ul>
                </div>
            </div>
            </div>
            
        </>
    )
}
  