import React from 'react'
import { fetchPricebook } from "../actions/pricebookActions"

import { connect } from 'react-redux'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, CogIcon } from '@heroicons/react/solid'
import { }from "../actions/pricebookActions"


const mapStateToProps = (state, ownProps) => {

   if (!state.pricebooks || !state.pricebooks.items) { return { pricebookLoading: true }}

  return {
    pricebooks: state.pricebooks.items.filter( p => p.active), 
    currentName: state.pricebook && state.pricebook.json ? state.pricebook.json.name : "Select",
    pricebookLoading: state.pricebook ? state.pricebook.isLoading : false
  }
}


const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    onSelect: (pricebookId) => fetchPricebook(pricebookId, true)    
  }
}

function RenderContent(pricebooks, pricebookName, onSelect, largeStyle=false) {

  if (!pricebooks) return <></>
  
  const buttonStyle = largeStyle ? 'underline' : 'text-sm';

  return (
    <Menu as="div" className="relative inline-block text-left z-40">
      <div>
        <Menu.Button className={buttonStyle + " inline-flex justify-center w-full rounded-md  bg-white font-medium text-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
          {pricebookName}
          {!largeStyle &&
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          }
          
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items   className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          
          {!largeStyle &&
            <Menu.Item>
            <Menu.Button className="inline-flex justify-start text-left ">                      
                    <div className="inline-flex justify-start text-left text-gray-700 block px-4 py-4 text-sm  w-56 border-b-2 border-gray-200 hover:bg-gray-200" >
                    <a className="inline-flex justify-start text-left" href="/pricebooks" ><CogIcon className="mr-1 ml-2   h-5 w-5 text-gray-500" aria-hidden="true" /> Manage Pricebooks</a>
                    </div>
                    </Menu.Button>                    
            </Menu.Item>
          }
          <div className="py-1">
            { pricebooks.map((pricebook,index) =>  (
                    <Menu.Item>

                  <React.Fragment key={index}> 
                     <Menu.Button className="inline-flex justify-start text-left ">
                      <div className="text-gray-700 block px-4 py-2 text-sm  w-56 border-t-2 border-gray-50 hover:bg-gray-200" 
                        onClick={ () => onSelect(pricebook.id)}>
                        {pricebook.name} 
                      </div>
                    </Menu.Button>
                    

                </React.Fragment>   
                    {/* {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {pricebook.name}
                      </a>
                    )} */}
                    </Menu.Item>  
            ))}
            
          
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
 

const PricebookDropdownFn = ({ pricebooks, currentName, onSelect, largeStyle}) => (
  RenderContent(pricebooks, currentName, onSelect, largeStyle) 
)

const PricebookDropdown = connect(mapStateToProps, mapDispatchToProps)(PricebookDropdownFn)

export default PricebookDropdown;

