import {
    RECEIVE_FETCH_PRICING_PAGES,
    RECEIVE_GET_PRICING_SPECS,
    REQUEST_FETCH_PRICING_PAGES,
    RECEIVE_PRICING_SAVE,
    RECEIVE_PRICING_CREATE,
    RECEIVE_GET_PRICING,
    RECEIVE_PRICING_ARCHIVE,
    RECEIVE_PRICING_CLONE,
    RECEIVE_PRICING_REFRESH,
    GLOBAL_LOGOUT,
    INITIATE_NEW_PRICEBOOK_FLOW  
  } from '../constants'


const initData = { 
    isAuthenticated: false,
    fetchingPricing: false,
    lastError: ''
}

export default function pricingPages(
    state = initData,
    action
  ) {

    let tempPricing = null;
    let tempPricingPages = null;

    switch (action.type) {
      case GLOBAL_LOGOUT:  
        return null; // { lastPricebookId : action.lastPricebookId}
      case INITIATE_NEW_PRICEBOOK_FLOW:
        return Object.assign({}, state, {
          data: []
        })
      case RECEIVE_GET_PRICING_SPECS:
        return Object.assign({}, state, {
          specs: action.specs
        })
      case REQUEST_FETCH_PRICING_PAGES:
        return Object.assign({}, state, {
          fetchingPricing: true
        }) 
      case RECEIVE_PRICING_SAVE:
      case RECEIVE_PRICING_CREATE:
      case RECEIVE_GET_PRICING:
        tempPricingPages = JSON.parse(JSON.stringify(state.data)).filter(p => p.id !== action.pricing.id);
        tempPricingPages.push(action.pricing);
          
        return Object.assign({}, state, {
          data: tempPricingPages
        }) 
       
      case RECEIVE_FETCH_PRICING_PAGES:
        return Object.assign({}, state, {
          fetchingPricing: false,
          data: action.data
        })
      case RECEIVE_PRICING_REFRESH:
        tempPricingPages = JSON.parse(JSON.stringify(state.data));
        tempPricing = tempPricingPages.find( p=> p.id === action.pricingId);

        if (tempPricing) { tempPricing.isRefreshing = true; }
  
        return Object.assign({}, state, {
          data: tempPricingPages
        }) 
      case RECEIVE_PRICING_CLONE: 

        tempPricingPages = JSON.parse(JSON.stringify(state.data));
        
        if (tempPricingPages.find( p=> p.id === action.pricingPage.id)) {
          tempPricingPages = tempPricingPages.map(p=> { return p.id === action.pricingPage.id ? action.pricingPage : p } );
        } else {
          tempPricingPages.push(action.pricingPage);
        }

        return Object.assign({}, state, {
          data: tempPricingPages
        })  

      case RECEIVE_PRICING_ARCHIVE:

        tempPricingPages = JSON.parse(JSON.stringify(state.data));
        tempPricing = tempPricingPages.find( p=> p.id === action.pricingId);

        if (tempPricing) { tempPricing.active = !action.archived; }
  
        return Object.assign({}, state, {
          data: tempPricingPages
        })
      default:
        return state
    }
  }
