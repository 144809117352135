/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid' 

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dropdown({items, currentItem, primary=false, onSelect}) {

  const buttonColor = primary ? "text-indigo-600 hover:text-indigo-500" : " text-gray-700 hover:bg-gray-50";
   
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={buttonColor + " inline-flex justify-center w-full   bg-white text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
          {currentItem}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1">
          {items.map((item, index) => (
             <>
             <Menu.Item>
              {({ active }) => (
                <a 
                  onClick={() => onSelect(item.key)}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {item.name}
                </a>
              )}
            </Menu.Item>            
             </>   
          ))}
            
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
