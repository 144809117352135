import { withRouter } from "react-router-dom";
import { initalizeUser, logoutUser } from './actions/userActions'
import { configureAmplify } from './libs/amplifyHelper'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getPageFromUrl, getShowPricebookFromPage } from './libs/selectors'
import { authContent, loadingContent } from './AppAuthContent' 
import { getBreadcumbPagesFromUrl ,getShowBreadcrumbsLogic  } from './libs/selectors' 
import { isInitalized } from './libs/selectors'
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Header }  from "./auth/Header";
import { Footer } from "./auth/Footer";
import { SignInHeader } from "./auth/SignInHeader";
import { SignInFooter } from "./auth/SignInFooter";
import '@aws-amplify/ui-react/styles.css'; 

configureAmplify(); 

const mapStateToProps = (state, ownProps) => {
  return { 
    loading: !isInitalized(state),  
    pricebookSelected:  Boolean( state.pricebook && state.pricebook.json)  
  }   
}
  
function AppMain({ signOut, user, loading, pricebookSelected }) {

  const page =  getPageFromUrl(useLocation().pathname);
  const pages = getBreadcumbPagesFromUrl(useLocation().pathname).map( p =>  ({ name: p})); 
  const breadcrumbs = null; // getShowBreadcrumbsLogic(page) ? null : pages;
  const signOutHandler = () => {  logoutUser(); signOut(); }
   
  initalizeUser(user);

  if (loading) {
    return loadingContent();
  }
                    
  return authContent (breadcrumbs, page, pricebookSelected, getShowPricebookFromPage(page), signOutHandler); 
  
}

export default withRouter(withAuthenticator(connect( mapStateToProps)(AppMain), {
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
}));



