import * as constants from '../constants';
import {createProductFeature, createProductValueMetric, createProductMultiOption, createProductMultiOptionOption} from '../libs/factory' 
 
 
export default function productEdit(
  state = {
    data: null, 
    newAndUnsaved: false,
    isBusy: false,
    isLoading: true
  },
  action
) { 
  let feature = null;
  let productId = null;
  let multiOption = null;
  let product = (state && state.data) ? {...state.data} : null;
  
  // eslint-disable-next-line no-extend-native
  Array.prototype.move = function(from,to){
    this.splice(to,0,this.splice(from,1)[0]);
    return this;
  };

  switch (action.type) {
    case constants.GLOBAL_LOGOUT:    
    case constants.CLEAR_PRODUCT_EDIT:
    case constants.RECEIVE_PRICEBOOK:
      return null;
    case constants.SET_UP_EDIT_PRODUCT_START:
      return Object.assign({}, state, {
        isLoading: true
      });
    case constants.SET_UP_EDIT_PRODUCT:

      return Object.assign({}, state, {
        data: action.product, 
        isLoading: false,
        isBusy: false,
        label: "",
        pageName: action.new ? "Add Product" : "Editing " + action.product.name,
        newAndUnsaved: action.new
      });
    case constants.ADD_PRODUCT_FEATURE:

      feature = createProductFeature(state.data.id);
      feature.toggable = action.toggable;

      product.features.push(feature);

      return Object.assign({}, state, {
        changed: true,
        data: product, 
      });

    case constants.SELECT_PRICEBOOK_VALUEMETRIC:
    
      if (action.valueMetricId) { 
        product.valueMetrics = [ {id: action.valueMetricId}]
      } else {
        product.valueMetrics = [];
      }

      return {
        ...state,
        data: product,
        changed: true, 
      };
    case constants.ADD_PRODUCT_VALUEMETRIC:
 
      productId = state.data.id;

      let valueMetric = createProductValueMetric(productId);
      product.valueMetrics.push(valueMetric);

      return {
        ...state,
        data: product,
        changed: true, 
      };
    case constants.ADD_PRODUCT_MULTIOPTION:
 
        productId = state.data.id;
  
        multiOption = createProductMultiOption(productId);
        product.multiOptions.push(multiOption);
  
        return {
          ...state,
          data: product,
          changed: true, 
        };

    case constants.ADD_PRODUCT_MULTIOPTION_OPTION:
 
        multiOption = product.multiOptions.find( mo => mo.id === action.multiOptionId);
         
        multiOption.options.push(createProductMultiOptionOption("", multiOption.options.length));
  
        return {
          ...state,
          data: product,
          changed: true, 
        };
    case constants.DELETE_PRODUCT_MULTIOPTION_OPTION:

      multiOption = product.multiOptions.find( mo => mo.id === action.multiOptionId);
        
      multiOption.options.splice(action.index,1);

      return {
        ...state,
        data: product,
        changed: true, 
      };
    case constants.RECEIVE_PRODUCTS: 
      return Object.assign({}, state, {
        data: null,
        isLoading: true,
        label: ""
      });
   

    case constants.EDIT_PRODUCT_VALUEMETRIC_FIELD:

      state.data.valueMetrics.find(vm => vm.id === action.valueMetricId)[action.name] = action.value;

      return Object.assign({}, state, {
        changed: true,
        data: product,
      });
    case constants.EDIT_PRODUCT_MULTIOPTION_FIELD:

        state.data.multiOptions.find(multiOption => multiOption.id === action.multiOptionId)[action.name] = action.value;
  
        return Object.assign({}, state, {
          changed: true,
          data: product,
        });
    case constants.EDIT_PRODUCT_MULTIOPTION_OPTION_FIELD:

        multiOption = product.multiOptions.find( mo => mo.id === action.multiOptionId); 
        multiOption.options[action.index].name = action.value;

        return Object.assign({}, state, {
          changed: true,
          data: product,
        });
    case constants.EDIT_PRODUCT_FEATURE_FIELD:
      state.data.features.find(f => f.id === action.featureId)[action.name] = action.value;
 
      return Object.assign({}, state, {
        changed: true,
        data: product,
      });

    case constants.REORDER_PRODUCT_VALUEMETRIC:

      product.valueMetrics.move(action.from, action.to);
      return Object.assign({}, state, {
        changed: true,
        data: product,
      });

    case constants.REORDER_PRODUCT_FEATURE:
    
        if (action.toggable) {
          
          let toggableFeatures = [...product.features.filter(tf => tf.toggable && tf.active)];
          toggableFeatures.move(action.from, action.to);
          product.features = toggableFeatures.concat(product.features.filter(tf => !(tf.toggable && tf.active)));
          
          return Object.assign({}, state, {
            data: product
          }); 

        }
        else {

          let includedFeatures = [...product.features.filter(tf => !tf.toggable && tf.active)];
          includedFeatures.move(action.from, action.to);
          product.features = includedFeatures.concat(product.features.filter(tf => !(!tf.toggable && tf.active)));
          
          return Object.assign({}, state, {
            data: product
          }); 
 
        } 

    case constants.EDIT_PRODUCT_FIELD: 
      product[action.name] = action.value;
 
      return Object.assign({}, state, {
        changed: true,
        data: product,
      });
    case constants.REQUEST_PRODUCT_SAVE:
      return Object.assign({}, state, {
        isBusy: true
      });
    case constants.RECEIVE_PRODUCT_SAVE:
      return Object.assign({}, state, {
        label: "",
        data: null,
        isLoading: false,
        isBusy: false
      });
    case constants.RECEIVE_PRODUCT_SAVE_ERROR:
      return Object.assign({}, state, {
        label: "Error saving product: " + action.error,
        isLoading: false,
        isBusy: false
      });
    case constants.REQUEST_PRODUCT_UPDATE:
      product[action.name] = action.value;
      return Object.assign({}, state, {
        label: "",
        isLoading: false,
        data: product,
      });
    default:
      return state;
  }
}
