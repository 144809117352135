import * as factory from './factory'; 
import { formatFrequency, formatFrequency2, formatFrequency3 } from "./formatHelpers";
import { formatCurrency, formatDate, getUrl, getScriptCode } from "./helper";
import * as constants from '../constants';
import {  archiveProduct } from "../actions/productsActions"
import { archiveSpreadsheet,refreshSpreadsheet } from "../actions/spreadsheetActions"
import {  archivePlan, clonePlan } from "../actions/plansActions"
import {  archiveSegment } from "../actions/segmentActions"
import {  archivePricebook, clonePricebook, fetchActuals, refreshPricebook } from "../actions/pricebookActions" 
import * as pricebookSelector from '../libs/pricebookSelector'
import { refreshPricingSync,refreshPricing,  archivePricing, clonePricing } from "../actions/pricingActions"

export function getPlanFrequency(plan, plural=false) {
  if (!plan) { return 'invalid' }

  const baseCharge = pricebookSelector.getBaseCharge(plan);

  return formatFrequency3(baseCharge, plural);
   

} 
export function getPlanPrice(plan) {
  if (!plan) { return '--' }
 
  return `${formatCurrency(plan.minCharge)}/m`;
}

export function checkIfGTokenIsRequired(userState) {
 
  if (userState && userState.reqeustReauth) { 
    return true;
  }

  if (userState && userState.data) {  
    return userState.data.gToken ? false : true;
  }

  return false;
}

export function psEventsToRows(psEvents) {

  let rows = [];

  if (psEvents) {
   rows =  psEvents.map (psEvent =>
      [
        // ({id : product.id, active : product.active , type: constants.FIELD_TYPE.ATTRIBUTES}),
        ({type: constants.FIELD_TYPE.TEXT, value: formatDate(psEvent.timestamp) }),
        ({type: constants.FIELD_TYPE.TEXT, value: psEvent.eventSource }),
        ({type: constants.FIELD_TYPE.TEXT, value: psEvent.eventState }),
        ({type: constants.FIELD_TYPE.TEXT, value: psEvent.message ? psEvent.message.toString() : '' })
      ]
    )
  }

  return rows;

}

export function getValueMetricName(pricebookVMs, productVMs) {
   
  if (!pricebookVMs || !productVMs || productVMs.length === 0) { return null; }

  const productVMid = productVMs[0].id;
  const pricebookVM = pricebookVMs.find(vm => vm.id === productVMid);
 
  return pricebookVM ? pricebookVM.name : null;

}



export function getValueMetricName2(pricebookVMs, planVM) {
   
  if (!pricebookVMs || !planVM ) { return null; }
 
  const pricebookVM = pricebookVMs.find(vm => vm.id === planVM.id);
 
  return pricebookVM ? pricebookVM.name : null;

}

export function getFeatruesSummary(product) {

  let total = 0;
  let totalChecked = 0;
  product.toggableFeatures.forEach((tf) => {
    total += 1;
    if (tf.checked) { totalChecked += 1 }
  });

  return total === 0 ? '-' : totalChecked + '/' + total + ' features';
}

export function spreadsheetUpdateModeChoices(autoUpdate=true) {

  let retValue = [{
    text : "Auto",
    id: 'auto',
    checked: autoUpdate,
    disabled: false
  },
  {
    text : "Manual",
    id: 'manual',
    checked: !autoUpdate,
    disabled: false
  }];
  return retValue;
}

export function chargeAndDisocuntAmountSummary(charge, discount) {

  let summary = '';
  if (charge && charge.active) {
    summary += 'for ' + formatCurrency(charge.price) + ' billed ' + formatFrequency2(charge).toLowerCase();

    if (discount && discount.active) {
      summary = summary + ' with a discount of ' +  (discount.type === 'fixed' ? (formatCurrency(discount.value)) : (discount.value + '%'));
      summary += ' for ' + discount.duration + ' ' + formatFrequency(charge.frequency, discount.duration > 1);
    }
  }
  return summary;

}

export function spreadsheetsTypesToRadioList(spreadsheetType, spreadsheetSpecs) {

  const retValue = []; 

  if (spreadsheetSpecs) {
    spreadsheetSpecs.forEach(spreadsheetSpec => {

      const entry = [];
  
      entry.text = spreadsheetSpec.displayName;
      entry.id = spreadsheetSpec.type;
      entry.checked = spreadsheetSpec.type === spreadsheetType;

      retValue.push(entry);
    })
  }

  console.log(retValue);
  return retValue;
}

export function spreadsheetsToCheckboxList(spreadsheetType, sheets, spreadsheetSpecs) {

  const retValue = [];

  if (spreadsheetSpecs && spreadsheetType && sheets) {
    // This function does this:
    // it takes a set of sheets (DB objects that belong to a spreadsheet instance)
    // it matches them with schema objects
    const spreadsheetSpec = spreadsheetSpecs.find(ss => ss.type === spreadsheetType);
    
    // console.log(spreadsheetSpecs);
    // console.log(spreadsheetType);
    sheets.forEach(sheet => { 
      
      const sheetSchema = spreadsheetSpec.sheets.find(ss => ss.type === sheet.sheetType);
      // console.log(sheet);
      // console.log(spreadsheetSpec);
      retValue.push( {
        text : sheetSchema.defaultName,
        id: sheetSchema.type,
        checked: sheet.active,
        disabled: !sheetSchema.optional
      })
    })
  }

  return retValue;
}

export function featureObjectsToRows(features, toggable) {

  // return [{title:'item1'}, {title:'item2'}, {title:'item3'}];
  let rows = features.map( feature =>
      ({ title: feature.name, id: feature.id, active: feature.active, toggable: feature.toggable, tooltip: feature.tooltip })
  ).filter(r=> r.active && r.toggable === toggable);

  // rows = rows.filter(r => r.active);

  return rows;
}

export function valueMetricObjectsToRows(valueMetrics) {
  // //console.logog('valueMetricObjectsToRows');
  // //console.logog(valueMetrics);

  // return [{title:'item1'}, {title:'item2'}, {title:'item3'}];
  let rows = valueMetrics.map( valueMetric =>
      ({ title: valueMetric.unit, id: valueMetric.id, active: valueMetric.active, tooltip: valueMetric.tooltip })
  ).filter(r=> r.active);

  // rows = rows.filter(r => r.active);

  // //console.logog(rows);

  return rows;
}


export function multiOptionObjectsToRows(multiOptions) {
   let rows = multiOptions.map( multiOption =>
      ({ title: multiOption.name, id: multiOption.id, active: multiOption.active, tooltip: multiOption.tooltip, options: multiOption.options })
  ).filter(r=> r.active);
 
  return rows;
}

export function pricebooksObjectsToRows(pricebooks) {

  if (!pricebooks) { return []; } 
  
  let rows = pricebooks.map( pricebook =>
    [ 

      ({type: constants.FIELD_TYPE.PILL, value: pricebook.isPricebookLoading ? 'Loading' : (!pricebook.active ? 'Inactive' : 'Active'), loading: pricebook.isPricebookLoading, positive: Boolean(pricebook.active)} ),
      ({type: constants.FIELD_TYPE.TEXT, value: pricebook.name  }),    
       
      // ({type: constants.FIELD_TYPE.LINK_BUTTON, value: 'Edit',  url: '/pricebooks/edit/' + pricebook.id }),
      // ({type: constants.FIELD_TYPE.BUTTON, value: 'Fetch', callback: (()=>fetchActuals(pricebook.id))}),
      // ({type: constants.FIELD_TYPE.BUTTON, value: 'Clone', callback: (()=>clonePricebook(pricebook.id))}),
      // ({type: constants.FIELD_TYPE.BUTTON, value: pricebook.active ? 'Inactivate' : 'Activate', callback: (()=>archivePricebook(pricebook.id, pricebook.active))}),
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : pricebook.id, active : pricebook.active, value: '' }),
    ]
  )
  return rows;
}



export function pricebookObjectsToRowDetails(pricebooks ) {

  if (!pricebooks) { return []; }

  let rowWithDetails = [];

  pricebooks.forEach(pricebook => {
    rowWithDetails.push(pricebookObjectToDetails(pricebook, pricebook.isPricebookLoading));
  })

  return rowWithDetails;
 
}


export function pricebookObjectToDetails(pricebook, isLoading ) {

  if (!pricebook) { return []; }

 

    // console.log(pricebook.isPricebookLoading);

    let pricebookState = '';

    if (isLoading) {
      pricebookState = 'Loading'; 
    } else {
      if (pricebook.active) {
        pricebookState =  'Active';
      } else {
        pricebookState = 'Inactive';
      }
    }

    // if (pricebook.isPricebookLoading === true) {
    //   console.log(pricebook.id, pricebookState);
    // } else if (pricebook.isPricebookLoading === false) {
    //   console.log(pricebook.id, pricebookState);
    // }

    let blah = 
  
    [ 
      ({type: constants.FIELD_TYPE.STATE, value:  pricebookState}),
      ({type: constants.FIELD_TYPE.HEADER, value: pricebook.name}),
      ({type: constants.FIELD_TYPE.TEXT, value: pricebook.description}), 
      ({type: constants.FIELD_TYPE.BUTTON_GROUP, value:  
        [          
        {name: 'Clone', callback: () => { clonePricebook(pricebook.id)}},       
        {name: 'Edit', url: `/pricebooks/details/${pricebook.id}`}, 
        {name: 'Refresh', callback: () => { refreshPricebook(pricebook.id)}}, 
        {name: 'Inactivate', callback: () => {  archivePricebook(pricebook.id, pricebook.active) }}, ] 
      }), 
      
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : pricebook.id, active : pricebook.active, state: pricebookState, loading: isLoading  }), 
            
    ];

    if (pricebook.products) {  
      
      blah.push({type: constants.FIELD_TYPE.SPACER});
      blah.push({type: constants.FIELD_TYPE.LINK, value: 'Products', url: '/products'});
      blah.push({type: constants.FIELD_TYPE.LIST, value: pricebook.products.filter(product => product.active).map(p => p.name )}); 
    }

    if (pricebook.plans) {  

      blah.push({type: constants.FIELD_TYPE.SPACER});
      blah.push({type: constants.FIELD_TYPE.LINK, newTab: false, value: 'Plans', url: '/plans'});
      blah.push({type: constants.FIELD_TYPE.LIST, value: pricebook.plans.filter(plan => plan.active).map(p => p.name )}); 
    }
    if (pricebook.valueMetrics) { 
      // blah.push({type: constants.FIELD_TYPE.SECTION, value: "Usage Metrics"})
      blah.push({type: constants.FIELD_TYPE.SPACER});;

      blah.push({type: constants.FIELD_TYPE.LINK, value: 'Usage Metrics',  url: `/pricebooks/details/${pricebook.id}`});
      blah.push({type: constants.FIELD_TYPE.LIST, value: pricebook.valueMetrics.filter(vm => vm.active).map(v => v.name )}); 
    }


    return blah 
}


export function valueMetricPricebookObjectsToRows(valueMetrics) {

  if (!valueMetrics) { return []; } 
  
  let rows = valueMetrics.map( valueMetric =>
    [ 
      ({type: constants.FIELD_TYPE.LINK, value: valueMetric.name, badge: !valueMetric.active ? 'Inactive' : '', url: '/pricebooks/'+ valueMetric.id}),
      ({type: constants.FIELD_TYPE.TEXT, value: (valueMetric.unit) }),
      ({type: constants.FIELD_TYPE.BUTTON, value: valueMetric.active ? 'Inactivate' : 'Activate', callback: (()=>archivePricebook(valueMetric.id, valueMetric.active))}),
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : valueMetric.id, active : valueMetric.active, value: '' }),
    ]
  )
  return rows;
}


export function segmentsObjectsToRows(segments) {

  if (!segments) { return []; }

  let rows = segments.map( segment =>
    [ 
      ({type: constants.FIELD_TYPE.LINK, value: segment.name, badge: !segment.active ? 'Inactive' : '', url: '/segments/edit/' + segment.id}),
      ({type: constants.FIELD_TYPE.TEXT, value: (segment.churnFrequency) }),
      ({type: constants.FIELD_TYPE.TEXT, value: (segment.chartMogulId) }),
      ({type: constants.FIELD_TYPE.BUTTON, value: segment.active ? 'Inactivate' : 'Activate', callback: (()=>archiveSegment(segment.id, segment.active))}),
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : segment.id, active : segment.active, value: '' }),
      
    ]
  )
  return rows;
}


export function pricingPagesObjectsToRows(pricingPages) {

  if (!pricingPages) { return []; }

  console.log(pricingPages);

  let rows = pricingPages.map( pricingPage =>
    [  
      ({type: constants.FIELD_TYPE.PILL, 
        // value: !pricingPage.active ? 'Inactive' : (pricingPage.isRefreshing ? 'Refreshing' : 'Active'),       
        value: !pricingPage.active ? 'Inactive' : 'Active', 
        positive: Boolean(pricingPage.active)} ),
      ({type: constants.FIELD_TYPE.LINK, value:  pricingPage.name, url:'/pricing/edit/' + pricingPage.id, }),
      // ({type: constants.FIELD_TYPE.LINK, value:  pricingPage.name, url: '/pricing/' + pricingPage.id, }),
      ({type: constants.FIELD_TYPE.TEXT, value: pricingPage.isRefreshing ? 'Refreshing...' : formatDate(pricingPage.lastModified) }),
      // ({type: constants.FIELD_TYPE.BUTTON, value:  'Edit', url: '/pricing/edit/' + pricingPage.id, }),
      // ({type: constants.FIELD_TYPE.BUTTON, value:  'Refresh', callback: (()=>refreshPricing(pricingPage.id))}),
      // ({type: constants.FIELD_TYPE.NEW_TAB_LINK, value:  'Preview', url: pricingPage.htmlUrl }),
      // ({type: constants.FIELD_TYPE.BUTTON, value: pricingPage.active ? 'Inactivate' : 'Activate', callback: (()=>archivePricing(pricingPage.id, pricingPage.active))}),      
      // ({type: constants.FIELD_TYPE.BUTTON, value:  'Clone', callback: (()=>clonePricing(pricingPage.id))}),
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : pricingPage.id, active : pricingPage.active, value: '' }), 
    
           
       
    ]
  )
  return rows;
}


export function assetObjectsToRowDetails(pricingPages, specs ) {

  if (!pricingPages) { return []; }
  
  let rowDetails = [];

  pricingPages.forEach(pricingPage => {
    rowDetails.push(assetObjectsToDetails(pricingPage, specs));
  })

    //console.logog(rows);
  return rowDetails;
}


export function assetObjectsToDetails(pricingPage, specs) {

  if (!pricingPage) { return null; }

  let spec = specs.find(spec => spec.id === pricingPage.templateId)
  let templateName = spec? spec.displayName : '';
  

  return ( 
    [ 
      ({type: constants.FIELD_TYPE.HEADER, value: pricingPage.name}),

      ({type: constants.FIELD_TYPE.SECTION, value: "Template"}),      
      ({type: constants.FIELD_TYPE.TEXT, value: templateName}), 
      ({type: constants.FIELD_TYPE.SECTION, value: "Links"}),
      ({type: constants.FIELD_TYPE.LINK, value: "Preview Page", newTab: true, newWindow: true, url:pricingPage.htmlUrl}),      
      ({type: constants.FIELD_TYPE.SECTION, value: "Embed Script"}),
      ({type: constants.FIELD_TYPE.SCRIPT, value: getScriptCode(pricingPage ? pricingPage.loaderUrl : null) }),      
      ({type: constants.FIELD_TYPE.BUTTON_GROUP, value:  
        [
        {name: 'Edit', url: `/assets/details/${pricingPage.id}`}, 
        {name: 'Preview', url:`${pricingPage.htmlUrl}`, target: "_blank"},
        {name: 'Refresh', callback: () => { refreshPricing(pricingPage.id)}},
        {name: 'Clone', callback: () => { clonePricing(pricingPage.id)}},
        {name: pricingPage.active ? 'Inactivate' : 'Activate', callback: () => {  archivePricing(pricingPage.id, pricingPage.active) }}, ] 
      }), 
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : pricingPage.id, active : pricingPage.active, value: '' }), 
            
    ]
  )
 
  }

  // <Button variant="link" onClick={() =>refreshPricingSync(this.props.match.params.pricingId)}>Refresh</Button>

export function isAnySpreadsheetUpdating(spreadsheets) {

  let updating = false;

  // //console.logog('isAnySpreadsheetUpdating');
  // //console.logog(spreadsheets);

  spreadsheets.forEach((spreadsheet) => {
    if (spreadsheet.active && spreadsheet.spreadsheetState !== 'ready') {
      updating = true;
      //console.logog('found one that is updating');
    }
  });

  return updating;
}

export function spreadsheetsObjectsToRows(spreadsheets) {

  if (!spreadsheets) { return []; }
 
  // console.log(spreadsheets);
  
  let rows = spreadsheets.map( spreadsheet =>
    [
      // ({id : product.id, active : product.active , type: constants.FIELD_TYPE.ATTRIBUTES}),


      ({type: constants.FIELD_TYPE.LINK, value: spreadsheet.originalName, badge: !spreadsheet.active ? 'Inactive' : '', newWindow: false, url: '/spreadsheets/edit/' + spreadsheet.id}),
      ({type: constants.FIELD_TYPE.TEXT, value: spreadsheet.spreadsheetState }),
      ({type: constants.FIELD_TYPE.TEXT, value: spreadsheet.spreadsheetType }),
      ({type: constants.FIELD_TYPE.TEXT, value: spreadsheet.autoUpdate ? 'auto' : 'manual' }),
      ({type: constants.FIELD_TYPE.LINK, value: 'Google Link', newWindow: true, url: getUrl(spreadsheet.googleId)}),
      ({type: constants.FIELD_TYPE.TEXT, value: formatDate(spreadsheet.createDate) }),
      ({type: constants.FIELD_TYPE.TEXT, value: formatDate(spreadsheet.updatedDate) }),
      ({type: constants.FIELD_TYPE.BUTTON, value: 'Refresh', callback: (()=>refreshSpreadsheet(spreadsheet.id))}),
      ({type: constants.FIELD_TYPE.BUTTON, value: spreadsheet.active ? 'Inactivate' : 'Activate', callback: (()=>archiveSpreadsheet(spreadsheet.id, spreadsheet.active))}),
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : spreadsheet.id, active : spreadsheet.active, value: '' }),
    ]
  )
  return rows;
}

export function plansObjectsToRows(pricebookId, plans, products) {

  if (!plans) { return []; }

  let rows = plans.map( plan =>
    [ 
      ({type: constants.FIELD_TYPE.PILL, value: !plan.active ? 'Inactive' : 'Active', positive: Boolean(plan.active)} ),
      ({type: constants.FIELD_TYPE.LINK, value: plan.name, badge: !plan.active ? 'Inactive' : '', url: '/plans/details/' + plan.id}),
      ({type: constants.FIELD_TYPE.TEXT, value: plan.charges[0] ? formatFrequency2(plan.charges[0]) : "" }),
      ({type: constants.FIELD_TYPE.CURRENCY, value: formatCurrency(plan.minCharge) }),
      ({type: constants.FIELD_TYPE.CURRENCY, value: formatCurrency(plan.minCharge*12) }),
      ({type: constants.FIELD_TYPE.NUMBER, value: pricebookSelector.getPlanProducts2(plan, products).length}),
      ({type: constants.FIELD_TYPE.NUMBER, value: pricebookSelector.getPlanAddons2(plan, products).length}), 
      
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : plan.id, active : plan.active, value: '' }), 
    ]
  )
  return rows;
}

export function planObjectsToRowDetails(plans, products) {

  if (!plans) { return []; }
 
  let rowDetails = [];

  plans.forEach(plan =>{
    rowDetails.push(planObjectsToDetails(plan, products));
  })

    //console.logog(rows);
  return rowDetails;
}


export function planObjectsToDetails(plan, products) {

  if (!plan) { return null; }

  return (
    [ 
      ({type: constants.FIELD_TYPE.HEADER, value: plan.name}),
      ({type: constants.FIELD_TYPE.TEXT, value: plan.description}),
      ({type: constants.FIELD_TYPE.SECTION, value: "Billing"}),
      ({type: constants.FIELD_TYPE.TEXT, value: plan.charges[0] ? formatFrequency2(plan.charges[0]) : "" }),
      ({type: constants.FIELD_TYPE.SECTION, value: "Monthly Cost"}),
      ({type: constants.FIELD_TYPE.TEXT, value: formatCurrency(plan.minCharge) }),
      ({type: constants.FIELD_TYPE.SECTION, value: "Yearly Cost"}),
      ({type: constants.FIELD_TYPE.TEXT, value: formatCurrency(plan.minCharge*12) }),     
      ({type: constants.FIELD_TYPE.SECTION, value: "Products"}),
      ({type: constants.FIELD_TYPE.LIST, value: pricebookSelector.getPlanProducts2(plan, products).map(prod => prod.name)}),
      ({type: constants.FIELD_TYPE.SECTION, value: "Add-ons"}),
      ({type: constants.FIELD_TYPE.LIST, value: pricebookSelector.getPlanAddons2(plan, products).map(prod => prod.name)}),
      ({type: constants.FIELD_TYPE.BUTTON_GROUP, value: 
        [{name: 'Edit', url: `plans/details/${plan.id}` }, 
        {name: 'Clone', callback: () => { clonePlan(plan.pricebookId, plan.id)  }}, 
        {name: 'Inactivate', callback: () => {  archivePlan(plan.id, plan.active) }}, ] 
      }), 
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : plan.id, active : plan.active, value: '' }), 
            
    ]
  );
}

export function productObjectsToRows(products) {

  if (!products) { return []; }

  let rows = products.map( product =>
    [ 
      ({type: constants.FIELD_TYPE.PILL, value: !product.active ? 'Inactive' : 'Active', positive: Boolean(product.active),  callback: (()=>archiveProduct(product.id, product.active))}),
      ({type: constants.FIELD_TYPE.LINK, value: product.name, badge: !product.active ? 'Inactive' : '', url: '/products/details/' + product.id}),
      ({type: constants.FIELD_TYPE.TEXT, value: product.valueMetrics.filter(vm => vm.active).map((vm, index) => ((index > 0 && index<=product.valueMetrics.length-1) ? ', ' : '') + vm.unit)}),
      ({type: constants.FIELD_TYPE.NUMBER, value: product.multiOptions.filter(f => f.active).length}),
      ({type: constants.FIELD_TYPE.NUMBER, value: product.features.filter(f => f.active).length}),
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : product.id, active : product.active, value: '' }),    
    ]
  )

    //console.logog(rows);
  return rows;
}

export function productObjectsToRowDetails(products) {

  if (!products) { return null; }

  let detailedRows = [];

  products.forEach(product => {
    detailedRows.push(productObjectsToDetails(product))
  })

  return detailedRows;
 
}


export function productObjectsToDetails(product) {

  if (!product) { return null; }
 
  return (
    [ 
      ({type: constants.FIELD_TYPE.HEADER, value: product.name}),
      ({type: constants.FIELD_TYPE.TEXT, value: product.description}),
      ({type: constants.FIELD_TYPE.SECTION, value: "Features"}),
      ({type: constants.FIELD_TYPE.LIST, value: product.features.map(f => f.name )}),
      ({type: constants.FIELD_TYPE.BUTTON_GROUP, value: 
        [{name: 'Edit', url: `products/details/${product.id}`},
        {name: 'Map', url: `products/map/${product.id}`},  
        {name: 'Inactivate', callback: () => { archiveProduct(product.id, product.active) }}, ]
      }),
      ({type: constants.FIELD_TYPE.ATTRIBUTES, id : product.id, active : product.active, value: '' }), 
            
    ] );
 
 
}

// need a mapper

const urlPageMap = [ 
    {name: constants.PAGES.PRODUCTS, url: '/products'},
    {name: constants.PAGES.PLANS, url: '/plans'},
    {name: constants.PAGES.ASSETS, url: '/assets'},

    {name: constants.PAGES.DETAILS, url: '#'}

  ];

export function getUrlFromPage(page) {

  const pair = urlPageMap.find(pm => pm.name === page);

  if (pair) {
    return pair.url;
  }

  return "#";
}

export function getBreadcrumbsPricebookDropdown(page) {

  if (page === constants.PAGES.PROFILE) {
    return false;
  } 
  if (page === constants.PAGES.DASHBOARD) {
    return false;
  }

  if (page === constants.PAGES.NEW) {
    return false;
  }
  if (page === constants.PAGES.DETAILS) {
    return false;
  }

  return  true;
}
export function isInitalized(state) {
  
  // return Boolean( state.user && state.user.data && state.pricebooks);
  return Boolean( state.user && state.user.isInitiated);
}

export function getShowBreadcrumbsLogic(page) {

  return Boolean(page !==constants.PAGES.DETAILS);
}
export function getBreadcumbPagesFromUrl(url) {

  const retValue = [];

  if (url.startsWith('/dashboard')) {
    retValue.push(constants.PAGES.DASHBOARD);
  }
  else if (url.startsWith('/profile')) {
    retValue.push(constants.PAGES.PROFILE);
  } else if (url.startsWith('/products/details')) {
    retValue.push(constants.PAGES.PRODUCTS);
    retValue.push(constants.PAGES.DETAILS);
  } else if (url.startsWith('/plans/details')) {
    retValue.push(constants.PAGES.PLANS);
    retValue.push(constants.PAGES.DETAILS);
  } else {
    const page = getPageFromUrl(url);
    if (page !== constants.PAGES.PRICEBOOKS ){
      retValue.push(page);
    }  
  }

  return retValue;
}
export function isNewPricebookPage(url) {
  return url.startsWith('/pricebooks/details/new');
}

export function getShowPricebookFromPage(page) {
  if ( page === constants.PAGES.NEW) {
    return false;
  }

  return true; 
}

export function getPageFromUrl(url) {

  if (url) {

    if (url.startsWith('/dashboard')) {
      return constants.PAGES.DASHBOARD;
    }
    if (url.startsWith('/profile')) {
      return constants.PAGES.PROFILE;
    }
    if (url.startsWith('/new')) {
      return constants.PAGES.NEW;
    }
    if (url.includes('details')) {
      return constants.PAGES.DETAILS;
    }
    if (url.includes('pricebook')) {
      return constants.PAGES.PRICEBOOKS;
    }
    else if (url.includes('products/plans/')){
      return constants.PAGES.PRODUCTS;
    }
    else if (url.includes('plans')) {
      return constants.PAGES.PLANS;
    }
    else if (url.includes('pricing')) {
      return constants.PAGES.PRICING;
    }
    else if (url.includes('segment')) {
      return constants.PAGES.SEGMENTS;
    }
    else if (url.includes('export')) {
      return constants.PAGES.SHEETS;
    }
    else if (url.includes('product')) {
      return constants.PAGES.PRODUCTS;
    }
    else if (url.includes('spreadsheets')) {
      return constants.PAGES.SPREADSHEETS;
    }
    else if (url.includes('assets')) {
      return constants.PAGES.ASSETS;
    }
    else {
      return constants.PAGES.DASHBOARD;
    }
  }
  return 'plans';

}

export function getProductPlanHeaders(product) {
 
  let headers = [
    {value: 'Include', type: constants.FIELD_TYPE.BOOLEAN},
    { value: 'Frequency', type : constants.FIELD_TYPE.READONLY}, 
    { value: 'Plan', type : constants.FIELD_TYPE.READONLY},
    {value: 'Add-on', type: constants.FIELD_TYPE.BOOLEAN} ];
  // let product = products.find(p => p.id === productId)

  if (product) {
    product.features.forEach((feature) => {
      if (feature.active && feature.toggable) {
        headers.push({value: feature.name, type: constants.FIELD_TYPE.BOOLEAN})
      }
    });

    product.multiOptions.forEach((multiOption) => {
      if (multiOption.active) {
        headers.push({value: multiOption.name,type: constants.FIELD_TYPE.TEXT})
      }
    });

    product.valueMetrics.forEach((valueMetric) => {
      if (valueMetric.active) {
        headers.push({value: valueMetric.unit,type: constants.FIELD_TYPE.TEXT})
      }
    });
  }

  return headers;
}

export function getProductPlanRows(product, plans) {

  // given a product -> go through 
  //console.logog('getProductPlanRows')
  
  let rows = [];

  if (product) {
    plans.forEach((plan) => {
      let row = [];
      let disabled = true;
      let isAddon = pricebookSelector.isProductAnAddon(plan, product.id);
      let isIncluded = pricebookSelector.isProductIncluded(plan, product.id);

      // column: included  
      if (isIncluded) {
        row.push({ type: constants.FIELD_TYPE.BOOLEAN, value: true, id : product.id , planId : plan.id, object : constants.OBJECT_TYPE.PRODUCT});
        disabled = isAddon;
      } else {
        row.push({ type: constants.FIELD_TYPE.BOOLEAN, value: false, id : product.id , planId : plan.id,  object : constants.OBJECT_TYPE.PRODUCT});
      } 

      // column: frequency    
      let frequency = plan.charges[0] ? formatFrequency2(plan.charges[0]) : "";  
      row.push({value: frequency, type: constants.FIELD_TYPE.READONLY}) 

      // column: plan name  
      row.push({value: plan.name, type: constants.FIELD_TYPE.READONLY})

      // column: addon
      //console.logog('pricebookSelector.isProductAnAddon(plan, product.id): ', pricebookSelector.isProductAnAddon(plan, product.id));
      if (isAddon) {
        row.push({ type: constants.FIELD_TYPE.BOOLEAN, value: true, id : product.id , planId : plan.id, object : constants.OBJECT_TYPE.ADDON, disabled : !isIncluded}); 
      } else {
        row.push({ type: constants.FIELD_TYPE.BOOLEAN, value: false, id : product.id , planId : plan.id,  object : constants.OBJECT_TYPE.ADDON, disabled : !isIncluded});
      } 
      

      product.features.forEach((feature) => {

        if (feature.active && feature.toggable) {
 
          if (pricebookSelector.getPlanProductToggableFeature(plan, feature.id)) {
            row.push({ type: constants.FIELD_TYPE.BOOLEAN, value: true, id : feature.id , planId : plan.id, disabled});
          } else {
            row.push({ type: constants.FIELD_TYPE.BOOLEAN, value: false, id : feature.id, planId : plan.id, disabled});
          }
        }

      });

      product.multiOptions.forEach((multiOption) => {
 
        // const multiSelect = plan.multiSelects.find(ms => ms.multiOptionId === multiOption.id);
        const multiSelect = pricebookSelector.getBaseCharge(plan).multiSelects.find(ms => ms.multiOptionId === multiOption.id);
        
        const optionName = multiSelect ? 
            multiOption.options[multiSelect.selectIndex].name : "select option";

        // this will get tricky
        
        if (multiOption.active) {
          row.push({ type: constants.FIELD_TYPE.DROPDOWN, value: optionName, id : multiOption.id , options: multiOption.options, planId : plan.id, disabled});
        }

      });

      product.valueMetrics.forEach((valueMetric) => {
        if (valueMetric.active) {
          // let vc = plan.products.valueCharges.find(vc => vc.valueMetric.id === valueMetric.id);
          let vc = pricebookSelector.getPlanProductValueChargeFromValueMetricId(plan, valueMetric.id);
          if (vc) {
            row.push({ type: constants.FIELD_TYPE.TEXT, value: vc.unitsInBase, id : vc.id, planId : plan.id, disabled });
          } else {
            throw new Error("ughh... not supposed to happen ");
            row.push({ type: constants.FIELD_TYPE.TEXT, value: '', id : null, planId : plan.id, disabled});
          }
        }
      });
      rows.push(row);
    });
  }

  return rows;
}


function findFeature(products, id) {

  if (!products) { return null; }

  let product = products.find(product => product.toggableFeatures.find(feature => feature.id === id));
  
    if (product) { 
      let feature = product.toggableFeatures.find(feature => feature.id === id);
      // //console.logog('found value charge');
      // //console.logog(vc);
    return feature; }
   
  return null;
}

function findValueCharge(products, id) {

  if (!products) { return null; }

  let product = products.find(product => product.valueCharges.find(vc => vc.valueMetric.id === id));
  
    if (product) { 
      let vc = product.valueCharges.find(vc => vc.valueMetric.id === id);
      // //console.logog('found value charge');
      // //console.logog(vc);
    return vc; }
   
  return null;
}

export function getCurrentSegments(pricebook) {

  return pricebook.segmentsData;
 
}
 
export function getPlan(state, planId) {

  let pPlan = null;

  if (state.pricebook && state.pricebook.json.plans) {
     state.pricebook.json.plans.forEach(pricebookPlan => {
        if (pricebookPlan.id === planId) {
          pPlan = pricebookPlan;
        }
      });
  }

  return pPlan;
}

export function getFilteredProducts(products, activeOnly) {

  let filteredProducts = [];

  if (activeOnly) {
    products.forEach(p => {
      if (p.active) {
        p.valueMetrics = p.valueMetrics.filter (vm => vm.active);
        filteredProducts.push(p);
      }
    })
  } else{
    filteredProducts = products;
  }

  return filteredProducts;

}

export function getProductName(id, products) {

  let product = null;

  products.forEach(p => { if (p.id === id) { product = p; }});

  return product ? product.name : null;

}

export function getAddons(plan, products) {

    let shapedAddonCharges =
      plan.addonCharges.map(addonCharge => Object.assign(
        { productName: getProductName(addonCharge.addonProductRefId, products) },
        addonCharge));

    return shapedAddonCharges;
}

export function getValueCharges(plan, products) {

  let allVCs = [];

  plan.valueCharges.forEach(vc => {
    let tempVC = Object.assign({}, vc);
    products.forEach(p => {
      p.valueMetrics.forEach(vm => {
        if (vc.valueMetric.id === vm.id) {
          tempVC.valueMetric = vm;
          tempVC.extra = vc.unitIncrementPrice && vc.unitIncrementPrice > 0;
          tempVC.unlimited = (vc.unitsInBase === -1);
          allVCs.push(tempVC);
        }
      })
    })
  })

  return allVCs;

}

export function getProductAddon(plan, productId, productActive=true) {

    // //console.logog("In getProductAddon");
    let rAddon = null;

    if (plan.addonCharges) {
      plan.addonCharges.forEach(addon => {
        if (addon.addonProductRefId === productId) {
          rAddon =  addon;
        }
      });
    }

    if (!rAddon) {
      rAddon = factory.createAddonCharge(plan.id, productId);
      plan.addonCharges.push(rAddon);
    }


    rAddon.price = parseFloat(rAddon.price);
    rAddon.active = productActive;

    return rAddon;
}

export function getProductAddonDiscount(plan, productId) {

    let productAddon = getProductAddon(plan, productId);
    let rDiscount = null;
    if (plan.discounts) {
        plan.discounts.forEach(discount => {
          if (discount.chargeId === productAddon.id) {
            rDiscount =  discount;
          }
        });
    }

    if (!rDiscount) {
      rDiscount = factory.createChargeDiscount(plan.id, productAddon.id);
      rDiscount.active = false;
      plan.discounts.push(rDiscount);
    }

    return rDiscount;
}

export function getProductAddonCharages(addons, product) {
  let addonCharges = [];

  addons.forEach((addonCharge) => {
    if (addonCharge.addonProductRefId === product.id) {
      addonCharges.push(addonCharge);
    }
  });

  return addonCharges;
}

export function getProductAddonDiscounts(addons, discounts, product) {
  let chargeDiscounts = [];
  let addonCharges = getProductAddonCharages(addons, product);

  discounts.forEach((discount) => {
    addonCharges.forEach(charge => {
      // //console.logog("discount.chargeId: " + discount.chargeId);
      // //console.logog("charge: " + charge.id);
      if (discount.chargeId === charge.id ) {

          // //console.logog("getProductAddonDiscounts");
          // //console.logog(discounts);
          // //console.logog(addonCharges);
          if (discount.active) {
            chargeDiscounts.push(discount);
          }

      }
    });
  });

  return chargeDiscounts;
}

export function getProductValueCharges(plan, product) {

  let allVCs = [];

  product.valueMetrics.forEach(vm => {
    let found = false;

    plan.valueCharges.forEach(vc => {
      if (!found && vc.valueMetric.id === vm.id) {
        let tempVC = Object.assign({}, vc);
        tempVC.valueMetric = vm;
        tempVC.extra = vc.unitIncrementPrice && vc.unitIncrementPrice > 0;
        tempVC.unlimited = (vc.unitsInBase === -1);
        allVCs.push(tempVC);
        found = true;
      }
    })

    if (!found) {
      let tempVC = factory.getVMCharge(plan.id, vm.id, 1);
      tempVC.valueMetric = vm;
      tempVC.extra = tempVC.unitIncrementPrice && tempVC.unitIncrementPrice > 0;
      tempVC.unlimited = false;

      allVCs.push(tempVC);
    }
  })



  // plan.valueCharges.forEach(vc => {
  //   let tempVC = Object.assign({}, vc);
  //   product.valueMetrics.forEach(vm => {
  //     if (vc.valueMetric.id == vm.id) {
  //       tempVC.valueMetric = vm;
  //       tempVC.extra = vc.unitIncrementPrice && vc.unitIncrementPrice > 0;
  //       tempVC.unlimited = (vc.unitsInBase == -1);
  //       allVCs.push(tempVC);
  //     }
  //   })
  // })

  return allVCs;
}

export function getProductToggableFeature(plan, product) {

    // toggable features =>
    let allTFs = [];

    product.features.forEach(f => {
      if (f.toggable) {
        let checked = false;

        if (plan.toggableFeatures) {
            plan.toggableFeatures.forEach(tf => {
              if (tf.id === f.id) {
                checked = true;
              }
            })
        }

        allTFs.push(Object.assign({checked: checked, productId: product.id}, f));
      }
    });

    // //console.logog(allTFs);

    return allTFs;
}

export function getToggableFeatures(plan, products) {
    // toggable features =>
    let allTFs = [];
    products.forEach(p => {
      p.features.forEach(f => {
        if (f.toggable) {
          let checked = false;

          if (plan.toggableFeatures) {
              plan.toggableFeatures.forEach(tf => {
                if (tf.id === f.id) {
                  checked = true;
                }
              })
          }

          allTFs.push(Object.assign({checked: checked, productId: p.id}, f));
        }
      })
    });

    return allTFs;
}
 