


export const PRICING_ROW_TYPE = {
    VALUEMETRIC: 'ValueMetric',
    MULTIOPTION: 'MultiOption',
    FEATURE: 'Feature',
    SECTION: 'Section',
    PRODUCTNAME: 'ProductName',
    ADDON: 'Addon'
  };
  
 

export function getCtaConfig(ctaConfig, planId) {

  console.log('in getCtaConfig')
  console.log(ctaConfig)

  const lCtaConfig = ctaConfig ? ctaConfig : [];
  const planConfig = lCtaConfig.find( cc => cc.planId === planId);

  console.log(planConfig)

  return planConfig ? planConfig : { planId, ctaUrl : '', ctaCaption : '' }
}



export function setCtaConfig(ctaConfig, planId, ctaCaption, ctaUrl) {

  const lCtaConfig = ctaConfig ? [...ctaConfig] : [];
  const planConfig = lCtaConfig.find( cc => cc.planId === planId);

  if (planConfig) {
    planConfig.ctaCaption = ctaCaption;
    planConfig.ctaUrl = ctaUrl;
  } else {
    lCtaConfig.push({planId, ctaCaption, ctaUrl})
  }

  return lCtaConfig;

  // return planConfig ? planConfig : { planId, ctaUrl : '', ctaCaption : '' }
}

export function mergePricingRowOrder(pricingRows, products, plans) {

  const mergedPricingRows = [...pricingRows];
  const defaultPricingRows = getDefaultPricingRowOrder(products, plans);
  defaultPricingRows.forEach( pricingRow => {
    const existingPricingRow = mergedPricingRows.find( r=> compareRows(r, pricingRow));
    if (!existingPricingRow) {
      mergedPricingRows.push(pricingRow);
    } else {
      existingPricingRow.name = pricingRow.name;
    }
  })

  return mergedPricingRows;
}

export function getDefaultDiscounts(plans, products) {

  let allDiscounts = [];

  // plans.forEach(p => {
  //   p.discounts.forEach(d => {
  //     allDiscounts.push(d);
  //   })
  // })
  plans.map(plan => { allDiscounts= allDiscounts.concat(plan.discounts)});
  
  let chargeDiscounts = allDiscounts.filter(discount => discount.level === 'charge');

  let allCharges = [];
  plans.map(plan => { 
    allCharges = allCharges.concat(plan.charges);
    allCharges = allCharges.concat(plan.addonCharges);
  });

  let allAddons = [];
  chargeDiscounts.forEach( cDiscount => {
  
    let charge = allCharges.find(charge => charge.id === cDiscount.chargeId);
  
    // console.log(cDiscount.chargeId);
    // console.log(charge);


    if (charge && charge.addonProductRefId) {
      // look up the product
      const addonProduct = products.find ( p=> p.id === charge.addonProductRefId);
      allAddons.push(addonProduct.name);
    }
  })
  

  // chargeId 
  console.log(allAddons);
  // console.log(allCharges);
  return allDiscounts;

}


export function getDefaultAddons(plans, products) {

  let allAddonCharges = [];
  let allAddons = [];

  plans.forEach( plan => {
    plan.addonCharges.forEach(addonCharge => {

      if (addonCharge.active && !allAddonCharges.find (ac => ac.addonProductRefId === addonCharge.addonProductRefId)) {
        allAddonCharges.push(addonCharge);
      }
    })
  })

  allAddonCharges.forEach(addonCharge => {
    
    const addonProduct = products.find ( p=> p.id === addonCharge.addonProductRefId);
    if (addonProduct) {
      allAddons.push({productId: addonProduct.id, id: null, type: PRICING_ROW_TYPE.ADDON, name: addonProduct.name})
    } 
  })
   

  // chargeId 
  // console.log(allAddons);
  return allAddons;

}

export function getDefaultPricingRowOrder(products, plans=[]) {
 
    products.sort((p1, p2)=> { return p2.features.length - p1.features.length; })
    const addonsRows = getDefaultAddons(plans, products);


    let retValue = [];
    products.forEach(product => {
      
      // check if product is an addon
      const isProductAnAddon = Boolean(addonsRows.find(row => row.productId === product.id));
 
      if (product.active && !isProductAnAddon) {

        retValue.push({productId: product.id, id: null, type: PRICING_ROW_TYPE.PRODUCTNAME, name: product.name});
        product.features.forEach(feature => {
          if (feature.active) {
            retValue.push({productId: product.id, id: feature.id, type: PRICING_ROW_TYPE.FEATURE, name: feature.name});
          }          
        })
    
        product.valueMetrics.forEach(vm => {
          if (vm.active) {
            retValue.push({productId: product.id, id: vm.id, type: PRICING_ROW_TYPE.VALUEMETRIC, name: vm.unit });
          }          
        })
  
        product.multiOptions.forEach(mo => {
          if (mo.active) {
            retValue.push({productId: product.id, id: mo.id, type: PRICING_ROW_TYPE.MULTIOPTION, name: mo.name });
          }          
        })
  
      }
      
    });

    retValue = retValue.concat(addonsRows);
  
    return retValue;
  }
  
  export function getPlanCellValue(plan, products, productEntry) {
  
    const excludedSymbol = ``;  
    const emptySymbol = ``;
    const unlimitedSymbol = '&#8734;';
    const includedSymbol = <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Applies to Personal plan"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
     
    let value = excludedSymbol;
    let product = null;
    let multiSelect = null;
    let multiOption = null;
    let productObject = null;
  
    switch (productEntry.type) {
      
      case PRICING_ROW_TYPE.FEATURE:
        product = products.find(product => product.id === productEntry.productId);
       
  
        if (product && product.features) {
          productObject = product.features.find(feature => feature.id === productEntry.id);
          if (productObject) {
            if (productObject.toggable) {
              value = plan.toggableFeatures.find(tf => tf.id === productObject.id) ? includedSymbol : excludedSymbol;
            } else {
              value = includedSymbol;
            } 
          }       
        }
        break;
      case PRICING_ROW_TYPE.VALUEMETRIC:
        productObject = plan.valueCharges.find(vc => vc.valueMetric.id === productEntry.id);
        if (productObject) { 
            if (parseFloat(productObject.unitsInBase) === 0) value = excludedSymbol;
            else if (parseFloat(productObject.unitsInBase) === -1) value = unlimitedSymbol;
            else value = productObject.unitsInBase; 
        }
        break;
      case PRICING_ROW_TYPE.MULTIOPTION:
        product = products.find(product => product.id === productEntry.productId);


        multiSelect = plan.multiSelects.find(ms => ms.multiOptionId === productEntry.id);
        if (product && product.multiOptions) {
          
          multiOption = product.multiOptions.find(mo => mo.id === productEntry.id);
        }
         
        if (multiOption && multiSelect && multiSelect.selectIndex < multiOption.options.length) { 
          value = multiOption.options[multiSelect.selectIndex].name
        }
        break;
      default:
        value = emptySymbol;
    }
  
    return value;
  
  }

  export function filterNonDefaultRows(products, plans, rowConfig) {

    let retValue = [];
    const defaultRows = getDefaultPricingRowOrder(products, plans);

    rowConfig.forEach(row => {
      
      const dRow = defaultRows.find(drow => compareRows(drow, row) );
      if (row.type === "Section") {
        retValue.push(row);
      } else if (dRow) {
        retValue.push(dRow);
      } 
    })

    // console.log(defaultRows)
    // retValue = rowConfig.filter(row => defaultRows.find(drow => compareRows(drow, row) ));   
    // retValue = defaultRows.filter(row => rowConfig.find(drow => compareRows(drow, row) ));

    return retValue;

  }
  
  export function filterInactviceRowConfig(rowConfig, products) {
  
    let retValue = [];

    rowConfig.forEach(rowEntry => {
      let product = products.find(product => product.id === rowEntry.productId); 
      let productObject = null;

      if (product) {

        switch (rowEntry.type) {
      
          case PRICING_ROW_TYPE.FEATURE:
            if (product && product.features) {
              productObject = product.features.find(feature => feature.id === rowEntry.id);
              if (productObject &&productObject.active ) {
                retValue.push(rowEntry);
              }       
            }
            break;
          case PRICING_ROW_TYPE.VALUEMETRIC:
            if (product && product.valueMetrics) {
              productObject = product.valueMetrics.find(vm => vm.id === rowEntry.id);
              if (productObject &&productObject.active ) {
                retValue.push(rowEntry);
              }    
            }
            break;
          case PRICING_ROW_TYPE.MULTIOPTION:
            if (product && product.multiOptions) {
              productObject = product.multiOptions.find(mo => mo.id === rowEntry.id);
              if (productObject &&productObject.active ) {
                retValue.push(rowEntry);
              }    
            }
            break; 
          default:
            retValue.push(rowEntry);
        }
      } else {

        // Sections do not have associted product name associted with them
        if (rowEntry.type === PRICING_ROW_TYPE.SECTION) {
          retValue.push(rowEntry); 
        }
      }
    
    })
  
    
    return retValue;
  } 

  export function compareRows(row1, row2) {
  
    if (!row1 || !row2) { return false; }
    return row1.type === row2.type && row1.productId === row2.productId && row1.id === row2.id;
    
  }

  export function hideRow(row) {

    if (!row) { throw new Error("hideRow: row cannot be null!")}

     
    return { ...row, hide: true }
  }

  export function showRow(row) {

    if (!row) { throw new Error("hideRow: row cannot be null!")}
 
    return { ...row, hide: false }
  }

  export function renameRow(row, name) {

    if (!row) { throw new Error("renameRow: row cannot be null!")}
 
    return { ...row, name }
  }


  export function isRowHidden(row) {

    if (!row) { throw new Error("isVisible: row cannot be null!")}
    return (row.hide === true); 
  }
  
  export function moveRow(defaultRows, rowEntry, toRow) {
  
    let to = toRow ? defaultRows.findIndex( row => compareRows(row, toRow)) : 0;  
    let from = defaultRows.findIndex( row => compareRows(row, rowEntry));
    
   
    if (to === -1) { to = 0; }
    if (from === -1) { from = 0; }
  

    // console.log(`moving from ${from} to ${to}`);
    defaultRows.splice(to, 0, defaultRows.splice(from,1)[0]);
    
  }


  export function movePricingRows(defaultRows, from, to) {
  
    // let to = toRow ? defaultRows.findIndex( row => compareRows(row, toRow)) : 0;  
    // let from = defaultRows.findIndex( row => compareRows(row, rowEntry));
    
   
    if (to === -1) { to = 0; }
    if (from === -1) { from = 0; }
  

    // console.log(`moving from ${from} to ${to}`);
    defaultRows.splice(to, 0, defaultRows.splice(from,1)[0]);
    
    return defaultRows;
  }