import * as constants from '../constants';

export default function pricebookEdit(
  state = {
    data: null, 
    isLoading: true,
    valueMetricShowModal: false,
    valueMetric : {}
  },
  action
) {
  const pricebook = state ? {...state.data}: {};
  const valueMetric = state ?  {...state.valueMetric }: {};
  let valueMetricTemp = null;
  let valueMetricExisting = null;

  pricebook.lastUpdated = Date.now();
  

  switch (action.type) {
    case constants.GLOBAL_LOGOUT:
      return null;
    case constants.SET_UP_EDIT_PRICEBOOK_START:
      return {
        ...state,
        valueMetricShowModal: false,
        valueMetric: {},
        isLoading: true
      };

    case constants.RECEIVE_PRICEBOOKS:
      return {
        ...state,
        data: null,
        isLoading: true,
        label: ""
      };
    case constants.SET_UP_EDIT_PRICEBOOK: 
      return {
        ...state,
        data: action.pricebook,
        isLoading: false,
        pageName: action.new ? "Add Pricebook" : "Editing " + action.pricebook.name,
        label: ""
      };
    case constants.SET_UP_EDIT_PRICEBOOK_ERROR: 
      return {
        ...state,
        isLoading: false
      }; 
    case constants.ADD_PRICEBOOK_VALUEMETRIC:
      pricebook.valueMetrics.push(action.value); 
      return {
        ...state, 
        data: pricebook
      };
       
    case constants.REMOVE_PRICEBOOK_VALUEMETRIC_FIELD:
      
      valueMetricTemp = pricebook.valueMetrics.find(vm => vm.id === action.valueMetricId);
      if (valueMetric) {
        valueMetricTemp.active = false;
      }
      
      return {
        ...state, 
        data: pricebook
      };
    case constants.EDIT_PRICEBOOK_VALUEMETRIC_FIELD:

      valueMetricTemp = pricebook.valueMetrics.find (vm => vm.id === action.valueMetricId);
      valueMetricTemp[action.name] = action.value;

      
      return {
        ...state, 
        data: pricebook
      };
       

    case constants.CANCEL_EDIT_PRICEBOOK_VALUEMETRIC:
      return Object.assign({}, state, { 
         valueMetricShowModal: false
      });

    case constants.END_EDIT_PRICEBOOK_VALUEMETRIC:
      valueMetricExisting = pricebook.valueMetrics.find(vm => vm.id === action.valueMetricId);

      if (!valueMetricExisting) {
        pricebook.valueMetrics.push(valueMetric);
      } else {
        valueMetricExisting.name = valueMetric.name;
        valueMetricExisting.unit = valueMetric.unit;
      }

      return Object.assign({}, state, { 
        data: pricebook,
        valueMetricShowModal: false
      });
    
    case constants.EDIT_PRICEBOOK_FIELD:      
      pricebook[action.name] = action.value;
      return {
        ...state,
        data: pricebook
      };
      return state;
    case constants.RECEIVE_PRICEBOOK_SAVE:
      return {
        ...state,
        label: "",
        data: null,
        isLoading: false
      };
    case constants.RECEIVE_PRICEBOOK_SAVE_ERROR:
      return {
        ...state,
        label: "Error saving pricebook: " + action.error,
        isLoading: false
      };
    case constants.REQUEST_PRICEBOOK_UPDATE:
      pricebook[action.name] = action.value;
      return {
        ...state,
        label: "",
        isLoading: false,
        data: pricebook,
      };
    default:
      return state;
  }
}
