
import { v4 as uuidv4 } from 'uuid';
import { PLAN_ORDER } from '../constants'

export function getVMCharge(planId, valueMetricId, value ) {

  let json = {
       "id": uuidv4(),
       "active": true,
       "price": 0,
       "frequency": "none",
       "type": "usage",
       "planId": planId,
       "addon": false,
       "valueMetric": {
         "id": valueMetricId
       },
       "unitsInBase": value
     }

   return json;
}

export function createEmptySection() {
  return ({id:  uuidv4(), name : "", type: "Section"});
}

export function createBaseCharge(planId, value=10 ) {

  let json = {
       "id": uuidv4(),
       "active": true,
       "price": value,
       "frequency": "monthly",
       "type": "fixed",
       "planId": planId,
       "addon": false,
       "toggableFeatures": [], 
       "multiSelects": []
     }

   return json;
}

export function createAddonCharge(planId, addonProductRefId, active=true ) {

  let json = {
       "id": uuidv4(),
       "active": active,
       "price": 1,
       "addonProductRefId": addonProductRefId,
       "frequency": "monthly",
       "type": "fixed",
       "planId": planId,
       "addon": true,
       "toggableFeatures": [], 
       "multiSelects": []
     }

   return json;
}

export function createPlanDiscount(planId ) {

  let json = {
       "id": uuidv4(),
       "active": true,
       "planId": planId,
       "level": "plan",
       "type": "percentage",
       "value": 100,
       "duration": 1
     }

   return json;
}

export function createProduct(pricebookId) {

  let json = {
       "id": uuidv4(),
       "active": true,
       "pricebookId": pricebookId,
       "name": "",
       "description": "",
       "features": [],
       "valueMetrics": [],
       "multiOptions": []

     }

   return json;
}

export function createSegment(pricebookId) {

  let json = {
       "id": uuidv4(),
       "active": true,
       "name": "",
       "startDate" : Date.now(),
       "pricebookId" : pricebookId,
       "chartMogulId" : "",
       "churnFrequency" : "monthly"
     }

   return json;
}


export function createPricingPage(name, templateId, spec) {

  let json = {
       "id": uuidv4(),
       "active": true,
       autoUpdate: true,
       name,
       templateId,
       rowConfig : [],
       hiddenPlans : [],
       planOrder : PLAN_ORDER.ASCENDING,
       ctaCaption : "Select {plan}",
       urlSlug : "",
       description : "",
       popularYearlyPlanId : "",
       popularMonthlyPlanId : "",
       ctaUrl : "",
       contactUsUrl : "",
       mostPopularCaption : "Most Popular Plan",
       backgroundColor: spec ? spec.backgroundColor : "",
       textColor:  spec ? spec.textColor : "",  
       tintColor: spec ? spec.tintColor : "" ,  
       includeSymbolColor: spec ? spec.includeSymbolColor : "#dddddd" ,  
       excludeSymbolColor: spec ? spec.excludeSymbolColor : "#dddddd" ,  
       fontFamily: spec ? spec.fontFamily : "Open Sans" 
     }

   return json;
}

export function createSpreadsheet(pricebookId) {

  // this.name = "";
  //    this.spreadsheetState = STATE.NEW; // NEW | CREATING | ERROR
  //    this.createDate = "";
  //    this.updatedDate = "";
  //    this.spreadsheetType = "";
  //    this.originalName = "";
  //    this.displayName = "";
  //    this.lastErrorMessage = "";
  //    this.googleId = "";
  //    this.pricebookId = "";
  //    this.autoUpdate = false;
  //    this.modelType = modelFactory.MODEL_NAME.SPREADSHEET;
  //    this.sheets = [];

  let json = {
       "id": uuidv4(),
       "active": true,
       "originalName": "",
       "displayName": "",
       "pricebookId" : pricebookId,
       "spreadsheetType": "", 
       "spreadsheetState" : 'new',
       "autoUpdate": false,
       'sheets': []
     }

   return json;
}


export function createSheet(spreadsheetId, type, name="", active=true) {

  //  this.name = "";
  // this.sheetState = STATE.NEW;
  // this.spreadsheetId = "";
  // this.originalName = "";
  // this.sheetType = "";
  // this.googleId = "";

  // make sure type matches one of the constants


  let json = {
       "id": uuidv4(),
       "active": active,
       "name": name,
       "sheetState" : 'new',
       "originalName": name,
       "spreadsheetId" : spreadsheetId,
       "sheetType": type,     
     }

   return json;
}

export function createPricebook() {

  let json = {
       "id": uuidv4(),
       "active": true,
       "name": "",
       "description": "",
       "plans": [],
       "valueMetrics": [],
       "products": []

     }

   return json;
}

export function createPlan() {

  let json = {
       "id": uuidv4(),
       "active": true,
       "name": "",
       "description": "",
       "charges": [],
       "valueCharges": [],
       "toggableFeatures": [],
       "multiSelects": [],
       "products": [],
       "addonCharges": [],
       "discounts": [],
       "costs": []
     }

   return json;
}

export function createProductFeature(productId ) {

  let json = {
       "id": uuidv4(),
       "active": true,
       "productId": productId,
       "name": "",
       "toggable": true
     }

   return json;
}


export function createProductMultiOption(productId ) {

  let json = {
       "id": uuidv4(),
       "active": true, 
       "productId": productId,
       "name": "",
       "options": []
    }

   return json;
}


export function createPlanMultiSelect(multiOptionId, selectIndex ) {

  let json = {
       "multiOptionId":multiOptionId,
       "selectIndex": selectIndex
    }

   return json;
}


export function createProductMultiOptionOption(name, id ) {

  let json = {
       name: name, 
       id: uuidv4() //"" + id
    }

   return json;
}

export function createProductValueMetric(productId ) {

  let json = {
       "id": uuidv4(),
       "active": true,
       "productId": productId,
       "minimum" : 0,
       "maximum" : -1,
       "unitIncrements" :1,
       "unit" : ""
     }

   return json;
}


export function createPricebookValueMetric(pricebookId ) {

  let json = {
       "id": uuidv4(),
       "active": true,
       "pricebookId": pricebookId,
       "minimum" : 0,
       "maximum" : -1,
       "unitIncrements" :1,
       "name" : "",
       "unit" : ""
     }

   return json;
}


export function createPlanValueCharge(valueMetricId,   productId ) {


  let json = {
       "id": uuidv4(),
       "unitsInBase": 0,
       "unitIncrementPrice": 0,
       "unitIncrements": 0,
       "valueMetric" : {id : valueMetricId}, 
       "addonProductRefId" : productId, 
       "multiSelects": [],
       "bandPrices": []
    }

   return json;
}

export function createAddonValueCharge(valueMetricId, addonChargeId, productId) {

  let json = {
       "id": uuidv4(),
       "unitsInBase": 0,
       "unitIncrementPrice": 0,
       "unitIncrements": 0,
       "valueMetric" : {id : valueMetricId}, 
       "addonProductRefId" : productId,
       "addonChargeRefId" : addonChargeId
    }

   return json;
}


export function createChargeDiscount(planId, chargeId, active=true) {

  let json = {
       "id": uuidv4(),
       "active": active,
       "chargeId": chargeId,
       "planId": planId,
       "level": "charge",
       "type": "percentage",
       "value": 100,
       "duration": 1 
     }

   return json;
}



 //
