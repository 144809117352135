import React, { Component } from "react";   
import { connect } from 'react-redux'  
import {getPlanFrequency, getPlanPrice } from "../libs/selectors" 
import PreviewAssetControl from '../controls/previewAssetControl'
import Dropdown from '../controls/dropdownControl'
import {  ClipboardCopyIcon, ExternalLinkIcon } from '@heroicons/react/solid'
import { getScriptCode } from "../libs/helper"

const mapProductStateToProps = state => {
 
  if (!state.user || !state.user.data) {
    return;
  }

  const pricebook = state.pricebook &&  state.pricebook.json   ? state.pricebook.json : null;
  const products= pricebook ? pricebook.products.filter(p => p.active) : [];
  const plans = pricebook ? pricebook.plans.filter(p => p.active).map(plan=> 
    ({ ...plan, 
      frequency: getPlanFrequency(plan),
      price : getPlanPrice(plan)

    })) : [];

  const assets = (state.pricingPages &&  state.pricingPages.data) ? state.pricingPages.data.filter(asset => asset.active) : [];
  
  // logic for default script url
  const pricingPage =  assets.length > 0 ? assets[0] : {};
  const defaultPricingPage = assets.length > 0 ? assets[0] : {};
  const defaultAssetId = defaultPricingPage.id;

  return  {
    tenantId: state.user.data.tenantId,
    pricebookName: pricebook  ? pricebook.name : "No pricebook selected",    
    pricebookSelected : Boolean(pricebook),
    products,
    plans,     
    assets,   
    defaultAssetId,
    previewScriptUrl: defaultPricingPage.scriptUrl,
    previewAssetName: defaultPricingPage.name,
    // defaultPricingPage, // "https://share.pricingsaas.com/418cc186-283d-4b39-a102-0839be956c55/fa3d25b2-b95b-49f1-83ec-79296d1f140a.js",  
    userId: state.user.data.id,
    hasPricebooks: Boolean(state.pricebooks && state.pricebooks.items && (state.pricebooks.items.length > 0))
  }
}

const elementCaptionHTML = (caption) => <> 
                              <div className="mt-0">
                                <span className="text-sm text-gray-500">{caption}</span> 
                              </div> 
                              </>

const elementLinkHTML = (caption, url) => <> 
                              <a className="text-blue-700" href={url}>{caption}</a>
                              </>


export  class DashboardPage extends Component {
 
  constructor(props) {
    super(props);
  }

  render() { 

    let previewScriptUrl = this.props.previewScriptUrl;
    let previewAssetName = this.props.previewAssetName;
    const assetId = this.props.match.params.assetId; 

    let pricingPage = null;

    if (assetId) {

      let myAsset = this.props.assets.find(asset => asset.id === assetId);
      pricingPage = myAsset;
      
      if (myAsset) {
        previewScriptUrl = myAsset.loaderUrl;
        previewAssetName = myAsset.name;
      } else {
       console.log(this.props.assets);
      }
    } else if (this.props.defaultAssetId && this.props.pricebookSelected) {

      window.location.href = `/dashboard/${this.props.defaultAssetId}`;  
      return (<></>);
    } 

    if (!this.props.hasPricebooks) {
      window.location.href = `/new`;  
      return (<></>);
    }

    const instructions = (this.props.hasPricebooks) &&
                          <>     
                          {/* Pring page preview control */}
                          <div className="mt-6 text-gray-700">
                            {/*  preview */}
                            Here's the preview of your your pricing page. Use the script below to embed it on your website.
                              
                          </div>
                          </>

    const previewContent = (this.props.hasPricebooks) &&
                            <>        
                            {/* Pring page preview control */}
                            <div className="my-6 text-gray-700">
                              {/*  preview */}
                              <div className="flex justify-between  mt-6 ">
                                  
                                  {elementCaptionHTML('Preview:')}
                                  
                                  <Dropdown 
                                      items={this.props.assets.map( (asset, assetIndex) => { return {name: asset.name, key:asset.id}})} 
                                      currentItem={previewAssetName}                                  
                                      primary={false}
                                      onSelect={(assetId) => {
                                        window.location.href = `/dashboard/${assetId}`;   } }
                                  />  
                                </div>
                                <hr/>
                                <PreviewAssetControl
                                  scriptUrl={previewScriptUrl}
                                />
                          </div>
                          </>

  const embedScriptCode = getScriptCode( pricingPage ? pricingPage.loaderUrl : null);
                         
  const embedScriptBody = <>
                            <div class="h=full overflow-scroll text-sm"><code><div className="flex flex-col ">{embedScriptCode}
                              </div> 
                              <div className="mt-2">
                              {`<!-- place this tag where you want the widget to appear. -->`}
                              </div>
                              <div>
                              {`<div id="psPricing"></div>`}
                              </div></code></div>
                              </> 

    const embedScript = (this.props.hasPricebooks) && 
                          <>   
                            <div className="py-6">
                            Copy and paste this script to embed the pricing page on your website 
                            <div className="flow-root h-10 resize-y rounded-md text-gray-700 border border-gray-300 overflow-hidden bg-gray-100 p-2">
                                {embedScriptBody}
                            </div> 


                            <div className="flex justify-between "> 

                              {/* Copy to clipboard button */}
                              <div className="mt-2   inline-flex align-middle  text-blue-900 hover:text-blue-600 " onClick={() => navigator.clipboard.writeText(embedScriptCode)}> 
                              <ClipboardCopyIcon className="h-5 w-5 mr-2 text-blue-900 " aria-hidden="true" />
                              <button class="btn ">
                                Copy to clipboard
                              </button>                 
                              </div>

                              {/* Copy to clipboard button */}
                              <div className="mt-2   inline-flex align-middle  text-blue-900 hover:text-blue-600 " onClick={() => window.open(pricingPage.htmlUrl, '_blank') }> 
                              <ExternalLinkIcon className="h-5 w-5 mr-2 text-blue-900 " aria-hidden="true" />
                              
                              <button class="btn ">
                                Preview 
                              </button>                 
                              </div> 

                            </div>
                          </div>
                        </>

    const pageContent = (this.props.hasPricebooks) ?
                          <>                            

                      

                            <div className="mt-12 grid grid-cols-1 gap-4 sm:grid-cols-3">
                              
                                <div
                                  key='products'
                                  className="relative bg-gray-100 p-6  rounded-md flex items-top space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                
                                  <div className="flex-1 min-w-0">
                                  {/*  pricebook products */}
                                  {elementCaptionHTML('Products:')}
                                  <div className="flex flex-col text-gray-900">
                                
                                    { this.props.products.map ( product => 
                                      <>
                                        <div>{product.name} ({elementLinkHTML('Edit', "/products/details/" + product.id)})  </div>
                                      </>
                                      )}
                                  </div>     
                                  </div>
                                </div>


                                <div
                                  key='plans'
                                  className="relative   flex items-top space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                
                                  <div className="flex-1 min-w-0 bg-gray-100 p-6  rounded-md">
                                   {/*  pricebook plans */}
                                    {elementCaptionHTML('Plans:')}
                                    <div className="flex flex-col text-gray-900">
                                      { this.props.plans.map ( plan => 
                                        <>
                                          <div>{plan.name} - {plan.frequency} - {plan.price} ({elementLinkHTML('Edit', "/plans/details/" + plan.id)})  </div>
                                        </>
                                        )}
                                    </div> 
                                  </div>
                                </div>


                                <div
                                  key='assets'
                                  className="relative  flex items-top space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                
                                  <div className="flex-1 min-w-0 bg-gray-100 p-6  rounded-md">
                                  {/*  assets */}
                                  {elementCaptionHTML('Assets:')}
                                  <div className="flex flex-col text-gray-900">
                                    { this.props.assets.map ( asset => 
                                      <> 
                                        <div>{asset.name} ({elementLinkHTML('Edit', "/assets/details/" + asset.id)})  </div>
                                      </>
                                      )}
                                  </div>   
                                  </div>
                                </div>
                            </div>

                            
                              
                            
                          </>
                          :
                           <div> 
                           <div className="mt-12">
                              Looks like this workspace is empty. Add a pricebook to get started.
                           </div>                                            
                           <button
                            type="button"
                            onClick={()=>{this.props.history.push('/new')} }
                            className="inline-flex items-center mt-6 justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                          >Add Pricebook</button>
                         </div>;
                           
    return (
      <>
    <div className="max-w-7xl bg-white m-auto p-10 border rounded-md mb-2">      
        <div className="flex justify-between flex-col	">
          <h3 className="text-lg leading-6 font-medium text-gray-900">👋 &nbsp;&nbsp;Hello and Welcome to Pricing SaaS!!</h3>             
        </div>   
        <div className="mt-5  border-t border-gray-200 flex flex-col">
          {instructions}
        </div> 
      </div>
      
      <div className="max-w-7xl bg-white m-auto px-10 border rounded-md ">
       <div className="flex flex-col">
       {previewContent}
       </div>
      </div>

      <div className="mt-2 max-w-7xl bg-white m-auto px-10 border rounded-md ">
       <div className="flex flex-col">
       {embedScript}
       </div>
      </div>

      <div className="mt-2 max-w-7xl bg-white m-auto px-10 border rounded-md pb-6 ">
       <div className="flex flex-col">
       {pageContent}
     </div>
     </div>
     </>
     ) 
  }
}


export default connect(
  mapProductStateToProps 
)(DashboardPage)
