import React  from "react";  
import ReactDragListView from 'react-drag-listview';

import { XIcon, ViewListIcon} from '@heroicons/react/solid'

function EditFeaturesControl ({items=[], changeHandler, deleteHandler, addHandler, tooltipHandler=null, reorderHandler=null}) {
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (reorderHandler) {
        reorderHandler(fromIndex, toIndex);
      } 
    },
    nodeSelector: 'li',
    handleSelector: 'a',
    lineClassName: 'dragLine'
  };

  return <>
          <div className='controlBox'>
          
            <div className="controlInputList">
              <ReactDragListView {...dragProps}>
                <ol>
                  {items.map((item, index) => (
                    <li key={index}>
                      <div className="flex justify-start text-center  my-4 text-gray-700">
                        {/* &nbsp;
                        { tooltipHandler ? <><img height='16px' src="/tooltip.png" onClick={() => deleteHandler(item.id)} />&nbsp;</> : <></> }  */}
                        <a href="#"><ViewListIcon className="h-5 w-5 text-gray-600 mr-2" aria-hidden="true"/> </a>                     
                      <input
                        type="text"
                        className="w-full" 
                        placeholder="Enter name"
                        onChange={(event) => {changeHandler(item.id, event.target.value)}}
                        value={item.title}/>


                        <XIcon className="h-5 w-5 text-gray-600" aria-hidden="true" onClick={() =>  deleteHandler(item.id)} /> 

                        {/* &nbsp;
                        { tooltipHandler ? 
                            <Form.Control
                            type="text"
                            className="controlText"
                            placeholder="Enter Tooltip"
                            onChange={(event) => {tooltipHandler(item.id, event.target.value)}}
                            value={item.tooltip}/>
                          : <></>
                        } */}
                          
                      </div>
                    </li>
                  ))}
                </ol>
              </ReactDragListView>
              <div className="mt-10">
              <a
                 onClick={() => addHandler()}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >Add Feature</a>
              </div> 
            </div>
          </div>
        </>
}

export default (EditFeaturesControl);
