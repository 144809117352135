
import { connect } from 'react-redux'
import {
  editAddonField, 
  editChargeDiscountField
} from '../actions/planEditActions' 
import React from 'react'  
import { formatFrequency } from "../libs/formatHelpers"
import NumberFormat from 'react-number-format'; 
import { PencilIcon } from '@heroicons/react/solid'
import Dropdown from "./dropdownControl"

const EditAddonCharge = ({
  id, // Charge Object id 
  price,
  frequency,
  discountActive=false,
  discountValue,
  discountType,
  discountDuration,
  discountId, 
  editChargeFieldHandler,
  editChargeDiscountFieldHandler}) => (
    <> 
    <div className="flex  justify-start   text-center max-w-3xl ">
      <div className="flex  justify-start   text-center max-w-3xl ">
      <NumberFormat
        id="amountControl"
        className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"        
        thousandSeparator={true}
        prefix={'$'}
        value={price}
        decimalScale={2}
        isAllowed={values => { const {formattedValue, value} = values; return value >= 0 &&  value <= 100000000} }
        onValueChange={values => {
          const {formattedValue, value} = values;
          editChargeFieldHandler(id, 'price', value)
        }}
      />
      <PencilIcon className="h-5 w-5 mr-6 text-gray-600" aria-hidden="true" />
      </div>
      <div className="mx-2">per</div>
      <Dropdown 
          
          items={[{name:'Year', key: 'annual'}, {name:'Month', key: 'monthly'}, {name:'One Time', key: 'onetime'}]}
          currentItem={formatFrequency(frequency) }
          onSelect={(key)=> editChargeFieldHandler(id, 'frequency', key)}/> 
          

          <div className="mx-2">with</div>
          <Dropdown 
          
            items={[{name:'No Discount', key: 'remove'}, {name:'Percent Discount', key: 'percentage'}, {name:'Dollar Discount', key: 'fixed'} ]}
            currentItem={discountActive ? (discountType === 'percentage' ? 'percent discount' : 'dollar discount') : 'no discount'}
            onSelect={(key)=>  { 
              
              if (key === 'remove') {
                editChargeDiscountFieldHandler(discountId, 'remove')
              } else {
                editChargeDiscountFieldHandler(discountId, 'add');
                editChargeDiscountFieldHandler(discountId, 'type', key)
              }
              
            
            }}/> 

            

    {(discountActive) &&
      <> 
        
        <div className="mx-2">of</div> 
        <div className="flex  justify-start   text-center max-w-3xl ">
     
          <NumberFormat
            id="discountAmountControl"
            className="text-center shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"        
        
            thousandSeparator={false}
            prefix={discountType === 'fixed' ? '$' : ''}
            suffix={discountType === 'percentage' ? '%' : ''}
            value={discountValue}
            decimalScale={2}
            isAllowed={values => { const {formattedValue, value} = values;
            return (discountType === 'percentage') ? value >= 0 && value <= 100 : value >= 0 && value <= 1000000000 } }
            onValueChange={values => {
              const {formattedValue, value} = values;
              editChargeDiscountFieldHandler(discountId, 'value', value);
            }}
          />

          <PencilIcon className="h-5 w-5 mr-6 text-gray-600" aria-hidden="true" />
        </div>
 
        <div className="mx-2">for</div>
        <div className="flex  justify-start   text-center max-w-3xl ">
      
          <NumberFormat
          id="discountDurationControl"
          className="text-center shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md" 
          thousandSeparator={false}
          autoFocus
          value={discountDuration}
          decimalScale={1}
          isAllowed={values => { const {formattedValue, value} = values; return value >= 0 &&  value <= 100} }
          onValueChange={values => {
            const {formattedValue, value} = values;
            editChargeDiscountFieldHandler(discountId, 'duration', value);
          }}
          />

          <PencilIcon className="h-5 w-5 mr-6 text-gray-600" aria-hidden="true" />
        </div>
        <div className="mx-2">{formatFrequency(frequency, discountDuration>1)}</div>
        </> 
    }
    </div> 
    </>
)


const mapStateToAddonProps = (state, ownProps) => {


    if (!ownProps.discount) { return; }
  
    return  {
      id : ownProps.charge.id, // Charge Object id
      active : ownProps.charge.active,
      hidden : ownProps.hidden,
      price : ownProps.charge.price,
      frequency : ownProps.charge.frequency,
      discountActive : ownProps.discount.active,
      discountValue : ownProps.discount.value,
      discountType : ownProps.discount.type,
      discountDuration : ownProps.discount.duration,
      discountId : ownProps.discount.id,
      discount: ownProps.discount
    }
  }
  
  const mapDispatchToAddonProps = dispatch => {
    return {
      // dispatching plain actions
      editChargeFieldHandler: (chargeId, name, value) => editAddonField(chargeId, name, value),
      editChargeDiscountFieldHandler: (discountId, name, value) => editChargeDiscountField(discountId, name, value)
    }
  }
  
  
  export default connect(
    mapStateToAddonProps,
    mapDispatchToAddonProps )(EditAddonCharge)
  

