import {
    GLOBAL_LOGOUT,
    RECEIVE_PRICEBOOKS, 
    RECEIVE_SAMPLE_PRICEBOOKS, 
    SELECT_SAMPLE_PRICEBOOK_TEMPLATE,
    SET_NEW_PRICEBOOK_NAME,
    SET_NEW_PRICEBOOK_PROP,
    RECEIVE_PRICEBOOKS_ERROR,
    REQUEST_PRICEBOOK,
    REQUEST_PRICEBOOK_ERROR,
    RECEIVE_PRICEBOOK,
    REQUEST_PRICEBOOKS,
    RECEIVE_PRICEBOOK_SAVE,
    RECEIVE_PRICEBOOK_ARCHIVE, 
    RECEIVE_PRICEBOOK_CLONE,
    ADD_NEW_PRICEBOOK_PLAN,
    REMOVE_NEW_PRICEBOOK_PLAN,
    REORDER_NEW_PRICEBOOK_PLAN,
    SAVE_PROFILE_EDIT,
    INITIATE_NEW_PRICEBOOK_FLOW,
    RECEIVE_PRICEBOOK_CREATE_ERROR,
    REQUEST_PRICEBOOK_CREATE,
    RECEIVE_PRICING_CREATE,
    RECEIVE_PRICING_CREATE_ERROR
  } from '../constants'
  
  const initData = {
    items: [], 
    isFetching: true,
    lastError: '',
    newPricebookLoading: false,
    newPricebookPricingUrl: '',
    newPricebookError: null
  }
  
  export default function pricebooks(
    state = initData,
    action
  ) {


    let tempPricebooks = [];
    let tempPricebook = null;
    let newPricebookProps = {}; 
    const defaultPricebookProps = { planNames : ['Basic', 'Pro', 'Pro Plus'], annualOnly: false,  freemium: false};
    
    switch (action.type) {
  
    case GLOBAL_LOGOUT:    
      return null;
    case RECEIVE_PRICEBOOKS:
        return Object.assign({}, state, {
          items: formatPricebooks(action.pricebooks),
          isFetching: false,
          lastError: ''
        })  
    case RECEIVE_PRICING_CREATE:
      return Object.assign({}, state, {
        newPricebookLoading: false,
        newPricebookPricingUrl: action.pricing.htmlUrl,
        newPricebookError: null
      }) 
    
    case RECEIVE_PRICEBOOK_CREATE_ERROR:
      return Object.assign({}, state, {
        newPricebookLoading: false,
        newPricebookPricingUrl: '',
        newPricebookError: action.message
      })   
    case RECEIVE_PRICING_CREATE_ERROR:
    case INITIATE_NEW_PRICEBOOK_FLOW:
      return Object.assign({}, state, {
        newPricebookLoading: false,
        newPricebookPricingUrl: '',
        newPricebookError: null 
      })   
    case REQUEST_PRICEBOOK_CREATE: 
      return Object.assign({}, state, {
        newPricebookLoading: true,
        newPricebookPricingUrl: '',
        newPricebookError: null
      })   
    case RECEIVE_SAMPLE_PRICEBOOKS:
      return Object.assign({}, state, {
        newPricebookProps : defaultPricebookProps,
        samples: formatPricebooks(action.pricebooks)
      })   
    case SELECT_SAMPLE_PRICEBOOK_TEMPLATE:
      return Object.assign({}, state, {
        newTemplateId: (action.id)
      })  
    case SET_NEW_PRICEBOOK_NAME:
      return Object.assign({}, state, {
        newPricebookName: (action.name)
      })        
    case SET_NEW_PRICEBOOK_PROP:
      if (state.newPricebookProps) {
        newPricebookProps = {...state.newPricebookProps};
        newPricebookProps[action.key] = action.value;
      }

      return Object.assign({}, state, {
        newPricebookProps
      })  
    case ADD_NEW_PRICEBOOK_PLAN:
    case REMOVE_NEW_PRICEBOOK_PLAN:
    case REORDER_NEW_PRICEBOOK_PLAN:
      if (state.newPricebookProps) {
        newPricebookProps = {...state.newPricebookProps};
      }
      newPricebookProps.planNames = action.planNames;

      console.log(newPricebookProps);

      return Object.assign({}, state, {
        newPricebookProps
      })  
    
    case SAVE_PROFILE_EDIT:
      return Object.assign({}, state, {
        items: []       
      })
    case RECEIVE_PRICEBOOKS_ERROR:
      return Object.assign({}, state, {
        items: [],
        isFetching: false,
        lastError: action.error
      })

      case REQUEST_PRICEBOOK : {

        console.log('in REQUEST_PRICEBOOK', action.pricebookId);
        tempPricebooks = [ ...state.items];
        // tempPricebook = tempPricebooks.find( p=> p.id === action.pricebookId);
        
        
        const pricebookIndex = tempPricebooks.findIndex( p=> p.id === action.pricebookId);
        console.log(pricebookIndex)

        if (pricebookIndex >= 0) {
        
          tempPricebooks[pricebookIndex].isPricebookLoading = true;
          console.log('marking pricbook as loading ', action.pricebookId);
        } else {
          console.log('temp pricebook is null');
        }
 
        return Object.assign({}, state, {

          items : tempPricebooks 
        })
      }

      case REQUEST_PRICEBOOK_ERROR:
      case RECEIVE_PRICEBOOK: {
        tempPricebooks = [ ...state.items];
        tempPricebook = tempPricebooks.find( p=> p.id === action.pricebookId);
  
        if (tempPricebook) {
          tempPricebook.isPricebookLoading = false;
        } else {
          tempPricebooks.push(action.pricebook);
        }

        return Object.assign({}, state, {
          items : tempPricebooks
        })
      }
 
      case REQUEST_PRICEBOOKS:
        return Object.assign({}, state, {
          isFetching: true,
          lastError: ''
        })
  
      case RECEIVE_PRICEBOOK_SAVE:
        tempPricebooks = [ ...state.items];
        tempPricebook = tempPricebooks.find( p=> p.id === action.pricebookId);
  
        if (tempPricebook) {
          tempPricebook.name = action.pricebook.name;
          tempPricebook.description = action.pricebook.description;
  
        } else {
          tempPricebooks.push(action.pricebook);
        }
  
        return Object.assign({}, state, {
          items : tempPricebooks
        })

      case RECEIVE_PRICEBOOK_CLONE:
          tempPricebooks = [ ...state.items]; 
          tempPricebooks.push(action.pricebook); 

          return Object.assign({}, state, {
            items : tempPricebooks
          })
   
  
      case RECEIVE_PRICEBOOK_ARCHIVE:
  
          tempPricebooks = [ ...state.items];
          tempPricebook = tempPricebooks.find( p=> p.id === action.pricebookId);
  
          if (tempPricebook) {
            tempPricebook.active = !action.archived;
          }
  
          return Object.assign({}, state, {
            items : tempPricebooks
          })
   
  
      default:
        return state
    }
  }
  

  function formatPricebooks(data) {

    let pricebooks = [];

    if (data) {
      pricebooks = data.map( p => ({ name: p.name, active: p.active, description: p.description, id:p.id}))
    }
    return pricebooks;
  }