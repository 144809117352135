

import React, { Component } from "react";
import { connect } from 'react-redux' 

import ListDetailsView from '../controls/listDetailsViewControl'
import { plansObjectsToRows, planObjectsToRowDetails, planObjectsToDetails } from  "../libs/selectors"
import { setupPlanEdit, setupDefaultPlanEdit } from "../actions/planEditActions"

const mapStateToDynamicTableProps = (state) => {
 
    if (!state.pricebook || !state.pricebook.json) return {};
   
    return  { 
      addItemCaption: 'Add Plan',
      addItemUrl: '/plans/details/new',
      isLoading: state.pricebook.isPricebookLoading,  
      headers: [ 'Status', 'Plan', 'Billing', '$/Month', '$/Year', 'Products', 'Add-ons'],
      rows: plansObjectsToRows(state.pricebook.json.id, state.pricebook.json.plans, state.pricebook.json.products),
      details: planObjectsToRowDetails(state.pricebook.json.plans, state.pricebook.json.products), 
      selectedDetails: planObjectsToDetails(state.planEdit ? state.planEdit.plan : null, state.pricebook.json.products), 
    } 
  } 


  const mapDispatchToProps = dispatch => {
    return { 
      loadDetailsCallback: (id) => setupPlanEdit(id)
    }
  }
  
  
  const PlansListDetailsView = connect(
    mapStateToDynamicTableProps, mapDispatchToProps )(ListDetailsView)

export class PlansPage extends Component {
    constructor(props) {
        super(props);
     
      }


      async componentDidMount() {
        

        // Load first applicable
        setupDefaultPlanEdit();
        
      }

      render() {
        console.log("in plans page render");
          
        return <PlansListDetailsView/>
      }

}


export default PlansPage