import {
    GLOBAL_LOGOUT,
    RECEIVE_PRICEBOOK, 
    RECEIVE_PRICEBOOKS,
    RECEIVE_PRICEBOOK_REVENUE,
    RECEIVE_PRICEBOOK_ERROR, 
    SET_UP_EDIT_PRICING,
    RECEIVE_PRODUCT_SAVE,
    RECEIVE_PRODUCT_ARCHIVE,
    RECEIVE_PLAN_SAVE,
    RECEIVE_PLAN_CLONE,
    RECEIVE_PLAN_ARCHIVE,
    RECEIVE_MAPPINGS_SAVE,
    UPDATE_SEGMENT_SAVE,
    RECEIVE_SEGMENT_ARCHIVE,
    RECEIVE_GET_PRICING,
    RECEIVE_PRICING_SAVE,
    RECEIVE_PRICING_CREATE,
    RECEIVE_PRICING_CREATE_ERROR,
    RECEIVE_GET_PRICING_ERROR,
    REQUEST_PRICEBOOK,
    REQUEST_PRICEBOOK_ERROR,
    REQUEST_GET_PRICING,
    RECEIVE_PRICEBOOK_SAVE,
    REQUEST_PRICING_REFRESH,
    SAVE_PROFILE_EDIT
  } from '../constants'

  import * as pricebookSelector from '../libs/pricebookSelector'
  
  const initData = {
    name: '',
    description: '',
    lastError: '' ,
    isPricebookLoading : false
  }

  let tempProducts = [];
  let tempProduct = null;
  let tempPlans = [];
  let tempPlan = null;
  let tempSegments = [];
  let tempSegment = null;
  let tempPricebook = null;
  let tempPricing = null;
  
  export default function pricebooks(
    state = initData,
    action
  ) {
    
    switch (action.type) {
  
    case SAVE_PROFILE_EDIT:
    case GLOBAL_LOGOUT:
      return null;
    case REQUEST_PRICEBOOK:
      // check ID before marking it as loading to make sure its current 
      return Object.assign({}, state,  
          { 
            isPricebookLoading: true 
          }
        )
    case RECEIVE_PRICEBOOK_SAVE:
    case RECEIVE_PRICEBOOK:
        return Object.assign({}, state, 
          // shapePricebook(action.pricebook),
          // action.pricebook,
          
            { lastError: '',  
              isPricebookLoading: false,
              json : action.pricebook,
              editPricing: null 
            }
          )   
   
    case REQUEST_PRICEBOOK_ERROR:
      return Object.assign({}, state,  
          {  
            isPricebookLoading: false
          }
        )   

    case RECEIVE_PRICEBOOK_REVENUE:
      return Object.assign({}, state, 
        // shapePricebook(action.pricebook),
        // action.pricebook,
        
          { lastError: '',  
            revenue : action.revenue 
          }
        )   
    case RECEIVE_PRICEBOOK_ERROR:
      return Object.assign({}, state, {
        lastError: action.error
      })
     
    case RECEIVE_PRICEBOOK_SAVE:
       
        tempPricebook = pricebookSelector.clonePricebookJson( state.json ); 
        if (tempPricebook) {
          tempPricebook.name = action.pricebook.name;
          tempPricebook.description = action.pricebook.description;
          tempPricebook.valueMetrics = action.pricebook.valueMetrics;
        }
  
        return Object.assign({}, state, {
          json : tempPricebook
        }) 
    case SET_UP_EDIT_PRICING:
      return Object.assign({}, state, {
        editPricing: action.pricing
      })

    case RECEIVE_PRODUCT_SAVE:
      tempPricebook = pricebookSelector.clonePricebookJson( state.json ); 
      tempProducts =  tempPricebook.products; 
      tempProduct = tempProducts.find( p=> p.id === action.productId);

      if (tempProduct) {
        tempProduct = action.product;
        tempProducts = tempProducts.map(p=> { return p.id === action.productId ? tempProduct : p } );
      } else {
        tempProducts.push((action.product)); 
      }

      tempPricebook.products = tempProducts;

      return Object.assign({}, state, { 
        json: tempPricebook
      })
      
    case RECEIVE_PRODUCT_ARCHIVE:

      tempPricebook = pricebookSelector.clonePricebookJson( state.json );  
      
      tempProducts = tempPricebook.products;
      tempProduct = tempProducts.find( p=> p.id === action.productId);

      if (tempProduct) {
        tempProduct.active = !action.archived;
      }

      return Object.assign({}, state, {
        json: tempPricebook
      })

    case RECEIVE_PLAN_ARCHIVE:

      tempPricebook = pricebookSelector.clonePricebookJson( state.json );  

      tempPlans = tempPricebook.plans;
      tempPlan = tempPlans.find( p=> p.id === action.planId);

      if (tempPlan) { tempPlan.active = !action.archived; }

      return Object.assign({}, state, {
        json: tempPricebook
      })

    case RECEIVE_SEGMENT_ARCHIVE:
      tempPricebook = pricebookSelector.clonePricebookJson( state.json );  

      tempSegments = tempPricebook.segmentsData;
      tempSegment = tempSegments.find( p=> p.id === action.segmentId);

      if (tempSegment) {
        tempSegment.active = !action.archived;
      }

      return Object.assign({}, state, {
        json: tempPricebook
      })
    case RECEIVE_PLAN_CLONE:
    case RECEIVE_PLAN_SAVE: 

      tempPricebook = pricebookSelector.clonePricebookJson( state.json );  
      tempPlans = tempPricebook.plans;
      
      if (tempPricebook.plans.find( p=> p.id === action.planId)) {
        tempPricebook.plans = tempPlans.map(p=> { return p.id === action.planId ? action.plan : p } );
      } else {
        tempPricebook.plans.push(action.plan);
      }

      return Object.assign({}, state, {
        json: tempPricebook
      })  

    case RECEIVE_MAPPINGS_SAVE:
      
      tempPricebook = pricebookSelector.clonePricebookJson( state.json ); 
      tempPricebook.plans = action.plans;
      
      return Object.assign({}, state, { 
        json: tempPricebook
      })

    case REQUEST_GET_PRICING: 
    case REQUEST_PRICING_REFRESH:  
      return Object.assign({}, state, { 
        fetchingPricing: true
      }) 
    // case RECEIVE_PRICING_SAVE:
    // case RECEIVE_PRICING_CREATE:
    // case RECEIVE_GET_PRICING:
    //   return Object.assign({}, state, 
       
    //     {  
    //       fetchingPricing: false,
    //       pricing: { ... action.pricing}
    //   })
    case RECEIVE_PRICING_CREATE_ERROR:
    case RECEIVE_GET_PRICING_ERROR:
      return Object.assign({}, state, { 
        fetchingPricing: false
      }) 
    case UPDATE_SEGMENT_SAVE:

      console.log('in update seg save');
      tempPricebook = pricebookSelector.clonePricebookJson( state.json ); 
      tempSegments = tempPricebook.segmentsData; 
      tempSegment = tempSegments.find( p=> p.id === action.segmentId);

      if (tempSegment) {
        tempSegment = action.segment;
        tempSegments = tempSegments.map(p=> { return p.id === action.segmentId ? tempSegment : p } );
      } else {
        tempSegments.push(action.segment);
      }

      tempPricebook.segmentsData = tempSegments;

      return Object.assign({}, state, {
        json: tempPricebook
      }) 

    default:
      return state
    }
  }
   