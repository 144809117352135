import * as constants from '../constants'; 
import { createSheet } from '../libs/factory'  

export default function spreadsheetEdit(
  state = {  
    isLoading: true,
    newAndUnsaved: false,
    sheets: []
  },
  action
) { 
  let tempSheets = null;
  let tempSheet = null;
  let tempSpreadsheet = null;

  switch (action.type) {
    case constants.GLOBAL_LOGOUT:
      return null;

    case constants.SET_UP_EDIT_SPREADSHEET:

      tempSpreadsheet = {...action.spreadsheet};
        
      if (action.spreadsheet.spreadsheetType) {
        action.spreadsheet.sheets = mergeSheetsWithDefaults(tempSpreadsheet.id, tempSpreadsheet.spreadsheetType, tempSpreadsheet.sheets, action.specs);  
      }

      return {
        ...state,
        data: tempSpreadsheet,
        newAndUnsaved: action.newAndUnsaved,
        specs: action.specs,
        isLoading: false,
        label: "",
        isBusy: false,
        pageName: action.newAndUnsaved ? "Add Spreadsheet" : "Editing " + action.spreadsheet.originalName
      };

    case constants.EDIT_SPREADSHEET_FIELD:

      tempSpreadsheet = {...state.data};

      if (action.name === 'spreadsheetType') {
        tempSpreadsheet.spreadsheetType = (action.value);
        tempSpreadsheet.sheets= getDefaultSheets(tempSpreadsheet.id, action.value, state.specs);
      
        return {
          ...state,
          data: tempSpreadsheet 
        }
      }  else if (action.name === 'sheets')  {
        tempSheets = tempSpreadsheet.sheets || [];// check for null 
        tempSheet = tempSheets.find(e => e.sheetType === action.id );
        
        if (tempSheet) {
          tempSheet.active = action.value;
          tempSpreadsheet.sheets = tempSpreadsheet.sheets.map ( s => s.sheetType === tempSheet.sheetType ? tempSheet : s);
          
        } else {
          tempSheet = createSheet(tempSpreadsheet.id, action.id);
          tempSheet.active =  action.value;
          tempSpreadsheet.sheets = tempSpreadsheet.sheets.concat([tempSheet]);
        } 

        return {
          ...state,
          data: tempSpreadsheet,
        }
      } else {
          tempSpreadsheet[action.name] = action.value;
          return {
            ...state,
            data: tempSpreadsheet
          }; 
      }
 
    default:
      return state;
  }
}

function mergeSheetsWithDefaults(spreadsheetId, spreadsheetType, sheets, specs) {

  let mergedSheets = [];
  // let spreadsheetSchema = spreadsheetsSchema.find(ss => ss.type === spreadsheetType);

  let spreadsheetSchema = specs.find( ss => ss.type === spreadsheetType)

  spreadsheetSchema.sheets.forEach((schemaSheet) => {
    
    let sheet = sheets.find(ms => ms.sheetType === schemaSheet.type );
    if (sheet) {
      mergedSheets.push(sheet);
    } else {
      mergedSheets.push(createSheet(spreadsheetId, schemaSheet.type, schemaSheet.defaultName, !schemaSheet.optional));
    }
    
  })  

  return mergedSheets;
}

function getDefaultSheets(spreadsheetId, spreadsheetType, specs) {
 

  let sheets = [];
  let spreadsheetSchema = specs.find( ss => ss.type === spreadsheetType);

  spreadsheetSchema.sheets.forEach((schemaSheet) => {

    sheets.push(createSheet(spreadsheetId, schemaSheet.type, schemaSheet.defaultName, !schemaSheet.optional));


  })

  return sheets;
}


// function getDefaultSheets(spreadsheetId, spreadsheetIndex) {
 

//   let sheets = [];
//   let spreadsheetSchema = spreadsheetsSchema[spreadsheetIndex]

//   spreadsheetSchema.sheets.forEach((schemaSheet) => {

//     sheets.push(createSheet(spreadsheetId, schemaSheet.type, schemaSheet.defaultName, !schemaSheet.optional));


//   })

//   return sheets;
// }