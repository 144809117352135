 
import { connect } from 'react-redux' 
import React from 'react'
import EditValueCharge from './editValueChargeControl'  
import * as pricebookSelector from '../libs/pricebookSelector'
import { editValueChargeField } from '../actions/planEditActions'

const EditChargeValueCharges = ({

    valueCharges,
    editValueChargeFieldHandler
 }) => {
     
    if (valueCharges.length === 0) return <></>

    return (
    <>

      <div className="text-gray-400 text-xs mb-2 flex flex-start">Value Metrics</div>
      {valueCharges.map((vc) => (
        <EditValueCharge    
            key={vc.id} {...vc}
            unit={vc.valueMetric.unit}
            unlimited={parseFloat(vc.unitsInBase) === -1}
            changeFieldHandler={editValueChargeFieldHandler}/>
        ))}
    </>)
}


const mapStateToEditAddonProps = (state, ownProps) => {

    const valueCharges = 
      ownProps.charge ? 
      pricebookSelector.getPlanAddonValueCharges( ownProps.plan, ownProps.charge, ownProps.product )
      : 
      pricebookSelector.getPlanProductValueCharges( ownProps.plan, ownProps.product ); 

     
    return  {
        valueCharges
    }
  }
  
  const mapDispatchToEditAddonProps = dispatch => {
    return { 
      editValueChargeFieldHandler: (chargeId, name, value) => editValueChargeField(chargeId, name, value),
    }
  }
  
  export default connect(
    mapStateToEditAddonProps,
    mapDispatchToEditAddonProps )(EditChargeValueCharges)
  

