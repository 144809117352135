import { combineReducers } from 'redux'
import pricebooks from './reducers/pricebooksReducer'
import spreadsheets from './reducers/spreadsheetsReducer'
import pricebook from './reducers/pricebookReducer'
import user from './reducers/userReducer'
import userEdit from './reducers/userEditReducer'
import pricebookEdit from './reducers/pricebookEditReducer'
import planEdit from './reducers/planEditReducer'
import productEdit from './reducers/productEditReducer'
import segmentEdit from './reducers/segmentEditReducer'
import productPlansEdit from './reducers/productPlansEditReducer'
import spreadsheetEdit from './reducers/spreadsheetEditReducer'
import pricingEdit from './reducers/pricingEditReducer'
import pricingPages from './reducers/pricingPagesReducer'
import info from './reducers/infoReducer'
 
const pricingSaaSApp = combineReducers({
    pricebooks,
    pricebook,
    pricebookEdit,
    spreadsheets,
    planEdit,
    productEdit,
    segmentEdit,
    productPlansEdit,
    spreadsheetEdit,
    pricingEdit,
    pricingPages,
    info,
    user ,
    userEdit 
  })
  
export default pricingSaaSApp