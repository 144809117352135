import React  from "react";
// import { connect } from 'react-redux' 
import * as pricebookSelector from '../libs/pricebookSelector' 
// import ProductSelectorDD from '../components/product/ProductSelectorDD' 
import EditChargeFeatures from '../controls/editChargeFeaturesControl';
import EditChargeMultiOptions from './editChargeMultiOptionsControl'
import EditChargeValueCharges from './editChargeValueChargesControl';
import Dropdown from "./dropdownControl"
import EditAddonChage from './editAddonChargeControl';

// const mapStateToProductSelectorProps = (state, ownProps) => {
//   return  {
//     products: ownProps.products.filter(p => !p.checked)
//   }
// }

// const mapDispatchToProductSelectorProps = (dispatch, ownProps) => {
//   return {
//     editPlanProductFieldHanlder: (productId, name, value) => ownProps.editPlanProductFieldHandler(productId, name, value),
//     selectorHandler: (productId) => ownProps.selectorHandler(productId)
//   }
// }

// const SelectProductSelectorDD = connect(
//   mapStateToProductSelectorProps,
//   mapDispatchToProductSelectorProps )
//   (ProductSelectorDD)

function AddonSelectorControl ({
  addonCharges, discounts, selectedProducts, availableProducts, products, plan,
  removeProductHandler,
  editPlanProductFieldHandler}) {
 
  if (!addonCharges) return <></>
  
  return <>
        <div className="flex w-full flex-col justify-start">
       
             { addonCharges.map((addonCharge) => (
               <>
                <div className=" border-b mb-2 border-gray-200 ">
                  <div className="flex justify-between	">
                    <div className="flex justify-start text-center min-w-600 ">
                      { pricebookSelector.getProductNameFromId(selectedProducts, addonCharge.addonProductRefId) }
                    </div>
                    <a                    
                      onClick={() => removeProductHandler( addonCharge.addonProductRefId, addonCharge.id )}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >Remove</a>
                  </div> 
                  <div key={addonCharge.id}>   
                  
                      <div className="my-4 ml-2">  
                      
                      <EditAddonChage charge={addonCharge} discount={ discounts.find( discount => discount.chargeId === addonCharge.id)}/>
                      </div>
                     
                      <div className="my-4">
                      <EditChargeFeatures charge={addonCharge} plan={plan} product={products.find(prod => prod.id === addonCharge.addonProductRefId)}/>                       
                      </div>         
                          
                      <div className="my-4">
                      <EditChargeMultiOptions plan={plan} product={products.find(prod => prod.id === addonCharge.addonProductRefId)} charge={addonCharge}/> 
                      </div>
                      
                      <div className="my-4">
                      <EditChargeValueCharges plan={plan} product={products.find(prod => prod.id === addonCharge.addonProductRefId)} charge={addonCharge}/> 
                      </div>                   
                  </div>
                </div>
              </>
               
             ))}

              <div className="flex justify-start  my-2 ">
              { availableProducts.length > 0 ?              
                  <Dropdown 
                    items={availableProducts.map( (product, productIndex) => { return {name: product.name, key:product.id}})} 
                    currentItem="Add an Addon"
                    primary={true}
                    onSelect={(productId)=> editPlanProductFieldHandler(productId, 'checked', true)}/>  
                  
                  :
                    <div title="No more products to add" className="text-sm font-medium text-gray-500">Add an Addon</div>
              } 
              </div>  
           

         </div>
       </>
}

export default (AddonSelectorControl);
 