import React  from "react"; 
import ReactDragListView from 'react-drag-listview'; 
import { Switch } from '@headlessui/react'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function SelectListControl ({ items=[], changeHandler }) {
  

  return <> 
            <div className="w-full">
              {/* <ReactDragListView {...dragProps}> */}
                <ol>
                  {items.map((item, index) => (
                    <>
                    <li key={index}>
                    
                      <div className="flex justify-between	">

                      <div className="flex justify-start text-center min-w-600 my-2">                          
                          <Switch
                            checked={item.enabled}
                            onChange={(value) => changeHandler(item.key, value)}
                            className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            <span className="sr-only">Use setting</span>
                            <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
                            <span
                              aria-hidden="true"
                              className={classNames(
                                item.enabled ? 'bg-blue-600' : 'bg-gray-200',
                                'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
                              )}
                            />
                            <span
                              aria-hidden="true"
                              className={classNames(
                                item.enabled ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
                              )}
                            />
                          </Switch>
                          <div className="px-4">
                            {item.name} 
                            {item.configCallback && item.enabled && 
                              <> (<a onClick={item.configCallback} className="text-blue-700">Config</a>)</>
                            }
                          </div>
                          
                          {/* onChange={(event) => {changeHandler(item.id, event.target.value)}} */}
                      </div>
                      
                      </div>
                    </li>
                    
                    </>
                  ))}
                  
                </ol>
              {/* </ReactDragListView> */}
            
               
            </div>
        </>
}

export default (SelectListControl);
