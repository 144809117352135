

import React, { Component } from "react";
import { connect } from 'react-redux' 

import ListDetailsView from '../controls/listDetailsViewControl'
import { pricingPagesObjectsToRows, assetObjectsToDetails, assetObjectsToRowDetails } from  "../libs/selectors"
import { canAddPricingPage } from "../libs/membership"
import { loadPricingEdit, setupDefaultPricingEdit } from "../actions/pricingEditActions"
import { fetchPricingPages } from "../actions/pricingActions"
import { fibonacciTimer } from "../libs/helper";

const mapStateToDynamicTableProps = (state) => {
 
    if (!state.pricebook || !state.pricebook.json) return {};
    
    return  { 
      addItemCaption: 'Add Pricing Page',
      // addItemUrl: canAddPricingPage(state.user, state.pricingPages) ?  '/assets/details/new' : '/checkout',
      addItemUrl: '/assets/details/new',
      isLoading: state.pricebook.isPricebookLoading, 
      headers: ['State', 'Name', 'Last Modified' ],
      rows: pricingPagesObjectsToRows(state.pricingPages.data),
      details: assetObjectsToRowDetails(state.pricingPages.data,  state.pricingPages.specs),
      selectedDetails: assetObjectsToDetails(state.pricingEdit ? state.pricingEdit.data : null, state.pricingPages.specs)
      
    } 
  } 

  const mapDispatchToProps = dispatch => {
    return { 
      loadDetailsCallback: (id) => loadPricingEdit(id)
    }
  }
  
        
  const ProductsListDetailsView = connect(
    mapStateToDynamicTableProps, mapDispatchToProps )(ListDetailsView)

export class AssetsPage extends Component {
    constructor(props) {
        super(props);    
        this.timer = null; 
      }

      async componentDidMount() {
        fibonacciTimer(() => fetchPricingPages(), 60, this.timer);      
        setupDefaultPricingEdit();
      }

      render() { 
        return <ProductsListDetailsView/>
      } 
}


export default AssetsPage