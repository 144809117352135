import React, { Component } from "react";   
import { connect } from 'react-redux' 
import { loadTemplates, initiateNewPricebookFlow, 
  selectSampleTemplate, 
  setNewPricebookProp, 
  setNewPricebookName, 
  addPlan, removePlan, updatePlan, reorderPlan, 
  createNewPricebook } from '../actions/pricebookNewActions'
import Dropdown from "../controls/dropdownControl"
import { Switch } from '@headlessui/react'
import EditPlanNamesControl from "../controls/editPlanNamesControl"
import WizardControl from "../controls/wizardControl"
import PricingLayoutControl from "../controls/pricingLayoutControl"
import PricingTypeControl from "../controls/pricingTypeControl" 
  

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
 

const paymentFrequencies = [
  { id: 'email', title: 'Monthly and Annual', value: false },
  { id: 'sms', title: 'Annual Only', value: true }, 
]

const mapStateToPlanNamesListProps = (state, ownProps) => {


  let planNames = ['Basic', 'Pro', 'Pro Plus']


  // const toggableFeatures = pricebookSelector.getProductToggableFeatures(state.productEdit.data);
 
  if (state.pricebooks && state.pricebooks.newPricebookProps && state.pricebooks.newPricebookProps.planNames) {
    planNames = state.pricebooks.newPricebookProps.planNames;
  }

  return  {
    items: planNames.map( (name, index) => { return { title: name, id: index}}), //featureObjectsToRows(toggableFeatures, true),
    label: 'PlanNames',
    buttonLabel: 'Add Plan'
  }
}


const mapDispatchToPlanNamesListProps = dispatch => {
  return {
    changeHandler: (index, value) => updatePlan(index, value),
    // tooltipHandler: (featureId, value) => actions.editFeatureField(featureId, 'tooltip', value),
    reorderHandler: (from, to) => reorderPlan(from, to),
    deleteHandler: (index) => removePlan(index),
    addHandler: () => addPlan()
  }
}

const NewEditPlanNamesControl = connect(
  mapStateToPlanNamesListProps,
  mapDispatchToPlanNamesListProps  )
  (EditPlanNamesControl)

const mapProductStateToProps = state => {
  
  if (!state.pricebooks || !state.pricebooks.samples) {
    return { samples : []};
  }

  let currentTemplate = "Select Template";
  if (state.pricebooks.newTemplateId) {
    const template = state.pricebooks.samples.find(sample => sample.id === state.pricebooks.newTemplateId);
    if (template) { currentTemplate = template.name; }
  }

  let includeFreemium = false;
  let onlyAnnualPlans = false;
  let planNames = ['Basic', 'Pro', 'Pro Plus']
  let currentTemplateId =  state.pricebooks.newTemplateId; 
  let currentPricingTemplateId = null;

  const isLoading = state.pricebooks ? state.pricebooks.newPricebookLoading : true;
  const newPricingPageUrl = state.pricebooks ? state.pricebooks.newPricebookPricingUrl : null; 
  const createErrorMessage  = state.pricebooks ? state.pricebooks.newPricebookError : null; 

  if (state.pricebooks.newPricebookProps) {

    includeFreemium = state.pricebooks.newPricebookProps.freemium; 
    onlyAnnualPlans = state.pricebooks.newPricebookProps.annualOnly;
    currentPricingTemplateId = state.pricebooks.newPricebookProps.templateId;
   

    console.log('currentTemplateId', currentTemplateId);

    if (state.pricebooks.newPricebookProps.planNames) {
      planNames = state.pricebooks.newPricebookProps.planNames;
    }
  }  

 
 
  return  {
    samples: state.pricebooks.samples,
    specs: state.pricingPages.specs,
    currentTemplate,
    includeFreemium,
    onlyAnnualPlans,
    isLoading,
    createErrorMessage,
    validPlanConfig : planNames.length > 0,
    newPricingPageUrl,
    currentTemplateId,
    currentPricingTemplateId,
    planNames,
    name : state.pricebooks.newPricebookName
  }
}

export  class NewPricebookPage extends Component {

  async componentDidMount() {
    initiateNewPricebookFlow();
     loadTemplates();
  }

  renderSamples(samples, currentTemplate) {
    return <>
            <Dropdown 
              items={samples.map( (item, itemIndex) => { return {name: item.name, key:item.id}})} 
              currentItem={currentTemplate}
              primary={true}
              onSelect={(row)=> selectSampleTemplate(row)}
            />              
           </>
  }

  renderSwitch(value, key, onChangeHandler) {

    return <Switch
          checked={value}
          onChange={(p) => onChangeHandler(key, p)}
          className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
            <span className="sr-only">Use setting</span>
                <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
                <span
                  aria-hidden="true"
                  className={classNames(
                    value ? 'bg-blue-600' : 'bg-gray-200',
                    'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
                  )}
                />
                <span
                  aria-hidden="true"
                  className={classNames(
                    value? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
                  )}
                />
        </Switch>

  }

  renderOptions(onChangeHandler) {

    const selected = false;
    const setSelected = null;

    return ( 
      <div>
     
      <fieldset className="mt-4">
        <div className="space-y-4">
          {paymentFrequencies.map((paymentFrequency) => (
            <div key={paymentFrequency.id} className="flex items-center">
              <input
                id={paymentFrequency.id}
                name="notification-method"
                type="radio"
                onClick={()=>onChangeHandler(paymentFrequency.value)}
                defaultChecked={paymentFrequency.id === 'email'}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 text-lg"
              />
              <label htmlFor={paymentFrequency.id} className="ml-3 block text-lg font-medium text-gray-700">
                {paymentFrequency.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
    )
  }

  render() {

    if (this.props.isLoading) {
      return (
          <>
        
            <div className="max-w-7xl bg-white m-auto p-10 border rounded-md h-96  flex items-center justify-center 	">   
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
             Building your pricing page ... hang on one sec! 
            </div>
          </>
        )
    } 

    if (this.props.newPricingPageUrl) {
      return (
          <>
             <div className="max-w-7xl bg-white m-auto p-10 border rounded-md h-96  flex flex-col items-center text-lg justify-center 	">   
             <div> Your pricing page is ready!&nbsp;&nbsp;&nbsp;🎉</div>
             <div className="p-6">
              <a href={this.props.newPricingPageUrl} target="_blank">               
              <button
              type="button"
                    className="inline-flex px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  >
                Preview
              </button>               
              </a> 
              <span className="ml-8 text-blue-700">
                <a href="/Dashboard">
                  Continue
                </a>
                </span>  
              </div>
            </div>
          </>
        )
    }

    if (this.props.createErrorMessage) {
      return (
        <>
           <div className="max-w-7xl bg-white m-auto p-10 border rounded-md h-96  flex flex-col items-center text-lg justify-center 	">   
           <div> Oh no, looks like something went wrong!</div>
           <div className="p-6">{this.props.createErrorMessage}</div>
           <a href='/new' className="text-blue-700">Try again</a>
           </div>
        </>)
    }
    const step0 = {};
 
    step0.html = <> 
                  <PricingTypeControl templates={this.props.samples} currentTemplateId={this.props.currentTemplateId} changeHandler={(value) => selectSampleTemplate(value)}/></>
    
    step0.caption = "Pricing Type";
    step0.instructions = "Pick a pricing model"
    step0.valid =  Boolean(this.props.currentTemplateId);
    step0.errorMessage =  "Pick a model first..."

    const step1 = {}
    step1.html = <>
                  <div className="p-20 w-full max-w-3xl">
                    <div className="flex justify-between text-lg  text-center max-w-3xl pb-5 text-lg text-gray-700"> 
                      Free 
                      {this.renderSwitch(this.props.includeFreemium, 'freemium', setNewPricebookProp)}   
                    </div>    
                    <NewEditPlanNamesControl/>

                    
                  </div>
                 </>
    step1.caption = "Plan Names";
    step1.instructions = "Configure and name your plans"
    step1.valid =  this.props.validPlanConfig;
    step1.errorMessage =  "You've got to have at least one plan..."
    

    const step2 = {}
    step2.html = <>            
                  <div className="flex flex-col  justify-start p-10 text-lg text-center max-w-3xl ">       
                  
                   
                    <div>
                      {this.renderOptions( (value) => setNewPricebookProp('annualOnly', value))}
                      {/* {this.renderSwitch(!this.props.onlyAnnualPlans, 'annualOnly', (key,value) => setNewPricebookProp(key, !value))} */}
                      {/* &nbsp; &nbsp; Include Monthly Plans */}
                    </div>
                  </div> 
                 </>
    step2.caption = "Payment Frequency"
    step2.instructions = "Choose your payment options";
    step2.valid = true;

    
    const step3 = {};

    step3.html = <> <PricingLayoutControl specs={this.props.specs} currentTemplateId={this.props.currentPricingTemplateId}  changeHandler={(value) => setNewPricebookProp('templateId', value)}/></>    
    step3.caption = "Pricing Layout";
    step3.instructions = "Pick a layout for your pricing page";

    step3.errorMessage = "Choose your pricing page layout...";
    step3.valid = Boolean(this.props.currentPricingTemplateId);
    



    const step4 = {};
    step4.html = <> 
                  <div className="p-20 w-full max-w-3xl">
                    <div className="flex  justify-start   text-center max-w-3xl pb-5"> 
                  <input
                      type="text"
                      name="name"
                      value={this.props.name}
                      onChange={(event) => {setNewPricebookName(event.target.value)}}
                      id="name"
                      className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block  text-xl border-gray-300 rounded-md"
                      placeholder="Type pricebook name"
                      /> 
                  </div>
                  </div>
                </>
    step4.instructions = "Give this pricebook a name";
    step4.caption = "Pricebook Name";
    step4.errorMessage = "Pricebook name cannot be empty";
    step4.valid = Boolean(this.props.name);
      
    const steps = [step3, step1, step2, step0, step4];

    return (
      <div className="max-w-7xl bg-white m-auto p-10 border rounded-md ">
      <WizardControl steps={steps} finishCaption='Create' onFinishHandler={() => createNewPricebook(() => this.props.history.push('/dashboard'))}/>      
      </div>
    )
    return (
    <div className="max-w-7xl bg-white m-auto p-10 border rounded-md ">
        <div>
          <div className="flex justify-between	">
            <h3 className="text-lg leading-6 font-medium text-gray-900">New Pricebook </h3>
            <div>
                      
                {/* Edit */}
            </div>
          </div> 
        </div>
        <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex  justify-start   text-center max-w-3xl ">
                  <input
                      type="text"
                      name="name"
                      value={this.props.name}
                      onChange={(event) => {setNewPricebookName(event.target.value)}}
                      id="name"
                      className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                      placeholder="Type pricebook name"
                      />
                  {/* <PencilIcon className="h-5 w-5" aria-hidden="true" /> */}
                </div>                
              </dd>
            </div>   

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Template</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex  justify-start   text-center max-w-3xl "> 
                  {this.renderSamples(this.props.samples, this.props.currentTemplate)}
                </div>                 
              </dd>
            </div>                         

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Free Tier</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex  justify-start   text-center max-w-3xl "> 
                    {this.renderSwitch(this.props.includeFreemium, 'freemium', setNewPricebookProp)}
                </div>                 
              </dd>
            </div>          

             <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Include Monthly Plans</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex  justify-start   text-center max-w-3xl "> 
                    {this.renderSwitch(!this.props.onlyAnnualPlans, 'annualOnly', (key,value) => setNewPricebookProp(key, !value))}
                </div>                 
              </dd>
            </div>            

             <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Plan Names</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="flex  justify-start   text-center max-w-3xl "> 
                    <NewEditPlanNamesControl/>
                </div>                 
              </dd>
            </div>        
          </dl>
        </div> 
        <div className="flex  justify-end   items-center w-full "> 
            <a
              // onClick={() => this.props.cancelEdit()}
              className="font-medium px-6 text-gray-600 hover:text-gray-500"
            >Cancel</a>          
            <button
              type="button"
              onClick={() => createNewPricebook(() => this.props.history.push('/products'))}
              className="inline-flex px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            >
          Save
        </button> 
      </div>
      </div>) 
  }
}


export default connect(
  mapProductStateToProps 
)(NewPricebookPage)
