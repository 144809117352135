import React, { Component } from "react";   
import { connect } from 'react-redux' 
import PricebooksDropdown from '../controls/pricebookDropdown'
import { updateSubscriptionF } from '../actions/userActions'

const mapProductStateToProps = state => {
 
  return  {
    isLoading: state.pricebooks ? state.pricebooks.isFetching : false   
  }
}


const mapStateToProps = (state, ownProps) => {

    if (!state.pricebooks || !state.pricebooks.items) { return { pricebookLoading: true }}
  
    return {
      pricebooks: state.pricebooks.items.filter( p => p.active),
      lastError:  state.pricebooks.lastError,
      currentName: state.pricebook && state.pricebook.json ? state.pricebook.json.name : "Select",
      pricebookLoading: state.pricebook ? state.pricebook.isLoading : false
    }
  }
  
  const ActivePricebooksDropdown = connect(
    mapStateToProps
  )(PricebooksDropdown)


export  class SuccessPage extends Component {


  async componentDidMount() {

    // load the Google SDK 
    updateSubscriptionF( ()=> { window.location.href='/profile/t/billing' })
  
  }
  

  render() {
     
    return (
            <div class="flex items-center justify-center h-full w-full border-1 border-gray-800">
              <div className="max-w-7xl bg-white m-auto p-10 align-middle  ">  Thanks for the purchase!
              </div>
            </div>
           ) 
  }
}


export default connect(
  mapProductStateToProps 
)(SuccessPage)
