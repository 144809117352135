import React  from "react";  
import ReactDragListView from 'react-drag-listview';

import { XIcon, ViewListIcon} from '@heroicons/react/solid'
import {  CubeIcon} from '@heroicons/react/outline'

function EditValueMetricsControl ({items=[], changeHandler, deleteHandler, addHandler, tooltipHandler=null, reorderHandler=null}) {
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (reorderHandler) {
        reorderHandler(fromIndex, toIndex);
      } 
    },
    nodeSelector: 'li',
    handleSelector: 'a',
    lineClassName: 'dragLine'
  };
 
  return <> 
          
            <div className="w-full  	  ">
              <ReactDragListView {...dragProps}>
                <ol>
                  {items.map((item, index) => (
                    <li key={index}>
                      <div className="flex justify-start text-center flex justify-between  my-4 text-gray-700">
                        <div className="flex justify-start">
                        {/* &nbsp;
                        { tooltipHandler ? <><img height='16px' src="/tooltip.png" onClick={() => deleteHandler(item.id)} />&nbsp;</> : <></> }  */}
                        <a href="#"><CubeIcon className="h-5 w-5 text-gray-600 mr-2" aria-hidden="true"/> </a>                     
                      <input
                        type="text"
                        className="" 
                        placeholder="Enter name"
                        onChange={(event) => {changeHandler(item.id, 'name', event.target.value)}}
                        value={item.name}/>
                      <input
                        type="text"
                        className="" 
                        placeholder="Enter unit"
                        onChange={(event) => {changeHandler(item.id, 'unit', event.target.value)}}
                        value={item.unit}/>
                      </div>

                        <XIcon className="h-5 w-5 text-gray-600" aria-hidden="true" onClick={() =>  deleteHandler(item.id)} /> 

                        {/* &nbsp;
                        { tooltipHandler ? 
                            <Form.Control
                            type="text"
                            className="controlText"
                            placeholder="Enter Tooltip"
                            onChange={(event) => {tooltipHandler(item.id, event.target.value)}}
                            value={item.tooltip}/>
                          : <></>
                        } */}
                          
                      </div>
                    </li>
                  ))}
                </ol>
              </ReactDragListView>
              <div className="mt-10 flex justify-start">
              <a
                 onClick={() => addHandler()}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >Add Value Metric</a>
              </div> 
            </div> 
        </>
}

export default (EditValueMetricsControl);
