import { API } from "aws-amplify";
import { store } from '../store';
import * as constants from '../constants';
import { HandleApiError } from "../libs/apiHelper";

export function archivePlan(planId, archive=true) {

  store.dispatch( (dispatch, getState) => {

   dispatch({ planId: planId, type: constants.REQUEST_PLAN_ARCHIVE, archived: archive  });

    const pricebookId = getState().pricebook.json.id;
    let url = (archive) ? "/plans/archive/"+pricebookId+"/"+planId : "/plans/unarchive/"+pricebookId+"/"+planId;

    console.log(url);

    API.put("plans", url).then(
      response => dispatch({
        type: constants.RECEIVE_PLAN_ARCHIVE,
        planId: planId,
        archived: archive,
        receivedAt: Date.now()
      }),
      error => {
          HandleApiError(error);
          dispatch({
            type: constants.RECEIVE_PLAN_ARCHIVE_ERROR,
            error,
            receivedAt: Date.now()
          })
        }
      )
  });
}


export function clonePlan(pricebookId, planId, name=null) {

  store.dispatch( (dispatch, getState) => {

    dispatch({ type: constants.REQUEST_PLAN_CLONE, planId});

    let url = "/plans/clone/"+pricebookId+"/"+planId;

    console.log("Cloning plan: " + planId);
    console.log("Cloning plan URL: " + url);

    let myInit = {
        body: { name : name}
    }

    return API.put("pricebooks", url, myInit).then(
      response => {
        dispatch({ type: constants.RECEIVE_PLAN_CLONE, plan: response["data"] }); 
      },
      error => {
        HandleApiError(error); 
      }
    );
  });
}