import { API } from "aws-amplify";
import { store } from '../store';
import * as constants from '../constants';
import { HandleApiError } from '../libs/apiHelper'
import { createProduct } from '../libs/factory'

export function addProduct(callback) { 

    store.dispatch((dispatch, getState) => {
      console.log('adding product with pricebook id ', getState().pricebook.json.id);
      let product = createProduct(getState().pricebook.json.id);
      
      dispatch({
      type: constants.ADD_PRODUCT,
      product});

      callback(product.id);
    });
}

export function archiveProduct(productId, archive=true) {

  store.dispatch( (dispatch, getState) => {

   dispatch({ productId: productId, type: constants.REQUEST_PRODUCT_ARCHIVE, archived: archive  });

    const pricebookId = getState().pricebook.json.id;
    const url = (archive) ? "/products/archive/"+pricebookId+"/"+productId : "/products/unarchive/"+pricebookId+"/"+productId;

    console.log('calling url: ', url);
    API.put("products", url).then(
      response => dispatch({
        type: constants.RECEIVE_PRODUCT_ARCHIVE,
        productId: productId,
        pricebookId: pricebookId,
        archived: archive,
        receivedAt: Date.now()
      }),
      error =>
      {
        HandleApiError(error);
        dispatch({
          type: constants.RECEIVE_PRODUCT_ARCHIVE_ERROR,
          error,
          receivedAt: Date.now()
        });
      }
    )
  });
}
