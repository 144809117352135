import { API } from "aws-amplify";
import { store } from '../store';
import * as constants from '../constants';
import { createProduct } from '../libs/factory'  
import { HandleApiError, prepObjectForApiCall } from "../libs/apiHelper";
export function editProductField(name, value) {
      return store.dispatch({
      type: constants.EDIT_PRODUCT_FIELD,
      name,
      value
    });
} 

export function selectValueMetric (valueMetricId) {
  return store.dispatch({
    type: constants.SELECT_PRICEBOOK_VALUEMETRIC,
    valueMetricId
  });
}

export function editValueMetricField(valueMetricId, name, value) {
      return store.dispatch({
      type: constants.EDIT_PRODUCT_VALUEMETRIC_FIELD,
      valueMetricId,
      name,
      value
    });
}


export function editMultiOptionField(multiOptionId, name, value) {
  return store.dispatch({
  type: constants.EDIT_PRODUCT_MULTIOPTION_FIELD,
  multiOptionId,
  name,
  value
});
}


export function editMultiOptionOptionField(multiOptionId, index, value) {
  return store.dispatch({
  type: constants.EDIT_PRODUCT_MULTIOPTION_OPTION_FIELD,
  multiOptionId,
  index,
  value
});
}



export function addProductValueMetric() {
      return store.dispatch({
      type: constants.ADD_PRODUCT_VALUEMETRIC
    });
}


export function addProductMultiOption() {
  return store.dispatch({
  type: constants.ADD_PRODUCT_MULTIOPTION
});
}

export function addProductMultiOptionOption(multiOptionId) {
  return store.dispatch({
  type: constants.ADD_PRODUCT_MULTIOPTION_OPTION,
  multiOptionId
});
}

export function deleteProductMultiOptionOption(multiOptionId, index) {
  return store.dispatch({
  type: constants.DELETE_PRODUCT_MULTIOPTION_OPTION,
  multiOptionId,
  index
});
}


export function reorderValueMetricHandler(from, to) {

  return store.dispatch((dispatch, getState) => {
 
      dispatch({
        type: constants.REORDER_PRODUCT_VALUEMETRIC,
        from: from,
        to: to
      });
  });
}



export function reorderMultiOptionHandler(from, to) {

  return store.dispatch((dispatch, getState) => {
 
      dispatch({
        type: constants.REORDER_PRODUCT_MULTIOPTION,
        from: from,
        to: to
      });
  });
}

export function reorderFeature(from, to, toggable=false) {

  return store.dispatch((dispatch, getState) => {

      try { 

        let fromOffset = 0;
        let toOffset = 0;

        dispatch({
          type: constants.REORDER_PRODUCT_FEATURE,
          from: from + fromOffset,
          to: to + toOffset,
          toggable

        });

      } catch (e) {
        console.log('ooops, something went wrong', e);
      }

  });
}

export function editFeatureField(featureId, name, value) {
      return store.dispatch({
      type: constants.EDIT_PRODUCT_FEATURE_FIELD,
      featureId,
      name,
      value
    });
}

export function addProductFeature(toggable=true) {
      return store.dispatch({
      toggable,
      type: constants.ADD_PRODUCT_FEATURE
    });
}

export function setupDefaultProductEdit(active=true) {
  return store.dispatch((dispatch, getState) => { 
    
    if (!getState().pricebook) {
       console.log("ERROR - pricebook not set - setupDefaultProductEdit")
       return; 
    }

    const pricebook = getState().pricebook.json;

    if (!pricebook) { return; }

    const products = active ? pricebook.products.filter(p => p.active) : pricebook.products;

    if (products.length > 0) {
      setupProductEdit(products[0].id);
    } else {
      dispatch({
        type: constants.CLEAR_PRODUCT_EDIT
      });
    }

  });
}

export function setupProductEdit(productId) { 
   loadProductEdit(productId);
}

export function loadProductEdit(productId) {

  console.log("loadProductEdit: " + productId);

  return store.dispatch((dispatch, getState) => {

    dispatch({
      type: constants.SET_UP_EDIT_PRODUCT_START
    });

    let product = null;
    let state = getState();

    let newProduct = productId === 'new';

    if (newProduct) {
        product = createProduct(getState().pricebook.json.id);
    } else {
      product = state.pricebook.json.products.find(p => p.id === productId);
    }

    if (product) {
      dispatch({
        type: constants.SET_UP_EDIT_PRODUCT,
        product : product,
        new: newProduct
      });

    } else {
      // load from api
      API.get("products", "/products/"+productId).then(
          response => dispatch({
            type: constants.SET_UP_EDIT_PRODUCT,
            product: response["data"],
            receivedAt: Date.now()
          }),
          error => dispatch({
            type: constants.SET_UP_EDIT_PRODUCT_ERROR,
            error,
            receivedAt: Date.now()
          })
        );  
    }
  });
}

export function saveProductEdit(onSuccess) {

  return store.dispatch((dispatch, getState) => {


    let state = getState();
    let pricebookId = getState().pricebook.json.id; 
    let product = state.productEdit.data;
     
    let productData = prepObjectForApiCall(product);  
    let url = "/products/update/" + pricebookId + "/" +productData.id;
    let myInit = {
        body: productData
    }
    console.log("calling api with ", url);

    dispatch({
      type: constants.REQUEST_PRODUCT_SAVE,
      url, 
      product: productData
    });


    API.put("products", url, myInit).then(
      response => {
        dispatch({
          productId: productData.id,
          product: productData,
          type: constants.RECEIVE_PRODUCT_SAVE
        });

        onSuccess();
      },
      error => {
        HandleApiError(error)
        dispatch({
         type: constants.RECEIVE_PRODUCT_SAVE_ERROR,
         error,
         receivedAt: Date.now()
       })
      }
    );
  });
}
