import { store } from '../store';
import * as constants from '../constants';
import { API } from "aws-amplify";
import { HandleApiError, prepObjectForApiCall } from "../libs/apiHelper";
import * as pricingHelper from '../libs/pricingHelper'
import { createPricingPage, createEmptySection } from '../libs/factory';
import { filterInactviceRowConfig, filterNonDefaultRows } from '../libs/pricingHelper' 
import { createFactory } from 'react';

export function createPricingEdit(templateId) {
 
  return store.dispatch((dispatch, getState) => {

    const state = getState();

    if (!templateId) {
      templateId = state.pricingPages.specs[0].id;
    }
 
    const spec = state.pricingPages.specs.find(s => s.id === templateId);
    const defaultName = `${state.pricebook.json.name} ${spec.id}`;
    const editPricing = createPricingPage(defaultName, templateId, spec);
    const rowConfig = pricingHelper.getDefaultPricingRowOrder(state.pricebook.json.products, state.pricebook.json.plans);
    
    editPricing.urlSlug = generateSlug(editPricing);
    editPricing.rowConfig = rowConfig;
 
    dispatch({
      type: constants.SET_UP_EDIT_PRICING,
      pricing : editPricing 
    });
 
  });
}

function generateSlug(pricing) {

  let name = pricing.urlSlug;
  if (!name) { name = pricing.name; }
  if (!name) { name = pricing.templateId; }
  
  const noSpacefileName = name.replace(/\s+/g, '-');
  const encodedName = encodeURI(noSpacefileName);

  return encodedName;
} 

function isValidHex(hex) {
  if (hex) { return true; }

  return false;
}


export function setupDefaultPricingEdit(active=true) {
  
  return store.dispatch((dispatch, getState) => { 
 

    const pricingPages = getState().pricingPages;

    if (!pricingPages) { return; }

    if (getState().pricingEdit && getState().pricingEdit.data && getState().pricingEdit.data.id) {  
      return; 
    }  

    const rPricingPages = active ? pricingPages.data.filter(p => p.active) : pricingPages.data;

    if (rPricingPages.length > 0) {
      loadPricingEdit(rPricingPages[0].id);
    } else {
      dispatch({
        type: constants.CLEAR_ASSET_EDIT
      });
  
    }

  });

}

export function loadPricingEdit(pricingId) {
 
  return store.dispatch((dispatch, getState) => {
 
    if (!pricingId) {
      console.log("loadPricingEdit requries a valid pricingId");
      return;
    }

    // make a copy of pricing
    // strip out anything that does not belong to edit
    //const currentPricing = getState().pricebook.pricing;

    let currentPricing = getState().pricingPages.data.find(pp => pp.id === pricingId); 
    const products = getState().pricebook.json.products;

    console.log("loadPricingEdit ", pricingId);
    console.log(currentPricing);

    if (!currentPricing) {
      // console.log("Invalid pricingId - not found in pricing pages", pricingId);
      // return;

      createPricingEdit(null);
      return;
    }


    
    const editPricing = JSON.parse(JSON.stringify(currentPricing));
    editPricing.rowConfig = filterInactviceRowConfig(editPricing.rowConfig, products);
    editPricing.rowConfig  = filterNonDefaultRows(products, editPricing.plans, editPricing.rowConfig);
    
    const spec = getState().pricingPages.specs.find(s => s.id === currentPricing.templateId);

 
    if (spec) { 
      editPricing.backgroundColor = isValidHex(editPricing.backgroundColor) ? editPricing.backgroundColor : spec.backgroundColor;
      editPricing.textColor = isValidHex(editPricing.textColor) ? editPricing.textColor : spec.textColor;
      editPricing.tintColor = isValidHex(editPricing.tintColor) ? editPricing.tintColor : spec.tintColor;
      editPricing.ctaColor = isValidHex(editPricing.ctaColor) ? editPricing.ctaColor : spec.ctaColor;
      editPricing.fontFmaily = editPricing.fontFamily ? editPricing.fontFamily  : spec.fontFamily; 
      editPricing.ctaCaption = editPricing.ctaCaption ? editPricing.ctaCaption  : spec.ctaCaption;
      editPricing.includeSymbolColor = isValidHex(editPricing.includeSymbolColor) ? editPricing.includeSymbolColor : spec.includeSymbolColor;
      editPricing.excludeSymbolColor = isValidHex(editPricing.excludeSymbolColor) ? editPricing.excludeSymbolColor : spec.excludeSymbolColor;
    }
 
    editPricing.urlSlug = generateSlug(currentPricing);

    // delete editPricing.cssUrl;
    // delete editPricing.htmlUrl;
    // delete editPricing.scriptUrl;
 
    dispatch({
      type: constants.SET_UP_EDIT_PRICING,
      pricing : editPricing 
    });
 
  });
}


export function editPricingField(name, value) {

    return store.dispatch({
      type: constants.EDIT_PRICING_FIELD,
      name,
      value
    });
}



export function pricingHidePlan(planId) {

  return store.dispatch({
    type: constants.PRICING_HIDE_PLAN,
    planId
  });
}

export function pricingRemoveHidePlan(planId) {

  return store.dispatch({
    type: constants.PRICING_REMOVE_HIDE_PLAN,
    planId
  });
}


export function resetPricing() {

  store.dispatch( (dispatch, getState) => {

    const state = getState();
    let rowConfig = pricingHelper.getDefaultPricingRowOrder(state.pricebook.json.products, state.pricebook.json.plans);
    rowConfig = pricingHelper.filterNonDefaultRows(state.pricebook.json.products, state.pricebook.json.plans, rowConfig);
    
    return store.dispatch({
      type: constants.RESET_PRICING,
      rowConfig
    });

  });
}

export function reorderRows(from, to) {

  // pricingHelper.moveRow(pricingRows2, pricingRows[fromIndex], pricingRows[toIndex]);

  store.dispatch( (dispatch, getState) => {

    const state = getState();

    // const rows = state.priceook.editPricing.rowConfig;
    const rows = [...state.pricingEdit.rows];
    const rowConfig = pricingHelper.movePricingRows(rows, from, to);

    console.log(rowConfig)
    
    return store.dispatch({
      type: constants.MOVE_PRICING_ROW,
      rowConfig
    });

  });
}

{/* <Form.Control placeholder="Custom section..." as='input' size="sm" value={pricingRow.name}
onChange={(event) => {  
  const updatedPricingRows =  
    pricingRows.map( pRow => pricingHelper.compareRows(pRow, pricingRow) ? pricingHelper.renameRow(pricingRow, event.target.value) : pRow );
  setPricingRows(updatedPricingRows) 

}}/> */}


export function renameRow(index, name) {

  // pricingHelper.moveRow(pricingRows2, pricingRows[fromIndex], pricingRows[toIndex]);

  store.dispatch( (dispatch, getState) => {

    const state = getState();

    // const rows = state.priceook.editPricing.rowConfig;
    const rowConfig = [...state.pricingEdit.rows];
    rowConfig[index].name = name;


    // const updatedPricingRows =  
    //   rowConfig.map( pRow => pricingHelper.compareRows(pRow, pricingRow) ? pricingHelper.renameRow(pricingRow, event.target.value) : pRow );
    
    // rowConfig.splice(index,1);
    
    console.log(rowConfig)
    
    return store.dispatch({
      type: constants.RENAME_PRICING_ROW,
      rowConfig
    });

  });
}

export function hideRow(index) {

  // pricingHelper.moveRow(pricingRows2, pricingRows[fromIndex], pricingRows[toIndex]);

  store.dispatch( (dispatch, getState) => {

    const state = getState();

    // const rows = state.priceook.editPricing.rowConfig;
    const rowConfig = [...state.pricingEdit.rows];
    
    rowConfig.splice(index,1);
    
    console.log(rowConfig)
    
    return store.dispatch({
      type: constants.HIDE_PRICING_ROW,
      rowConfig
    });

  });
}



export function addRow(row) {

  // pricingHelper.moveRow(pricingRows2, pricingRows[fromIndex], pricingRows[toIndex]);

  store.dispatch( (dispatch, getState) => {

    const state = getState();

    // const rows = state.priceook.editPricing.rowConfig;
    const rowConfig = [...state.pricingEdit.rows];
    
    if (row !== "Section") {
      rowConfig.push(row);
    } else { 
      rowConfig.push(createEmptySection());
    }
    
    console.log(rowConfig);
    
    
    return store.dispatch({
      type: constants.ADD_PRICING_ROW,
      rowConfig
    });

  });
}





export function savePricingEdit(callback) {

  return store.dispatch((dispatch, getState) => {
    const pricebook = getState().pricebook;
    const pricingEdit = getState().pricingEdit.data;
    pricingEdit.rowConfig = getState().pricingEdit.rows;
    const pricebookId = pricebook.json.id;
    const pricing = prepObjectForApiCall(pricingEdit);
    const pricingId = pricing.id;
    const url = `/pricing/update/${pricebookId}/${pricingId}`;

    if (!pricing.urlSlug) {
      pricing.urlSlug = generateSlug(pricing);
    } else {
      pricing.urlSlug = generateSlug(pricing);
    }
 
    const myInit = {
        body: pricing
    } 

    console.log(myInit);
    
    API.put("pricing", url, myInit).then(
      response => {
          // dispatch({ planId: planData.id, plan: planData, type: constants.RECEIVE_PLAN_SAVE })
          
          console.log("Pricing changes saved successfully. Invoking page refresh")
          
          let url =  "/jobs/refreshPricingSync/" + pricebookId + "/" + pricingId;
          // const refreshUrl = `/jobs/refreshPricingSync/${pricebookId}`;
          // console.log("Calling API", refreshUrl);
          
          API.put("spreadsheets", url).then(

          // Refresh pricing page
          // API.put("jobs", refreshUrl, myInit).then(
            response => {
              dispatch({ pricing: response["pricing"], type: constants.RECEIVE_PRICING_SAVE })
              callback();
            },
            error => {
              HandleApiError(error);
              dispatch({ data: error, type: constants.RECEIVE_PRICING_SAVE_ERROR });
            });
        },
        error => {
          HandleApiError(error);
          dispatch({ data: error, type: constants.RECEIVE_PRICING_SAVE_ERROR });
        }
      );
    });
}
