import React  from "react";
import { connect } from 'react-redux' 
import * as pricebookSelector from '../libs/pricebookSelector' 
// import ProductSelectorDD from '../components/product/ProductSelectorDD' 
import EditChargeFeatures from '../controls/editChargeFeaturesControl';
import EditChargeMultiOptions from './editChargeMultiOptionsControl' 
import Dropdown from "./dropdownControl" 


const mapStateToProductSelectorProps = (state, ownProps) => {
  return  {
    products: ownProps.products.filter(p => !p.checked)
  }
}

const mapDispatchToProductSelectorProps = (dispatch, ownProps) => {
  return {
    editPlanProductFieldHanlder: (productId, name, value) => ownProps.editPlanProductFieldHandler(productId, name, value),
    selectorHandler: (productId) => ownProps.selectorHandler(productId)
  }
}

// const SelectProductSelectorDD = connect(
//   mapStateToProductSelectorProps,
//   mapDispatchToProductSelectorProps )
//   (ProductSelectorDD)

 

function ProductSelectorControl ({ label, info='',  selectedProducts, availableProducts, products, plan,
showConfiguratorHandle, 
removeProductHandler,
editPlanProductFieldHandler}) { 
  if (!selectedProducts) return <></>
  
  return <>
        <div className="flex w-full flex-col justify-start">
       
             { selectedProducts.map((selectedProduct) => (
               <>
                <div className=" border-b border-gray-200 ">
                  <div className="flex justify-between	">
                    <div className="flex justify-start text-center min-w-600 ">
                      {pricebookSelector.getProductNameFromId(products, selectedProduct.id)}
                    </div>
                    <a
                      onClick={() => removeProductHandler( selectedProduct.id )}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >Remove</a>
                  </div> 
                  <div key={selectedProduct.id}>        
                      <div className="my-4">
                      <EditChargeFeatures  plan={plan} charge={pricebookSelector.getBaseCharge(plan)} product={products.find(prod => prod.id === selectedProduct.id)}/>  
                      </div>         
                          
                      <div className="my-4">
                      <EditChargeMultiOptions    plan={plan}  charge={pricebookSelector.getBaseCharge(plan)} product={products.find(prod => prod.id === selectedProduct.id)}/> 
                      </div> 
                  </div>
                </div>
              </>
               
             ))}

              <div className="flex justify-start  my-4 py-5">
              { availableProducts.length > 0 ?              
                  <Dropdown 
                    items={availableProducts.map( (product, productIndex) => { return {name: product.name, key:product.id}})} 
                    currentItem="Add a Product"
                    primary={true}
                    onSelect={(productId)=> editPlanProductFieldHandler(productId, 'checked', true)}/>  
                  
                  :
                    <div title="No more products to add" className="text-sm font-medium text-gray-500">Add a product</div>

              } 
                
                </div>  
           

         </div>
       </>
}

export default (ProductSelectorControl);
