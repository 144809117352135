import { FIELD_TYPE } from '../constants';


  let interval = 1000;
  let totalTime = 0;
  const maxTime = 1000*60*60;

  export function getScriptCode(scriptUrl) {

    if (!scriptUrl) { return ' '}

    return `<script>
        (function() {    
            var s = document.createElement('script');    
            s.src = '${scriptUrl}';    
            s.async = true;    s.type = 'text/javascript';    
            var x = document.getElementsByTagName('script')[0];    
            x.parentNode.insertBefore(s, x);  
        })();
    </script>`
  }

  export function fibonacciTimer(fn, maxSeconds, timer) { 
  
    fn();

    if (timer) { clearInterval(timer); }
    if (interval < (1000*maxSeconds)) { interval = interval * 2 }

    totalTime += interval;

    if (totalTime > maxTime) { return; }

    timer = setInterval(() => {
      fibonacciTimer(fn, maxSeconds, timer)
    },interval) 
  }

  export function filterActive(rows, activeOnly=true) {

    let filteredRows = [];

    if (rows) {

      rows.forEach(row => { 
        let attributesRow = row.find(c => c.type === FIELD_TYPE.ATTRIBUTES);

        if ((attributesRow && attributesRow.active)  || (!attributesRow) || (!activeOnly)) {
          filteredRows.push(row);
        }
      }) 
    }

    return filteredRows;
  }

  export function formatCurrency(number) {
    //return ("$" + parseFloat(number).toFixed(2)).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return ("$" + parseFloat(number).toFixed(2));

  }

  export function formatDate(dateUTC) {
    if (!dateUTC) { return '-'; }
    const event = new Date(dateUTC);
    return event.toLocaleString('en-US');
  }

  export function filterKeyword(rows= [], keyword='') {

    let filteredRows = []; 

    if (!rows) { rows = []; }

    if (keyword) {
        keyword = keyword.toLowerCase();

        const keywordsArray = keyword.split(" ");

        rows.forEach((row) => {
          for (let i=0; i<row.length; i++) {
            let cellValue = row[i].value.toString().toLowerCase();
            let found = false;

            // make sure each word is matched
            keywordsArray.forEach(keywordA => {
              if (!found && keywordA && cellValue.includes(keywordA)) {
                filteredRows.push(row);
                found = true;
              }
            })   
            
            if (found) { break;}
          }
        });
    } else {
      filteredRows = [...rows];
    }

    return filteredRows;
  }

  export function sortRows(rows, index=0, ascending=true) {
      
    let sortedRows = [];
    
    if (!rows) return sortedRows;

    index = (index<0) ? 0 : index;
    try {
      sortedRows =  [...rows];
      sortedRows = sortedRows.sort(
        (row1, row2) => {
          // console.log(row1[index]);
          try {
            //   if (!row1[index] || !row2[index]) {return 0;}
            if (row1[index].type === FIELD_TYPE.TEXT ||row1[index].type === FIELD_TYPE.LINK) {
              return ascending ? row1[index].value.localeCompare(row2[index].value) : row2[index].value.localeCompare(row1[index].value);
            } else if (row1[index].type === FIELD_TYPE.CURRENCY) {
              const v1 = parseFloat(row1[index].value.replace('$', '') );
              const v2 = parseFloat(row2[index].value.replace('$', '') );
               
              return ascending ? v1-v2: v2-v1;
            } else if (row1[index].type === FIELD_TYPE.NUMBER) {
              return ascending ? row1[index].value - row2[index].value : row2[index].value - row1[index].value;
            } else {
              return 0;
            }

          }catch (e) {
            console.log('index', index);
            console.log(row1[index]);
            console.log(row2[index]);
            console.log('caught an error ', e);
            return false;
          }
          // if (typeof(row1[index]) == 'string' && typeof(row2[index]) == 'string') {
          // } else {
          //   return false;
          // }
        }
      );
    } catch (e) {
        console.log("Error sorting rows", e);
    }

    return sortedRows;
  }


  export function convertUTCDateToLocalDate(pDate) {

      let date = new Date(pDate);
      // return date.toLocaleDateString('en-US');

      let newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
      let offset = date.getTimezoneOffset() / 60;
      let hours = date.getHours();

      newDate.setHours(hours - offset);
      let minutes = (newDate.getMinutes()<10?'0':'') + newDate.getMinutes();
      return newDate.toLocaleDateString('en-US') + " at " + newDate.getHours() + ":" + minutes;
  }

  export function getUrl(spreadsheetId) {

    if (spreadsheetId) {
        return 'https://docs.google.com/spreadsheets/d/' + spreadsheetId;
    }

    return "";

  }

  export function getSheetUrl(spreadsheetId, sheetId) {

    if (!spreadsheetId || !sheetId)  return "";

    let url = getUrl(spreadsheetId);
    url += "/edit#gid=" + sheetId;

    return url;
  }
