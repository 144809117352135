import { API } from "aws-amplify";
import { store } from '../store';
import * as constants from '../constants';
import { HandleApiError } from "../libs/apiHelper"; 
 
const samplesTenantId = `SAMPLE-MODELS-1111-1111-111111111111`;

export function initiateNewPricebookFlow() {
  store.dispatch((dispatch) => {
    dispatch({type: constants.INITIATE_NEW_PRICEBOOK_FLOW});
  })
}

export function loadTemplates(callback) {
  store.dispatch((dispatch, getState) => {
        dispatch(requestSamplePricebooks())

        console.log('fetching pricebook samples')

        const url = `/pricebooks/public/${samplesTenantId}`;
        console.log('calling API with URL', url);
 
        return API.get("pricebooks", url).then(
          response => {dispatch(receiveSamplePricebooks(response["data"] ))
          if (callback) { callback()} },
          error  => {
            HandleApiError(error);  
            dispatch(receiveSamplePricebookError(error));
          }
        ) 
      })
  }


export function addPlan(callback) {
  store.dispatch((dispatch, getState) => {

        const state = getState();
        const defaultPlanNames = [];
        const planNames = (state.pricebooks.newPricebookProps && state.pricebooks.newPricebookProps.planNames) ? 
          state.pricebooks.newPricebookProps.planNames : defaultPlanNames;

        planNames.push("");
        console.log(planNames)

        dispatch({ type: constants.ADD_NEW_PRICEBOOK_PLAN, planNames }) 
      })
  }

export function removePlan(planIndex) {
      store.dispatch((dispatch, getState) => {
        const state = getState();
        const defaultPlanNames = [];
        let planNames = (state.pricebooks.newPricebookProps && state.pricebooks.newPricebookProps.planNames) ? 
          state.pricebooks.newPricebookProps.planNames : defaultPlanNames;

        if (planNames.length > planIndex) {
          planNames.splice(planIndex,1);
        }
        dispatch({ type: constants.REMOVE_NEW_PRICEBOOK_PLAN, planNames }) 
      })
  }


  export function updatePlan(planIndex, value) {
    store.dispatch((dispatch, getState) => {
      const state = getState();
      const defaultPlanNames = [];
      let planNames = (state.pricebooks.newPricebookProps && state.pricebooks.newPricebookProps.planNames) ? 
        state.pricebooks.newPricebookProps.planNames : defaultPlanNames;

      if (planNames.length > planIndex) {
        planNames[planIndex] = value;
      }
      dispatch({ type: constants.REMOVE_NEW_PRICEBOOK_PLAN, planNames }) 
    })
}

  
const moveInArray = function (arr, from, to) {

	// Make sure a valid array is provided
	if (Object.prototype.toString.call(arr) !== '[object Array]') {
		throw new Error('Please provide a valid array');
	}

	// Delete the item from it's current position
	var item = arr.splice(from, 1);

	// Move the item to its new position
	arr.splice(to, 0, item[0]);

};

export function reorderPlan(from, to) {

  store.dispatch((dispatch, getState) => {
    const state = getState();
    const defaultPlanNames = [];
    let planNames = (state.pricebooks.newPricebookProps && state.pricebooks.newPricebookProps.planNames) ? 
      state.pricebooks.newPricebookProps.planNames : defaultPlanNames;
 
    moveInArray(planNames, from, to);

    dispatch({ type: constants.REORDER_NEW_PRICEBOOK_PLAN, planNames }) 
  }) 
} 


export function createNewPricebook(callback ) {

  store.dispatch((dispatch, getState) => {
    dispatch({type: constants.REQUEST_PRICEBOOK_CREATE})

    const state = getState();
    const tenantId = samplesTenantId;
    const pricebookTemplateId = state.pricebooks.newTemplateId;
    const pricebookProps =  state.pricebooks.newPricebookProps; 
    const pricebookName =  state.pricebooks.newPricebookName;
    
    if (!pricebookTemplateId) { throw new Error("Invalid pricebook ID")}

    const url = `/pricebooks/public/clone/${tenantId}/${pricebookTemplateId}`;
    let myInit = {
        body: { 
          params : pricebookProps ,
          name: pricebookName
        }
    }

    console.log('Creating a new pricebook from template ID', pricebookTemplateId);                      
    console.log('calling API with URL', url);
    console.log(myInit)        
 
    return API.put("pricebooks", url, myInit).then(
      response => {
        dispatch( 
           {
            type: constants.RECEIVE_PRICEBOOK,
            pricebookId: response["data"].id,
            pricebook: response["data"],  
            receivedAt: Date.now()
          }
        )
       

        const pricebookId = response["data"].id;
        let assets = response["assets"];

        if (assets && assets.length > 0) {
         
          dispatch({  type: constants.REQUEST_PRICING_CREATE });

          const pricingId = assets[0].id;

           let url =  "/jobs/refreshPricingSync/" + pricebookId + "/" + pricingId;
           console.log("Calling API", url);
          
          API.put("jobs", url).then(
                response => {
                dispatch({ pricing: response["pricing"], type: constants.RECEIVE_PRICING_CREATE })
                if (callback) { callback()} 
              },
              error => { 
                HandleApiError(error , constants.RECEIVE_PRICING_CREATE_ERROR); 
              }
          ); 
        } else {
          console.log('Oh no, this sample pricebook does not contain any pricing pages. Looks like a problem with sample data!');
          if (callback) { callback()} 
        }       
      },
      error  => { 
        HandleApiError(error , constants.RECEIVE_PRICEBOOK_CREATE_ERROR); 
      }
    ) 
  })
} 


export function selectSampleTemplate(id) { 

  store.dispatch((dispatch, getState) => {
    dispatch({
      type: constants.SELECT_SAMPLE_PRICEBOOK_TEMPLATE,
      id
    }) 
  });
}




export function setNewPricebookName(name) { 

  store.dispatch((dispatch, getState) => {
    dispatch({
      type: constants.SET_NEW_PRICEBOOK_NAME,
      name
    }) 
  });
}


export function setNewPricebookProp(key, value) { 

  store.dispatch((dispatch, getState) => {
    dispatch({
      type: constants.SET_NEW_PRICEBOOK_PROP,
      key,
      value
    }) 
  });
}

export function requestSamplePricebooks() { 
  return {
    type: constants.REQUEST_SAMPLE_PRICEBOOKS
  }
}

export function receiveSamplePricebooks(json) {
  return {
    type: constants.RECEIVE_SAMPLE_PRICEBOOKS,
    pricebooks: json,
    receivedAt: Date.now()
  }
}

export function receiveSamplePricebookError(error) {

  
  return {
    type: constants.RECEIVE_SAMPLE_PRICEBOOKS_ERROR,
    error
  }
}