import React from 'react'
import { fetchPricebook } from "../actions/pricebookActions"

import { connect } from 'react-redux'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, CogIcon } from '@heroicons/react/solid'
import { }from "../actions/pricebookActions"
import { useEffect } from 'react';
 
const PreviewAssetControl = ({ scriptUrl} ) =>  {

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = scriptUrl; 
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    return <div id="psPricing"></div>
}
 
export default PreviewAssetControl;