 
import React, { Component } from "react";
import { connect } from 'react-redux' 
import EditCell from '../controls/editCellControl'
import { getProductPlanHeaders, getProductPlanRows, getProductName } from "../libs/selectors"
import { setupProductPlansEdit, updateValue, saveProductPlansEdit } from "../actions/productPlansEditActions"

const mapEditProductPlansStateToProps = (state, ownProps) => {
  if (!state.productPlansEdit || !state.productPlansEdit.plans || !state.productPlansEdit.product ) { return { productName : 'loading', headers : [], rows : [] }; }

  return {
      productName : getProductName(ownProps.match.params.productId, state.pricebook.json.products),
      headers : getProductPlanHeaders(state.productPlansEdit.product),
      rows : getProductPlanRows(state.productPlansEdit.product, state.productPlansEdit.plans),
  }
}


const mapProductDispatchToProps = (state, ownProps) => {
  return {
    // dispatching plain actions
  
    saveEdit: (callback) => saveProductPlansEdit(callback) ,
    cancelEdit: () => {ownProps.history.push('/products') }
      
  }
}

const people = [
  { name: 'Jane Cooper', title: 'Regional Paradigm Technician', role: 'Admin', email: 'jane.cooper@example.com' },
  // More people...
]

export function Example(headers=[], rows=[]) {
  return (
    <div className="flex flex-col overflow-auto z-10 ">
      <div className="-my-2 overflow-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8  ">
          <div className="shadow overflow-y-visible pb-t  sm:rounded-lg ">
            <table className="min-w-full divide-y">
              <thead className="bg-gray-50 text-left">
                <tr>
                  {headers.map(header => 
                    <>
                       <th
                          scope="col"
                          className="p-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    </>
                    )}
                  
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 ">
                {rows.map((row, index) => (
                  <tr key={index}>
                    {row.map(cell => (<td className="p-5">  <EditCell object={cell.object} disabled={cell.disabled} value={cell.value} options={cell.options} type={cell.type} id={cell.id} planId={cell.planId} valueChangeHandler={updateValue}/> </td>))}                    
                  </tr>
                ))}
                <tr>
                  <td colspan={rows.length > 0 ? rows[0].length : 0} className="p-20 bg-gray-100">

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}


export class ProductMapPage extends Component { 

  async componentDidMount() {
    setupProductPlansEdit(this.props.match.params.productId);
  }

  render() {
    return <> 
        <div className="  overflow-y-visible m-auto ">

          
        <div >
          <div className="flex justify-between p-10	">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{this.props.productName} Plans{this.props.name}</h3>
            <div>
                <a
                  onClick={() => this.props.cancelEdit()}
                  className="font-medium px-6 text-gray-600 hover:text-gray-500"
                >Cancel</a>          
                <button
                  type="button"
                  onClick={() => this.props.saveEdit(() => this.props.cancelEdit())}
                  className="inline-flex items-center justify-center px-4 py-2   text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
              Save
            </button>
            </div>
          </div>
         

          </div>
          
          </div>

          <div className="w-full  px-10 pb-10 ">
            {Example( this.props.headers.map(cell => cell.value), this.props.rows) }
            {/* {this.renderEditElement()} */}
          </div>
      {/* <br/>
      <h1><div>{this.props.productName}</div></h1>
      <br/>
      {this.renderEditElement()}
      <br/>
      <div className='rightAlign'>
        <a href={'/products/'}>Cancel</a> &nbsp;&nbsp;
        <Button size="lg" onClick={() => saveProductPlansEdit(() => this.props.history.push('/products')) }>Update</Button>&nbsp;
      </div> */}
    </>

  }
}

export default connect(mapEditProductPlansStateToProps, mapProductDispatchToProps)(ProductMapPage)
