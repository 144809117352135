import React, { useState }   from "react";  

import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react' 
import {     
    DotsHorizontalIcon, 
  } from '@heroicons/react/solid'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


function getMenuItem( name, active, url, callback, target="_self") {

    if (url ) {
 
        return (
            <a
            href={url} 
            target={target}
            className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm'
            )}
            >
            {name} 
            </a>
        )
    } else {
        return (
            <a
            href="#"
            onClick={ callback }
            className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm'
            )}
            >
            {name} 
            </a>
        )
    }
 
}
  
function SplitButton ({     
    actions=[]}) {
 

        if (!actions || actions.length === 0) { return <></>; }
 
        const defaultButtonCaption = actions.value[0].name;
        const defaultButtonCallback = actions.value[0].callback;
        const defaultURL = actions.value[0].url;
        const target = actions.value[0].target ? actions.value[0].target :  "";
        const otherButtons = actions.value.slice(1);

        return( 
            <div className="flex align-top">
                {   target ? 
                        <a href={defaultURL} target={target} onClick={ defaultButtonCallback } className="text-blue-600 ">{defaultButtonCaption}</a> 
                        :
                        <a href={defaultURL}  onClick={ defaultButtonCallback } className="text-blue-600 ">{defaultButtonCaption}</a>
                }
            &nbsp;&nbsp; 
            { otherButtons &&
            
                    <Menu as="div" className="relative inline-block text-left">
                    <div className="mt-1">
                        <Menu.Button className="bg-blue-100  rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                        <span className="sr-only">Open options</span>
                        <DotsHorizontalIcon className="h-4 w-5 " aria-hidden="true" />
                        </Menu.Button>
                    </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                   
                    { otherButtons.map (otherButton => (
                            <Menu.Item>
                                { getMenuItem(otherButton.name, false, otherButton.url, otherButton.callback, otherButton.target)} 
                            </Menu.Item> 
                    ))} 
                        
                     
                    </div>
                    </Menu.Items>
                </Transition>
                </Menu>
            }
        </div>)

                
                
    }



export default (SplitButton);