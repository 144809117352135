import {
    GLOBAL_LOGOUT,
    REQUEST_API_ERROR,
    INFO_DISMISS_NOTIFICATION
  } from '../constants'
  
import { v4 as uuidv4 } from 'uuid';

 export class ServerError {

    constructor(message, details) {
 
        this.timestamp = (new Date()).getTime();
        this.message = message;
        this.details = details.toString();
        this.id = uuidv4();
    }
    
  }

  const initData = {
    serverErrors: []
  }
  
export default function info (
    state = initData,
    action
  ) {

    let tempServerErrors = [];
    if (!state) { state = initData; }
    
    switch (action.type) {
  
    case GLOBAL_LOGOUT:
        return null;
    case REQUEST_API_ERROR:
        tempServerErrors = [...state.serverErrors];
        tempServerErrors.push(new ServerError(action.message, action.details));
        // tempServerErrors.push(({ message: action.message, details: action.details, timestamp: action.timestamp, dismissed: false }));
        
        return Object.assign({}, state, {
          serverErrors: tempServerErrors
      });
    case INFO_DISMISS_NOTIFICATION:
      tempServerErrors = state.serverErrors.filter(a => a.id !== action.id);
      return Object.assign({}, state, {
        serverErrors: tempServerErrors
      });


    // case RECEIVE_PRICEBOOK_ERROR:
    //   return Object.assign({}, state, {
    //     lastError: action.error
    //   })
    default:
      return state
    }
  }
   