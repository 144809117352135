import React  from "react";  
import ReactDragListView from 'react-drag-listview';
import { PencilIcon } from '@heroicons/react/solid'


import { XIcon, ViewListIcon} from '@heroicons/react/solid'

function EditPlanNamesControl ({items=[], changeHandler, deleteHandler, addHandler, tooltipHandler=null, reorderHandler=null}) {
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (reorderHandler) {
        reorderHandler(fromIndex, toIndex);
      } 
    },
    nodeSelector: 'li',
    handleSelector: 'a',
    lineClassName: 'dragLine'
  };

  return <> 
            <div className="w-full text-lg">
              <ReactDragListView {...dragProps}>
                <ol>
                  {items.map((item, index) => (
                    <li key={index}>
                      <div className="flex justify-start text-center  my-4 text-gray-700 w-full">
                        {/* <a href="#"><ViewListIcon className="h-5 w-5 text-gray-600 mr-2" aria-hidden="true"/> </a>           */}
                        {/*  class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"  */}
 
                      <div className="w-full flex justify-start pr-2 " >
                        <input
                          type="text"   
                          className="w-full border-b"
                                                  
                          placeholder="Enter plan name"
                          onChange={(event) => {changeHandler(item.id, event.target.value)}}
                          value={item.title}/>
{/*                         
                        <PencilIcon className="h-5 w-5" aria-hidden="true" /> */}
                      </div>
                                     
                      {/* <input
                        type="text"
                        className="w-full" 
                        placeholder="Enter name"
                        onChange={(event) => {changeHandler(item.id, event.target.value)}}
                        value={item.title}/> */}


                        <XIcon className="h-5 w-5 text-gray-600 " aria-hidden="true" onClick={() =>  deleteHandler(item.id)} /> 

                        {/* &nbsp;
                        { tooltipHandler ? 
                            <Form.Control
                            type="text"
                            className="controlText"
                            placeholder="Enter Tooltip"
                            onChange={(event) => {tooltipHandler(item.id, event.target.value)}}
                            value={item.tooltip}/>
                          : <></>
                        } */}
                          
                      </div>
                    </li>
                  ))}
                </ol>
              </ReactDragListView>
              <div className="flex justify-end text-center  my-4">            
                <a
                  onClick={() => addHandler()}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >Add a Plan</a>
              </div> 
            </div>
        </>
}

export default (EditPlanNamesControl);
