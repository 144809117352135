/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react' 
import {addWorkspaceUser} from "../actions/userActions"

import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
 
    return {
       
     
    }
  }
   
export  function Example(pricebooks, open, closeModalHandler) {
  const [email, setEmail] = useState('')

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeModalHandler}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                {/* <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div> */}
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Add a User
                  </Dialog.Title>
                  
                </div>
              </div>
              <div className="flex flex-col items-center mt-5 sm:mt-6">
                <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(event) => {setEmail(event.target.value)}}
                    id="email"
                    className="shadow-sm focus:ring-indigo-500 w-full focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter a valid email address"
                    />

                <div >
                    <a
                    onClick={() => closeModalHandler()}
                    className="font-medium px-6 text-gray-600 hover:text-gray-500"
                    >Cancel</a>   
                    <button
                        type="button"
                        onClick={() => addWorkspaceUser(email, closeModalHandler)}
                        className="inline-flex items-center mt-6 justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                    >Add</button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}



export function AddUserModalControl ({ pricebooks,  open, closeModalHandler }) {
  

    console.log(pricebooks)
    return <> 
    {Example( pricebooks, open, closeModalHandler)}
    </>
}



export default connect(
    mapStateToProps 
  )(AddUserModalControl)
   

