
import { store } from '../store';
import * as pricebookActions from './pricebookActions';
import * as pricebookNewActions from './pricebookNewActions';
import * as pricingActions from './pricingActions';
import * as spreadsheetActions from './spreadsheetActions';
import * as constants from '../constants';
import { API } from "aws-amplify";
import { HandleApiError } from "../libs/apiHelper";
import { isInitalized } from "../libs/selectors" 

// function getEmail(user) {

//   if (!user || !user.attributes || !user.attributes.email) { return null; }

//   return user.attributes.email;
// }

function getAccessToken(user) {

  if (!user || !user.signInUserSession || !user.signInUserSession.accessToken) { return null; }

  return user.signInUserSession.accessToken.jwtToken;
}

export async function initalizeUser(user) {

  await store.dispatch((dispatch, getState) => {

    const state = getState();
    const isUserInitalized = isInitalized(state); // Boolean( state.user && state.user.data);
 
    if (isUserInitalized) { 
     
      // console.log("user already initatlized - nothing to do")
      return;
    }
    console.log("initalizing user")    

    const loadDefaultPricebookFn = (pricebooks) => {
      if (pricebooks && pricebooks.length > 0) {

        console.log("loadDefaultPricebookFn has been called!")
        pricebookActions.fetchPricebook(pricebooks[0].id) 

        pricebookNewActions.loadTemplates();
      }

      dispatch({ type: constants.USER_INITIATED })
    }

    fetchUser(dispatch, getAccessToken(user)).then( async () => {
      let p1 = verifySubscription(dispatch);
      let p2 = pricingActions.loadSpecsPromise(dispatch); ; 
      

      await p1;
      await p2; 

      pricebookActions.fetchPricebooks(loadDefaultPricebookFn);
      
    });
  });
} 

export async function logoutUser(callback) {
  store.dispatch((dispatch, getState) => {
    dispatch({
        type: constants.GLOBAL_LOGOUT,
        authenticated: false // do I need this
      })

      
  });
}

// export async function authenticateUser(user) {
  
//     store.dispatch((dispatch, getState) => {


//         // console.log('email:', getEmail(user));
//         console.log(user)
//         const state = getState();
//         const authenticated = Boolean(user);

//         dispatch({
//             type: constants.AUTHETNICATE_USER,
//             authenticated 
//           })
  
//         let currentAuthValue = state.user ? state.user.isAuthenticated : false;

//         // check if the user just got authenticated
//         if (authenticated && !currentAuthValue) {
//         // if (true) {

//           // console.log('fetching user for pricebook auth: ',  getEmail(user)); 
//           console.log('fetching user for pricebook auth: ',  getAccessToken(user));  
          
//           fetchUser(dispatch, getAccessToken(user)).then( () => {
//             pricebookActions.fetchPricebooks();
//             pricingActions.fetchPricingPages();
//             spreadsheetActions.loadSpecs();
//             pricingActions.loadSpecs();
//           });
//         } else if (currentAuthValue && !authenticated) {
//           dispatch({
//             type: constants.GLOBAL_LOGOUT,
//             authenticated 
//           })
//         }
//       });
//   }  



export async function updateSubscriptionF(callback) {

  store.dispatch((dispatch, getState) => {
    const state = getState();
    if (state.user && state.user.jwsToken) {
       updateSubscription(state.user.jwsToken, dispatch, callback);
      callback();
    }      
  });
}

export function updateSubscription(accessToken, dispatch, callback) { 

  return new Promise((resolve, reject) => {
    
    const url = `/users/subscription/${accessToken}`;
    
    API.put("users", url).then(response => {  
      console.log(response);   
      dispatch(receivedWorkspace(response["workspace"])) ;
      resolve(callback);
    }, error => {  HandleApiError(error) });

  });
}

export function verifySubscriptionF( ) {  
  store.dispatch((dispatch, getState) => { 
    verifySubscription(dispatch); 
  });       
}

export function verifySubscription( dispatch) { 

  return new Promise((resolve, reject) => {
    
    const url = `/users/subscription/verify`;
    
    API.get("users", url).then(response => {  
      // console.log(response);   
      dispatch(receivedWorkspace(response["workspace"])) ;
      resolve();
    }, error => {  HandleApiError(error); reject(); });

  });
}

export function fetchUser(dispatch, accessToken) { 

  return new Promise((resolve, reject) => {
    
      dispatch(requestUser());
      API.get("users", `/users/${accessToken}`).then(response => {  
        dispatch(receiveUser(response["data"], response["workspaces"], accessToken)) ;
        resolve(response["data"]);
      }, error => {  HandleApiError(error) });
  });
}

export async function addWorkspaceUser(email, callback) {
  
  await store.dispatch((dispatch, getState) => {

    const   tenantId = getState().user.tenantId; 
    
    const url = `/users/workspace/addUser`;
    const myInit = { body: { tenantId, email } } 
    console.log('calling url', url);

    API.put("users", url, myInit).then(response => {  
      console.log(response["message"]);
      dispatch(receivedWorkspace(response["workspace"])) ;
      callback();
      // resolve(response["data"]);
    }, error => {  HandleApiError(error) });
});
}


export async function removeWorkspaceUser(email, callback) {
  
  await store.dispatch((dispatch, getState) => {

    const   tenantId = getState().user.tenantId; 
    
    const url = `/users/workspace/removeUser`;
    const myInit = { body: { tenantId, email } } 
    console.log('calling url', url);
    console.log(myInit)

    API.put("users", url, myInit).then(response => {  
      console.log(response["message"]);
      dispatch(receivedWorkspace(response["workspace"])) ;
      if (callback) callback();
      // resolve(response["data"]);
    }, error => {  HandleApiError(error) });
});


}
 
export async function fetchWorkspace(workspaceId) { 

  await store.dispatch((dispatch, getState) => {

      if (!workspaceId) {
        workspaceId = getState().user.tenantId;
      }

      const url = `/users/workspace/${workspaceId}`;

      console.log("Getting url", url)
    
      // dispatch(requestUser());
      API.get("users", url).then(response => {  
        dispatch(receivedWorkspace(response["data"])) ;
        // resolve(response["data"]);
      }, error => {  HandleApiError(error) });
  });
}

export function receiveUser(json, workspaces, token) {
  return {
    type: constants.RECEIVE_USER,
    user: json,
    jwsToken: token,
    workspaces,
    receivedAt: Date.now()
  }
}


export function receivedWorkspace(json) {
  return {
    type: constants.RECEIVE_WORKSPACE,
    workspace: json, 
    receivedAt: Date.now()
  }
} 

export function receivedWorkspaceUser(json) {
  return {
    type: constants.RECEIVE_WORKSPACE_USER,
    workspace: json, 
    receivedAt: Date.now()
  }
}

export function requestUser() {
  return {
    type: constants.REQUEST_USER
  }
}

export function setupUserEdit() {
  
   store.dispatch((dispatch, getState) => {

    dispatch({
      type: constants.SET_UP_EDIT_USER
    });
  });
}

export function editProfileField(name, value) {

  return store.dispatch({
    type: constants.EDIT_PROFILE_FIELD,
    name,
    value
  });
}

export function saveUser(onSuccess) {

  return store.dispatch((dispatch, getState) => {

    dispatch({
      type: constants.REQUEST_USER_SAVE
    });

    let state = getState(); 
    let userData = state.user.editData;
      
    let url = "/users";
    let myInit = {
        body: userData
    }
    console.log("calling api with ", url);
    console.log(userData); 

    API.put("users", url, myInit).then(
      response => {
        dispatch({
          userId: userData.id,
          type: constants.RECEIVE_USER_SAVE
        });

        onSuccess();
      },
      error => {
        HandleApiError(error)
        dispatch({
         type: constants.RECEIVE_USER_SAVE_ERROR,
         error,
         receivedAt: Date.now()
       })
      }
    );
  });
}
