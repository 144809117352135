
import React, { useState }   from "react";  

export default function WizardControl({steps, finishCaption="Finish", onFinishHandler}) {

    const totalSteps = steps ? steps.length : 0;
    const [currentStep, setCurrent] = useState(0); 
     
    const hidePreviousStep = currentStep === 0 ? 'hidden' : '';
    const nextButtonCaption = currentStep >= (totalSteps-1) ? finishCaption : 'Next';
    const nextEnabled = steps[currentStep].valid;
       
    const stepHtml = steps && steps.length > 0 ? steps[currentStep].html : <></>;
    const stepCaption = steps && steps.length > 0 ? steps[currentStep].caption : <></>;
    const stepInstructions = steps && steps.length > 0 ? steps[currentStep].instructions : <></>;
    const errorMessage = steps && steps.length > 0 ? steps[currentStep].errorMessage : 'Complete the step first!';
    
    const stepProgress = `Step ${currentStep + 1} out of ${steps ? steps.length : "?"}`;

    return ( 
        <>
        {/* <div  className="text-sm font-medium text-gray-500 mb-10 border-b border-gray-200">
            {stepCaption}
        </div>   */}
         <div  className="text-sm font-medium text-gray-500 mb-10 border-b border-gray-200">
            {stepProgress}
        </div>  
        {stepInstructions &&
            <div className="pt-10 pb-10 flex flex-col  justify-start  text-center w-full m-auto text-xl max-w-3xl">
                {stepInstructions}
            </div>
        }
        <div className="flex  justify-center text-center mx-auto w-full mb-10 ">                
            {stepHtml}
        </div>
        <div className="flex justify-center">  
        <div>
                <a
                     onClick={() => setCurrent(currentStep-1)}
                    className={`font-medium px-6 text-gray-600 hover:text-gray-500 ${hidePreviousStep}`}
                >Previous</a>      
                {nextEnabled ? 
                    <button 
                        type="button"
                        onClick={() => { currentStep >= (totalSteps-1) ? onFinishHandler() : setCurrent(currentStep+1) }}
                        className=" inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 disabled:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                    >{nextButtonCaption}  </button>:
                    <button 
                        type="button"
                        onClick={() => { alert(errorMessage)}}
                        className=" inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 disabled:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
                    > {nextButtonCaption} </button>
                }    
               
                
        </div>
        </div>  
        </>
    )
}
  