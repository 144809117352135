import React  from "react";  
import ReactDragListView from 'react-drag-listview';
import { XIcon, ViewListIcon} from '@heroicons/react/solid'
import Dropdown from "./dropdownControl"
import { PencilIcon } from '@heroicons/react/solid'

function EditPricingRowsControl ({items=[], availableItems=[], changeHandler, hideHandler, renameHandler, addHandler, tooltipHandler=null, reorderHandler=null}) {
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (reorderHandler) {
        reorderHandler(fromIndex, toIndex);
      } 
    },
    nodeSelector: 'li',
    handleSelector: 'a',
    lineClassName: 'border-t  border-gray-200'
  };

  return <> 
            <div className="w-full ">
              <ReactDragListView {...dragProps}>
                <ol>
                  {items.map((item, index) => (
                    <li key={index}>
                      <div className="flex justify-start text-center  my-4 text-gray-700 hover:bg-gray-100">
                        {/* &nbsp;
                        { tooltipHandler ? <><img height='16px' src="/tooltip.png" onClick={() => deleteHandler(item.id)} />&nbsp;</> : <></> }  */}
                        <a href="#"><ViewListIcon className="h-5 w-5 text-gray-600 mr-2" aria-hidden="true"/> </a>                     
                     
                      {item.type === 'Section' ?  
                        <>
                        <div className="w-full flex justify-start " >
                        <input
                          type="text"
                          
                          placeholder="Enter section name"
                          onChange={(event) => {renameHandler(index, event.target.value)}}
                          value={item.name}/>
                        
                        <PencilIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                          
                        </>
                          :
                        <><div className="w-full flex justify-start text-left">{item.name}</div></>
                      }
                      <XIcon className="h-5 w-5 text-gray-600" aria-hidden="true" onClick={() =>  hideHandler(index)} /> 

                    
                      </div>
                    </li>
                  ))}
                </ol>
              </ReactDragListView>
             
              <div className="flex justify-start text-center  my-4">
              <Dropdown 
                    items={availableItems.map( (item, itemIndex) => { return {name: item.name, key:item.key}})} 
                    currentItem="Add a row"
                    primary={true}
                    onSelect={(row)=> addHandler(row)}
                    />  

          

              </div> 
            </div> 
        </>
}

export default (EditPricingRowsControl);
