
import React, { useState }   from "react";  

const layouts = [
    {
      name: 'Cards',
      keyword: 'cards',
    //   imgUr: 'https://images.ctfassets.net/lujecijeulpb/3DMFlFVTwnLeVdLiVLlEKx/e4b1c3361786c0a10d58601b301d15af/CardLayout.png',
      imgUr: '/CardLayoutThumbnail.png',
      svg:   
      <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 text-blue-600 "fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
      </svg>  
    },
    {
    name: 'Table',
        keyword: 'table',
        // imgUr: 'https://images.ctfassets.net/lujecijeulpb/1mGRpEWj2blDjxt6EQt7YT/cf4f28a6599e7387f14f53597110fad8/TableLayout.png',
        imgUr: '/TableLayoutThumbnail.png',
        svg: <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd" />
        </svg>  
    },
    {
    name: 'Emphasized',
    keyword: 'emphesized',
    // imgUr: 'https://images.ctfassets.net/lujecijeulpb/4x0o47c8kybsDcJIfa0F26/7b1066b354a8c7cf305b69aae57f1490/EmphasizedLayout.png',
        imgUr: '/EmphasizedThumbnail.png',
        svg: <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 text-blue-600" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
        </svg>
    },

     
  ]


  function getTemplates(specs) {

    const layoutTemplates = layouts;

    if (specs) {

    layouts.forEach(layout => {

         const template = specs.find( spec => spec.displayName.toLowerCase().includes(layout.keyword));

         if (template) {
             layout.templateId = template.id;
         } else {
            layout.templateId = null;
         }
    })
    }

    return layoutTemplates;

}

export default function PricingLayoutControl({specs, currentTemplateId, changeHandler}) {
 
    const [currentIndex, setIndex] = useState(-1);
 
    const templates = getTemplates(specs);

    const currentTemplateIndex = currentTemplateId ? templates.findIndex(t => t.templateId === currentTemplateId) : -1;

    return ( 
        <> 
           <div className="bg-white ">
               
            <div className="mx-auto pt-6 pb-12 px-4 w-full  ">
                <div className="space-y-12">        
                <ul
                    role="list"
                    className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
                >
                    {templates.map((template, index) => (
                    <li key={template.name}>
                        <div className="space-y-2 flex justify-center">
                        <div
                            onClick={() => { setIndex(index); changeHandler(template.templateId) }} 
                            className={`aspect-w-3 aspect-h-2 object-cover shadow-lg rounded-lg pt-5 border border-gray-100 ${currentTemplateIndex === index ? "bg-gray-300" : "hover:bg-gray-100"}`}>
                            <img className="w-full  " src={template.imgUr}/>
                            {/* {template.svg} */}
                            <div className="p-4 text-xl "> 
                            {template.name}
                            </div>
                        </div>
                        </div>
                        
                    </li>
                    ))}
                </ul>
                </div>
            </div>
            </div>
            
        </>
    )
}
  