import React, { Component, useEffect } from "react";   
import { connect } from 'react-redux' 
import { fetchWorkspace, verifySubscriptionF, removeWorkspaceUser } from "../actions/userActions";
import TabControl from "../controls/tabControl"  
import AddUserModalControl from "../controls/addUserModalControl"  
import { ROLES } from "../constants";


export const TABS = {
  PROFILE : 'Profile',
  WORKSPACE : 'Workspace',
  BILLING : 'Billing'
};

const getPlanCaption =  (plan) => {
  if (plan === 'premium') {
    return 'Premium'
  } else if (plan === 'standard') {
    return 'Standard'
  }

  return 'Free'
}

const mapProductStateToProps = state => {
 
  if (!state.user || !state.user.data) {
    return;
  }

  const workspaceUsers = state.user.workspace ? state.user.workspace.workspaceUsers.filter(wu => wu.active) : [];
  const email =  state.user.data.email;
  const workspaceUser = workspaceUsers.find(wU => wU.email === email);
  const isAdmin = workspaceUser ? workspaceUser.role === ROLES.ADMIN : false;
  const currentPlan = state.user.workspace ? getPlanCaption(state.user.workspace.subscriptionPlan) : 'Free';

  return  {
    tenantId: state.user.data.tenantId,
    email,
    isAdmin,
    currentPlan,
    userId: state.user.data.id,
    workspaceUsers 
  }
}

export  class ProfilePage extends Component {
  constructor(props) {
    super(props);

    const tabId = this.props.match.params.tabId ? this.props.match.params.tabId : '';
    const currentTab = tabId.toLowerCase() === TABS.BILLING.toLowerCase() ? TABS.BILLING : 
                          (tabId.toLowerCase() === TABS.WORKSPACE.toLowerCase() ? TABS.WORKSPACE :TABS.PROFILE);
    
    this.state = {
      currentTab,
      openModal: false
    };
 
  }

  async componentDidMount() {

    // load the Google SDK
    // fetchWorkspace();
    verifySubscriptionF(); // this will fetch workspace
    
  }

  render() {


    const profileButton = <>
                  <button
                    type="button"
                    onClick={()=>{this.props.history.push('/profile/edit')} }
                    className="inline-flex items-center mt-6 justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  >Edit</button>
                  </>

    const profileBody = <>
                          <div className="mt-5 border-t border-gray-200">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500">Email</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                                <div className="flex  justify-start   text-center max-w-3xl "> 
                                  {this.props.email}
                                </div>                 
                              </dd>
                            </div>
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500">Workspace Id</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                                <div className="flex  justify-start   text-center max-w-3xl "> 
                                  {this.props.tenantId}
                                </div>                 
                              </dd>
                            </div> 
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500">User Id</dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <div className="flex  justify-start   text-center max-w-3xl ">
                                {this.props.userId}
                              </div>
                              </dd>
                            </div> 
                          </dl>
                        </div>
                      </>

    const workspaceButton =  
               <> {this.props.isAdmin ? 
                  <button
                      type="button"
                      onClick={()=>{this.setState({openModal: true})} }
                      className="inline-flex items-center mt-6 justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  >Add User</button> 
                  : 
                  <button
                    type="button"
                    onClick={()=> alert('You are not an admin!')  }
                    className="inline-flex items-center mt-6 justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                    >Add User</button> 
                
                }</>

    const workspaceBody = 
      <> <div className="mt-5 py-6 px-2 border-t border-gray-200">
     { this.props.workspaceUsers.map((user) => ( 
            <div className="flex  justify-between py-2 text-sm text-gray-700  text-center  "> 
              <span>{user.email} ({user.role})</span> 
              { this.props.isAdmin && (
                user.email !== this.props.email ?
                  <a className="text-red-700" onClick={() => removeWorkspaceUser(user.email) }>Delete</a>
                  :
                  
                  <a className="text-gray-700" onClick={()=> alert("Cannot delete yourself!")} >Delete</a>
                  )
              }
            </div>         
          ))}
    </div>
    <AddUserModalControl open={this.state.openModal} closeModalHandler={ () => this.setState({openModal: false})}/>
    </>

    const billingButton =  
      <> {this.props.currentPlan === 'Free' ? 
        <button
            type="button"
            onClick={()=> window.location.href='/checkout'  }
            className="inline-flex items-center mt-6 justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
        >Subscribe</button> 
        : 
        <button
          type="button"
          onClick={()=> window.location.href=`https://${process.env.REACT_APP_CHARGEBEE_SITE}.chargebeeportal.com`  }
          // onClick={()=> window.open('https://pricingsaas-test.chargebeeportal.com', '_blank') }
          className="inline-flex items-center mt-6 justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          >Modify Subsctiption</button> 
      
      }</>

    const billingBody = 
    <> 
       <div className="mt-5 border-t border-gray-200">
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-500">Current Plan</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

            <div className="flex  justify-start   text-center max-w-3xl "> 
              {this.props.currentPlan}
            </div>                 
          </dd>
        </div>
        </dl>
      </div>

    </>
      
                        
    return (
    <>        
    <div className="max-w-7xl bg-white m-auto p-10 border rounded-md ">    
        <div className="flex justify-between	">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Settings </h3>         
        </div>              
        <div>
          <div className="flex justify-between	"> 
           <div className="mt-5"><TabControl tabs={[{name: TABS.PROFILE}, {name: TABS.WORKSPACE}, {name: TABS.BILLING}]} currentTab={this.state.currentTab} changeHandler={(tab) => this.setState({currentTab: tab})}/></div>
           {/* Edit */}
           
        
        {this.state.currentTab === TABS.PROFILE ? profileButton : (this.state.currentTab === TABS.WORKSPACE ? workspaceButton : billingButton) }
       
        

           {/* <div className="mt-6 h-10 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">          
                <a
                  type="button"
                  href={'/profile/edit'}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                Edit
              </a>
            </div> */}
          </div>
        </div>

        {this.state.currentTab === TABS.PROFILE ? profileBody : (this.state.currentTab === TABS.WORKSPACE ? workspaceBody : billingBody) }
      </div>
      </>
    ) 
  }


  renderSettingsTab() {
    
  }
}


export default connect(
  mapProductStateToProps 
)(ProfilePage)
