import { API } from "aws-amplify";
import * as constants from '../constants';
import { store } from '../store'; 
import { createPlan } from '../libs/factory'  
import { HandleApiError, prepObjectForApiCall } from "../libs/apiHelper"; 
import * as pricebookSelector from '../libs/pricebookSelector'

export function editPlanField(name, value) {
      return store.dispatch({
      type: constants.EDIT_PLAN_FIELD,
      name,
      value
    });
}

export function showProductSelector(show, productSelector=-1) {
      return store.dispatch({
      type: constants.SHOW_PRODUCT_SELECTOR,
      show,
      productSelector
    });
}

export function showProductConfigurator(show, product=null, addon=false, addonChargeId) { 
  return store.dispatch((dispatch, getState) => {

    if (!show) {

      dispatch( {
        type: constants.SHOW_PRODUCT_CONFIGURATOR,
        show
         
      });
    } else {

      const plan = getState().planEdit.plan;
      const addonCharge = plan.addonCharges.find(addon => addon.id === addonChargeId);
      const addonChargeDiscount = addonCharge ? plan.discounts.find(discount => discount.chargeId === addonCharge.id) : null;

      console.log(addonChargeId);
  
      // const addonCharge = product ? pricebookSelector.getPoductAddonCharge(plan, product.id) : {};
      // const addonChargeDiscount = product ? pricebookSelector.getPoductAddonChargeDiscount(plan, addonCharge.id) : {};
      if (addonCharge) { addonCharge.active = addon; }
      // addonChargeDiscount.active = addon; 
  
      dispatch( {
          type: constants.SHOW_PRODUCT_CONFIGURATOR,
          show,
          product,
          addonCharge,
          addonChargeDiscount
        });
     
    }
  });
}

export function editChargeField(chargeId, name, value) {

    return store.dispatch({
      type: constants.EDIT_CHARGE_FIELD,
      chargeId,
      name,
      value
    });
}

export function editValueChargeField(chargeId, name, value) {

    let numericValue = value === "unlimited" ? -1 : value;
 
    return store.dispatch({
      type: constants.EDIT_VALUECHARGE_FIELD,
      chargeId,
      name,
      value : numericValue
    });
} 


export function editPlanValueChargeField(name, value) {
 
  return store.dispatch({
    type: constants.EDIT_PLAN_VALUECHARGE_FIELD, 
    name,
    value 
  });
} 


export function editPlanValueChargeBandField(index, name, value) {
 
  return store.dispatch({
    type: constants.EDIT_PLAN_VALUECHARGE_BAND_FIELD, 
    index,
    name,
    value 
  });
} 



export function addPlanValueChargeBandField() {
 
  const bandPrice = { upperLimit: 100, price: 5.99 };
  return store.dispatch({
    type: constants.ADD_PLAN_VALUECHARGE_BAND_FIELD,
    bandPrice

  });
}

export function removePlanValueChargeBandField(index) {
  return store.dispatch({
    type: constants.REMOVE_PLAN_VALUECHARGE_BAND_FIELD ,
    index

  });
}



export function editAddonField(addonId, name, value) {

    return store.dispatch({
      type: constants.EDIT_ADDON_FIELD,
      addonId,
      name,
      value
    });
}

export function editChargeDiscountField(discountId, name, value) {
  console.log("editChargeDiscountField");
    return store.dispatch({
      type: constants.EDIT_CHARGE_DISCOUNT_FIELD,
      discountId,
      name,
      value
    });
}

export function toggleProductFeature(plan, featureId, value) {
    return store.dispatch({
      type: constants.TOGGLE_PRODUCT_FEATURE,
      plan,
      featureId,
      value
    });
}


export function selectProductMultiOption(multiOptionId, selectIndex, charge) {
  return store.dispatch({
    type: constants.SELECT_PRODUCT_MULTIOPTION,
    multiOptionId,
    selectIndex,
    charge
  });
}




export function toggleChargeFeature(charge, featureId, value) {
    return store.dispatch({
      type: constants.TOGGLE_CHARGE_FEATURE,
      charge,
      featureId,
      value
    });
}

export function editPlanAddonField(productId, name, value, chargeId) {
  return store.dispatch({
    type: constants.EDIT_PLAN_ADDON_FIELD,
    productId,
    chargeId,
    name,
    value
  });
}

export function editPlanProductField(productId, name, value) {
  return store.dispatch({
    type: constants.EDIT_PLAN_PRODUCT_FIELD,
    productId,
    name,
    value
  });
}

export function setupDefaultPlanEdit(active=true) {
  
  return store.dispatch((dispatch, getState) => { 
 
    const pricebook = getState().pricebook.json;

    if (!pricebook) { return; }

    const plans = active ? pricebook.plans.filter(p => p.active) : pricebook.plans;

    if (plans.length > 0) {
      setupPlanEdit(plans[0].id);
    } else {
      dispatch({
        type: constants.CLEAR_EDIT
      });
  
    }

  });

}

export function setupPlanEdit(planId) {

  console.log('setupPlanEdit', planId);

  return store.dispatch((dispatch, getState) => {
    const pricebook = getState().pricebook.json;
    let products = [...getState().pricebook.json.products];
    let plan = null;
    let newPlan = planId === 'new';

    if (newPlan) {
      plan = createPlan();
    } else {
      plan = getState().pricebook.json.plans.find(p => p.id === planId);
    }

    // this function will make sure we create proper defaults given the latest set of products
    plan = pricebookSelector.prepPlanForEdit(JSON.parse(JSON.stringify(plan)),products, pricebook);

    // console.log('setupPlanEdit plan');
    // console.log(plan);
    // console.log('setupPlanEdit products');
    // console.log(products);

    // check if tieredPricingEnabled should be on

    if (plan.valueCharges) {
      plan.valueCharges.forEach(vc => {
        vc.tieredPricingEnabled = vc.bandPrices && vc.bandPrices.length > 0; 
      })
    }

    dispatch({
      type: constants.SET_UP_EDIT_PLAN,
      plan,
      newAndUnsaved : newPlan,
      products
    });

  });
}

export function savePlanEdit(callback) {

  return store.dispatch((dispatch, getState) => {
    let plan = getState().planEdit.plan;
    let pricebookId = getState().pricebook.json.id;
    let planData = prepObjectForApiCall(plan);

    // todo: add code to clean up tiered pricing 

    dispatch({
      planId: planData.id,
      data: planData,
      type: constants.REQUEST_PLAN_UPDATE
    });

    let url = "/plans/update/" + pricebookId + "/" +planData.id;
 
    let myInit = {
        body: planData
    } 
    
    API.put("plans", url, myInit).then(
      response => {
          // dispatch({ planId: planData.id, plan: planData, type: constants.RECEIVE_PLAN_SAVE })
          dispatch({ planId: planData.id, plan: response["data"], type: constants.RECEIVE_PLAN_SAVE })
          callback();
        },
        error => {
          HandleApiError(error);
          dispatch({ planId: planData.id, data: error, type: constants.RECEIVE_PLAN_SAVE_ERROR });
        }
      );
    });
}
